import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '@/rpc_client/service_components/Login.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/places',
    name: 'Places',
    component: () => import('@/views/Places.vue')
  },
  {
    path: '/organizations',
    name: 'Organizations',
    component: () => import('@/views/Organizations.vue')
  },
  {
    path: '/actions',
    name: 'Actions',
    component: () => import('@/views/Actions.vue')
  },
  {
    path: '/materials',
    name: 'Materials',
    component: () => import('@/views/Materials.vue')
  },
  {
    path: '/machines',
    name: 'Machines',
    component: () => import('@/views/Machines.vue')
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('@/views/Orders.vue')
  },
  {
    path: '/labels',
    name: 'Labels',
    component: () => import('@/views/Labels.vue')
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () => import('@/views/Statistics.vue')
  },
  {
    path: '/options',
    name: 'Options',
    component: () => import('@/rpc_client/service_components/Options.vue')
  },
  {
    path: '/logs',
    name: 'Logs',
    component: () => import('@/rpc_client/service_components/Logs.vue')
  },
  {
    path: '/crawler',
    name: 'Crawler',
    component: () => import('@/views/Crawler.vue')
  },
  {
    path: '/maps-scrapper',
    name: 'MapsScrapper',
    component: () => import('@/views/MapsScrapper.vue')
  },
  {
    path: '/website',
    name: 'Website',
    component: () => import('@/views/Website.vue')
  },
  {
    path: '/departments',
    name: 'Departments',
    component: () => import('@/views/Departments.vue')
  },
  {
    path: '/api-test',
    name: 'ApiTests',
    component: () => import('@/views/ApiTest.vue')
  },
  {
    path: '/approximate-prices',
    name: 'ApproximatePrices',
    component: () => import('@/views/ApproximatePrices.vue')
  } 
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

export default router
