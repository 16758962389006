<template>
  <template v-if="!store.state.auth.isAuthenticated">
    <input style="margin-top: 20px;" v-model="phoneInput" placeholder="Телефон" @keypress.enter="sendPass((changeCommunicationType ? communicationType.SMS : communicationType.WhatsApp))">
    <span v-if="phoneError" style="color: red;">Некорректный номер телефона</span>
    <button v-show="!changeCommunicationType && !passSent" @click="sendPass(communicationType.WhatsApp)">Отправить код по WhatsApp</button>
    <template v-if="changeCommunicationType">
      <!-- <button v-show="!passSent" @click="sendPass(client_driven_msg.CommunicationType.Telegram)">Отправить код в Telegram</button> -->
      <button v-show="!passSent" @click="sendPass(communicationType.SMS)">Отправить код по SMS</button>
    </template>
    <Checkbox v-show="!passSent" v-model:checked="changeCommunicationType">У меня нет WhatsApp</Checkbox>
    <span v-show="passSent">Код отправлен. Еще раз отправить можно будет в {{ sendPassUnlockTime.toLocaleTimeString() }}</span>
    <input v-model="passInput" ref="codeInput" placeholder="Код" @keypress.enter="login()">
    <button @click="login()">Войти</button>
    <span v-show="wrongPass" style="color: red;">Неверный код</span>
    <div class="login-logo">
      <img src="../../global_assets/img/nav-logo.svg">
    </div>
  </template>
</template>

<script setup lang="ts">
import { ref, inject } from 'vue';

import { useStore } from '@/rpc_client/useStore';
import { RpcClient, returnFalse } from '@/rpc_client/apiAbstract';

import { extractPhoneRu } from '@/rpc_client/misc';
import { client_driven_msg } from '@/rpc_client/messages';
import Checkbox from '@/rpc_client/service_components/Checkbox.vue';
import { useRouter } from 'vue-router';

const router = useRouter()

const rpcClient = inject('RpcClient') as RpcClient;
const communicationType = client_driven_msg.CommunicationType;

const store = useStore();
const phoneInput = ref('');
if (process.env.NODE_ENV !== 'production') {
  phoneInput.value = store.state.custom.appName === 'marketplace' ? '79999999992' : '79999999990'; 
}
const passInput = ref('');

const wrongPass = ref(false);
const passSent = ref(false);
const codeInput = ref<HTMLInputElement | null>(null);
const sendPassUnlockTimeout = 30000; // 30 secs timeout
const sendPassUnlockTime = ref(new Date());

const changeCommunicationType = ref(false);

const phoneError = ref(false);
function sendPass (communicationType: client_driven_msg.CommunicationType) {
  phoneError.value = false;
  let phone = extractPhoneRu(phoneInput.value);
  if (phone.length === 0) { phoneError.value = true; return }
  rpcClient.auth(phone, communicationType);
  passSent.value = true;
  codeInput.value?.focus();
  wrongPass.value = false;
  sendPassUnlockTime.value = new Date(new Date().getTime() + sendPassUnlockTimeout);
  setTimeout(() => { passSent.value = false }, sendPassUnlockTimeout)
}

async function login () {
  if (!await rpcClient.loginByCode(passInput.value).catch(returnFalse)) wrongPass.value = true;

  if (store.state.custom.appName === 'marketplace') {
    router.push({ path: '/outgoing-orders' });
  } else {
    router.push({ path: '/orders' });
  }
}
</script>
