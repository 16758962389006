/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

import Long from 'long';

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

// long dep loosing in protobuf fix (webpack somehow doesnt bundle it normally)
$util.Long = Long;
$protobuf.configure();

export const object_msg = $root.object_msg = (() => {

    const object_msg = {};

    object_msg.ActionType = (function() {

        function ActionType(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        ActionType.prototype.url_name = "";
        ActionType.prototype.website_svg_icon = "";
        ActionType.prototype.website_main_page_position = 0;
        ActionType.prototype.action = "";
        ActionType.prototype.website_contacts_description = "";
        ActionType.prototype.website_main_page_enabled = false;
        ActionType.prototype.website_main_page_description = "";
        ActionType.prototype.website_action_page_enabled = false;

        ActionType.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(10).string(message.action);
            if (message.website_main_page_enabled != null && Object.hasOwnProperty.call(message, "website_main_page_enabled"))
                writer.uint32(16).bool(message.website_main_page_enabled);
            if (message.website_contacts_description != null && Object.hasOwnProperty.call(message, "website_contacts_description"))
                writer.uint32(26).string(message.website_contacts_description);
            if (message.url_name != null && Object.hasOwnProperty.call(message, "url_name"))
                writer.uint32(34).string(message.url_name);
            if (message.website_action_page_enabled != null && Object.hasOwnProperty.call(message, "website_action_page_enabled"))
                writer.uint32(40).bool(message.website_action_page_enabled);
            if (message.website_main_page_position != null && Object.hasOwnProperty.call(message, "website_main_page_position"))
                writer.uint32(48).int32(message.website_main_page_position);
            if (message.website_svg_icon != null && Object.hasOwnProperty.call(message, "website_svg_icon"))
                writer.uint32(58).string(message.website_svg_icon);
            if (message.website_main_page_description != null && Object.hasOwnProperty.call(message, "website_main_page_description"))
                writer.uint32(66).string(message.website_main_page_description);
            return writer;
        };

        ActionType.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.ActionType();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.url_name = reader.string();
                        break;
                    }
                case 7: {
                        message.website_svg_icon = reader.string();
                        break;
                    }
                case 6: {
                        message.website_main_page_position = reader.int32();
                        break;
                    }
                case 1: {
                        message.action = reader.string();
                        break;
                    }
                case 3: {
                        message.website_contacts_description = reader.string();
                        break;
                    }
                case 2: {
                        message.website_main_page_enabled = reader.bool();
                        break;
                    }
                case 8: {
                        message.website_main_page_description = reader.string();
                        break;
                    }
                case 5: {
                        message.website_action_page_enabled = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ActionType.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.ActionType)
                return object;
            let message = new $root.object_msg.ActionType();
            if (object.url_name != null)
                message.url_name = String(object.url_name);
            if (object.website_svg_icon != null)
                message.website_svg_icon = String(object.website_svg_icon);
            if (object.website_main_page_position != null)
                message.website_main_page_position = object.website_main_page_position | 0;
            if (object.action != null)
                message.action = String(object.action);
            if (object.website_contacts_description != null)
                message.website_contacts_description = String(object.website_contacts_description);
            if (object.website_main_page_enabled != null)
                message.website_main_page_enabled = Boolean(object.website_main_page_enabled);
            if (object.website_main_page_description != null)
                message.website_main_page_description = String(object.website_main_page_description);
            if (object.website_action_page_enabled != null)
                message.website_action_page_enabled = Boolean(object.website_action_page_enabled);
            return message;
        };

        ActionType.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.action = "";
                object.website_main_page_enabled = false;
                object.website_contacts_description = "";
                object.url_name = "";
                object.website_action_page_enabled = false;
                object.website_main_page_position = 0;
                object.website_svg_icon = "";
                object.website_main_page_description = "";
            }
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = message.action;
            if (message.website_main_page_enabled != null && message.hasOwnProperty("website_main_page_enabled"))
                object.website_main_page_enabled = message.website_main_page_enabled;
            if (message.website_contacts_description != null && message.hasOwnProperty("website_contacts_description"))
                object.website_contacts_description = message.website_contacts_description;
            if (message.url_name != null && message.hasOwnProperty("url_name"))
                object.url_name = message.url_name;
            if (message.website_action_page_enabled != null && message.hasOwnProperty("website_action_page_enabled"))
                object.website_action_page_enabled = message.website_action_page_enabled;
            if (message.website_main_page_position != null && message.hasOwnProperty("website_main_page_position"))
                object.website_main_page_position = message.website_main_page_position;
            if (message.website_svg_icon != null && message.hasOwnProperty("website_svg_icon"))
                object.website_svg_icon = message.website_svg_icon;
            if (message.website_main_page_description != null && message.hasOwnProperty("website_main_page_description"))
                object.website_main_page_description = message.website_main_page_description;
            return object;
        };

        ActionType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ActionType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.ActionType";
        };

        return ActionType;
    })();

    object_msg.ActionTypeArray = (function() {

        function ActionTypeArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        ActionTypeArray.prototype.array = $util.emptyArray;

        ActionTypeArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.ActionType.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        ActionTypeArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.ActionTypeArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.ActionType.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ActionTypeArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.ActionTypeArray)
                return object;
            let message = new $root.object_msg.ActionTypeArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.ActionTypeArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.ActionTypeArray.array: object expected");
                    message.array[i] = $root.object_msg.ActionType.fromObject(object.array[i]);
                }
            }
            return message;
        };

        ActionTypeArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.ActionType.toObject(message.array[j], options);
            }
            return object;
        };

        ActionTypeArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ActionTypeArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.ActionTypeArray";
        };

        return ActionTypeArray;
    })();

    object_msg.WebsiteAction = (function() {

        function WebsiteAction(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        WebsiteAction.prototype.url_name = "";
        WebsiteAction.prototype.website_svg_icon = "";
        WebsiteAction.prototype.website_main_page_position = 0;
        WebsiteAction.prototype.action = "";
        WebsiteAction.prototype.website_contacts_description = "";
        WebsiteAction.prototype.website_main_page_enabled = false;
        WebsiteAction.prototype.website_main_page_description = "";
        WebsiteAction.prototype.website_action_page_enabled = false;

        WebsiteAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(10).string(message.action);
            if (message.website_main_page_enabled != null && Object.hasOwnProperty.call(message, "website_main_page_enabled"))
                writer.uint32(16).bool(message.website_main_page_enabled);
            if (message.website_contacts_description != null && Object.hasOwnProperty.call(message, "website_contacts_description"))
                writer.uint32(26).string(message.website_contacts_description);
            if (message.url_name != null && Object.hasOwnProperty.call(message, "url_name"))
                writer.uint32(34).string(message.url_name);
            if (message.website_action_page_enabled != null && Object.hasOwnProperty.call(message, "website_action_page_enabled"))
                writer.uint32(40).bool(message.website_action_page_enabled);
            if (message.website_main_page_position != null && Object.hasOwnProperty.call(message, "website_main_page_position"))
                writer.uint32(48).int32(message.website_main_page_position);
            if (message.website_svg_icon != null && Object.hasOwnProperty.call(message, "website_svg_icon"))
                writer.uint32(58).string(message.website_svg_icon);
            if (message.website_main_page_description != null && Object.hasOwnProperty.call(message, "website_main_page_description"))
                writer.uint32(66).string(message.website_main_page_description);
            return writer;
        };

        WebsiteAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.WebsiteAction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.url_name = reader.string();
                        break;
                    }
                case 7: {
                        message.website_svg_icon = reader.string();
                        break;
                    }
                case 6: {
                        message.website_main_page_position = reader.int32();
                        break;
                    }
                case 1: {
                        message.action = reader.string();
                        break;
                    }
                case 3: {
                        message.website_contacts_description = reader.string();
                        break;
                    }
                case 2: {
                        message.website_main_page_enabled = reader.bool();
                        break;
                    }
                case 8: {
                        message.website_main_page_description = reader.string();
                        break;
                    }
                case 5: {
                        message.website_action_page_enabled = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        WebsiteAction.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.WebsiteAction)
                return object;
            let message = new $root.object_msg.WebsiteAction();
            if (object.url_name != null)
                message.url_name = String(object.url_name);
            if (object.website_svg_icon != null)
                message.website_svg_icon = String(object.website_svg_icon);
            if (object.website_main_page_position != null)
                message.website_main_page_position = object.website_main_page_position | 0;
            if (object.action != null)
                message.action = String(object.action);
            if (object.website_contacts_description != null)
                message.website_contacts_description = String(object.website_contacts_description);
            if (object.website_main_page_enabled != null)
                message.website_main_page_enabled = Boolean(object.website_main_page_enabled);
            if (object.website_main_page_description != null)
                message.website_main_page_description = String(object.website_main_page_description);
            if (object.website_action_page_enabled != null)
                message.website_action_page_enabled = Boolean(object.website_action_page_enabled);
            return message;
        };

        WebsiteAction.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.action = "";
                object.website_main_page_enabled = false;
                object.website_contacts_description = "";
                object.url_name = "";
                object.website_action_page_enabled = false;
                object.website_main_page_position = 0;
                object.website_svg_icon = "";
                object.website_main_page_description = "";
            }
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = message.action;
            if (message.website_main_page_enabled != null && message.hasOwnProperty("website_main_page_enabled"))
                object.website_main_page_enabled = message.website_main_page_enabled;
            if (message.website_contacts_description != null && message.hasOwnProperty("website_contacts_description"))
                object.website_contacts_description = message.website_contacts_description;
            if (message.url_name != null && message.hasOwnProperty("url_name"))
                object.url_name = message.url_name;
            if (message.website_action_page_enabled != null && message.hasOwnProperty("website_action_page_enabled"))
                object.website_action_page_enabled = message.website_action_page_enabled;
            if (message.website_main_page_position != null && message.hasOwnProperty("website_main_page_position"))
                object.website_main_page_position = message.website_main_page_position;
            if (message.website_svg_icon != null && message.hasOwnProperty("website_svg_icon"))
                object.website_svg_icon = message.website_svg_icon;
            if (message.website_main_page_description != null && message.hasOwnProperty("website_main_page_description"))
                object.website_main_page_description = message.website_main_page_description;
            return object;
        };

        WebsiteAction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        WebsiteAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.WebsiteAction";
        };

        return WebsiteAction;
    })();

    object_msg.WebsiteActionArray = (function() {

        function WebsiteActionArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        WebsiteActionArray.prototype.array = $util.emptyArray;

        WebsiteActionArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.WebsiteAction.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        WebsiteActionArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.WebsiteActionArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.WebsiteAction.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        WebsiteActionArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.WebsiteActionArray)
                return object;
            let message = new $root.object_msg.WebsiteActionArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.WebsiteActionArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.WebsiteActionArray.array: object expected");
                    message.array[i] = $root.object_msg.WebsiteAction.fromObject(object.array[i]);
                }
            }
            return message;
        };

        WebsiteActionArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.WebsiteAction.toObject(message.array[j], options);
            }
            return object;
        };

        WebsiteActionArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        WebsiteActionArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.WebsiteActionArray";
        };

        return WebsiteActionArray;
    })();

    object_msg.ActionMaterial = (function() {

        function ActionMaterial(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        ActionMaterial.prototype.action = "";
        ActionMaterial.prototype.material = "";

        ActionMaterial.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(10).string(message.action);
            if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                writer.uint32(18).string(message.material);
            return writer;
        };

        ActionMaterial.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.ActionMaterial();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.action = reader.string();
                        break;
                    }
                case 2: {
                        message.material = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ActionMaterial.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.ActionMaterial)
                return object;
            let message = new $root.object_msg.ActionMaterial();
            if (object.action != null)
                message.action = String(object.action);
            if (object.material != null)
                message.material = String(object.material);
            return message;
        };

        ActionMaterial.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.action = "";
                object.material = "";
            }
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = message.action;
            if (message.material != null && message.hasOwnProperty("material"))
                object.material = message.material;
            return object;
        };

        ActionMaterial.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ActionMaterial.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.ActionMaterial";
        };

        return ActionMaterial;
    })();

    object_msg.ActionMaterialArray = (function() {

        function ActionMaterialArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        ActionMaterialArray.prototype.array = $util.emptyArray;

        ActionMaterialArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.ActionMaterial.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        ActionMaterialArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.ActionMaterialArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.ActionMaterial.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ActionMaterialArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.ActionMaterialArray)
                return object;
            let message = new $root.object_msg.ActionMaterialArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.ActionMaterialArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.ActionMaterialArray.array: object expected");
                    message.array[i] = $root.object_msg.ActionMaterial.fromObject(object.array[i]);
                }
            }
            return message;
        };

        ActionMaterialArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.ActionMaterial.toObject(message.array[j], options);
            }
            return object;
        };

        ActionMaterialArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ActionMaterialArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.ActionMaterialArray";
        };

        return ActionMaterialArray;
    })();

    object_msg.MachineType = (function() {

        function MachineType(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        MachineType.prototype.model = "";

        MachineType.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.model != null && Object.hasOwnProperty.call(message, "model"))
                writer.uint32(10).string(message.model);
            return writer;
        };

        MachineType.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.MachineType();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.model = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        MachineType.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.MachineType)
                return object;
            let message = new $root.object_msg.MachineType();
            if (object.model != null)
                message.model = String(object.model);
            return message;
        };

        MachineType.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.model = "";
            if (message.model != null && message.hasOwnProperty("model"))
                object.model = message.model;
            return object;
        };

        MachineType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        MachineType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.MachineType";
        };

        return MachineType;
    })();

    object_msg.MachineTypeArray = (function() {

        function MachineTypeArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        MachineTypeArray.prototype.array = $util.emptyArray;

        MachineTypeArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.MachineType.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        MachineTypeArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.MachineTypeArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.MachineType.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        MachineTypeArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.MachineTypeArray)
                return object;
            let message = new $root.object_msg.MachineTypeArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.MachineTypeArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.MachineTypeArray.array: object expected");
                    message.array[i] = $root.object_msg.MachineType.fromObject(object.array[i]);
                }
            }
            return message;
        };

        MachineTypeArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.MachineType.toObject(message.array[j], options);
            }
            return object;
        };

        MachineTypeArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        MachineTypeArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.MachineTypeArray";
        };

        return MachineTypeArray;
    })();

    object_msg.MaterialType = (function() {

        function MaterialType(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        MaterialType.prototype.material = "";

        MaterialType.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                writer.uint32(10).string(message.material);
            return writer;
        };

        MaterialType.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.MaterialType();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.material = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        MaterialType.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.MaterialType)
                return object;
            let message = new $root.object_msg.MaterialType();
            if (object.material != null)
                message.material = String(object.material);
            return message;
        };

        MaterialType.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.material = "";
            if (message.material != null && message.hasOwnProperty("material"))
                object.material = message.material;
            return object;
        };

        MaterialType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        MaterialType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.MaterialType";
        };

        return MaterialType;
    })();

    object_msg.MaterialTypeArray = (function() {

        function MaterialTypeArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        MaterialTypeArray.prototype.array = $util.emptyArray;

        MaterialTypeArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.MaterialType.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        MaterialTypeArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.MaterialTypeArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.MaterialType.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        MaterialTypeArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.MaterialTypeArray)
                return object;
            let message = new $root.object_msg.MaterialTypeArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.MaterialTypeArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.MaterialTypeArray.array: object expected");
                    message.array[i] = $root.object_msg.MaterialType.fromObject(object.array[i]);
                }
            }
            return message;
        };

        MaterialTypeArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.MaterialType.toObject(message.array[j], options);
            }
            return object;
        };

        MaterialTypeArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        MaterialTypeArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.MaterialTypeArray";
        };

        return MaterialTypeArray;
    })();

    object_msg.LabelType = (function() {

        function LabelType(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LabelType.prototype.key = "";
        LabelType.prototype.value = "";

        LabelType.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(10).string(message.key);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(18).string(message.value);
            return writer;
        };

        LabelType.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LabelType();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                case 2: {
                        message.value = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LabelType.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LabelType)
                return object;
            let message = new $root.object_msg.LabelType();
            if (object.key != null)
                message.key = String(object.key);
            if (object.value != null)
                message.value = String(object.value);
            return message;
        };

        LabelType.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.value = "";
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            return object;
        };

        LabelType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LabelType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LabelType";
        };

        return LabelType;
    })();

    object_msg.LabelTypeArray = (function() {

        function LabelTypeArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LabelTypeArray.prototype.array = $util.emptyArray;

        LabelTypeArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.LabelType.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        LabelTypeArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LabelTypeArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.LabelType.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LabelTypeArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LabelTypeArray)
                return object;
            let message = new $root.object_msg.LabelTypeArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.LabelTypeArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.LabelTypeArray.array: object expected");
                    message.array[i] = $root.object_msg.LabelType.fromObject(object.array[i]);
                }
            }
            return message;
        };

        LabelTypeArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.LabelType.toObject(message.array[j], options);
            }
            return object;
        };

        LabelTypeArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LabelTypeArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LabelTypeArray";
        };

        return LabelTypeArray;
    })();

    object_msg.Place = (function() {

        function Place(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        Place.prototype.ok_url = "";
        Place.prototype.vk_url = "";
        Place.prototype.yandex_rating = 0;
        Place.prototype.youtube_url = "";
        Place.prototype.latitude = 0;
        Place.prototype.address = "";
        Place.prototype.archive = false;
        Place.prototype.longitude = 0;
        Place.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        Place.prototype.town = "";
        Place.prototype.name = "";
        Place.prototype.url = "";
        Place.prototype.organization_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        Place.prototype.map_url = "";
        Place.prototype.verified = false;
        Place.prototype.rating = 0;
        Place.prototype.creation_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Place.prototype.address_quality = 0;
        Place.prototype.description = "";
        Place.prototype.telegram_id = "";
        Place.prototype.yandex_rating_count = 0;
        Place.prototype.creator_user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        Place.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).uint64(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(18).string(message.name);
            if (message.town != null && Object.hasOwnProperty.call(message, "town"))
                writer.uint32(26).string(message.town);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                writer.uint32(34).string(message.address);
            if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                writer.uint32(45).float(message.latitude);
            if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                writer.uint32(53).float(message.longitude);
            if (message.archive != null && Object.hasOwnProperty.call(message, "archive"))
                writer.uint32(56).bool(message.archive);
            if (message.creation_time != null && Object.hasOwnProperty.call(message, "creation_time"))
                writer.uint32(64).int64(message.creation_time);
            if (message.rating != null && Object.hasOwnProperty.call(message, "rating"))
                writer.uint32(72).int32(message.rating);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(82).string(message.description);
            if (message.address_quality != null && Object.hasOwnProperty.call(message, "address_quality"))
                writer.uint32(88).int32(message.address_quality);
            if (message.organization_id != null && Object.hasOwnProperty.call(message, "organization_id"))
                writer.uint32(96).uint64(message.organization_id);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(106).string(message.url);
            if (message.verified != null && Object.hasOwnProperty.call(message, "verified"))
                writer.uint32(112).bool(message.verified);
            if (message.map_url != null && Object.hasOwnProperty.call(message, "map_url"))
                writer.uint32(122).string(message.map_url);
            if (message.vk_url != null && Object.hasOwnProperty.call(message, "vk_url"))
                writer.uint32(130).string(message.vk_url);
            if (message.ok_url != null && Object.hasOwnProperty.call(message, "ok_url"))
                writer.uint32(138).string(message.ok_url);
            if (message.youtube_url != null && Object.hasOwnProperty.call(message, "youtube_url"))
                writer.uint32(146).string(message.youtube_url);
            if (message.yandex_rating != null && Object.hasOwnProperty.call(message, "yandex_rating"))
                writer.uint32(157).float(message.yandex_rating);
            if (message.yandex_rating_count != null && Object.hasOwnProperty.call(message, "yandex_rating_count"))
                writer.uint32(160).int32(message.yandex_rating_count);
            if (message.telegram_id != null && Object.hasOwnProperty.call(message, "telegram_id"))
                writer.uint32(170).string(message.telegram_id);
            if (message.creator_user_id != null && Object.hasOwnProperty.call(message, "creator_user_id"))
                writer.uint32(176).uint64(message.creator_user_id);
            return writer;
        };

        Place.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.Place();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 17: {
                        message.ok_url = reader.string();
                        break;
                    }
                case 16: {
                        message.vk_url = reader.string();
                        break;
                    }
                case 19: {
                        message.yandex_rating = reader.float();
                        break;
                    }
                case 18: {
                        message.youtube_url = reader.string();
                        break;
                    }
                case 5: {
                        message.latitude = reader.float();
                        break;
                    }
                case 4: {
                        message.address = reader.string();
                        break;
                    }
                case 7: {
                        message.archive = reader.bool();
                        break;
                    }
                case 6: {
                        message.longitude = reader.float();
                        break;
                    }
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.town = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 13: {
                        message.url = reader.string();
                        break;
                    }
                case 12: {
                        message.organization_id = reader.uint64();
                        break;
                    }
                case 15: {
                        message.map_url = reader.string();
                        break;
                    }
                case 14: {
                        message.verified = reader.bool();
                        break;
                    }
                case 9: {
                        message.rating = reader.int32();
                        break;
                    }
                case 8: {
                        message.creation_time = reader.int64();
                        break;
                    }
                case 11: {
                        message.address_quality = reader.int32();
                        break;
                    }
                case 10: {
                        message.description = reader.string();
                        break;
                    }
                case 21: {
                        message.telegram_id = reader.string();
                        break;
                    }
                case 20: {
                        message.yandex_rating_count = reader.int32();
                        break;
                    }
                case 22: {
                        message.creator_user_id = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Place.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.Place)
                return object;
            let message = new $root.object_msg.Place();
            if (object.ok_url != null)
                message.ok_url = String(object.ok_url);
            if (object.vk_url != null)
                message.vk_url = String(object.vk_url);
            if (object.yandex_rating != null)
                message.yandex_rating = Number(object.yandex_rating);
            if (object.youtube_url != null)
                message.youtube_url = String(object.youtube_url);
            if (object.latitude != null)
                message.latitude = Number(object.latitude);
            if (object.address != null)
                message.address = String(object.address);
            if (object.archive != null)
                message.archive = Boolean(object.archive);
            if (object.longitude != null)
                message.longitude = Number(object.longitude);
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.town != null)
                message.town = String(object.town);
            if (object.name != null)
                message.name = String(object.name);
            if (object.url != null)
                message.url = String(object.url);
            if (object.organization_id != null)
                if ($util.Long)
                    (message.organization_id = $util.Long.fromValue(object.organization_id)).unsigned = true;
                else if (typeof object.organization_id === "string")
                    message.organization_id = parseInt(object.organization_id, 10);
                else if (typeof object.organization_id === "number")
                    message.organization_id = object.organization_id;
                else if (typeof object.organization_id === "object")
                    message.organization_id = new $util.LongBits(object.organization_id.low >>> 0, object.organization_id.high >>> 0).toNumber(true);
            if (object.map_url != null)
                message.map_url = String(object.map_url);
            if (object.verified != null)
                message.verified = Boolean(object.verified);
            if (object.rating != null)
                message.rating = object.rating | 0;
            if (object.creation_time != null)
                if ($util.Long)
                    (message.creation_time = $util.Long.fromValue(object.creation_time)).unsigned = false;
                else if (typeof object.creation_time === "string")
                    message.creation_time = parseInt(object.creation_time, 10);
                else if (typeof object.creation_time === "number")
                    message.creation_time = object.creation_time;
                else if (typeof object.creation_time === "object")
                    message.creation_time = new $util.LongBits(object.creation_time.low >>> 0, object.creation_time.high >>> 0).toNumber();
            if (object.address_quality != null)
                message.address_quality = object.address_quality | 0;
            if (object.description != null)
                message.description = String(object.description);
            if (object.telegram_id != null)
                message.telegram_id = String(object.telegram_id);
            if (object.yandex_rating_count != null)
                message.yandex_rating_count = object.yandex_rating_count | 0;
            if (object.creator_user_id != null)
                if ($util.Long)
                    (message.creator_user_id = $util.Long.fromValue(object.creator_user_id)).unsigned = true;
                else if (typeof object.creator_user_id === "string")
                    message.creator_user_id = parseInt(object.creator_user_id, 10);
                else if (typeof object.creator_user_id === "number")
                    message.creator_user_id = object.creator_user_id;
                else if (typeof object.creator_user_id === "object")
                    message.creator_user_id = new $util.LongBits(object.creator_user_id.low >>> 0, object.creator_user_id.high >>> 0).toNumber(true);
            return message;
        };

        Place.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.name = "";
                object.town = "";
                object.address = "";
                object.latitude = 0;
                object.longitude = 0;
                object.archive = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.creation_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.creation_time = options.longs === String ? "0" : 0;
                object.rating = 0;
                object.description = "";
                object.address_quality = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.organization_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.organization_id = options.longs === String ? "0" : 0;
                object.url = "";
                object.verified = false;
                object.map_url = "";
                object.vk_url = "";
                object.ok_url = "";
                object.youtube_url = "";
                object.yandex_rating = 0;
                object.yandex_rating_count = 0;
                object.telegram_id = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.creator_user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.creator_user_id = options.longs === String ? "0" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.town != null && message.hasOwnProperty("town"))
                object.town = message.town;
            if (message.address != null && message.hasOwnProperty("address"))
                object.address = message.address;
            if (message.latitude != null && message.hasOwnProperty("latitude"))
                object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
            if (message.longitude != null && message.hasOwnProperty("longitude"))
                object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
            if (message.archive != null && message.hasOwnProperty("archive"))
                object.archive = message.archive;
            if (message.creation_time != null && message.hasOwnProperty("creation_time"))
                if (typeof message.creation_time === "number")
                    object.creation_time = options.longs === String ? String(message.creation_time) : message.creation_time;
                else
                    object.creation_time = options.longs === String ? $util.Long.prototype.toString.call(message.creation_time) : options.longs === Number ? new $util.LongBits(message.creation_time.low >>> 0, message.creation_time.high >>> 0).toNumber() : message.creation_time;
            if (message.rating != null && message.hasOwnProperty("rating"))
                object.rating = message.rating;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.address_quality != null && message.hasOwnProperty("address_quality"))
                object.address_quality = message.address_quality;
            if (message.organization_id != null && message.hasOwnProperty("organization_id"))
                if (typeof message.organization_id === "number")
                    object.organization_id = options.longs === String ? String(message.organization_id) : message.organization_id;
                else
                    object.organization_id = options.longs === String ? $util.Long.prototype.toString.call(message.organization_id) : options.longs === Number ? new $util.LongBits(message.organization_id.low >>> 0, message.organization_id.high >>> 0).toNumber(true) : message.organization_id;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.verified != null && message.hasOwnProperty("verified"))
                object.verified = message.verified;
            if (message.map_url != null && message.hasOwnProperty("map_url"))
                object.map_url = message.map_url;
            if (message.vk_url != null && message.hasOwnProperty("vk_url"))
                object.vk_url = message.vk_url;
            if (message.ok_url != null && message.hasOwnProperty("ok_url"))
                object.ok_url = message.ok_url;
            if (message.youtube_url != null && message.hasOwnProperty("youtube_url"))
                object.youtube_url = message.youtube_url;
            if (message.yandex_rating != null && message.hasOwnProperty("yandex_rating"))
                object.yandex_rating = options.json && !isFinite(message.yandex_rating) ? String(message.yandex_rating) : message.yandex_rating;
            if (message.yandex_rating_count != null && message.hasOwnProperty("yandex_rating_count"))
                object.yandex_rating_count = message.yandex_rating_count;
            if (message.telegram_id != null && message.hasOwnProperty("telegram_id"))
                object.telegram_id = message.telegram_id;
            if (message.creator_user_id != null && message.hasOwnProperty("creator_user_id"))
                if (typeof message.creator_user_id === "number")
                    object.creator_user_id = options.longs === String ? String(message.creator_user_id) : message.creator_user_id;
                else
                    object.creator_user_id = options.longs === String ? $util.Long.prototype.toString.call(message.creator_user_id) : options.longs === Number ? new $util.LongBits(message.creator_user_id.low >>> 0, message.creator_user_id.high >>> 0).toNumber(true) : message.creator_user_id;
            return object;
        };

        Place.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        Place.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.Place";
        };

        return Place;
    })();

    object_msg.PlaceArray = (function() {

        function PlaceArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlaceArray.prototype.array = $util.emptyArray;

        PlaceArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.Place.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        PlaceArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlaceArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.Place.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlaceArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlaceArray)
                return object;
            let message = new $root.object_msg.PlaceArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.PlaceArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.PlaceArray.array: object expected");
                    message.array[i] = $root.object_msg.Place.fromObject(object.array[i]);
                }
            }
            return message;
        };

        PlaceArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.Place.toObject(message.array[j], options);
            }
            return object;
        };

        PlaceArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlaceArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlaceArray";
        };

        return PlaceArray;
    })();

    object_msg.OrganizationRu = (function() {

        function OrganizationRu(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrganizationRu.prototype.legal_address_quality = 0;
        OrganizationRu.prototype.archive = false;
        OrganizationRu.prototype.verified = false;
        OrganizationRu.prototype.phone = "";
        OrganizationRu.prototype.ogrn = "";
        OrganizationRu.prototype.kpp = "";
        OrganizationRu.prototype.with_nds = false;
        OrganizationRu.prototype.legal_address = "";
        OrganizationRu.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrganizationRu.prototype.inn = "";
        OrganizationRu.prototype.name = "";
        OrganizationRu.prototype.organization_type = "";
        OrganizationRu.prototype.payment_account = "";
        OrganizationRu.prototype.mailing_address_quality = 0;
        OrganizationRu.prototype.mailing_address = "";
        OrganizationRu.prototype.representative_name = "";
        OrganizationRu.prototype.nds_checked = false;
        OrganizationRu.prototype.bank_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrganizationRu.prototype.representative_position = "";
        OrganizationRu.prototype.creation_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        OrganizationRu.prototype.creator_user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        OrganizationRu.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).uint64(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(18).string(message.name);
            if (message.inn != null && Object.hasOwnProperty.call(message, "inn"))
                writer.uint32(26).string(message.inn);
            if (message.kpp != null && Object.hasOwnProperty.call(message, "kpp"))
                writer.uint32(34).string(message.kpp);
            if (message.ogrn != null && Object.hasOwnProperty.call(message, "ogrn"))
                writer.uint32(42).string(message.ogrn);
            if (message.legal_address != null && Object.hasOwnProperty.call(message, "legal_address"))
                writer.uint32(50).string(message.legal_address);
            if (message.with_nds != null && Object.hasOwnProperty.call(message, "with_nds"))
                writer.uint32(56).bool(message.with_nds);
            if (message.nds_checked != null && Object.hasOwnProperty.call(message, "nds_checked"))
                writer.uint32(64).bool(message.nds_checked);
            if (message.representative_name != null && Object.hasOwnProperty.call(message, "representative_name"))
                writer.uint32(74).string(message.representative_name);
            if (message.representative_position != null && Object.hasOwnProperty.call(message, "representative_position"))
                writer.uint32(82).string(message.representative_position);
            if (message.bank_id != null && Object.hasOwnProperty.call(message, "bank_id"))
                writer.uint32(88).uint64(message.bank_id);
            if (message.payment_account != null && Object.hasOwnProperty.call(message, "payment_account"))
                writer.uint32(98).string(message.payment_account);
            if (message.organization_type != null && Object.hasOwnProperty.call(message, "organization_type"))
                writer.uint32(106).string(message.organization_type);
            if (message.mailing_address != null && Object.hasOwnProperty.call(message, "mailing_address"))
                writer.uint32(114).string(message.mailing_address);
            if (message.mailing_address_quality != null && Object.hasOwnProperty.call(message, "mailing_address_quality"))
                writer.uint32(120).int32(message.mailing_address_quality);
            if (message.archive != null && Object.hasOwnProperty.call(message, "archive"))
                writer.uint32(128).bool(message.archive);
            if (message.legal_address_quality != null && Object.hasOwnProperty.call(message, "legal_address_quality"))
                writer.uint32(136).int32(message.legal_address_quality);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(146).string(message.phone);
            if (message.verified != null && Object.hasOwnProperty.call(message, "verified"))
                writer.uint32(152).bool(message.verified);
            if (message.creator_user_id != null && Object.hasOwnProperty.call(message, "creator_user_id"))
                writer.uint32(160).uint64(message.creator_user_id);
            if (message.creation_time != null && Object.hasOwnProperty.call(message, "creation_time"))
                writer.uint32(168).int64(message.creation_time);
            return writer;
        };

        OrganizationRu.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationRu();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 17: {
                        message.legal_address_quality = reader.int32();
                        break;
                    }
                case 16: {
                        message.archive = reader.bool();
                        break;
                    }
                case 19: {
                        message.verified = reader.bool();
                        break;
                    }
                case 18: {
                        message.phone = reader.string();
                        break;
                    }
                case 5: {
                        message.ogrn = reader.string();
                        break;
                    }
                case 4: {
                        message.kpp = reader.string();
                        break;
                    }
                case 7: {
                        message.with_nds = reader.bool();
                        break;
                    }
                case 6: {
                        message.legal_address = reader.string();
                        break;
                    }
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.inn = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 13: {
                        message.organization_type = reader.string();
                        break;
                    }
                case 12: {
                        message.payment_account = reader.string();
                        break;
                    }
                case 15: {
                        message.mailing_address_quality = reader.int32();
                        break;
                    }
                case 14: {
                        message.mailing_address = reader.string();
                        break;
                    }
                case 9: {
                        message.representative_name = reader.string();
                        break;
                    }
                case 8: {
                        message.nds_checked = reader.bool();
                        break;
                    }
                case 11: {
                        message.bank_id = reader.uint64();
                        break;
                    }
                case 10: {
                        message.representative_position = reader.string();
                        break;
                    }
                case 21: {
                        message.creation_time = reader.int64();
                        break;
                    }
                case 20: {
                        message.creator_user_id = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrganizationRu.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrganizationRu)
                return object;
            let message = new $root.object_msg.OrganizationRu();
            if (object.legal_address_quality != null)
                message.legal_address_quality = object.legal_address_quality | 0;
            if (object.archive != null)
                message.archive = Boolean(object.archive);
            if (object.verified != null)
                message.verified = Boolean(object.verified);
            if (object.phone != null)
                message.phone = String(object.phone);
            if (object.ogrn != null)
                message.ogrn = String(object.ogrn);
            if (object.kpp != null)
                message.kpp = String(object.kpp);
            if (object.with_nds != null)
                message.with_nds = Boolean(object.with_nds);
            if (object.legal_address != null)
                message.legal_address = String(object.legal_address);
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.inn != null)
                message.inn = String(object.inn);
            if (object.name != null)
                message.name = String(object.name);
            if (object.organization_type != null)
                message.organization_type = String(object.organization_type);
            if (object.payment_account != null)
                message.payment_account = String(object.payment_account);
            if (object.mailing_address_quality != null)
                message.mailing_address_quality = object.mailing_address_quality | 0;
            if (object.mailing_address != null)
                message.mailing_address = String(object.mailing_address);
            if (object.representative_name != null)
                message.representative_name = String(object.representative_name);
            if (object.nds_checked != null)
                message.nds_checked = Boolean(object.nds_checked);
            if (object.bank_id != null)
                if ($util.Long)
                    (message.bank_id = $util.Long.fromValue(object.bank_id)).unsigned = true;
                else if (typeof object.bank_id === "string")
                    message.bank_id = parseInt(object.bank_id, 10);
                else if (typeof object.bank_id === "number")
                    message.bank_id = object.bank_id;
                else if (typeof object.bank_id === "object")
                    message.bank_id = new $util.LongBits(object.bank_id.low >>> 0, object.bank_id.high >>> 0).toNumber(true);
            if (object.representative_position != null)
                message.representative_position = String(object.representative_position);
            if (object.creation_time != null)
                if ($util.Long)
                    (message.creation_time = $util.Long.fromValue(object.creation_time)).unsigned = false;
                else if (typeof object.creation_time === "string")
                    message.creation_time = parseInt(object.creation_time, 10);
                else if (typeof object.creation_time === "number")
                    message.creation_time = object.creation_time;
                else if (typeof object.creation_time === "object")
                    message.creation_time = new $util.LongBits(object.creation_time.low >>> 0, object.creation_time.high >>> 0).toNumber();
            if (object.creator_user_id != null)
                if ($util.Long)
                    (message.creator_user_id = $util.Long.fromValue(object.creator_user_id)).unsigned = true;
                else if (typeof object.creator_user_id === "string")
                    message.creator_user_id = parseInt(object.creator_user_id, 10);
                else if (typeof object.creator_user_id === "number")
                    message.creator_user_id = object.creator_user_id;
                else if (typeof object.creator_user_id === "object")
                    message.creator_user_id = new $util.LongBits(object.creator_user_id.low >>> 0, object.creator_user_id.high >>> 0).toNumber(true);
            return message;
        };

        OrganizationRu.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.name = "";
                object.inn = "";
                object.kpp = "";
                object.ogrn = "";
                object.legal_address = "";
                object.with_nds = false;
                object.nds_checked = false;
                object.representative_name = "";
                object.representative_position = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.bank_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.bank_id = options.longs === String ? "0" : 0;
                object.payment_account = "";
                object.organization_type = "";
                object.mailing_address = "";
                object.mailing_address_quality = 0;
                object.archive = false;
                object.legal_address_quality = 0;
                object.phone = "";
                object.verified = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.creator_user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.creator_user_id = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.creation_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.creation_time = options.longs === String ? "0" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.inn != null && message.hasOwnProperty("inn"))
                object.inn = message.inn;
            if (message.kpp != null && message.hasOwnProperty("kpp"))
                object.kpp = message.kpp;
            if (message.ogrn != null && message.hasOwnProperty("ogrn"))
                object.ogrn = message.ogrn;
            if (message.legal_address != null && message.hasOwnProperty("legal_address"))
                object.legal_address = message.legal_address;
            if (message.with_nds != null && message.hasOwnProperty("with_nds"))
                object.with_nds = message.with_nds;
            if (message.nds_checked != null && message.hasOwnProperty("nds_checked"))
                object.nds_checked = message.nds_checked;
            if (message.representative_name != null && message.hasOwnProperty("representative_name"))
                object.representative_name = message.representative_name;
            if (message.representative_position != null && message.hasOwnProperty("representative_position"))
                object.representative_position = message.representative_position;
            if (message.bank_id != null && message.hasOwnProperty("bank_id"))
                if (typeof message.bank_id === "number")
                    object.bank_id = options.longs === String ? String(message.bank_id) : message.bank_id;
                else
                    object.bank_id = options.longs === String ? $util.Long.prototype.toString.call(message.bank_id) : options.longs === Number ? new $util.LongBits(message.bank_id.low >>> 0, message.bank_id.high >>> 0).toNumber(true) : message.bank_id;
            if (message.payment_account != null && message.hasOwnProperty("payment_account"))
                object.payment_account = message.payment_account;
            if (message.organization_type != null && message.hasOwnProperty("organization_type"))
                object.organization_type = message.organization_type;
            if (message.mailing_address != null && message.hasOwnProperty("mailing_address"))
                object.mailing_address = message.mailing_address;
            if (message.mailing_address_quality != null && message.hasOwnProperty("mailing_address_quality"))
                object.mailing_address_quality = message.mailing_address_quality;
            if (message.archive != null && message.hasOwnProperty("archive"))
                object.archive = message.archive;
            if (message.legal_address_quality != null && message.hasOwnProperty("legal_address_quality"))
                object.legal_address_quality = message.legal_address_quality;
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.verified != null && message.hasOwnProperty("verified"))
                object.verified = message.verified;
            if (message.creator_user_id != null && message.hasOwnProperty("creator_user_id"))
                if (typeof message.creator_user_id === "number")
                    object.creator_user_id = options.longs === String ? String(message.creator_user_id) : message.creator_user_id;
                else
                    object.creator_user_id = options.longs === String ? $util.Long.prototype.toString.call(message.creator_user_id) : options.longs === Number ? new $util.LongBits(message.creator_user_id.low >>> 0, message.creator_user_id.high >>> 0).toNumber(true) : message.creator_user_id;
            if (message.creation_time != null && message.hasOwnProperty("creation_time"))
                if (typeof message.creation_time === "number")
                    object.creation_time = options.longs === String ? String(message.creation_time) : message.creation_time;
                else
                    object.creation_time = options.longs === String ? $util.Long.prototype.toString.call(message.creation_time) : options.longs === Number ? new $util.LongBits(message.creation_time.low >>> 0, message.creation_time.high >>> 0).toNumber() : message.creation_time;
            return object;
        };

        OrganizationRu.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrganizationRu.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrganizationRu";
        };

        return OrganizationRu;
    })();

    object_msg.OrganizationRuArray = (function() {

        function OrganizationRuArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrganizationRuArray.prototype.array = $util.emptyArray;

        OrganizationRuArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrganizationRu.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrganizationRuArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationRuArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrganizationRu.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrganizationRuArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrganizationRuArray)
                return object;
            let message = new $root.object_msg.OrganizationRuArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrganizationRuArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrganizationRuArray.array: object expected");
                    message.array[i] = $root.object_msg.OrganizationRu.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrganizationRuArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrganizationRu.toObject(message.array[j], options);
            }
            return object;
        };

        OrganizationRuArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrganizationRuArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrganizationRuArray";
        };

        return OrganizationRuArray;
    })();

    object_msg.OrganizationRuInfo = (function() {

        function OrganizationRuInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrganizationRuInfo.prototype.kpp = "";
        OrganizationRuInfo.prototype.legal_address = "";
        OrganizationRuInfo.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrganizationRuInfo.prototype.inn = "";
        OrganizationRuInfo.prototype.archive = false;
        OrganizationRuInfo.prototype.name = "";
        OrganizationRuInfo.prototype.verified = false;

        OrganizationRuInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).uint64(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(18).string(message.name);
            if (message.inn != null && Object.hasOwnProperty.call(message, "inn"))
                writer.uint32(26).string(message.inn);
            if (message.kpp != null && Object.hasOwnProperty.call(message, "kpp"))
                writer.uint32(34).string(message.kpp);
            if (message.legal_address != null && Object.hasOwnProperty.call(message, "legal_address"))
                writer.uint32(50).string(message.legal_address);
            if (message.archive != null && Object.hasOwnProperty.call(message, "archive"))
                writer.uint32(128).bool(message.archive);
            if (message.verified != null && Object.hasOwnProperty.call(message, "verified"))
                writer.uint32(152).bool(message.verified);
            return writer;
        };

        OrganizationRuInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationRuInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.kpp = reader.string();
                        break;
                    }
                case 6: {
                        message.legal_address = reader.string();
                        break;
                    }
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.inn = reader.string();
                        break;
                    }
                case 16: {
                        message.archive = reader.bool();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 19: {
                        message.verified = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrganizationRuInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrganizationRuInfo)
                return object;
            let message = new $root.object_msg.OrganizationRuInfo();
            if (object.kpp != null)
                message.kpp = String(object.kpp);
            if (object.legal_address != null)
                message.legal_address = String(object.legal_address);
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.inn != null)
                message.inn = String(object.inn);
            if (object.archive != null)
                message.archive = Boolean(object.archive);
            if (object.name != null)
                message.name = String(object.name);
            if (object.verified != null)
                message.verified = Boolean(object.verified);
            return message;
        };

        OrganizationRuInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.name = "";
                object.inn = "";
                object.kpp = "";
                object.legal_address = "";
                object.archive = false;
                object.verified = false;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.inn != null && message.hasOwnProperty("inn"))
                object.inn = message.inn;
            if (message.kpp != null && message.hasOwnProperty("kpp"))
                object.kpp = message.kpp;
            if (message.legal_address != null && message.hasOwnProperty("legal_address"))
                object.legal_address = message.legal_address;
            if (message.archive != null && message.hasOwnProperty("archive"))
                object.archive = message.archive;
            if (message.verified != null && message.hasOwnProperty("verified"))
                object.verified = message.verified;
            return object;
        };

        OrganizationRuInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrganizationRuInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrganizationRuInfo";
        };

        return OrganizationRuInfo;
    })();

    object_msg.OrganizationRuInfoArray = (function() {

        function OrganizationRuInfoArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrganizationRuInfoArray.prototype.array = $util.emptyArray;

        OrganizationRuInfoArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrganizationRuInfo.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrganizationRuInfoArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationRuInfoArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrganizationRuInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrganizationRuInfoArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrganizationRuInfoArray)
                return object;
            let message = new $root.object_msg.OrganizationRuInfoArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrganizationRuInfoArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrganizationRuInfoArray.array: object expected");
                    message.array[i] = $root.object_msg.OrganizationRuInfo.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrganizationRuInfoArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrganizationRuInfo.toObject(message.array[j], options);
            }
            return object;
        };

        OrganizationRuInfoArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrganizationRuInfoArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrganizationRuInfoArray";
        };

        return OrganizationRuInfoArray;
    })();

    object_msg.BankRu = (function() {

        function BankRu(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        BankRu.prototype.swift = "";
        BankRu.prototype.correspondent_account = "";
        BankRu.prototype.kpp = "";
        BankRu.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        BankRu.prototype.bic = "";
        BankRu.prototype.name = "";
        BankRu.prototype.legal_address = "";
        BankRu.prototype.inn = "";

        BankRu.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).uint64(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(18).string(message.name);
            if (message.bic != null && Object.hasOwnProperty.call(message, "bic"))
                writer.uint32(26).string(message.bic);
            if (message.swift != null && Object.hasOwnProperty.call(message, "swift"))
                writer.uint32(34).string(message.swift);
            if (message.inn != null && Object.hasOwnProperty.call(message, "inn"))
                writer.uint32(42).string(message.inn);
            if (message.kpp != null && Object.hasOwnProperty.call(message, "kpp"))
                writer.uint32(50).string(message.kpp);
            if (message.correspondent_account != null && Object.hasOwnProperty.call(message, "correspondent_account"))
                writer.uint32(58).string(message.correspondent_account);
            if (message.legal_address != null && Object.hasOwnProperty.call(message, "legal_address"))
                writer.uint32(66).string(message.legal_address);
            return writer;
        };

        BankRu.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.BankRu();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.swift = reader.string();
                        break;
                    }
                case 7: {
                        message.correspondent_account = reader.string();
                        break;
                    }
                case 6: {
                        message.kpp = reader.string();
                        break;
                    }
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.bic = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 8: {
                        message.legal_address = reader.string();
                        break;
                    }
                case 5: {
                        message.inn = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        BankRu.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.BankRu)
                return object;
            let message = new $root.object_msg.BankRu();
            if (object.swift != null)
                message.swift = String(object.swift);
            if (object.correspondent_account != null)
                message.correspondent_account = String(object.correspondent_account);
            if (object.kpp != null)
                message.kpp = String(object.kpp);
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.bic != null)
                message.bic = String(object.bic);
            if (object.name != null)
                message.name = String(object.name);
            if (object.legal_address != null)
                message.legal_address = String(object.legal_address);
            if (object.inn != null)
                message.inn = String(object.inn);
            return message;
        };

        BankRu.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.name = "";
                object.bic = "";
                object.swift = "";
                object.inn = "";
                object.kpp = "";
                object.correspondent_account = "";
                object.legal_address = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.bic != null && message.hasOwnProperty("bic"))
                object.bic = message.bic;
            if (message.swift != null && message.hasOwnProperty("swift"))
                object.swift = message.swift;
            if (message.inn != null && message.hasOwnProperty("inn"))
                object.inn = message.inn;
            if (message.kpp != null && message.hasOwnProperty("kpp"))
                object.kpp = message.kpp;
            if (message.correspondent_account != null && message.hasOwnProperty("correspondent_account"))
                object.correspondent_account = message.correspondent_account;
            if (message.legal_address != null && message.hasOwnProperty("legal_address"))
                object.legal_address = message.legal_address;
            return object;
        };

        BankRu.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        BankRu.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.BankRu";
        };

        return BankRu;
    })();

    object_msg.BankRuArray = (function() {

        function BankRuArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        BankRuArray.prototype.array = $util.emptyArray;

        BankRuArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.BankRu.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        BankRuArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.BankRuArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.BankRu.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        BankRuArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.BankRuArray)
                return object;
            let message = new $root.object_msg.BankRuArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.BankRuArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.BankRuArray.array: object expected");
                    message.array[i] = $root.object_msg.BankRu.fromObject(object.array[i]);
                }
            }
            return message;
        };

        BankRuArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.BankRu.toObject(message.array[j], options);
            }
            return object;
        };

        BankRuArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        BankRuArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.BankRuArray";
        };

        return BankRuArray;
    })();

    object_msg.PlaceAction = (function() {

        function PlaceAction(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlaceAction.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        PlaceAction.prototype.unified = false;
        PlaceAction.prototype.action = "";
        PlaceAction.prototype.city_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        PlaceAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                writer.uint32(8).uint64(message.place_id);
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(18).string(message.action);
            if (message.unified != null && Object.hasOwnProperty.call(message, "unified"))
                writer.uint32(24).bool(message.unified);
            if (message.city_id != null && Object.hasOwnProperty.call(message, "city_id"))
                writer.uint32(40).uint64(message.city_id);
            return writer;
        };

        PlaceAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlaceAction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.place_id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.unified = reader.bool();
                        break;
                    }
                case 2: {
                        message.action = reader.string();
                        break;
                    }
                case 5: {
                        message.city_id = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlaceAction.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlaceAction)
                return object;
            let message = new $root.object_msg.PlaceAction();
            if (object.place_id != null)
                if ($util.Long)
                    (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                else if (typeof object.place_id === "string")
                    message.place_id = parseInt(object.place_id, 10);
                else if (typeof object.place_id === "number")
                    message.place_id = object.place_id;
                else if (typeof object.place_id === "object")
                    message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
            if (object.unified != null)
                message.unified = Boolean(object.unified);
            if (object.action != null)
                message.action = String(object.action);
            if (object.city_id != null)
                if ($util.Long)
                    (message.city_id = $util.Long.fromValue(object.city_id)).unsigned = true;
                else if (typeof object.city_id === "string")
                    message.city_id = parseInt(object.city_id, 10);
                else if (typeof object.city_id === "number")
                    message.city_id = object.city_id;
                else if (typeof object.city_id === "object")
                    message.city_id = new $util.LongBits(object.city_id.low >>> 0, object.city_id.high >>> 0).toNumber(true);
            return message;
        };

        PlaceAction.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.place_id = options.longs === String ? "0" : 0;
                object.action = "";
                object.unified = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.city_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.city_id = options.longs === String ? "0" : 0;
            }
            if (message.place_id != null && message.hasOwnProperty("place_id"))
                if (typeof message.place_id === "number")
                    object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                else
                    object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = message.action;
            if (message.unified != null && message.hasOwnProperty("unified"))
                object.unified = message.unified;
            if (message.city_id != null && message.hasOwnProperty("city_id"))
                if (typeof message.city_id === "number")
                    object.city_id = options.longs === String ? String(message.city_id) : message.city_id;
                else
                    object.city_id = options.longs === String ? $util.Long.prototype.toString.call(message.city_id) : options.longs === Number ? new $util.LongBits(message.city_id.low >>> 0, message.city_id.high >>> 0).toNumber(true) : message.city_id;
            return object;
        };

        PlaceAction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlaceAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlaceAction";
        };

        return PlaceAction;
    })();

    object_msg.PlaceActionArray = (function() {

        function PlaceActionArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlaceActionArray.prototype.array = $util.emptyArray;

        PlaceActionArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.PlaceAction.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        PlaceActionArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlaceActionArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.PlaceAction.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlaceActionArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlaceActionArray)
                return object;
            let message = new $root.object_msg.PlaceActionArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.PlaceActionArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.PlaceActionArray.array: object expected");
                    message.array[i] = $root.object_msg.PlaceAction.fromObject(object.array[i]);
                }
            }
            return message;
        };

        PlaceActionArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.PlaceAction.toObject(message.array[j], options);
            }
            return object;
        };

        PlaceActionArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlaceActionArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlaceActionArray";
        };

        return PlaceActionArray;
    })();

    object_msg.PlacePhone = (function() {

        function PlacePhone(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlacePhone.prototype.telegram = false;
        PlacePhone.prototype.description = "";
        PlacePhone.prototype.viber = false;
        PlacePhone.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        PlacePhone.prototype.whatsapp = false;
        PlacePhone.prototype.phone = "";
        PlacePhone.prototype.main = false;

        PlacePhone.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                writer.uint32(8).uint64(message.place_id);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(18).string(message.phone);
            if (message.whatsapp != null && Object.hasOwnProperty.call(message, "whatsapp"))
                writer.uint32(24).bool(message.whatsapp);
            if (message.telegram != null && Object.hasOwnProperty.call(message, "telegram"))
                writer.uint32(32).bool(message.telegram);
            if (message.main != null && Object.hasOwnProperty.call(message, "main"))
                writer.uint32(40).bool(message.main);
            if (message.viber != null && Object.hasOwnProperty.call(message, "viber"))
                writer.uint32(48).bool(message.viber);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(58).string(message.description);
            return writer;
        };

        PlacePhone.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacePhone();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.telegram = reader.bool();
                        break;
                    }
                case 7: {
                        message.description = reader.string();
                        break;
                    }
                case 6: {
                        message.viber = reader.bool();
                        break;
                    }
                case 1: {
                        message.place_id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.whatsapp = reader.bool();
                        break;
                    }
                case 2: {
                        message.phone = reader.string();
                        break;
                    }
                case 5: {
                        message.main = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlacePhone.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlacePhone)
                return object;
            let message = new $root.object_msg.PlacePhone();
            if (object.telegram != null)
                message.telegram = Boolean(object.telegram);
            if (object.description != null)
                message.description = String(object.description);
            if (object.viber != null)
                message.viber = Boolean(object.viber);
            if (object.place_id != null)
                if ($util.Long)
                    (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                else if (typeof object.place_id === "string")
                    message.place_id = parseInt(object.place_id, 10);
                else if (typeof object.place_id === "number")
                    message.place_id = object.place_id;
                else if (typeof object.place_id === "object")
                    message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
            if (object.whatsapp != null)
                message.whatsapp = Boolean(object.whatsapp);
            if (object.phone != null)
                message.phone = String(object.phone);
            if (object.main != null)
                message.main = Boolean(object.main);
            return message;
        };

        PlacePhone.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.place_id = options.longs === String ? "0" : 0;
                object.phone = "";
                object.whatsapp = false;
                object.telegram = false;
                object.main = false;
                object.viber = false;
                object.description = "";
            }
            if (message.place_id != null && message.hasOwnProperty("place_id"))
                if (typeof message.place_id === "number")
                    object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                else
                    object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.whatsapp != null && message.hasOwnProperty("whatsapp"))
                object.whatsapp = message.whatsapp;
            if (message.telegram != null && message.hasOwnProperty("telegram"))
                object.telegram = message.telegram;
            if (message.main != null && message.hasOwnProperty("main"))
                object.main = message.main;
            if (message.viber != null && message.hasOwnProperty("viber"))
                object.viber = message.viber;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            return object;
        };

        PlacePhone.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlacePhone.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlacePhone";
        };

        return PlacePhone;
    })();

    object_msg.PlacePhoneArray = (function() {

        function PlacePhoneArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlacePhoneArray.prototype.array = $util.emptyArray;

        PlacePhoneArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.PlacePhone.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        PlacePhoneArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacePhoneArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.PlacePhone.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlacePhoneArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlacePhoneArray)
                return object;
            let message = new $root.object_msg.PlacePhoneArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.PlacePhoneArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.PlacePhoneArray.array: object expected");
                    message.array[i] = $root.object_msg.PlacePhone.fromObject(object.array[i]);
                }
            }
            return message;
        };

        PlacePhoneArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.PlacePhone.toObject(message.array[j], options);
            }
            return object;
        };

        PlacePhoneArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlacePhoneArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlacePhoneArray";
        };

        return PlacePhoneArray;
    })();

    object_msg.PlaceEmail = (function() {

        function PlaceEmail(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlaceEmail.prototype.description = "";
        PlaceEmail.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        PlaceEmail.prototype.main = false;
        PlaceEmail.prototype.email = "";

        PlaceEmail.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                writer.uint32(8).uint64(message.place_id);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(18).string(message.email);
            if (message.main != null && Object.hasOwnProperty.call(message, "main"))
                writer.uint32(24).bool(message.main);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(34).string(message.description);
            return writer;
        };

        PlaceEmail.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlaceEmail();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.description = reader.string();
                        break;
                    }
                case 1: {
                        message.place_id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.main = reader.bool();
                        break;
                    }
                case 2: {
                        message.email = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlaceEmail.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlaceEmail)
                return object;
            let message = new $root.object_msg.PlaceEmail();
            if (object.description != null)
                message.description = String(object.description);
            if (object.place_id != null)
                if ($util.Long)
                    (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                else if (typeof object.place_id === "string")
                    message.place_id = parseInt(object.place_id, 10);
                else if (typeof object.place_id === "number")
                    message.place_id = object.place_id;
                else if (typeof object.place_id === "object")
                    message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
            if (object.main != null)
                message.main = Boolean(object.main);
            if (object.email != null)
                message.email = String(object.email);
            return message;
        };

        PlaceEmail.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.place_id = options.longs === String ? "0" : 0;
                object.email = "";
                object.main = false;
                object.description = "";
            }
            if (message.place_id != null && message.hasOwnProperty("place_id"))
                if (typeof message.place_id === "number")
                    object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                else
                    object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.main != null && message.hasOwnProperty("main"))
                object.main = message.main;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            return object;
        };

        PlaceEmail.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlaceEmail.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlaceEmail";
        };

        return PlaceEmail;
    })();

    object_msg.PlaceEmailArray = (function() {

        function PlaceEmailArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlaceEmailArray.prototype.array = $util.emptyArray;

        PlaceEmailArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.PlaceEmail.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        PlaceEmailArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlaceEmailArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.PlaceEmail.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlaceEmailArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlaceEmailArray)
                return object;
            let message = new $root.object_msg.PlaceEmailArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.PlaceEmailArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.PlaceEmailArray.array: object expected");
                    message.array[i] = $root.object_msg.PlaceEmail.fromObject(object.array[i]);
                }
            }
            return message;
        };

        PlaceEmailArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.PlaceEmail.toObject(message.array[j], options);
            }
            return object;
        };

        PlaceEmailArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlaceEmailArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlaceEmailArray";
        };

        return PlaceEmailArray;
    })();

    object_msg.PlaceMachine = (function() {

        function PlaceMachine(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlaceMachine.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        PlaceMachine.prototype.unified = false;
        PlaceMachine.prototype.model = "";

        PlaceMachine.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                writer.uint32(8).uint64(message.place_id);
            if (message.model != null && Object.hasOwnProperty.call(message, "model"))
                writer.uint32(18).string(message.model);
            if (message.unified != null && Object.hasOwnProperty.call(message, "unified"))
                writer.uint32(24).bool(message.unified);
            return writer;
        };

        PlaceMachine.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlaceMachine();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.place_id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.unified = reader.bool();
                        break;
                    }
                case 2: {
                        message.model = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlaceMachine.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlaceMachine)
                return object;
            let message = new $root.object_msg.PlaceMachine();
            if (object.place_id != null)
                if ($util.Long)
                    (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                else if (typeof object.place_id === "string")
                    message.place_id = parseInt(object.place_id, 10);
                else if (typeof object.place_id === "number")
                    message.place_id = object.place_id;
                else if (typeof object.place_id === "object")
                    message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
            if (object.unified != null)
                message.unified = Boolean(object.unified);
            if (object.model != null)
                message.model = String(object.model);
            return message;
        };

        PlaceMachine.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.place_id = options.longs === String ? "0" : 0;
                object.model = "";
                object.unified = false;
            }
            if (message.place_id != null && message.hasOwnProperty("place_id"))
                if (typeof message.place_id === "number")
                    object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                else
                    object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
            if (message.model != null && message.hasOwnProperty("model"))
                object.model = message.model;
            if (message.unified != null && message.hasOwnProperty("unified"))
                object.unified = message.unified;
            return object;
        };

        PlaceMachine.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlaceMachine.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlaceMachine";
        };

        return PlaceMachine;
    })();

    object_msg.PlaceMachineArray = (function() {

        function PlaceMachineArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlaceMachineArray.prototype.array = $util.emptyArray;

        PlaceMachineArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.PlaceMachine.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        PlaceMachineArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlaceMachineArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.PlaceMachine.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlaceMachineArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlaceMachineArray)
                return object;
            let message = new $root.object_msg.PlaceMachineArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.PlaceMachineArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.PlaceMachineArray.array: object expected");
                    message.array[i] = $root.object_msg.PlaceMachine.fromObject(object.array[i]);
                }
            }
            return message;
        };

        PlaceMachineArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.PlaceMachine.toObject(message.array[j], options);
            }
            return object;
        };

        PlaceMachineArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlaceMachineArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlaceMachineArray";
        };

        return PlaceMachineArray;
    })();

    object_msg.PlaceLabel = (function() {

        function PlaceLabel(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlaceLabel.prototype.unified = false;
        PlaceLabel.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        PlaceLabel.prototype.value = "";
        PlaceLabel.prototype.key = "";

        PlaceLabel.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                writer.uint32(8).uint64(message.place_id);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(18).string(message.key);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(26).string(message.value);
            if (message.unified != null && Object.hasOwnProperty.call(message, "unified"))
                writer.uint32(32).bool(message.unified);
            return writer;
        };

        PlaceLabel.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlaceLabel();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.unified = reader.bool();
                        break;
                    }
                case 1: {
                        message.place_id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.value = reader.string();
                        break;
                    }
                case 2: {
                        message.key = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlaceLabel.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlaceLabel)
                return object;
            let message = new $root.object_msg.PlaceLabel();
            if (object.unified != null)
                message.unified = Boolean(object.unified);
            if (object.place_id != null)
                if ($util.Long)
                    (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                else if (typeof object.place_id === "string")
                    message.place_id = parseInt(object.place_id, 10);
                else if (typeof object.place_id === "number")
                    message.place_id = object.place_id;
                else if (typeof object.place_id === "object")
                    message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
            if (object.value != null)
                message.value = String(object.value);
            if (object.key != null)
                message.key = String(object.key);
            return message;
        };

        PlaceLabel.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.place_id = options.longs === String ? "0" : 0;
                object.key = "";
                object.value = "";
                object.unified = false;
            }
            if (message.place_id != null && message.hasOwnProperty("place_id"))
                if (typeof message.place_id === "number")
                    object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                else
                    object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            if (message.unified != null && message.hasOwnProperty("unified"))
                object.unified = message.unified;
            return object;
        };

        PlaceLabel.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlaceLabel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlaceLabel";
        };

        return PlaceLabel;
    })();

    object_msg.PlaceLabelArray = (function() {

        function PlaceLabelArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlaceLabelArray.prototype.array = $util.emptyArray;

        PlaceLabelArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.PlaceLabel.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        PlaceLabelArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlaceLabelArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.PlaceLabel.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlaceLabelArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlaceLabelArray)
                return object;
            let message = new $root.object_msg.PlaceLabelArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.PlaceLabelArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.PlaceLabelArray.array: object expected");
                    message.array[i] = $root.object_msg.PlaceLabel.fromObject(object.array[i]);
                }
            }
            return message;
        };

        PlaceLabelArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.PlaceLabel.toObject(message.array[j], options);
            }
            return object;
        };

        PlaceLabelArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlaceLabelArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlaceLabelArray";
        };

        return PlaceLabelArray;
    })();

    object_msg.LaserCuttingPrice = (function() {

        function LaserCuttingPrice(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LaserCuttingPrice.prototype.min_m = 0;
        LaserCuttingPrice.prototype.currency = "";
        LaserCuttingPrice.prototype.price = 0;
        LaserCuttingPrice.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        LaserCuttingPrice.prototype.thickness_01mm = 0;
        LaserCuttingPrice.prototype.material = "";
        LaserCuttingPrice.prototype.material_unified = false;
        LaserCuttingPrice.prototype.max_m = 0;

        LaserCuttingPrice.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                writer.uint32(8).uint64(message.place_id);
            if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                writer.uint32(18).string(message.material);
            if (message.thickness_01mm != null && Object.hasOwnProperty.call(message, "thickness_01mm"))
                writer.uint32(24).int32(message.thickness_01mm);
            if (message.min_m != null && Object.hasOwnProperty.call(message, "min_m"))
                writer.uint32(32).int32(message.min_m);
            if (message.max_m != null && Object.hasOwnProperty.call(message, "max_m"))
                writer.uint32(40).int32(message.max_m);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                writer.uint32(53).float(message.price);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(58).string(message.currency);
            if (message.material_unified != null && Object.hasOwnProperty.call(message, "material_unified"))
                writer.uint32(64).bool(message.material_unified);
            return writer;
        };

        LaserCuttingPrice.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LaserCuttingPrice();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.min_m = reader.int32();
                        break;
                    }
                case 7: {
                        message.currency = reader.string();
                        break;
                    }
                case 6: {
                        message.price = reader.float();
                        break;
                    }
                case 1: {
                        message.place_id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.thickness_01mm = reader.int32();
                        break;
                    }
                case 2: {
                        message.material = reader.string();
                        break;
                    }
                case 8: {
                        message.material_unified = reader.bool();
                        break;
                    }
                case 5: {
                        message.max_m = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LaserCuttingPrice.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LaserCuttingPrice)
                return object;
            let message = new $root.object_msg.LaserCuttingPrice();
            if (object.min_m != null)
                message.min_m = object.min_m | 0;
            if (object.currency != null)
                message.currency = String(object.currency);
            if (object.price != null)
                message.price = Number(object.price);
            if (object.place_id != null)
                if ($util.Long)
                    (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                else if (typeof object.place_id === "string")
                    message.place_id = parseInt(object.place_id, 10);
                else if (typeof object.place_id === "number")
                    message.place_id = object.place_id;
                else if (typeof object.place_id === "object")
                    message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
            if (object.thickness_01mm != null)
                message.thickness_01mm = object.thickness_01mm | 0;
            if (object.material != null)
                message.material = String(object.material);
            if (object.material_unified != null)
                message.material_unified = Boolean(object.material_unified);
            if (object.max_m != null)
                message.max_m = object.max_m | 0;
            return message;
        };

        LaserCuttingPrice.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.place_id = options.longs === String ? "0" : 0;
                object.material = "";
                object.thickness_01mm = 0;
                object.min_m = 0;
                object.max_m = 0;
                object.price = 0;
                object.currency = "";
                object.material_unified = false;
            }
            if (message.place_id != null && message.hasOwnProperty("place_id"))
                if (typeof message.place_id === "number")
                    object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                else
                    object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
            if (message.material != null && message.hasOwnProperty("material"))
                object.material = message.material;
            if (message.thickness_01mm != null && message.hasOwnProperty("thickness_01mm"))
                object.thickness_01mm = message.thickness_01mm;
            if (message.min_m != null && message.hasOwnProperty("min_m"))
                object.min_m = message.min_m;
            if (message.max_m != null && message.hasOwnProperty("max_m"))
                object.max_m = message.max_m;
            if (message.price != null && message.hasOwnProperty("price"))
                object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            if (message.material_unified != null && message.hasOwnProperty("material_unified"))
                object.material_unified = message.material_unified;
            return object;
        };

        LaserCuttingPrice.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LaserCuttingPrice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LaserCuttingPrice";
        };

        return LaserCuttingPrice;
    })();

    object_msg.LaserCuttingPriceArray = (function() {

        function LaserCuttingPriceArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LaserCuttingPriceArray.prototype.array = $util.emptyArray;

        LaserCuttingPriceArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.LaserCuttingPrice.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        LaserCuttingPriceArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LaserCuttingPriceArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.LaserCuttingPrice.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LaserCuttingPriceArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LaserCuttingPriceArray)
                return object;
            let message = new $root.object_msg.LaserCuttingPriceArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.LaserCuttingPriceArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.LaserCuttingPriceArray.array: object expected");
                    message.array[i] = $root.object_msg.LaserCuttingPrice.fromObject(object.array[i]);
                }
            }
            return message;
        };

        LaserCuttingPriceArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.LaserCuttingPrice.toObject(message.array[j], options);
            }
            return object;
        };

        LaserCuttingPriceArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LaserCuttingPriceArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LaserCuttingPriceArray";
        };

        return LaserCuttingPriceArray;
    })();

    object_msg.LocalLaserCuttingPrice = (function() {

        function LocalLaserCuttingPrice(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LocalLaserCuttingPrice.prototype.min_m = 0;
        LocalLaserCuttingPrice.prototype.currency = "";
        LocalLaserCuttingPrice.prototype.price = 0;
        LocalLaserCuttingPrice.prototype.department_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        LocalLaserCuttingPrice.prototype.thickness_01mm = 0;
        LocalLaserCuttingPrice.prototype.material = "";
        LocalLaserCuttingPrice.prototype.reference_place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        LocalLaserCuttingPrice.prototype.max_m = 0;

        LocalLaserCuttingPrice.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.department_id != null && Object.hasOwnProperty.call(message, "department_id"))
                writer.uint32(8).uint64(message.department_id);
            if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                writer.uint32(18).string(message.material);
            if (message.thickness_01mm != null && Object.hasOwnProperty.call(message, "thickness_01mm"))
                writer.uint32(24).int32(message.thickness_01mm);
            if (message.min_m != null && Object.hasOwnProperty.call(message, "min_m"))
                writer.uint32(32).int32(message.min_m);
            if (message.max_m != null && Object.hasOwnProperty.call(message, "max_m"))
                writer.uint32(40).int32(message.max_m);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                writer.uint32(53).float(message.price);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(58).string(message.currency);
            if (message.reference_place_id != null && Object.hasOwnProperty.call(message, "reference_place_id"))
                writer.uint32(64).uint64(message.reference_place_id);
            return writer;
        };

        LocalLaserCuttingPrice.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LocalLaserCuttingPrice();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.min_m = reader.int32();
                        break;
                    }
                case 7: {
                        message.currency = reader.string();
                        break;
                    }
                case 6: {
                        message.price = reader.float();
                        break;
                    }
                case 1: {
                        message.department_id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.thickness_01mm = reader.int32();
                        break;
                    }
                case 2: {
                        message.material = reader.string();
                        break;
                    }
                case 8: {
                        message.reference_place_id = reader.uint64();
                        break;
                    }
                case 5: {
                        message.max_m = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LocalLaserCuttingPrice.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LocalLaserCuttingPrice)
                return object;
            let message = new $root.object_msg.LocalLaserCuttingPrice();
            if (object.min_m != null)
                message.min_m = object.min_m | 0;
            if (object.currency != null)
                message.currency = String(object.currency);
            if (object.price != null)
                message.price = Number(object.price);
            if (object.department_id != null)
                if ($util.Long)
                    (message.department_id = $util.Long.fromValue(object.department_id)).unsigned = true;
                else if (typeof object.department_id === "string")
                    message.department_id = parseInt(object.department_id, 10);
                else if (typeof object.department_id === "number")
                    message.department_id = object.department_id;
                else if (typeof object.department_id === "object")
                    message.department_id = new $util.LongBits(object.department_id.low >>> 0, object.department_id.high >>> 0).toNumber(true);
            if (object.thickness_01mm != null)
                message.thickness_01mm = object.thickness_01mm | 0;
            if (object.material != null)
                message.material = String(object.material);
            if (object.reference_place_id != null)
                if ($util.Long)
                    (message.reference_place_id = $util.Long.fromValue(object.reference_place_id)).unsigned = true;
                else if (typeof object.reference_place_id === "string")
                    message.reference_place_id = parseInt(object.reference_place_id, 10);
                else if (typeof object.reference_place_id === "number")
                    message.reference_place_id = object.reference_place_id;
                else if (typeof object.reference_place_id === "object")
                    message.reference_place_id = new $util.LongBits(object.reference_place_id.low >>> 0, object.reference_place_id.high >>> 0).toNumber(true);
            if (object.max_m != null)
                message.max_m = object.max_m | 0;
            return message;
        };

        LocalLaserCuttingPrice.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.department_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.department_id = options.longs === String ? "0" : 0;
                object.material = "";
                object.thickness_01mm = 0;
                object.min_m = 0;
                object.max_m = 0;
                object.price = 0;
                object.currency = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.reference_place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.reference_place_id = options.longs === String ? "0" : 0;
            }
            if (message.department_id != null && message.hasOwnProperty("department_id"))
                if (typeof message.department_id === "number")
                    object.department_id = options.longs === String ? String(message.department_id) : message.department_id;
                else
                    object.department_id = options.longs === String ? $util.Long.prototype.toString.call(message.department_id) : options.longs === Number ? new $util.LongBits(message.department_id.low >>> 0, message.department_id.high >>> 0).toNumber(true) : message.department_id;
            if (message.material != null && message.hasOwnProperty("material"))
                object.material = message.material;
            if (message.thickness_01mm != null && message.hasOwnProperty("thickness_01mm"))
                object.thickness_01mm = message.thickness_01mm;
            if (message.min_m != null && message.hasOwnProperty("min_m"))
                object.min_m = message.min_m;
            if (message.max_m != null && message.hasOwnProperty("max_m"))
                object.max_m = message.max_m;
            if (message.price != null && message.hasOwnProperty("price"))
                object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            if (message.reference_place_id != null && message.hasOwnProperty("reference_place_id"))
                if (typeof message.reference_place_id === "number")
                    object.reference_place_id = options.longs === String ? String(message.reference_place_id) : message.reference_place_id;
                else
                    object.reference_place_id = options.longs === String ? $util.Long.prototype.toString.call(message.reference_place_id) : options.longs === Number ? new $util.LongBits(message.reference_place_id.low >>> 0, message.reference_place_id.high >>> 0).toNumber(true) : message.reference_place_id;
            return object;
        };

        LocalLaserCuttingPrice.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LocalLaserCuttingPrice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LocalLaserCuttingPrice";
        };

        return LocalLaserCuttingPrice;
    })();

    object_msg.LocalLaserCuttingPriceArray = (function() {

        function LocalLaserCuttingPriceArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LocalLaserCuttingPriceArray.prototype.array = $util.emptyArray;

        LocalLaserCuttingPriceArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.LocalLaserCuttingPrice.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        LocalLaserCuttingPriceArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LocalLaserCuttingPriceArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.LocalLaserCuttingPrice.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LocalLaserCuttingPriceArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LocalLaserCuttingPriceArray)
                return object;
            let message = new $root.object_msg.LocalLaserCuttingPriceArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.LocalLaserCuttingPriceArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.LocalLaserCuttingPriceArray.array: object expected");
                    message.array[i] = $root.object_msg.LocalLaserCuttingPrice.fromObject(object.array[i]);
                }
            }
            return message;
        };

        LocalLaserCuttingPriceArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.LocalLaserCuttingPrice.toObject(message.array[j], options);
            }
            return object;
        };

        LocalLaserCuttingPriceArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LocalLaserCuttingPriceArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LocalLaserCuttingPriceArray";
        };

        return LocalLaserCuttingPriceArray;
    })();

    object_msg.LaserEngravingPrice = (function() {

        function LaserEngravingPrice(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LaserEngravingPrice.prototype.max_cm2 = 0;
        LaserEngravingPrice.prototype.material_unified = false;
        LaserEngravingPrice.prototype.currency = "";
        LaserEngravingPrice.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        LaserEngravingPrice.prototype.min_cm2 = 0;
        LaserEngravingPrice.prototype.material = "";
        LaserEngravingPrice.prototype.price = 0;

        LaserEngravingPrice.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                writer.uint32(8).uint64(message.place_id);
            if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                writer.uint32(18).string(message.material);
            if (message.min_cm2 != null && Object.hasOwnProperty.call(message, "min_cm2"))
                writer.uint32(24).int32(message.min_cm2);
            if (message.max_cm2 != null && Object.hasOwnProperty.call(message, "max_cm2"))
                writer.uint32(32).int32(message.max_cm2);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                writer.uint32(45).float(message.price);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(50).string(message.currency);
            if (message.material_unified != null && Object.hasOwnProperty.call(message, "material_unified"))
                writer.uint32(56).bool(message.material_unified);
            return writer;
        };

        LaserEngravingPrice.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LaserEngravingPrice();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.max_cm2 = reader.int32();
                        break;
                    }
                case 7: {
                        message.material_unified = reader.bool();
                        break;
                    }
                case 6: {
                        message.currency = reader.string();
                        break;
                    }
                case 1: {
                        message.place_id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.min_cm2 = reader.int32();
                        break;
                    }
                case 2: {
                        message.material = reader.string();
                        break;
                    }
                case 5: {
                        message.price = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LaserEngravingPrice.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LaserEngravingPrice)
                return object;
            let message = new $root.object_msg.LaserEngravingPrice();
            if (object.max_cm2 != null)
                message.max_cm2 = object.max_cm2 | 0;
            if (object.material_unified != null)
                message.material_unified = Boolean(object.material_unified);
            if (object.currency != null)
                message.currency = String(object.currency);
            if (object.place_id != null)
                if ($util.Long)
                    (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                else if (typeof object.place_id === "string")
                    message.place_id = parseInt(object.place_id, 10);
                else if (typeof object.place_id === "number")
                    message.place_id = object.place_id;
                else if (typeof object.place_id === "object")
                    message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
            if (object.min_cm2 != null)
                message.min_cm2 = object.min_cm2 | 0;
            if (object.material != null)
                message.material = String(object.material);
            if (object.price != null)
                message.price = Number(object.price);
            return message;
        };

        LaserEngravingPrice.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.place_id = options.longs === String ? "0" : 0;
                object.material = "";
                object.min_cm2 = 0;
                object.max_cm2 = 0;
                object.price = 0;
                object.currency = "";
                object.material_unified = false;
            }
            if (message.place_id != null && message.hasOwnProperty("place_id"))
                if (typeof message.place_id === "number")
                    object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                else
                    object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
            if (message.material != null && message.hasOwnProperty("material"))
                object.material = message.material;
            if (message.min_cm2 != null && message.hasOwnProperty("min_cm2"))
                object.min_cm2 = message.min_cm2;
            if (message.max_cm2 != null && message.hasOwnProperty("max_cm2"))
                object.max_cm2 = message.max_cm2;
            if (message.price != null && message.hasOwnProperty("price"))
                object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            if (message.material_unified != null && message.hasOwnProperty("material_unified"))
                object.material_unified = message.material_unified;
            return object;
        };

        LaserEngravingPrice.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LaserEngravingPrice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LaserEngravingPrice";
        };

        return LaserEngravingPrice;
    })();

    object_msg.LaserEngravingPriceArray = (function() {

        function LaserEngravingPriceArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LaserEngravingPriceArray.prototype.array = $util.emptyArray;

        LaserEngravingPriceArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.LaserEngravingPrice.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        LaserEngravingPriceArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LaserEngravingPriceArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.LaserEngravingPrice.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LaserEngravingPriceArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LaserEngravingPriceArray)
                return object;
            let message = new $root.object_msg.LaserEngravingPriceArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.LaserEngravingPriceArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.LaserEngravingPriceArray.array: object expected");
                    message.array[i] = $root.object_msg.LaserEngravingPrice.fromObject(object.array[i]);
                }
            }
            return message;
        };

        LaserEngravingPriceArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.LaserEngravingPrice.toObject(message.array[j], options);
            }
            return object;
        };

        LaserEngravingPriceArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LaserEngravingPriceArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LaserEngravingPriceArray";
        };

        return LaserEngravingPriceArray;
    })();

    object_msg.LocalLaserEngravingPrice = (function() {

        function LocalLaserEngravingPrice(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LocalLaserEngravingPrice.prototype.max_cm2 = 0;
        LocalLaserEngravingPrice.prototype.reference_place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        LocalLaserEngravingPrice.prototype.currency = "";
        LocalLaserEngravingPrice.prototype.department_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        LocalLaserEngravingPrice.prototype.min_cm2 = 0;
        LocalLaserEngravingPrice.prototype.material = "";
        LocalLaserEngravingPrice.prototype.price = 0;

        LocalLaserEngravingPrice.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.department_id != null && Object.hasOwnProperty.call(message, "department_id"))
                writer.uint32(8).uint64(message.department_id);
            if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                writer.uint32(18).string(message.material);
            if (message.min_cm2 != null && Object.hasOwnProperty.call(message, "min_cm2"))
                writer.uint32(24).int32(message.min_cm2);
            if (message.max_cm2 != null && Object.hasOwnProperty.call(message, "max_cm2"))
                writer.uint32(32).int32(message.max_cm2);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                writer.uint32(45).float(message.price);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(50).string(message.currency);
            if (message.reference_place_id != null && Object.hasOwnProperty.call(message, "reference_place_id"))
                writer.uint32(56).uint64(message.reference_place_id);
            return writer;
        };

        LocalLaserEngravingPrice.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LocalLaserEngravingPrice();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.max_cm2 = reader.int32();
                        break;
                    }
                case 7: {
                        message.reference_place_id = reader.uint64();
                        break;
                    }
                case 6: {
                        message.currency = reader.string();
                        break;
                    }
                case 1: {
                        message.department_id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.min_cm2 = reader.int32();
                        break;
                    }
                case 2: {
                        message.material = reader.string();
                        break;
                    }
                case 5: {
                        message.price = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LocalLaserEngravingPrice.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LocalLaserEngravingPrice)
                return object;
            let message = new $root.object_msg.LocalLaserEngravingPrice();
            if (object.max_cm2 != null)
                message.max_cm2 = object.max_cm2 | 0;
            if (object.reference_place_id != null)
                if ($util.Long)
                    (message.reference_place_id = $util.Long.fromValue(object.reference_place_id)).unsigned = true;
                else if (typeof object.reference_place_id === "string")
                    message.reference_place_id = parseInt(object.reference_place_id, 10);
                else if (typeof object.reference_place_id === "number")
                    message.reference_place_id = object.reference_place_id;
                else if (typeof object.reference_place_id === "object")
                    message.reference_place_id = new $util.LongBits(object.reference_place_id.low >>> 0, object.reference_place_id.high >>> 0).toNumber(true);
            if (object.currency != null)
                message.currency = String(object.currency);
            if (object.department_id != null)
                if ($util.Long)
                    (message.department_id = $util.Long.fromValue(object.department_id)).unsigned = true;
                else if (typeof object.department_id === "string")
                    message.department_id = parseInt(object.department_id, 10);
                else if (typeof object.department_id === "number")
                    message.department_id = object.department_id;
                else if (typeof object.department_id === "object")
                    message.department_id = new $util.LongBits(object.department_id.low >>> 0, object.department_id.high >>> 0).toNumber(true);
            if (object.min_cm2 != null)
                message.min_cm2 = object.min_cm2 | 0;
            if (object.material != null)
                message.material = String(object.material);
            if (object.price != null)
                message.price = Number(object.price);
            return message;
        };

        LocalLaserEngravingPrice.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.department_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.department_id = options.longs === String ? "0" : 0;
                object.material = "";
                object.min_cm2 = 0;
                object.max_cm2 = 0;
                object.price = 0;
                object.currency = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.reference_place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.reference_place_id = options.longs === String ? "0" : 0;
            }
            if (message.department_id != null && message.hasOwnProperty("department_id"))
                if (typeof message.department_id === "number")
                    object.department_id = options.longs === String ? String(message.department_id) : message.department_id;
                else
                    object.department_id = options.longs === String ? $util.Long.prototype.toString.call(message.department_id) : options.longs === Number ? new $util.LongBits(message.department_id.low >>> 0, message.department_id.high >>> 0).toNumber(true) : message.department_id;
            if (message.material != null && message.hasOwnProperty("material"))
                object.material = message.material;
            if (message.min_cm2 != null && message.hasOwnProperty("min_cm2"))
                object.min_cm2 = message.min_cm2;
            if (message.max_cm2 != null && message.hasOwnProperty("max_cm2"))
                object.max_cm2 = message.max_cm2;
            if (message.price != null && message.hasOwnProperty("price"))
                object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            if (message.reference_place_id != null && message.hasOwnProperty("reference_place_id"))
                if (typeof message.reference_place_id === "number")
                    object.reference_place_id = options.longs === String ? String(message.reference_place_id) : message.reference_place_id;
                else
                    object.reference_place_id = options.longs === String ? $util.Long.prototype.toString.call(message.reference_place_id) : options.longs === Number ? new $util.LongBits(message.reference_place_id.low >>> 0, message.reference_place_id.high >>> 0).toNumber(true) : message.reference_place_id;
            return object;
        };

        LocalLaserEngravingPrice.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LocalLaserEngravingPrice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LocalLaserEngravingPrice";
        };

        return LocalLaserEngravingPrice;
    })();

    object_msg.LocalLaserEngravingPriceArray = (function() {

        function LocalLaserEngravingPriceArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LocalLaserEngravingPriceArray.prototype.array = $util.emptyArray;

        LocalLaserEngravingPriceArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.LocalLaserEngravingPrice.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        LocalLaserEngravingPriceArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LocalLaserEngravingPriceArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.LocalLaserEngravingPrice.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LocalLaserEngravingPriceArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LocalLaserEngravingPriceArray)
                return object;
            let message = new $root.object_msg.LocalLaserEngravingPriceArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.LocalLaserEngravingPriceArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.LocalLaserEngravingPriceArray.array: object expected");
                    message.array[i] = $root.object_msg.LocalLaserEngravingPrice.fromObject(object.array[i]);
                }
            }
            return message;
        };

        LocalLaserEngravingPriceArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.LocalLaserEngravingPrice.toObject(message.array[j], options);
            }
            return object;
        };

        LocalLaserEngravingPriceArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LocalLaserEngravingPriceArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LocalLaserEngravingPriceArray";
        };

        return LocalLaserEngravingPriceArray;
    })();

    object_msg.logEntry = (function() {

        function logEntry(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        logEntry.prototype["function"] = "";
        logEntry.prototype.pod = "";
        logEntry.prototype.app = "";
        logEntry.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        logEntry.prototype.message = "";
        logEntry.prototype.level = 0;
        logEntry.prototype.processed_request = "";
        logEntry.prototype.phone = "";
        logEntry.prototype.raw_request = "";
        logEntry.prototype.honeypot_error_type = 0;
        logEntry.prototype.ip = "";
        logEntry.prototype.object = "";

        logEntry.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                writer.uint32(8).int64(message.time);
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                writer.uint32(16).int32(message.level);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(26).string(message.message);
            if (message["function"] != null && Object.hasOwnProperty.call(message, "function"))
                writer.uint32(34).string(message["function"]);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                writer.uint32(42).string(message.object);
            if (message.app != null && Object.hasOwnProperty.call(message, "app"))
                writer.uint32(50).string(message.app);
            if (message.pod != null && Object.hasOwnProperty.call(message, "pod"))
                writer.uint32(58).string(message.pod);
            if (message.raw_request != null && Object.hasOwnProperty.call(message, "raw_request"))
                writer.uint32(66).string(message.raw_request);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(74).string(message.phone);
            if (message.ip != null && Object.hasOwnProperty.call(message, "ip"))
                writer.uint32(82).string(message.ip);
            if (message.honeypot_error_type != null && Object.hasOwnProperty.call(message, "honeypot_error_type"))
                writer.uint32(88).int32(message.honeypot_error_type);
            if (message.processed_request != null && Object.hasOwnProperty.call(message, "processed_request"))
                writer.uint32(98).string(message.processed_request);
            return writer;
        };

        logEntry.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.logEntry();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message["function"] = reader.string();
                        break;
                    }
                case 7: {
                        message.pod = reader.string();
                        break;
                    }
                case 6: {
                        message.app = reader.string();
                        break;
                    }
                case 1: {
                        message.time = reader.int64();
                        break;
                    }
                case 3: {
                        message.message = reader.string();
                        break;
                    }
                case 2: {
                        message.level = reader.int32();
                        break;
                    }
                case 12: {
                        message.processed_request = reader.string();
                        break;
                    }
                case 9: {
                        message.phone = reader.string();
                        break;
                    }
                case 8: {
                        message.raw_request = reader.string();
                        break;
                    }
                case 11: {
                        message.honeypot_error_type = reader.int32();
                        break;
                    }
                case 10: {
                        message.ip = reader.string();
                        break;
                    }
                case 5: {
                        message.object = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        logEntry.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.logEntry)
                return object;
            let message = new $root.object_msg.logEntry();
            if (object["function"] != null)
                message["function"] = String(object["function"]);
            if (object.pod != null)
                message.pod = String(object.pod);
            if (object.app != null)
                message.app = String(object.app);
            if (object.time != null)
                if ($util.Long)
                    (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                else if (typeof object.time === "string")
                    message.time = parseInt(object.time, 10);
                else if (typeof object.time === "number")
                    message.time = object.time;
                else if (typeof object.time === "object")
                    message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
            if (object.message != null)
                message.message = String(object.message);
            if (object.level != null)
                message.level = object.level | 0;
            if (object.processed_request != null)
                message.processed_request = String(object.processed_request);
            if (object.phone != null)
                message.phone = String(object.phone);
            if (object.raw_request != null)
                message.raw_request = String(object.raw_request);
            if (object.honeypot_error_type != null)
                message.honeypot_error_type = object.honeypot_error_type | 0;
            if (object.ip != null)
                message.ip = String(object.ip);
            if (object.object != null)
                message.object = String(object.object);
            return message;
        };

        logEntry.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.time = options.longs === String ? "0" : 0;
                object.level = 0;
                object.message = "";
                object["function"] = "";
                object.object = "";
                object.app = "";
                object.pod = "";
                object.raw_request = "";
                object.phone = "";
                object.ip = "";
                object.honeypot_error_type = 0;
                object.processed_request = "";
            }
            if (message.time != null && message.hasOwnProperty("time"))
                if (typeof message.time === "number")
                    object.time = options.longs === String ? String(message.time) : message.time;
                else
                    object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
            if (message.level != null && message.hasOwnProperty("level"))
                object.level = message.level;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            if (message["function"] != null && message.hasOwnProperty("function"))
                object["function"] = message["function"];
            if (message.object != null && message.hasOwnProperty("object"))
                object.object = message.object;
            if (message.app != null && message.hasOwnProperty("app"))
                object.app = message.app;
            if (message.pod != null && message.hasOwnProperty("pod"))
                object.pod = message.pod;
            if (message.raw_request != null && message.hasOwnProperty("raw_request"))
                object.raw_request = message.raw_request;
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.ip != null && message.hasOwnProperty("ip"))
                object.ip = message.ip;
            if (message.honeypot_error_type != null && message.hasOwnProperty("honeypot_error_type"))
                object.honeypot_error_type = message.honeypot_error_type;
            if (message.processed_request != null && message.hasOwnProperty("processed_request"))
                object.processed_request = message.processed_request;
            return object;
        };

        logEntry.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        logEntry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.logEntry";
        };

        return logEntry;
    })();

    object_msg.logEntryArray = (function() {

        function logEntryArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        logEntryArray.prototype.array = $util.emptyArray;

        logEntryArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.logEntry.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        logEntryArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.logEntryArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.logEntry.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        logEntryArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.logEntryArray)
                return object;
            let message = new $root.object_msg.logEntryArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.logEntryArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.logEntryArray.array: object expected");
                    message.array[i] = $root.object_msg.logEntry.fromObject(object.array[i]);
                }
            }
            return message;
        };

        logEntryArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.logEntry.toObject(message.array[j], options);
            }
            return object;
        };

        logEntryArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        logEntryArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.logEntryArray";
        };

        return logEntryArray;
    })();

    object_msg.CityRu = (function() {

        function CityRu(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        CityRu.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        CityRu.prototype.region = "";
        CityRu.prototype.name = "";
        CityRu.prototype.population = 0;

        CityRu.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).uint64(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(18).string(message.name);
            if (message.region != null && Object.hasOwnProperty.call(message, "region"))
                writer.uint32(26).string(message.region);
            if (message.population != null && Object.hasOwnProperty.call(message, "population"))
                writer.uint32(40).int32(message.population);
            return writer;
        };

        CityRu.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.CityRu();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.region = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 5: {
                        message.population = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        CityRu.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.CityRu)
                return object;
            let message = new $root.object_msg.CityRu();
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.region != null)
                message.region = String(object.region);
            if (object.name != null)
                message.name = String(object.name);
            if (object.population != null)
                message.population = object.population | 0;
            return message;
        };

        CityRu.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.name = "";
                object.region = "";
                object.population = 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.region != null && message.hasOwnProperty("region"))
                object.region = message.region;
            if (message.population != null && message.hasOwnProperty("population"))
                object.population = message.population;
            return object;
        };

        CityRu.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        CityRu.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.CityRu";
        };

        return CityRu;
    })();

    object_msg.CityRuArray = (function() {

        function CityRuArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        CityRuArray.prototype.array = $util.emptyArray;

        CityRuArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.CityRu.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        CityRuArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.CityRuArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.CityRu.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        CityRuArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.CityRuArray)
                return object;
            let message = new $root.object_msg.CityRuArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.CityRuArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.CityRuArray.array: object expected");
                    message.array[i] = $root.object_msg.CityRu.fromObject(object.array[i]);
                }
            }
            return message;
        };

        CityRuArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.CityRu.toObject(message.array[j], options);
            }
            return object;
        };

        CityRuArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        CityRuArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.CityRuArray";
        };

        return CityRuArray;
    })();

    object_msg.CityAction = (function() {

        function CityAction(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        CityAction.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        CityAction.prototype.action = "";
        CityAction.prototype.city_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        CityAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                writer.uint32(8).uint64(message.place_id);
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(18).string(message.action);
            if (message.city_id != null && Object.hasOwnProperty.call(message, "city_id"))
                writer.uint32(40).uint64(message.city_id);
            return writer;
        };

        CityAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.CityAction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.place_id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.action = reader.string();
                        break;
                    }
                case 5: {
                        message.city_id = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        CityAction.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.CityAction)
                return object;
            let message = new $root.object_msg.CityAction();
            if (object.place_id != null)
                if ($util.Long)
                    (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                else if (typeof object.place_id === "string")
                    message.place_id = parseInt(object.place_id, 10);
                else if (typeof object.place_id === "number")
                    message.place_id = object.place_id;
                else if (typeof object.place_id === "object")
                    message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
            if (object.action != null)
                message.action = String(object.action);
            if (object.city_id != null)
                if ($util.Long)
                    (message.city_id = $util.Long.fromValue(object.city_id)).unsigned = true;
                else if (typeof object.city_id === "string")
                    message.city_id = parseInt(object.city_id, 10);
                else if (typeof object.city_id === "number")
                    message.city_id = object.city_id;
                else if (typeof object.city_id === "object")
                    message.city_id = new $util.LongBits(object.city_id.low >>> 0, object.city_id.high >>> 0).toNumber(true);
            return message;
        };

        CityAction.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.place_id = options.longs === String ? "0" : 0;
                object.action = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.city_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.city_id = options.longs === String ? "0" : 0;
            }
            if (message.place_id != null && message.hasOwnProperty("place_id"))
                if (typeof message.place_id === "number")
                    object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                else
                    object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = message.action;
            if (message.city_id != null && message.hasOwnProperty("city_id"))
                if (typeof message.city_id === "number")
                    object.city_id = options.longs === String ? String(message.city_id) : message.city_id;
                else
                    object.city_id = options.longs === String ? $util.Long.prototype.toString.call(message.city_id) : options.longs === Number ? new $util.LongBits(message.city_id.low >>> 0, message.city_id.high >>> 0).toNumber(true) : message.city_id;
            return object;
        };

        CityAction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        CityAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.CityAction";
        };

        return CityAction;
    })();

    object_msg.CityActionArray = (function() {

        function CityActionArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        CityActionArray.prototype.array = $util.emptyArray;

        CityActionArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.CityAction.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        CityActionArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.CityActionArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.CityAction.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        CityActionArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.CityActionArray)
                return object;
            let message = new $root.object_msg.CityActionArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.CityActionArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.CityActionArray.array: object expected");
                    message.array[i] = $root.object_msg.CityAction.fromObject(object.array[i]);
                }
            }
            return message;
        };

        CityActionArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.CityAction.toObject(message.array[j], options);
            }
            return object;
        };

        CityActionArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        CityActionArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.CityActionArray";
        };

        return CityActionArray;
    })();

    object_msg.Order = (function() {

        function Order(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        Order.prototype.delivery_profile_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        Order.prototype.delivery_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Order.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        Order.prototype.payment_by_individual = false;
        Order.prototype.name = "";
        Order.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        Order.prototype.payment_organization_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        Order.prototype.creation_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        Order.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).uint64(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(18).string(message.name);
            if (message.payment_by_individual != null && Object.hasOwnProperty.call(message, "payment_by_individual"))
                writer.uint32(24).bool(message.payment_by_individual);
            if (message.creation_time != null && Object.hasOwnProperty.call(message, "creation_time"))
                writer.uint32(40).int64(message.creation_time);
            if (message.delivery_time != null && Object.hasOwnProperty.call(message, "delivery_time"))
                writer.uint32(48).int64(message.delivery_time);
            if (message.delivery_profile_id != null && Object.hasOwnProperty.call(message, "delivery_profile_id"))
                writer.uint32(56).uint64(message.delivery_profile_id);
            if (message.payment_organization_id != null && Object.hasOwnProperty.call(message, "payment_organization_id"))
                writer.uint32(64).uint64(message.payment_organization_id);
            if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                writer.uint32(72).uint64(message.user_id);
            return writer;
        };

        Order.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.Order();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 7: {
                        message.delivery_profile_id = reader.uint64();
                        break;
                    }
                case 6: {
                        message.delivery_time = reader.int64();
                        break;
                    }
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.payment_by_individual = reader.bool();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 9: {
                        message.user_id = reader.uint64();
                        break;
                    }
                case 8: {
                        message.payment_organization_id = reader.uint64();
                        break;
                    }
                case 5: {
                        message.creation_time = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Order.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.Order)
                return object;
            let message = new $root.object_msg.Order();
            if (object.delivery_profile_id != null)
                if ($util.Long)
                    (message.delivery_profile_id = $util.Long.fromValue(object.delivery_profile_id)).unsigned = true;
                else if (typeof object.delivery_profile_id === "string")
                    message.delivery_profile_id = parseInt(object.delivery_profile_id, 10);
                else if (typeof object.delivery_profile_id === "number")
                    message.delivery_profile_id = object.delivery_profile_id;
                else if (typeof object.delivery_profile_id === "object")
                    message.delivery_profile_id = new $util.LongBits(object.delivery_profile_id.low >>> 0, object.delivery_profile_id.high >>> 0).toNumber(true);
            if (object.delivery_time != null)
                if ($util.Long)
                    (message.delivery_time = $util.Long.fromValue(object.delivery_time)).unsigned = false;
                else if (typeof object.delivery_time === "string")
                    message.delivery_time = parseInt(object.delivery_time, 10);
                else if (typeof object.delivery_time === "number")
                    message.delivery_time = object.delivery_time;
                else if (typeof object.delivery_time === "object")
                    message.delivery_time = new $util.LongBits(object.delivery_time.low >>> 0, object.delivery_time.high >>> 0).toNumber();
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.payment_by_individual != null)
                message.payment_by_individual = Boolean(object.payment_by_individual);
            if (object.name != null)
                message.name = String(object.name);
            if (object.user_id != null)
                if ($util.Long)
                    (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = true;
                else if (typeof object.user_id === "string")
                    message.user_id = parseInt(object.user_id, 10);
                else if (typeof object.user_id === "number")
                    message.user_id = object.user_id;
                else if (typeof object.user_id === "object")
                    message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber(true);
            if (object.payment_organization_id != null)
                if ($util.Long)
                    (message.payment_organization_id = $util.Long.fromValue(object.payment_organization_id)).unsigned = true;
                else if (typeof object.payment_organization_id === "string")
                    message.payment_organization_id = parseInt(object.payment_organization_id, 10);
                else if (typeof object.payment_organization_id === "number")
                    message.payment_organization_id = object.payment_organization_id;
                else if (typeof object.payment_organization_id === "object")
                    message.payment_organization_id = new $util.LongBits(object.payment_organization_id.low >>> 0, object.payment_organization_id.high >>> 0).toNumber(true);
            if (object.creation_time != null)
                if ($util.Long)
                    (message.creation_time = $util.Long.fromValue(object.creation_time)).unsigned = false;
                else if (typeof object.creation_time === "string")
                    message.creation_time = parseInt(object.creation_time, 10);
                else if (typeof object.creation_time === "number")
                    message.creation_time = object.creation_time;
                else if (typeof object.creation_time === "object")
                    message.creation_time = new $util.LongBits(object.creation_time.low >>> 0, object.creation_time.high >>> 0).toNumber();
            return message;
        };

        Order.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.name = "";
                object.payment_by_individual = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.creation_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.creation_time = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.delivery_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.delivery_time = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.delivery_profile_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.delivery_profile_id = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.payment_organization_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.payment_organization_id = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.user_id = options.longs === String ? "0" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.payment_by_individual != null && message.hasOwnProperty("payment_by_individual"))
                object.payment_by_individual = message.payment_by_individual;
            if (message.creation_time != null && message.hasOwnProperty("creation_time"))
                if (typeof message.creation_time === "number")
                    object.creation_time = options.longs === String ? String(message.creation_time) : message.creation_time;
                else
                    object.creation_time = options.longs === String ? $util.Long.prototype.toString.call(message.creation_time) : options.longs === Number ? new $util.LongBits(message.creation_time.low >>> 0, message.creation_time.high >>> 0).toNumber() : message.creation_time;
            if (message.delivery_time != null && message.hasOwnProperty("delivery_time"))
                if (typeof message.delivery_time === "number")
                    object.delivery_time = options.longs === String ? String(message.delivery_time) : message.delivery_time;
                else
                    object.delivery_time = options.longs === String ? $util.Long.prototype.toString.call(message.delivery_time) : options.longs === Number ? new $util.LongBits(message.delivery_time.low >>> 0, message.delivery_time.high >>> 0).toNumber() : message.delivery_time;
            if (message.delivery_profile_id != null && message.hasOwnProperty("delivery_profile_id"))
                if (typeof message.delivery_profile_id === "number")
                    object.delivery_profile_id = options.longs === String ? String(message.delivery_profile_id) : message.delivery_profile_id;
                else
                    object.delivery_profile_id = options.longs === String ? $util.Long.prototype.toString.call(message.delivery_profile_id) : options.longs === Number ? new $util.LongBits(message.delivery_profile_id.low >>> 0, message.delivery_profile_id.high >>> 0).toNumber(true) : message.delivery_profile_id;
            if (message.payment_organization_id != null && message.hasOwnProperty("payment_organization_id"))
                if (typeof message.payment_organization_id === "number")
                    object.payment_organization_id = options.longs === String ? String(message.payment_organization_id) : message.payment_organization_id;
                else
                    object.payment_organization_id = options.longs === String ? $util.Long.prototype.toString.call(message.payment_organization_id) : options.longs === Number ? new $util.LongBits(message.payment_organization_id.low >>> 0, message.payment_organization_id.high >>> 0).toNumber(true) : message.payment_organization_id;
            if (message.user_id != null && message.hasOwnProperty("user_id"))
                if (typeof message.user_id === "number")
                    object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                else
                    object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber(true) : message.user_id;
            return object;
        };

        Order.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        Order.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.Order";
        };

        return Order;
    })();

    object_msg.OrderArray = (function() {

        function OrderArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderArray.prototype.array = $util.emptyArray;

        OrderArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.Order.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrderArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.Order.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderArray)
                return object;
            let message = new $root.object_msg.OrderArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrderArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrderArray.array: object expected");
                    message.array[i] = $root.object_msg.Order.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrderArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.Order.toObject(message.array[j], options);
            }
            return object;
        };

        OrderArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderArray";
        };

        return OrderArray;
    })();

    object_msg.OrderStatus = (function() {

        function OrderStatus(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStatus.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderStatus.prototype.status = "";
        OrderStatus.prototype.creation_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        OrderStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(8).uint64(message.order_id);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(18).string(message.status);
            if (message.creation_time != null && Object.hasOwnProperty.call(message, "creation_time"))
                writer.uint32(40).int64(message.creation_time);
            return writer;
        };

        OrderStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStatus();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.order_id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.status = reader.string();
                        break;
                    }
                case 5: {
                        message.creation_time = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStatus)
                return object;
            let message = new $root.object_msg.OrderStatus();
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            if (object.status != null)
                message.status = String(object.status);
            if (object.creation_time != null)
                if ($util.Long)
                    (message.creation_time = $util.Long.fromValue(object.creation_time)).unsigned = false;
                else if (typeof object.creation_time === "string")
                    message.creation_time = parseInt(object.creation_time, 10);
                else if (typeof object.creation_time === "number")
                    message.creation_time = object.creation_time;
                else if (typeof object.creation_time === "object")
                    message.creation_time = new $util.LongBits(object.creation_time.low >>> 0, object.creation_time.high >>> 0).toNumber();
            return message;
        };

        OrderStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
                object.status = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.creation_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.creation_time = options.longs === String ? "0" : 0;
            }
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.creation_time != null && message.hasOwnProperty("creation_time"))
                if (typeof message.creation_time === "number")
                    object.creation_time = options.longs === String ? String(message.creation_time) : message.creation_time;
                else
                    object.creation_time = options.longs === String ? $util.Long.prototype.toString.call(message.creation_time) : options.longs === Number ? new $util.LongBits(message.creation_time.low >>> 0, message.creation_time.high >>> 0).toNumber() : message.creation_time;
            return object;
        };

        OrderStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStatus";
        };

        return OrderStatus;
    })();

    object_msg.OrderStatusArray = (function() {

        function OrderStatusArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStatusArray.prototype.array = $util.emptyArray;

        OrderStatusArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrderStatus.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrderStatusArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStatusArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrderStatus.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStatusArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStatusArray)
                return object;
            let message = new $root.object_msg.OrderStatusArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrderStatusArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrderStatusArray.array: object expected");
                    message.array[i] = $root.object_msg.OrderStatus.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrderStatusArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrderStatus.toObject(message.array[j], options);
            }
            return object;
        };

        OrderStatusArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStatusArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStatusArray";
        };

        return OrderStatusArray;
    })();

    object_msg.OrderComment = (function() {

        function OrderComment(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderComment.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderComment.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderComment.prototype.author_name = "";
        OrderComment.prototype.comment = "";
        OrderComment.prototype.creation_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        OrderComment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(8).uint64(message.order_id);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(18).string(message.comment);
            if (message.author_name != null && Object.hasOwnProperty.call(message, "author_name"))
                writer.uint32(26).string(message.author_name);
            if (message.creation_time != null && Object.hasOwnProperty.call(message, "creation_time"))
                writer.uint32(40).int64(message.creation_time);
            if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                writer.uint32(48).uint64(message.user_id);
            return writer;
        };

        OrderComment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderComment();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 6: {
                        message.user_id = reader.uint64();
                        break;
                    }
                case 1: {
                        message.order_id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.author_name = reader.string();
                        break;
                    }
                case 2: {
                        message.comment = reader.string();
                        break;
                    }
                case 5: {
                        message.creation_time = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderComment.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderComment)
                return object;
            let message = new $root.object_msg.OrderComment();
            if (object.user_id != null)
                if ($util.Long)
                    (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = true;
                else if (typeof object.user_id === "string")
                    message.user_id = parseInt(object.user_id, 10);
                else if (typeof object.user_id === "number")
                    message.user_id = object.user_id;
                else if (typeof object.user_id === "object")
                    message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber(true);
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            if (object.author_name != null)
                message.author_name = String(object.author_name);
            if (object.comment != null)
                message.comment = String(object.comment);
            if (object.creation_time != null)
                if ($util.Long)
                    (message.creation_time = $util.Long.fromValue(object.creation_time)).unsigned = false;
                else if (typeof object.creation_time === "string")
                    message.creation_time = parseInt(object.creation_time, 10);
                else if (typeof object.creation_time === "number")
                    message.creation_time = object.creation_time;
                else if (typeof object.creation_time === "object")
                    message.creation_time = new $util.LongBits(object.creation_time.low >>> 0, object.creation_time.high >>> 0).toNumber();
            return message;
        };

        OrderComment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
                object.comment = "";
                object.author_name = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.creation_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.creation_time = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.user_id = options.longs === String ? "0" : 0;
            }
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            if (message.comment != null && message.hasOwnProperty("comment"))
                object.comment = message.comment;
            if (message.author_name != null && message.hasOwnProperty("author_name"))
                object.author_name = message.author_name;
            if (message.creation_time != null && message.hasOwnProperty("creation_time"))
                if (typeof message.creation_time === "number")
                    object.creation_time = options.longs === String ? String(message.creation_time) : message.creation_time;
                else
                    object.creation_time = options.longs === String ? $util.Long.prototype.toString.call(message.creation_time) : options.longs === Number ? new $util.LongBits(message.creation_time.low >>> 0, message.creation_time.high >>> 0).toNumber() : message.creation_time;
            if (message.user_id != null && message.hasOwnProperty("user_id"))
                if (typeof message.user_id === "number")
                    object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                else
                    object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber(true) : message.user_id;
            return object;
        };

        OrderComment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderComment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderComment";
        };

        return OrderComment;
    })();

    object_msg.OrderCommentArray = (function() {

        function OrderCommentArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderCommentArray.prototype.array = $util.emptyArray;

        OrderCommentArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrderComment.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrderCommentArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderCommentArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrderComment.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderCommentArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderCommentArray)
                return object;
            let message = new $root.object_msg.OrderCommentArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrderCommentArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrderCommentArray.array: object expected");
                    message.array[i] = $root.object_msg.OrderComment.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrderCommentArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrderComment.toObject(message.array[j], options);
            }
            return object;
        };

        OrderCommentArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderCommentArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderCommentArray";
        };

        return OrderCommentArray;
    })();

    object_msg.OrderCommentFile = (function() {

        function OrderCommentFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderCommentFile.prototype.file_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderCommentFile.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderCommentFile.prototype.original_name = "";
        OrderCommentFile.prototype.comment_creation_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        OrderCommentFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(8).uint64(message.order_id);
            if (message.original_name != null && Object.hasOwnProperty.call(message, "original_name"))
                writer.uint32(26).string(message.original_name);
            if (message.comment_creation_time != null && Object.hasOwnProperty.call(message, "comment_creation_time"))
                writer.uint32(40).int64(message.comment_creation_time);
            if (message.file_id != null && Object.hasOwnProperty.call(message, "file_id"))
                writer.uint32(48).uint64(message.file_id);
            return writer;
        };

        OrderCommentFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderCommentFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 6: {
                        message.file_id = reader.uint64();
                        break;
                    }
                case 1: {
                        message.order_id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.original_name = reader.string();
                        break;
                    }
                case 5: {
                        message.comment_creation_time = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderCommentFile.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderCommentFile)
                return object;
            let message = new $root.object_msg.OrderCommentFile();
            if (object.file_id != null)
                if ($util.Long)
                    (message.file_id = $util.Long.fromValue(object.file_id)).unsigned = true;
                else if (typeof object.file_id === "string")
                    message.file_id = parseInt(object.file_id, 10);
                else if (typeof object.file_id === "number")
                    message.file_id = object.file_id;
                else if (typeof object.file_id === "object")
                    message.file_id = new $util.LongBits(object.file_id.low >>> 0, object.file_id.high >>> 0).toNumber(true);
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            if (object.original_name != null)
                message.original_name = String(object.original_name);
            if (object.comment_creation_time != null)
                if ($util.Long)
                    (message.comment_creation_time = $util.Long.fromValue(object.comment_creation_time)).unsigned = false;
                else if (typeof object.comment_creation_time === "string")
                    message.comment_creation_time = parseInt(object.comment_creation_time, 10);
                else if (typeof object.comment_creation_time === "number")
                    message.comment_creation_time = object.comment_creation_time;
                else if (typeof object.comment_creation_time === "object")
                    message.comment_creation_time = new $util.LongBits(object.comment_creation_time.low >>> 0, object.comment_creation_time.high >>> 0).toNumber();
            return message;
        };

        OrderCommentFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
                object.original_name = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.comment_creation_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.comment_creation_time = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.file_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.file_id = options.longs === String ? "0" : 0;
            }
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            if (message.original_name != null && message.hasOwnProperty("original_name"))
                object.original_name = message.original_name;
            if (message.comment_creation_time != null && message.hasOwnProperty("comment_creation_time"))
                if (typeof message.comment_creation_time === "number")
                    object.comment_creation_time = options.longs === String ? String(message.comment_creation_time) : message.comment_creation_time;
                else
                    object.comment_creation_time = options.longs === String ? $util.Long.prototype.toString.call(message.comment_creation_time) : options.longs === Number ? new $util.LongBits(message.comment_creation_time.low >>> 0, message.comment_creation_time.high >>> 0).toNumber() : message.comment_creation_time;
            if (message.file_id != null && message.hasOwnProperty("file_id"))
                if (typeof message.file_id === "number")
                    object.file_id = options.longs === String ? String(message.file_id) : message.file_id;
                else
                    object.file_id = options.longs === String ? $util.Long.prototype.toString.call(message.file_id) : options.longs === Number ? new $util.LongBits(message.file_id.low >>> 0, message.file_id.high >>> 0).toNumber(true) : message.file_id;
            return object;
        };

        OrderCommentFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderCommentFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderCommentFile";
        };

        return OrderCommentFile;
    })();

    object_msg.OrderCommentFileArray = (function() {

        function OrderCommentFileArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderCommentFileArray.prototype.array = $util.emptyArray;

        OrderCommentFileArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrderCommentFile.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrderCommentFileArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderCommentFileArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrderCommentFile.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderCommentFileArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderCommentFileArray)
                return object;
            let message = new $root.object_msg.OrderCommentFileArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrderCommentFileArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrderCommentFileArray.array: object expected");
                    message.array[i] = $root.object_msg.OrderCommentFile.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrderCommentFileArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrderCommentFile.toObject(message.array[j], options);
            }
            return object;
        };

        OrderCommentFileArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderCommentFileArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderCommentFileArray";
        };

        return OrderCommentFileArray;
    })();

    object_msg.UserDeliveryProfile = (function() {

        function UserDeliveryProfile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        UserDeliveryProfile.prototype.phone = "";
        UserDeliveryProfile.prototype.town = "";
        UserDeliveryProfile.prototype.last_use_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        UserDeliveryProfile.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        UserDeliveryProfile.prototype.address = "";
        UserDeliveryProfile.prototype.contact_person = "";
        UserDeliveryProfile.prototype.longitude = 0;
        UserDeliveryProfile.prototype.latitude = 0;
        UserDeliveryProfile.prototype.archive = false;
        UserDeliveryProfile.prototype.address_quality = 0;
        UserDeliveryProfile.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        UserDeliveryProfile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).uint64(message.id);
            if (message.contact_person != null && Object.hasOwnProperty.call(message, "contact_person"))
                writer.uint32(18).string(message.contact_person);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                writer.uint32(26).string(message.address);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(34).string(message.phone);
            if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                writer.uint32(40).uint64(message.user_id);
            if (message.last_use_time != null && Object.hasOwnProperty.call(message, "last_use_time"))
                writer.uint32(48).int64(message.last_use_time);
            if (message.town != null && Object.hasOwnProperty.call(message, "town"))
                writer.uint32(58).string(message.town);
            if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                writer.uint32(69).float(message.latitude);
            if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                writer.uint32(77).float(message.longitude);
            if (message.address_quality != null && Object.hasOwnProperty.call(message, "address_quality"))
                writer.uint32(80).int32(message.address_quality);
            if (message.archive != null && Object.hasOwnProperty.call(message, "archive"))
                writer.uint32(88).bool(message.archive);
            return writer;
        };

        UserDeliveryProfile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.UserDeliveryProfile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.phone = reader.string();
                        break;
                    }
                case 7: {
                        message.town = reader.string();
                        break;
                    }
                case 6: {
                        message.last_use_time = reader.int64();
                        break;
                    }
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.address = reader.string();
                        break;
                    }
                case 2: {
                        message.contact_person = reader.string();
                        break;
                    }
                case 9: {
                        message.longitude = reader.float();
                        break;
                    }
                case 8: {
                        message.latitude = reader.float();
                        break;
                    }
                case 11: {
                        message.archive = reader.bool();
                        break;
                    }
                case 10: {
                        message.address_quality = reader.int32();
                        break;
                    }
                case 5: {
                        message.user_id = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        UserDeliveryProfile.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.UserDeliveryProfile)
                return object;
            let message = new $root.object_msg.UserDeliveryProfile();
            if (object.phone != null)
                message.phone = String(object.phone);
            if (object.town != null)
                message.town = String(object.town);
            if (object.last_use_time != null)
                if ($util.Long)
                    (message.last_use_time = $util.Long.fromValue(object.last_use_time)).unsigned = false;
                else if (typeof object.last_use_time === "string")
                    message.last_use_time = parseInt(object.last_use_time, 10);
                else if (typeof object.last_use_time === "number")
                    message.last_use_time = object.last_use_time;
                else if (typeof object.last_use_time === "object")
                    message.last_use_time = new $util.LongBits(object.last_use_time.low >>> 0, object.last_use_time.high >>> 0).toNumber();
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.address != null)
                message.address = String(object.address);
            if (object.contact_person != null)
                message.contact_person = String(object.contact_person);
            if (object.longitude != null)
                message.longitude = Number(object.longitude);
            if (object.latitude != null)
                message.latitude = Number(object.latitude);
            if (object.archive != null)
                message.archive = Boolean(object.archive);
            if (object.address_quality != null)
                message.address_quality = object.address_quality | 0;
            if (object.user_id != null)
                if ($util.Long)
                    (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = true;
                else if (typeof object.user_id === "string")
                    message.user_id = parseInt(object.user_id, 10);
                else if (typeof object.user_id === "number")
                    message.user_id = object.user_id;
                else if (typeof object.user_id === "object")
                    message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber(true);
            return message;
        };

        UserDeliveryProfile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.contact_person = "";
                object.address = "";
                object.phone = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.user_id = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.last_use_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.last_use_time = options.longs === String ? "0" : 0;
                object.town = "";
                object.latitude = 0;
                object.longitude = 0;
                object.address_quality = 0;
                object.archive = false;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.contact_person != null && message.hasOwnProperty("contact_person"))
                object.contact_person = message.contact_person;
            if (message.address != null && message.hasOwnProperty("address"))
                object.address = message.address;
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.user_id != null && message.hasOwnProperty("user_id"))
                if (typeof message.user_id === "number")
                    object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                else
                    object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber(true) : message.user_id;
            if (message.last_use_time != null && message.hasOwnProperty("last_use_time"))
                if (typeof message.last_use_time === "number")
                    object.last_use_time = options.longs === String ? String(message.last_use_time) : message.last_use_time;
                else
                    object.last_use_time = options.longs === String ? $util.Long.prototype.toString.call(message.last_use_time) : options.longs === Number ? new $util.LongBits(message.last_use_time.low >>> 0, message.last_use_time.high >>> 0).toNumber() : message.last_use_time;
            if (message.town != null && message.hasOwnProperty("town"))
                object.town = message.town;
            if (message.latitude != null && message.hasOwnProperty("latitude"))
                object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
            if (message.longitude != null && message.hasOwnProperty("longitude"))
                object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
            if (message.address_quality != null && message.hasOwnProperty("address_quality"))
                object.address_quality = message.address_quality;
            if (message.archive != null && message.hasOwnProperty("archive"))
                object.archive = message.archive;
            return object;
        };

        UserDeliveryProfile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        UserDeliveryProfile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.UserDeliveryProfile";
        };

        return UserDeliveryProfile;
    })();

    object_msg.UserDeliveryProfileArray = (function() {

        function UserDeliveryProfileArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        UserDeliveryProfileArray.prototype.array = $util.emptyArray;

        UserDeliveryProfileArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.UserDeliveryProfile.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        UserDeliveryProfileArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.UserDeliveryProfileArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.UserDeliveryProfile.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        UserDeliveryProfileArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.UserDeliveryProfileArray)
                return object;
            let message = new $root.object_msg.UserDeliveryProfileArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.UserDeliveryProfileArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.UserDeliveryProfileArray.array: object expected");
                    message.array[i] = $root.object_msg.UserDeliveryProfile.fromObject(object.array[i]);
                }
            }
            return message;
        };

        UserDeliveryProfileArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.UserDeliveryProfile.toObject(message.array[j], options);
            }
            return object;
        };

        UserDeliveryProfileArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        UserDeliveryProfileArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.UserDeliveryProfileArray";
        };

        return UserDeliveryProfileArray;
    })();

    object_msg.OrderStage = (function() {

        function OrderStage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStage.prototype.action = "";
        OrderStage.prototype.approved_by_manager = false;
        OrderStage.prototype.clients_material = false;
        OrderStage.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderStage.prototype.name = "";
        OrderStage.prototype.stage_number = 0;
        OrderStage.prototype.price = 0;
        OrderStage.prototype.manager_user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderStage.prototype.in_predefined_range = false;
        OrderStage.prototype.approximate_price = 0;
        OrderStage.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        OrderStage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(8).uint64(message.order_id);
            if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                writer.uint32(16).int32(message.stage_number);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(26).string(message.name);
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(34).string(message.action);
            if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                writer.uint32(40).uint64(message.place_id);
            if (message.clients_material != null && Object.hasOwnProperty.call(message, "clients_material"))
                writer.uint32(48).bool(message.clients_material);
            if (message.approved_by_manager != null && Object.hasOwnProperty.call(message, "approved_by_manager"))
                writer.uint32(56).bool(message.approved_by_manager);
            if (message.manager_user_id != null && Object.hasOwnProperty.call(message, "manager_user_id"))
                writer.uint32(64).uint64(message.manager_user_id);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                writer.uint32(77).float(message.price);
            if (message.approximate_price != null && Object.hasOwnProperty.call(message, "approximate_price"))
                writer.uint32(85).float(message.approximate_price);
            if (message.in_predefined_range != null && Object.hasOwnProperty.call(message, "in_predefined_range"))
                writer.uint32(88).bool(message.in_predefined_range);
            return writer;
        };

        OrderStage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.action = reader.string();
                        break;
                    }
                case 7: {
                        message.approved_by_manager = reader.bool();
                        break;
                    }
                case 6: {
                        message.clients_material = reader.bool();
                        break;
                    }
                case 1: {
                        message.order_id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.stage_number = reader.int32();
                        break;
                    }
                case 9: {
                        message.price = reader.float();
                        break;
                    }
                case 8: {
                        message.manager_user_id = reader.uint64();
                        break;
                    }
                case 11: {
                        message.in_predefined_range = reader.bool();
                        break;
                    }
                case 10: {
                        message.approximate_price = reader.float();
                        break;
                    }
                case 5: {
                        message.place_id = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStage.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStage)
                return object;
            let message = new $root.object_msg.OrderStage();
            if (object.action != null)
                message.action = String(object.action);
            if (object.approved_by_manager != null)
                message.approved_by_manager = Boolean(object.approved_by_manager);
            if (object.clients_material != null)
                message.clients_material = Boolean(object.clients_material);
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            if (object.name != null)
                message.name = String(object.name);
            if (object.stage_number != null)
                message.stage_number = object.stage_number | 0;
            if (object.price != null)
                message.price = Number(object.price);
            if (object.manager_user_id != null)
                if ($util.Long)
                    (message.manager_user_id = $util.Long.fromValue(object.manager_user_id)).unsigned = true;
                else if (typeof object.manager_user_id === "string")
                    message.manager_user_id = parseInt(object.manager_user_id, 10);
                else if (typeof object.manager_user_id === "number")
                    message.manager_user_id = object.manager_user_id;
                else if (typeof object.manager_user_id === "object")
                    message.manager_user_id = new $util.LongBits(object.manager_user_id.low >>> 0, object.manager_user_id.high >>> 0).toNumber(true);
            if (object.in_predefined_range != null)
                message.in_predefined_range = Boolean(object.in_predefined_range);
            if (object.approximate_price != null)
                message.approximate_price = Number(object.approximate_price);
            if (object.place_id != null)
                if ($util.Long)
                    (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                else if (typeof object.place_id === "string")
                    message.place_id = parseInt(object.place_id, 10);
                else if (typeof object.place_id === "number")
                    message.place_id = object.place_id;
                else if (typeof object.place_id === "object")
                    message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
            return message;
        };

        OrderStage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
                object.stage_number = 0;
                object.name = "";
                object.action = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.place_id = options.longs === String ? "0" : 0;
                object.clients_material = false;
                object.approved_by_manager = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.manager_user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.manager_user_id = options.longs === String ? "0" : 0;
                object.price = 0;
                object.approximate_price = 0;
                object.in_predefined_range = false;
            }
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                object.stage_number = message.stage_number;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = message.action;
            if (message.place_id != null && message.hasOwnProperty("place_id"))
                if (typeof message.place_id === "number")
                    object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                else
                    object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
            if (message.clients_material != null && message.hasOwnProperty("clients_material"))
                object.clients_material = message.clients_material;
            if (message.approved_by_manager != null && message.hasOwnProperty("approved_by_manager"))
                object.approved_by_manager = message.approved_by_manager;
            if (message.manager_user_id != null && message.hasOwnProperty("manager_user_id"))
                if (typeof message.manager_user_id === "number")
                    object.manager_user_id = options.longs === String ? String(message.manager_user_id) : message.manager_user_id;
                else
                    object.manager_user_id = options.longs === String ? $util.Long.prototype.toString.call(message.manager_user_id) : options.longs === Number ? new $util.LongBits(message.manager_user_id.low >>> 0, message.manager_user_id.high >>> 0).toNumber(true) : message.manager_user_id;
            if (message.price != null && message.hasOwnProperty("price"))
                object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
            if (message.approximate_price != null && message.hasOwnProperty("approximate_price"))
                object.approximate_price = options.json && !isFinite(message.approximate_price) ? String(message.approximate_price) : message.approximate_price;
            if (message.in_predefined_range != null && message.hasOwnProperty("in_predefined_range"))
                object.in_predefined_range = message.in_predefined_range;
            return object;
        };

        OrderStage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStage";
        };

        return OrderStage;
    })();

    object_msg.OrderStageArray = (function() {

        function OrderStageArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStageArray.prototype.array = $util.emptyArray;

        OrderStageArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrderStage.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrderStageArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStageArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrderStage.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStageArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStageArray)
                return object;
            let message = new $root.object_msg.OrderStageArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrderStageArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrderStageArray.array: object expected");
                    message.array[i] = $root.object_msg.OrderStage.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrderStageArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrderStage.toObject(message.array[j], options);
            }
            return object;
        };

        OrderStageArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStageArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStageArray";
        };

        return OrderStageArray;
    })();

    object_msg.OrderStageStatus = (function() {

        function OrderStageStatus(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStageStatus.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderStageStatus.prototype.status = "";
        OrderStageStatus.prototype.stage_number = 0;
        OrderStageStatus.prototype.creation_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        OrderStageStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(8).uint64(message.order_id);
            if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                writer.uint32(16).int32(message.stage_number);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(26).string(message.status);
            if (message.creation_time != null && Object.hasOwnProperty.call(message, "creation_time"))
                writer.uint32(40).int64(message.creation_time);
            return writer;
        };

        OrderStageStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStageStatus();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.order_id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.status = reader.string();
                        break;
                    }
                case 2: {
                        message.stage_number = reader.int32();
                        break;
                    }
                case 5: {
                        message.creation_time = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStageStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStageStatus)
                return object;
            let message = new $root.object_msg.OrderStageStatus();
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            if (object.status != null)
                message.status = String(object.status);
            if (object.stage_number != null)
                message.stage_number = object.stage_number | 0;
            if (object.creation_time != null)
                if ($util.Long)
                    (message.creation_time = $util.Long.fromValue(object.creation_time)).unsigned = false;
                else if (typeof object.creation_time === "string")
                    message.creation_time = parseInt(object.creation_time, 10);
                else if (typeof object.creation_time === "number")
                    message.creation_time = object.creation_time;
                else if (typeof object.creation_time === "object")
                    message.creation_time = new $util.LongBits(object.creation_time.low >>> 0, object.creation_time.high >>> 0).toNumber();
            return message;
        };

        OrderStageStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
                object.stage_number = 0;
                object.status = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.creation_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.creation_time = options.longs === String ? "0" : 0;
            }
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                object.stage_number = message.stage_number;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.creation_time != null && message.hasOwnProperty("creation_time"))
                if (typeof message.creation_time === "number")
                    object.creation_time = options.longs === String ? String(message.creation_time) : message.creation_time;
                else
                    object.creation_time = options.longs === String ? $util.Long.prototype.toString.call(message.creation_time) : options.longs === Number ? new $util.LongBits(message.creation_time.low >>> 0, message.creation_time.high >>> 0).toNumber() : message.creation_time;
            return object;
        };

        OrderStageStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStageStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStageStatus";
        };

        return OrderStageStatus;
    })();

    object_msg.OrderStageStatusArray = (function() {

        function OrderStageStatusArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStageStatusArray.prototype.array = $util.emptyArray;

        OrderStageStatusArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrderStageStatus.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrderStageStatusArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStageStatusArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrderStageStatus.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStageStatusArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStageStatusArray)
                return object;
            let message = new $root.object_msg.OrderStageStatusArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrderStageStatusArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrderStageStatusArray.array: object expected");
                    message.array[i] = $root.object_msg.OrderStageStatus.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrderStageStatusArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrderStageStatus.toObject(message.array[j], options);
            }
            return object;
        };

        OrderStageStatusArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStageStatusArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStageStatusArray";
        };

        return OrderStageStatusArray;
    })();

    object_msg.OrderStageDeliveryProfile = (function() {

        function OrderStageDeliveryProfile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStageDeliveryProfile.prototype.phone = "";
        OrderStageDeliveryProfile.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderStageDeliveryProfile.prototype.contact_person = "";
        OrderStageDeliveryProfile.prototype.stage_number = 0;
        OrderStageDeliveryProfile.prototype.address = "";

        OrderStageDeliveryProfile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(8).uint64(message.order_id);
            if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                writer.uint32(16).int32(message.stage_number);
            if (message.contact_person != null && Object.hasOwnProperty.call(message, "contact_person"))
                writer.uint32(26).string(message.contact_person);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(34).string(message.phone);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                writer.uint32(42).string(message.address);
            return writer;
        };

        OrderStageDeliveryProfile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStageDeliveryProfile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.phone = reader.string();
                        break;
                    }
                case 1: {
                        message.order_id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.contact_person = reader.string();
                        break;
                    }
                case 2: {
                        message.stage_number = reader.int32();
                        break;
                    }
                case 5: {
                        message.address = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStageDeliveryProfile.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStageDeliveryProfile)
                return object;
            let message = new $root.object_msg.OrderStageDeliveryProfile();
            if (object.phone != null)
                message.phone = String(object.phone);
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            if (object.contact_person != null)
                message.contact_person = String(object.contact_person);
            if (object.stage_number != null)
                message.stage_number = object.stage_number | 0;
            if (object.address != null)
                message.address = String(object.address);
            return message;
        };

        OrderStageDeliveryProfile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
                object.stage_number = 0;
                object.contact_person = "";
                object.phone = "";
                object.address = "";
            }
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                object.stage_number = message.stage_number;
            if (message.contact_person != null && message.hasOwnProperty("contact_person"))
                object.contact_person = message.contact_person;
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.address != null && message.hasOwnProperty("address"))
                object.address = message.address;
            return object;
        };

        OrderStageDeliveryProfile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStageDeliveryProfile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStageDeliveryProfile";
        };

        return OrderStageDeliveryProfile;
    })();

    object_msg.OrderStageDeliveryProfileArray = (function() {

        function OrderStageDeliveryProfileArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStageDeliveryProfileArray.prototype.array = $util.emptyArray;

        OrderStageDeliveryProfileArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrderStageDeliveryProfile.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrderStageDeliveryProfileArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStageDeliveryProfileArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrderStageDeliveryProfile.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStageDeliveryProfileArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStageDeliveryProfileArray)
                return object;
            let message = new $root.object_msg.OrderStageDeliveryProfileArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrderStageDeliveryProfileArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrderStageDeliveryProfileArray.array: object expected");
                    message.array[i] = $root.object_msg.OrderStageDeliveryProfile.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrderStageDeliveryProfileArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrderStageDeliveryProfile.toObject(message.array[j], options);
            }
            return object;
        };

        OrderStageDeliveryProfileArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStageDeliveryProfileArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStageDeliveryProfileArray";
        };

        return OrderStageDeliveryProfileArray;
    })();

    object_msg.OrderStageComment = (function() {

        function OrderStageComment(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStageComment.prototype.author_name = "";
        OrderStageComment.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderStageComment.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderStageComment.prototype.comment = "";
        OrderStageComment.prototype.stage_number = 0;
        OrderStageComment.prototype.creation_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        OrderStageComment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(8).uint64(message.order_id);
            if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                writer.uint32(16).int32(message.stage_number);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(26).string(message.comment);
            if (message.author_name != null && Object.hasOwnProperty.call(message, "author_name"))
                writer.uint32(34).string(message.author_name);
            if (message.creation_time != null && Object.hasOwnProperty.call(message, "creation_time"))
                writer.uint32(40).int64(message.creation_time);
            if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                writer.uint32(48).uint64(message.user_id);
            return writer;
        };

        OrderStageComment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStageComment();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.author_name = reader.string();
                        break;
                    }
                case 6: {
                        message.user_id = reader.uint64();
                        break;
                    }
                case 1: {
                        message.order_id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.comment = reader.string();
                        break;
                    }
                case 2: {
                        message.stage_number = reader.int32();
                        break;
                    }
                case 5: {
                        message.creation_time = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStageComment.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStageComment)
                return object;
            let message = new $root.object_msg.OrderStageComment();
            if (object.author_name != null)
                message.author_name = String(object.author_name);
            if (object.user_id != null)
                if ($util.Long)
                    (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = true;
                else if (typeof object.user_id === "string")
                    message.user_id = parseInt(object.user_id, 10);
                else if (typeof object.user_id === "number")
                    message.user_id = object.user_id;
                else if (typeof object.user_id === "object")
                    message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber(true);
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            if (object.comment != null)
                message.comment = String(object.comment);
            if (object.stage_number != null)
                message.stage_number = object.stage_number | 0;
            if (object.creation_time != null)
                if ($util.Long)
                    (message.creation_time = $util.Long.fromValue(object.creation_time)).unsigned = false;
                else if (typeof object.creation_time === "string")
                    message.creation_time = parseInt(object.creation_time, 10);
                else if (typeof object.creation_time === "number")
                    message.creation_time = object.creation_time;
                else if (typeof object.creation_time === "object")
                    message.creation_time = new $util.LongBits(object.creation_time.low >>> 0, object.creation_time.high >>> 0).toNumber();
            return message;
        };

        OrderStageComment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
                object.stage_number = 0;
                object.comment = "";
                object.author_name = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.creation_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.creation_time = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.user_id = options.longs === String ? "0" : 0;
            }
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                object.stage_number = message.stage_number;
            if (message.comment != null && message.hasOwnProperty("comment"))
                object.comment = message.comment;
            if (message.author_name != null && message.hasOwnProperty("author_name"))
                object.author_name = message.author_name;
            if (message.creation_time != null && message.hasOwnProperty("creation_time"))
                if (typeof message.creation_time === "number")
                    object.creation_time = options.longs === String ? String(message.creation_time) : message.creation_time;
                else
                    object.creation_time = options.longs === String ? $util.Long.prototype.toString.call(message.creation_time) : options.longs === Number ? new $util.LongBits(message.creation_time.low >>> 0, message.creation_time.high >>> 0).toNumber() : message.creation_time;
            if (message.user_id != null && message.hasOwnProperty("user_id"))
                if (typeof message.user_id === "number")
                    object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                else
                    object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber(true) : message.user_id;
            return object;
        };

        OrderStageComment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStageComment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStageComment";
        };

        return OrderStageComment;
    })();

    object_msg.OrderStageCommentArray = (function() {

        function OrderStageCommentArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStageCommentArray.prototype.array = $util.emptyArray;

        OrderStageCommentArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrderStageComment.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrderStageCommentArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStageCommentArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrderStageComment.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStageCommentArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStageCommentArray)
                return object;
            let message = new $root.object_msg.OrderStageCommentArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrderStageCommentArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrderStageCommentArray.array: object expected");
                    message.array[i] = $root.object_msg.OrderStageComment.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrderStageCommentArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrderStageComment.toObject(message.array[j], options);
            }
            return object;
        };

        OrderStageCommentArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStageCommentArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStageCommentArray";
        };

        return OrderStageCommentArray;
    })();

    object_msg.OrderStageCommentFile = (function() {

        function OrderStageCommentFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStageCommentFile.prototype.file_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderStageCommentFile.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderStageCommentFile.prototype.original_name = "";
        OrderStageCommentFile.prototype.stage_number = 0;
        OrderStageCommentFile.prototype.comment_creation_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        OrderStageCommentFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(8).uint64(message.order_id);
            if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                writer.uint32(16).int32(message.stage_number);
            if (message.original_name != null && Object.hasOwnProperty.call(message, "original_name"))
                writer.uint32(26).string(message.original_name);
            if (message.comment_creation_time != null && Object.hasOwnProperty.call(message, "comment_creation_time"))
                writer.uint32(40).int64(message.comment_creation_time);
            if (message.file_id != null && Object.hasOwnProperty.call(message, "file_id"))
                writer.uint32(48).uint64(message.file_id);
            return writer;
        };

        OrderStageCommentFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStageCommentFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 6: {
                        message.file_id = reader.uint64();
                        break;
                    }
                case 1: {
                        message.order_id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.original_name = reader.string();
                        break;
                    }
                case 2: {
                        message.stage_number = reader.int32();
                        break;
                    }
                case 5: {
                        message.comment_creation_time = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStageCommentFile.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStageCommentFile)
                return object;
            let message = new $root.object_msg.OrderStageCommentFile();
            if (object.file_id != null)
                if ($util.Long)
                    (message.file_id = $util.Long.fromValue(object.file_id)).unsigned = true;
                else if (typeof object.file_id === "string")
                    message.file_id = parseInt(object.file_id, 10);
                else if (typeof object.file_id === "number")
                    message.file_id = object.file_id;
                else if (typeof object.file_id === "object")
                    message.file_id = new $util.LongBits(object.file_id.low >>> 0, object.file_id.high >>> 0).toNumber(true);
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            if (object.original_name != null)
                message.original_name = String(object.original_name);
            if (object.stage_number != null)
                message.stage_number = object.stage_number | 0;
            if (object.comment_creation_time != null)
                if ($util.Long)
                    (message.comment_creation_time = $util.Long.fromValue(object.comment_creation_time)).unsigned = false;
                else if (typeof object.comment_creation_time === "string")
                    message.comment_creation_time = parseInt(object.comment_creation_time, 10);
                else if (typeof object.comment_creation_time === "number")
                    message.comment_creation_time = object.comment_creation_time;
                else if (typeof object.comment_creation_time === "object")
                    message.comment_creation_time = new $util.LongBits(object.comment_creation_time.low >>> 0, object.comment_creation_time.high >>> 0).toNumber();
            return message;
        };

        OrderStageCommentFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
                object.stage_number = 0;
                object.original_name = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.comment_creation_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.comment_creation_time = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.file_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.file_id = options.longs === String ? "0" : 0;
            }
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                object.stage_number = message.stage_number;
            if (message.original_name != null && message.hasOwnProperty("original_name"))
                object.original_name = message.original_name;
            if (message.comment_creation_time != null && message.hasOwnProperty("comment_creation_time"))
                if (typeof message.comment_creation_time === "number")
                    object.comment_creation_time = options.longs === String ? String(message.comment_creation_time) : message.comment_creation_time;
                else
                    object.comment_creation_time = options.longs === String ? $util.Long.prototype.toString.call(message.comment_creation_time) : options.longs === Number ? new $util.LongBits(message.comment_creation_time.low >>> 0, message.comment_creation_time.high >>> 0).toNumber() : message.comment_creation_time;
            if (message.file_id != null && message.hasOwnProperty("file_id"))
                if (typeof message.file_id === "number")
                    object.file_id = options.longs === String ? String(message.file_id) : message.file_id;
                else
                    object.file_id = options.longs === String ? $util.Long.prototype.toString.call(message.file_id) : options.longs === Number ? new $util.LongBits(message.file_id.low >>> 0, message.file_id.high >>> 0).toNumber(true) : message.file_id;
            return object;
        };

        OrderStageCommentFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStageCommentFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStageCommentFile";
        };

        return OrderStageCommentFile;
    })();

    object_msg.OrderStageCommentFileArray = (function() {

        function OrderStageCommentFileArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStageCommentFileArray.prototype.array = $util.emptyArray;

        OrderStageCommentFileArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrderStageCommentFile.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrderStageCommentFileArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStageCommentFileArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrderStageCommentFile.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStageCommentFileArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStageCommentFileArray)
                return object;
            let message = new $root.object_msg.OrderStageCommentFileArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrderStageCommentFileArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrderStageCommentFileArray.array: object expected");
                    message.array[i] = $root.object_msg.OrderStageCommentFile.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrderStageCommentFileArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrderStageCommentFile.toObject(message.array[j], options);
            }
            return object;
        };

        OrderStageCommentFileArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStageCommentFileArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStageCommentFileArray";
        };

        return OrderStageCommentFileArray;
    })();

    object_msg.UploadedFile = (function() {

        function UploadedFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        UploadedFile.prototype.owner_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        UploadedFile.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        UploadedFile.prototype.upload_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        UploadedFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).uint64(message.id);
            if (message.upload_time != null && Object.hasOwnProperty.call(message, "upload_time"))
                writer.uint32(40).int64(message.upload_time);
            if (message.owner_id != null && Object.hasOwnProperty.call(message, "owner_id"))
                writer.uint32(48).uint64(message.owner_id);
            return writer;
        };

        UploadedFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.UploadedFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 6: {
                        message.owner_id = reader.uint64();
                        break;
                    }
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 5: {
                        message.upload_time = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        UploadedFile.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.UploadedFile)
                return object;
            let message = new $root.object_msg.UploadedFile();
            if (object.owner_id != null)
                if ($util.Long)
                    (message.owner_id = $util.Long.fromValue(object.owner_id)).unsigned = true;
                else if (typeof object.owner_id === "string")
                    message.owner_id = parseInt(object.owner_id, 10);
                else if (typeof object.owner_id === "number")
                    message.owner_id = object.owner_id;
                else if (typeof object.owner_id === "object")
                    message.owner_id = new $util.LongBits(object.owner_id.low >>> 0, object.owner_id.high >>> 0).toNumber(true);
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.upload_time != null)
                if ($util.Long)
                    (message.upload_time = $util.Long.fromValue(object.upload_time)).unsigned = false;
                else if (typeof object.upload_time === "string")
                    message.upload_time = parseInt(object.upload_time, 10);
                else if (typeof object.upload_time === "number")
                    message.upload_time = object.upload_time;
                else if (typeof object.upload_time === "object")
                    message.upload_time = new $util.LongBits(object.upload_time.low >>> 0, object.upload_time.high >>> 0).toNumber();
            return message;
        };

        UploadedFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.upload_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.upload_time = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.owner_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.owner_id = options.longs === String ? "0" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.upload_time != null && message.hasOwnProperty("upload_time"))
                if (typeof message.upload_time === "number")
                    object.upload_time = options.longs === String ? String(message.upload_time) : message.upload_time;
                else
                    object.upload_time = options.longs === String ? $util.Long.prototype.toString.call(message.upload_time) : options.longs === Number ? new $util.LongBits(message.upload_time.low >>> 0, message.upload_time.high >>> 0).toNumber() : message.upload_time;
            if (message.owner_id != null && message.hasOwnProperty("owner_id"))
                if (typeof message.owner_id === "number")
                    object.owner_id = options.longs === String ? String(message.owner_id) : message.owner_id;
                else
                    object.owner_id = options.longs === String ? $util.Long.prototype.toString.call(message.owner_id) : options.longs === Number ? new $util.LongBits(message.owner_id.low >>> 0, message.owner_id.high >>> 0).toNumber(true) : message.owner_id;
            return object;
        };

        UploadedFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        UploadedFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.UploadedFile";
        };

        return UploadedFile;
    })();

    object_msg.UploadedFileArray = (function() {

        function UploadedFileArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        UploadedFileArray.prototype.array = $util.emptyArray;

        UploadedFileArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.UploadedFile.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        UploadedFileArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.UploadedFileArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.UploadedFile.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        UploadedFileArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.UploadedFileArray)
                return object;
            let message = new $root.object_msg.UploadedFileArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.UploadedFileArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.UploadedFileArray.array: object expected");
                    message.array[i] = $root.object_msg.UploadedFile.fromObject(object.array[i]);
                }
            }
            return message;
        };

        UploadedFileArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.UploadedFile.toObject(message.array[j], options);
            }
            return object;
        };

        UploadedFileArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        UploadedFileArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.UploadedFileArray";
        };

        return UploadedFileArray;
    })();

    object_msg.CommercialOfferRequest = (function() {

        function CommercialOfferRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        CommercialOfferRequest.prototype.communication_type = "";
        CommercialOfferRequest.prototype.reply_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        CommercialOfferRequest.prototype.send_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        CommercialOfferRequest.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        CommercialOfferRequest.prototype.receiver = "";
        CommercialOfferRequest.prototype.stage_number = 0;
        CommercialOfferRequest.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        CommercialOfferRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(8).uint64(message.order_id);
            if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                writer.uint32(16).int32(message.stage_number);
            if (message.receiver != null && Object.hasOwnProperty.call(message, "receiver"))
                writer.uint32(26).string(message.receiver);
            if (message.communication_type != null && Object.hasOwnProperty.call(message, "communication_type"))
                writer.uint32(34).string(message.communication_type);
            if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                writer.uint32(40).uint64(message.place_id);
            if (message.send_time != null && Object.hasOwnProperty.call(message, "send_time"))
                writer.uint32(48).int64(message.send_time);
            if (message.reply_time != null && Object.hasOwnProperty.call(message, "reply_time"))
                writer.uint32(56).int64(message.reply_time);
            return writer;
        };

        CommercialOfferRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.CommercialOfferRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.communication_type = reader.string();
                        break;
                    }
                case 7: {
                        message.reply_time = reader.int64();
                        break;
                    }
                case 6: {
                        message.send_time = reader.int64();
                        break;
                    }
                case 1: {
                        message.order_id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.receiver = reader.string();
                        break;
                    }
                case 2: {
                        message.stage_number = reader.int32();
                        break;
                    }
                case 5: {
                        message.place_id = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        CommercialOfferRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.CommercialOfferRequest)
                return object;
            let message = new $root.object_msg.CommercialOfferRequest();
            if (object.communication_type != null)
                message.communication_type = String(object.communication_type);
            if (object.reply_time != null)
                if ($util.Long)
                    (message.reply_time = $util.Long.fromValue(object.reply_time)).unsigned = false;
                else if (typeof object.reply_time === "string")
                    message.reply_time = parseInt(object.reply_time, 10);
                else if (typeof object.reply_time === "number")
                    message.reply_time = object.reply_time;
                else if (typeof object.reply_time === "object")
                    message.reply_time = new $util.LongBits(object.reply_time.low >>> 0, object.reply_time.high >>> 0).toNumber();
            if (object.send_time != null)
                if ($util.Long)
                    (message.send_time = $util.Long.fromValue(object.send_time)).unsigned = false;
                else if (typeof object.send_time === "string")
                    message.send_time = parseInt(object.send_time, 10);
                else if (typeof object.send_time === "number")
                    message.send_time = object.send_time;
                else if (typeof object.send_time === "object")
                    message.send_time = new $util.LongBits(object.send_time.low >>> 0, object.send_time.high >>> 0).toNumber();
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            if (object.receiver != null)
                message.receiver = String(object.receiver);
            if (object.stage_number != null)
                message.stage_number = object.stage_number | 0;
            if (object.place_id != null)
                if ($util.Long)
                    (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                else if (typeof object.place_id === "string")
                    message.place_id = parseInt(object.place_id, 10);
                else if (typeof object.place_id === "number")
                    message.place_id = object.place_id;
                else if (typeof object.place_id === "object")
                    message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
            return message;
        };

        CommercialOfferRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
                object.stage_number = 0;
                object.receiver = "";
                object.communication_type = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.place_id = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.send_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.send_time = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.reply_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.reply_time = options.longs === String ? "0" : 0;
            }
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                object.stage_number = message.stage_number;
            if (message.receiver != null && message.hasOwnProperty("receiver"))
                object.receiver = message.receiver;
            if (message.communication_type != null && message.hasOwnProperty("communication_type"))
                object.communication_type = message.communication_type;
            if (message.place_id != null && message.hasOwnProperty("place_id"))
                if (typeof message.place_id === "number")
                    object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                else
                    object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
            if (message.send_time != null && message.hasOwnProperty("send_time"))
                if (typeof message.send_time === "number")
                    object.send_time = options.longs === String ? String(message.send_time) : message.send_time;
                else
                    object.send_time = options.longs === String ? $util.Long.prototype.toString.call(message.send_time) : options.longs === Number ? new $util.LongBits(message.send_time.low >>> 0, message.send_time.high >>> 0).toNumber() : message.send_time;
            if (message.reply_time != null && message.hasOwnProperty("reply_time"))
                if (typeof message.reply_time === "number")
                    object.reply_time = options.longs === String ? String(message.reply_time) : message.reply_time;
                else
                    object.reply_time = options.longs === String ? $util.Long.prototype.toString.call(message.reply_time) : options.longs === Number ? new $util.LongBits(message.reply_time.low >>> 0, message.reply_time.high >>> 0).toNumber() : message.reply_time;
            return object;
        };

        CommercialOfferRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        CommercialOfferRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.CommercialOfferRequest";
        };

        return CommercialOfferRequest;
    })();

    object_msg.CommercialOfferRequestArray = (function() {

        function CommercialOfferRequestArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        CommercialOfferRequestArray.prototype.array = $util.emptyArray;

        CommercialOfferRequestArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.CommercialOfferRequest.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        CommercialOfferRequestArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.CommercialOfferRequestArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.CommercialOfferRequest.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        CommercialOfferRequestArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.CommercialOfferRequestArray)
                return object;
            let message = new $root.object_msg.CommercialOfferRequestArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.CommercialOfferRequestArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.CommercialOfferRequestArray.array: object expected");
                    message.array[i] = $root.object_msg.CommercialOfferRequest.fromObject(object.array[i]);
                }
            }
            return message;
        };

        CommercialOfferRequestArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.CommercialOfferRequest.toObject(message.array[j], options);
            }
            return object;
        };

        CommercialOfferRequestArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        CommercialOfferRequestArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.CommercialOfferRequestArray";
        };

        return CommercialOfferRequestArray;
    })();

    object_msg.LaserCuttingStage = (function() {

        function LaserCuttingStage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LaserCuttingStage.prototype.thickness_01mm = 0;
        LaserCuttingStage.prototype.material_unified = false;
        LaserCuttingStage.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        LaserCuttingStage.prototype.material = "";
        LaserCuttingStage.prototype.stage_number = 0;
        LaserCuttingStage.prototype.meters = 0;

        LaserCuttingStage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(8).uint64(message.order_id);
            if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                writer.uint32(16).int32(message.stage_number);
            if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                writer.uint32(26).string(message.material);
            if (message.thickness_01mm != null && Object.hasOwnProperty.call(message, "thickness_01mm"))
                writer.uint32(32).int32(message.thickness_01mm);
            if (message.meters != null && Object.hasOwnProperty.call(message, "meters"))
                writer.uint32(40).int32(message.meters);
            if (message.material_unified != null && Object.hasOwnProperty.call(message, "material_unified"))
                writer.uint32(48).bool(message.material_unified);
            return writer;
        };

        LaserCuttingStage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LaserCuttingStage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.thickness_01mm = reader.int32();
                        break;
                    }
                case 6: {
                        message.material_unified = reader.bool();
                        break;
                    }
                case 1: {
                        message.order_id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.material = reader.string();
                        break;
                    }
                case 2: {
                        message.stage_number = reader.int32();
                        break;
                    }
                case 5: {
                        message.meters = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LaserCuttingStage.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LaserCuttingStage)
                return object;
            let message = new $root.object_msg.LaserCuttingStage();
            if (object.thickness_01mm != null)
                message.thickness_01mm = object.thickness_01mm | 0;
            if (object.material_unified != null)
                message.material_unified = Boolean(object.material_unified);
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            if (object.material != null)
                message.material = String(object.material);
            if (object.stage_number != null)
                message.stage_number = object.stage_number | 0;
            if (object.meters != null)
                message.meters = object.meters | 0;
            return message;
        };

        LaserCuttingStage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
                object.stage_number = 0;
                object.material = "";
                object.thickness_01mm = 0;
                object.meters = 0;
                object.material_unified = false;
            }
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                object.stage_number = message.stage_number;
            if (message.material != null && message.hasOwnProperty("material"))
                object.material = message.material;
            if (message.thickness_01mm != null && message.hasOwnProperty("thickness_01mm"))
                object.thickness_01mm = message.thickness_01mm;
            if (message.meters != null && message.hasOwnProperty("meters"))
                object.meters = message.meters;
            if (message.material_unified != null && message.hasOwnProperty("material_unified"))
                object.material_unified = message.material_unified;
            return object;
        };

        LaserCuttingStage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LaserCuttingStage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LaserCuttingStage";
        };

        return LaserCuttingStage;
    })();

    object_msg.LaserCuttingStageArray = (function() {

        function LaserCuttingStageArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LaserCuttingStageArray.prototype.array = $util.emptyArray;

        LaserCuttingStageArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.LaserCuttingStage.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        LaserCuttingStageArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LaserCuttingStageArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.LaserCuttingStage.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LaserCuttingStageArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LaserCuttingStageArray)
                return object;
            let message = new $root.object_msg.LaserCuttingStageArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.LaserCuttingStageArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.LaserCuttingStageArray.array: object expected");
                    message.array[i] = $root.object_msg.LaserCuttingStage.fromObject(object.array[i]);
                }
            }
            return message;
        };

        LaserCuttingStageArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.LaserCuttingStage.toObject(message.array[j], options);
            }
            return object;
        };

        LaserCuttingStageArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LaserCuttingStageArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LaserCuttingStageArray";
        };

        return LaserCuttingStageArray;
    })();

    object_msg.LaserEngravingStage = (function() {

        function LaserEngravingStage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LaserEngravingStage.prototype.centimeters2 = 0;
        LaserEngravingStage.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        LaserEngravingStage.prototype.material = "";
        LaserEngravingStage.prototype.stage_number = 0;
        LaserEngravingStage.prototype.material_unified = false;

        LaserEngravingStage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(8).uint64(message.order_id);
            if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                writer.uint32(16).int32(message.stage_number);
            if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                writer.uint32(26).string(message.material);
            if (message.centimeters2 != null && Object.hasOwnProperty.call(message, "centimeters2"))
                writer.uint32(32).int32(message.centimeters2);
            if (message.material_unified != null && Object.hasOwnProperty.call(message, "material_unified"))
                writer.uint32(40).bool(message.material_unified);
            return writer;
        };

        LaserEngravingStage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LaserEngravingStage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.centimeters2 = reader.int32();
                        break;
                    }
                case 1: {
                        message.order_id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.material = reader.string();
                        break;
                    }
                case 2: {
                        message.stage_number = reader.int32();
                        break;
                    }
                case 5: {
                        message.material_unified = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LaserEngravingStage.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LaserEngravingStage)
                return object;
            let message = new $root.object_msg.LaserEngravingStage();
            if (object.centimeters2 != null)
                message.centimeters2 = object.centimeters2 | 0;
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            if (object.material != null)
                message.material = String(object.material);
            if (object.stage_number != null)
                message.stage_number = object.stage_number | 0;
            if (object.material_unified != null)
                message.material_unified = Boolean(object.material_unified);
            return message;
        };

        LaserEngravingStage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
                object.stage_number = 0;
                object.material = "";
                object.centimeters2 = 0;
                object.material_unified = false;
            }
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                object.stage_number = message.stage_number;
            if (message.material != null && message.hasOwnProperty("material"))
                object.material = message.material;
            if (message.centimeters2 != null && message.hasOwnProperty("centimeters2"))
                object.centimeters2 = message.centimeters2;
            if (message.material_unified != null && message.hasOwnProperty("material_unified"))
                object.material_unified = message.material_unified;
            return object;
        };

        LaserEngravingStage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LaserEngravingStage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LaserEngravingStage";
        };

        return LaserEngravingStage;
    })();

    object_msg.LaserEngravingStageArray = (function() {

        function LaserEngravingStageArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LaserEngravingStageArray.prototype.array = $util.emptyArray;

        LaserEngravingStageArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.LaserEngravingStage.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        LaserEngravingStageArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LaserEngravingStageArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.LaserEngravingStage.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LaserEngravingStageArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LaserEngravingStageArray)
                return object;
            let message = new $root.object_msg.LaserEngravingStageArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.LaserEngravingStageArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.LaserEngravingStageArray.array: object expected");
                    message.array[i] = $root.object_msg.LaserEngravingStage.fromObject(object.array[i]);
                }
            }
            return message;
        };

        LaserEngravingStageArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.LaserEngravingStage.toObject(message.array[j], options);
            }
            return object;
        };

        LaserEngravingStageArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LaserEngravingStageArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LaserEngravingStageArray";
        };

        return LaserEngravingStageArray;
    })();

    object_msg.WebCrawlerWebsites = (function() {

        function WebCrawlerWebsites(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        WebCrawlerWebsites.prototype.possible_redirect = "";
        WebCrawlerWebsites.prototype.collected_info_json = "";
        WebCrawlerWebsites.prototype.url = "";
        WebCrawlerWebsites.prototype.curl_request_failed = false;
        WebCrawlerWebsites.prototype.postponed = false;
        WebCrawlerWebsites.prototype.compressed_text_length = 0;

        WebCrawlerWebsites.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(10).string(message.url);
            if (message.postponed != null && Object.hasOwnProperty.call(message, "postponed"))
                writer.uint32(16).bool(message.postponed);
            if (message.curl_request_failed != null && Object.hasOwnProperty.call(message, "curl_request_failed"))
                writer.uint32(24).bool(message.curl_request_failed);
            if (message.possible_redirect != null && Object.hasOwnProperty.call(message, "possible_redirect"))
                writer.uint32(34).string(message.possible_redirect);
            if (message.compressed_text_length != null && Object.hasOwnProperty.call(message, "compressed_text_length"))
                writer.uint32(40).int32(message.compressed_text_length);
            if (message.collected_info_json != null && Object.hasOwnProperty.call(message, "collected_info_json"))
                writer.uint32(50).string(message.collected_info_json);
            return writer;
        };

        WebCrawlerWebsites.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.WebCrawlerWebsites();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.possible_redirect = reader.string();
                        break;
                    }
                case 6: {
                        message.collected_info_json = reader.string();
                        break;
                    }
                case 1: {
                        message.url = reader.string();
                        break;
                    }
                case 3: {
                        message.curl_request_failed = reader.bool();
                        break;
                    }
                case 2: {
                        message.postponed = reader.bool();
                        break;
                    }
                case 5: {
                        message.compressed_text_length = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        WebCrawlerWebsites.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.WebCrawlerWebsites)
                return object;
            let message = new $root.object_msg.WebCrawlerWebsites();
            if (object.possible_redirect != null)
                message.possible_redirect = String(object.possible_redirect);
            if (object.collected_info_json != null)
                message.collected_info_json = String(object.collected_info_json);
            if (object.url != null)
                message.url = String(object.url);
            if (object.curl_request_failed != null)
                message.curl_request_failed = Boolean(object.curl_request_failed);
            if (object.postponed != null)
                message.postponed = Boolean(object.postponed);
            if (object.compressed_text_length != null)
                message.compressed_text_length = object.compressed_text_length | 0;
            return message;
        };

        WebCrawlerWebsites.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.url = "";
                object.postponed = false;
                object.curl_request_failed = false;
                object.possible_redirect = "";
                object.compressed_text_length = 0;
                object.collected_info_json = "";
            }
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.postponed != null && message.hasOwnProperty("postponed"))
                object.postponed = message.postponed;
            if (message.curl_request_failed != null && message.hasOwnProperty("curl_request_failed"))
                object.curl_request_failed = message.curl_request_failed;
            if (message.possible_redirect != null && message.hasOwnProperty("possible_redirect"))
                object.possible_redirect = message.possible_redirect;
            if (message.compressed_text_length != null && message.hasOwnProperty("compressed_text_length"))
                object.compressed_text_length = message.compressed_text_length;
            if (message.collected_info_json != null && message.hasOwnProperty("collected_info_json"))
                object.collected_info_json = message.collected_info_json;
            return object;
        };

        WebCrawlerWebsites.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        WebCrawlerWebsites.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.WebCrawlerWebsites";
        };

        return WebCrawlerWebsites;
    })();

    object_msg.WebCrawlerWebsitesArray = (function() {

        function WebCrawlerWebsitesArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        WebCrawlerWebsitesArray.prototype.array = $util.emptyArray;

        WebCrawlerWebsitesArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.WebCrawlerWebsites.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        WebCrawlerWebsitesArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.WebCrawlerWebsitesArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.WebCrawlerWebsites.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        WebCrawlerWebsitesArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.WebCrawlerWebsitesArray)
                return object;
            let message = new $root.object_msg.WebCrawlerWebsitesArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.WebCrawlerWebsitesArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.WebCrawlerWebsitesArray.array: object expected");
                    message.array[i] = $root.object_msg.WebCrawlerWebsites.fromObject(object.array[i]);
                }
            }
            return message;
        };

        WebCrawlerWebsitesArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.WebCrawlerWebsites.toObject(message.array[j], options);
            }
            return object;
        };

        WebCrawlerWebsitesArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        WebCrawlerWebsitesArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.WebCrawlerWebsitesArray";
        };

        return WebCrawlerWebsitesArray;
    })();

    object_msg.WebCrawlerPendingPage = (function() {

        function WebCrawlerPendingPage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        WebCrawlerPendingPage.prototype.compressed_text = "";
        WebCrawlerPendingPage.prototype.sent_prices = false;
        WebCrawlerPendingPage.prototype.sent_urls_inn = false;
        WebCrawlerPendingPage.prototype.main_url = "";
        WebCrawlerPendingPage.prototype.original_text = "";
        WebCrawlerPendingPage.prototype.pending_url = "";
        WebCrawlerPendingPage.prototype.parsed_name_phones_emails = false;
        WebCrawlerPendingPage.prototype.parsed_prices = false;
        WebCrawlerPendingPage.prototype.parsed_approved_price_urls = false;
        WebCrawlerPendingPage.prototype.sent_approved_price_urls = false;
        WebCrawlerPendingPage.prototype.sent_name_phones_emails = false;
        WebCrawlerPendingPage.prototype.parsed_urls_inn = false;
        WebCrawlerPendingPage.prototype.parsed_actions = false;
        WebCrawlerPendingPage.prototype.sent_actions = false;

        WebCrawlerPendingPage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.main_url != null && Object.hasOwnProperty.call(message, "main_url"))
                writer.uint32(10).string(message.main_url);
            if (message.pending_url != null && Object.hasOwnProperty.call(message, "pending_url"))
                writer.uint32(18).string(message.pending_url);
            if (message.original_text != null && Object.hasOwnProperty.call(message, "original_text"))
                writer.uint32(26).string(message.original_text);
            if (message.compressed_text != null && Object.hasOwnProperty.call(message, "compressed_text"))
                writer.uint32(34).string(message.compressed_text);
            if (message.sent_actions != null && Object.hasOwnProperty.call(message, "sent_actions"))
                writer.uint32(40).bool(message.sent_actions);
            if (message.sent_urls_inn != null && Object.hasOwnProperty.call(message, "sent_urls_inn"))
                writer.uint32(48).bool(message.sent_urls_inn);
            if (message.sent_prices != null && Object.hasOwnProperty.call(message, "sent_prices"))
                writer.uint32(56).bool(message.sent_prices);
            if (message.sent_name_phones_emails != null && Object.hasOwnProperty.call(message, "sent_name_phones_emails"))
                writer.uint32(64).bool(message.sent_name_phones_emails);
            if (message.sent_approved_price_urls != null && Object.hasOwnProperty.call(message, "sent_approved_price_urls"))
                writer.uint32(72).bool(message.sent_approved_price_urls);
            if (message.parsed_actions != null && Object.hasOwnProperty.call(message, "parsed_actions"))
                writer.uint32(80).bool(message.parsed_actions);
            if (message.parsed_urls_inn != null && Object.hasOwnProperty.call(message, "parsed_urls_inn"))
                writer.uint32(88).bool(message.parsed_urls_inn);
            if (message.parsed_prices != null && Object.hasOwnProperty.call(message, "parsed_prices"))
                writer.uint32(96).bool(message.parsed_prices);
            if (message.parsed_name_phones_emails != null && Object.hasOwnProperty.call(message, "parsed_name_phones_emails"))
                writer.uint32(104).bool(message.parsed_name_phones_emails);
            if (message.parsed_approved_price_urls != null && Object.hasOwnProperty.call(message, "parsed_approved_price_urls"))
                writer.uint32(112).bool(message.parsed_approved_price_urls);
            return writer;
        };

        WebCrawlerPendingPage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.WebCrawlerPendingPage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.compressed_text = reader.string();
                        break;
                    }
                case 7: {
                        message.sent_prices = reader.bool();
                        break;
                    }
                case 6: {
                        message.sent_urls_inn = reader.bool();
                        break;
                    }
                case 1: {
                        message.main_url = reader.string();
                        break;
                    }
                case 3: {
                        message.original_text = reader.string();
                        break;
                    }
                case 2: {
                        message.pending_url = reader.string();
                        break;
                    }
                case 13: {
                        message.parsed_name_phones_emails = reader.bool();
                        break;
                    }
                case 12: {
                        message.parsed_prices = reader.bool();
                        break;
                    }
                case 14: {
                        message.parsed_approved_price_urls = reader.bool();
                        break;
                    }
                case 9: {
                        message.sent_approved_price_urls = reader.bool();
                        break;
                    }
                case 8: {
                        message.sent_name_phones_emails = reader.bool();
                        break;
                    }
                case 11: {
                        message.parsed_urls_inn = reader.bool();
                        break;
                    }
                case 10: {
                        message.parsed_actions = reader.bool();
                        break;
                    }
                case 5: {
                        message.sent_actions = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        WebCrawlerPendingPage.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.WebCrawlerPendingPage)
                return object;
            let message = new $root.object_msg.WebCrawlerPendingPage();
            if (object.compressed_text != null)
                message.compressed_text = String(object.compressed_text);
            if (object.sent_prices != null)
                message.sent_prices = Boolean(object.sent_prices);
            if (object.sent_urls_inn != null)
                message.sent_urls_inn = Boolean(object.sent_urls_inn);
            if (object.main_url != null)
                message.main_url = String(object.main_url);
            if (object.original_text != null)
                message.original_text = String(object.original_text);
            if (object.pending_url != null)
                message.pending_url = String(object.pending_url);
            if (object.parsed_name_phones_emails != null)
                message.parsed_name_phones_emails = Boolean(object.parsed_name_phones_emails);
            if (object.parsed_prices != null)
                message.parsed_prices = Boolean(object.parsed_prices);
            if (object.parsed_approved_price_urls != null)
                message.parsed_approved_price_urls = Boolean(object.parsed_approved_price_urls);
            if (object.sent_approved_price_urls != null)
                message.sent_approved_price_urls = Boolean(object.sent_approved_price_urls);
            if (object.sent_name_phones_emails != null)
                message.sent_name_phones_emails = Boolean(object.sent_name_phones_emails);
            if (object.parsed_urls_inn != null)
                message.parsed_urls_inn = Boolean(object.parsed_urls_inn);
            if (object.parsed_actions != null)
                message.parsed_actions = Boolean(object.parsed_actions);
            if (object.sent_actions != null)
                message.sent_actions = Boolean(object.sent_actions);
            return message;
        };

        WebCrawlerPendingPage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.main_url = "";
                object.pending_url = "";
                object.original_text = "";
                object.compressed_text = "";
                object.sent_actions = false;
                object.sent_urls_inn = false;
                object.sent_prices = false;
                object.sent_name_phones_emails = false;
                object.sent_approved_price_urls = false;
                object.parsed_actions = false;
                object.parsed_urls_inn = false;
                object.parsed_prices = false;
                object.parsed_name_phones_emails = false;
                object.parsed_approved_price_urls = false;
            }
            if (message.main_url != null && message.hasOwnProperty("main_url"))
                object.main_url = message.main_url;
            if (message.pending_url != null && message.hasOwnProperty("pending_url"))
                object.pending_url = message.pending_url;
            if (message.original_text != null && message.hasOwnProperty("original_text"))
                object.original_text = message.original_text;
            if (message.compressed_text != null && message.hasOwnProperty("compressed_text"))
                object.compressed_text = message.compressed_text;
            if (message.sent_actions != null && message.hasOwnProperty("sent_actions"))
                object.sent_actions = message.sent_actions;
            if (message.sent_urls_inn != null && message.hasOwnProperty("sent_urls_inn"))
                object.sent_urls_inn = message.sent_urls_inn;
            if (message.sent_prices != null && message.hasOwnProperty("sent_prices"))
                object.sent_prices = message.sent_prices;
            if (message.sent_name_phones_emails != null && message.hasOwnProperty("sent_name_phones_emails"))
                object.sent_name_phones_emails = message.sent_name_phones_emails;
            if (message.sent_approved_price_urls != null && message.hasOwnProperty("sent_approved_price_urls"))
                object.sent_approved_price_urls = message.sent_approved_price_urls;
            if (message.parsed_actions != null && message.hasOwnProperty("parsed_actions"))
                object.parsed_actions = message.parsed_actions;
            if (message.parsed_urls_inn != null && message.hasOwnProperty("parsed_urls_inn"))
                object.parsed_urls_inn = message.parsed_urls_inn;
            if (message.parsed_prices != null && message.hasOwnProperty("parsed_prices"))
                object.parsed_prices = message.parsed_prices;
            if (message.parsed_name_phones_emails != null && message.hasOwnProperty("parsed_name_phones_emails"))
                object.parsed_name_phones_emails = message.parsed_name_phones_emails;
            if (message.parsed_approved_price_urls != null && message.hasOwnProperty("parsed_approved_price_urls"))
                object.parsed_approved_price_urls = message.parsed_approved_price_urls;
            return object;
        };

        WebCrawlerPendingPage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        WebCrawlerPendingPage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.WebCrawlerPendingPage";
        };

        return WebCrawlerPendingPage;
    })();

    object_msg.WebCrawlerPendingPageArray = (function() {

        function WebCrawlerPendingPageArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        WebCrawlerPendingPageArray.prototype.array = $util.emptyArray;

        WebCrawlerPendingPageArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.WebCrawlerPendingPage.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        WebCrawlerPendingPageArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.WebCrawlerPendingPageArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.WebCrawlerPendingPage.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        WebCrawlerPendingPageArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.WebCrawlerPendingPageArray)
                return object;
            let message = new $root.object_msg.WebCrawlerPendingPageArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.WebCrawlerPendingPageArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.WebCrawlerPendingPageArray.array: object expected");
                    message.array[i] = $root.object_msg.WebCrawlerPendingPage.fromObject(object.array[i]);
                }
            }
            return message;
        };

        WebCrawlerPendingPageArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.WebCrawlerPendingPage.toObject(message.array[j], options);
            }
            return object;
        };

        WebCrawlerPendingPageArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        WebCrawlerPendingPageArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.WebCrawlerPendingPageArray";
        };

        return WebCrawlerPendingPageArray;
    })();

    object_msg.WebCrawlerUselessPath = (function() {

        function WebCrawlerUselessPath(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        WebCrawlerUselessPath.prototype.path = "";
        WebCrawlerUselessPath.prototype.permanent = false;

        WebCrawlerUselessPath.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                writer.uint32(10).string(message.path);
            if (message.permanent != null && Object.hasOwnProperty.call(message, "permanent"))
                writer.uint32(16).bool(message.permanent);
            return writer;
        };

        WebCrawlerUselessPath.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.WebCrawlerUselessPath();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.path = reader.string();
                        break;
                    }
                case 2: {
                        message.permanent = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        WebCrawlerUselessPath.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.WebCrawlerUselessPath)
                return object;
            let message = new $root.object_msg.WebCrawlerUselessPath();
            if (object.path != null)
                message.path = String(object.path);
            if (object.permanent != null)
                message.permanent = Boolean(object.permanent);
            return message;
        };

        WebCrawlerUselessPath.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.path = "";
                object.permanent = false;
            }
            if (message.path != null && message.hasOwnProperty("path"))
                object.path = message.path;
            if (message.permanent != null && message.hasOwnProperty("permanent"))
                object.permanent = message.permanent;
            return object;
        };

        WebCrawlerUselessPath.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        WebCrawlerUselessPath.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.WebCrawlerUselessPath";
        };

        return WebCrawlerUselessPath;
    })();

    object_msg.WebCrawlerUselessPathArray = (function() {

        function WebCrawlerUselessPathArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        WebCrawlerUselessPathArray.prototype.array = $util.emptyArray;

        WebCrawlerUselessPathArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.WebCrawlerUselessPath.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        WebCrawlerUselessPathArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.WebCrawlerUselessPathArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.WebCrawlerUselessPath.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        WebCrawlerUselessPathArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.WebCrawlerUselessPathArray)
                return object;
            let message = new $root.object_msg.WebCrawlerUselessPathArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.WebCrawlerUselessPathArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.WebCrawlerUselessPathArray.array: object expected");
                    message.array[i] = $root.object_msg.WebCrawlerUselessPath.fromObject(object.array[i]);
                }
            }
            return message;
        };

        WebCrawlerUselessPathArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.WebCrawlerUselessPath.toObject(message.array[j], options);
            }
            return object;
        };

        WebCrawlerUselessPathArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        WebCrawlerUselessPathArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.WebCrawlerUselessPathArray";
        };

        return WebCrawlerUselessPathArray;
    })();

    object_msg.WebCrawlerPricePath = (function() {

        function WebCrawlerPricePath(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        WebCrawlerPricePath.prototype.path = "";

        WebCrawlerPricePath.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                writer.uint32(10).string(message.path);
            return writer;
        };

        WebCrawlerPricePath.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.WebCrawlerPricePath();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.path = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        WebCrawlerPricePath.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.WebCrawlerPricePath)
                return object;
            let message = new $root.object_msg.WebCrawlerPricePath();
            if (object.path != null)
                message.path = String(object.path);
            return message;
        };

        WebCrawlerPricePath.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.path = "";
            if (message.path != null && message.hasOwnProperty("path"))
                object.path = message.path;
            return object;
        };

        WebCrawlerPricePath.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        WebCrawlerPricePath.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.WebCrawlerPricePath";
        };

        return WebCrawlerPricePath;
    })();

    object_msg.WebCrawlerPricePathArray = (function() {

        function WebCrawlerPricePathArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        WebCrawlerPricePathArray.prototype.array = $util.emptyArray;

        WebCrawlerPricePathArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.WebCrawlerPricePath.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        WebCrawlerPricePathArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.WebCrawlerPricePathArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.WebCrawlerPricePath.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        WebCrawlerPricePathArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.WebCrawlerPricePathArray)
                return object;
            let message = new $root.object_msg.WebCrawlerPricePathArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.WebCrawlerPricePathArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.WebCrawlerPricePathArray.array: object expected");
                    message.array[i] = $root.object_msg.WebCrawlerPricePath.fromObject(object.array[i]);
                }
            }
            return message;
        };

        WebCrawlerPricePathArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.WebCrawlerPricePath.toObject(message.array[j], options);
            }
            return object;
        };

        WebCrawlerPricePathArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        WebCrawlerPricePathArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.WebCrawlerPricePathArray";
        };

        return WebCrawlerPricePathArray;
    })();

    object_msg.Department = (function() {

        function Department(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        Department.prototype.longitude = 0;
        Department.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        Department.prototype.latitude = 0;
        Department.prototype.address = "";
        Department.prototype.city_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        Department.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).uint64(message.id);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                writer.uint32(18).string(message.address);
            if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                writer.uint32(29).float(message.latitude);
            if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                writer.uint32(37).float(message.longitude);
            if (message.city_id != null && Object.hasOwnProperty.call(message, "city_id"))
                writer.uint32(40).uint64(message.city_id);
            return writer;
        };

        Department.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.Department();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4: {
                        message.longitude = reader.float();
                        break;
                    }
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.latitude = reader.float();
                        break;
                    }
                case 2: {
                        message.address = reader.string();
                        break;
                    }
                case 5: {
                        message.city_id = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Department.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.Department)
                return object;
            let message = new $root.object_msg.Department();
            if (object.longitude != null)
                message.longitude = Number(object.longitude);
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.latitude != null)
                message.latitude = Number(object.latitude);
            if (object.address != null)
                message.address = String(object.address);
            if (object.city_id != null)
                if ($util.Long)
                    (message.city_id = $util.Long.fromValue(object.city_id)).unsigned = true;
                else if (typeof object.city_id === "string")
                    message.city_id = parseInt(object.city_id, 10);
                else if (typeof object.city_id === "number")
                    message.city_id = object.city_id;
                else if (typeof object.city_id === "object")
                    message.city_id = new $util.LongBits(object.city_id.low >>> 0, object.city_id.high >>> 0).toNumber(true);
            return message;
        };

        Department.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.address = "";
                object.latitude = 0;
                object.longitude = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.city_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.city_id = options.longs === String ? "0" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.address != null && message.hasOwnProperty("address"))
                object.address = message.address;
            if (message.latitude != null && message.hasOwnProperty("latitude"))
                object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
            if (message.longitude != null && message.hasOwnProperty("longitude"))
                object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
            if (message.city_id != null && message.hasOwnProperty("city_id"))
                if (typeof message.city_id === "number")
                    object.city_id = options.longs === String ? String(message.city_id) : message.city_id;
                else
                    object.city_id = options.longs === String ? $util.Long.prototype.toString.call(message.city_id) : options.longs === Number ? new $util.LongBits(message.city_id.low >>> 0, message.city_id.high >>> 0).toNumber(true) : message.city_id;
            return object;
        };

        Department.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        Department.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.Department";
        };

        return Department;
    })();

    object_msg.DepartmentArray = (function() {

        function DepartmentArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        DepartmentArray.prototype.array = $util.emptyArray;

        DepartmentArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.Department.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        DepartmentArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.DepartmentArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.Department.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        DepartmentArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.DepartmentArray)
                return object;
            let message = new $root.object_msg.DepartmentArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.DepartmentArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.DepartmentArray.array: object expected");
                    message.array[i] = $root.object_msg.Department.fromObject(object.array[i]);
                }
            }
            return message;
        };

        DepartmentArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.Department.toObject(message.array[j], options);
            }
            return object;
        };

        DepartmentArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        DepartmentArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.DepartmentArray";
        };

        return DepartmentArray;
    })();

    object_msg.DepartmentAction = (function() {

        function DepartmentAction(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        DepartmentAction.prototype.department_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        DepartmentAction.prototype.markup_percentage = 0;
        DepartmentAction.prototype.action = "";

        DepartmentAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.department_id != null && Object.hasOwnProperty.call(message, "department_id"))
                writer.uint32(8).uint64(message.department_id);
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(18).string(message.action);
            if (message.markup_percentage != null && Object.hasOwnProperty.call(message, "markup_percentage"))
                writer.uint32(24).int32(message.markup_percentage);
            return writer;
        };

        DepartmentAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.DepartmentAction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.department_id = reader.uint64();
                        break;
                    }
                case 3: {
                        message.markup_percentage = reader.int32();
                        break;
                    }
                case 2: {
                        message.action = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        DepartmentAction.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.DepartmentAction)
                return object;
            let message = new $root.object_msg.DepartmentAction();
            if (object.department_id != null)
                if ($util.Long)
                    (message.department_id = $util.Long.fromValue(object.department_id)).unsigned = true;
                else if (typeof object.department_id === "string")
                    message.department_id = parseInt(object.department_id, 10);
                else if (typeof object.department_id === "number")
                    message.department_id = object.department_id;
                else if (typeof object.department_id === "object")
                    message.department_id = new $util.LongBits(object.department_id.low >>> 0, object.department_id.high >>> 0).toNumber(true);
            if (object.markup_percentage != null)
                message.markup_percentage = object.markup_percentage | 0;
            if (object.action != null)
                message.action = String(object.action);
            return message;
        };

        DepartmentAction.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.department_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.department_id = options.longs === String ? "0" : 0;
                object.action = "";
                object.markup_percentage = 0;
            }
            if (message.department_id != null && message.hasOwnProperty("department_id"))
                if (typeof message.department_id === "number")
                    object.department_id = options.longs === String ? String(message.department_id) : message.department_id;
                else
                    object.department_id = options.longs === String ? $util.Long.prototype.toString.call(message.department_id) : options.longs === Number ? new $util.LongBits(message.department_id.low >>> 0, message.department_id.high >>> 0).toNumber(true) : message.department_id;
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = message.action;
            if (message.markup_percentage != null && message.hasOwnProperty("markup_percentage"))
                object.markup_percentage = message.markup_percentage;
            return object;
        };

        DepartmentAction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        DepartmentAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.DepartmentAction";
        };

        return DepartmentAction;
    })();

    object_msg.DepartmentActionArray = (function() {

        function DepartmentActionArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        DepartmentActionArray.prototype.array = $util.emptyArray;

        DepartmentActionArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.DepartmentAction.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        DepartmentActionArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.DepartmentActionArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.DepartmentAction.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        DepartmentActionArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.DepartmentActionArray)
                return object;
            let message = new $root.object_msg.DepartmentActionArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.DepartmentActionArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.DepartmentActionArray.array: object expected");
                    message.array[i] = $root.object_msg.DepartmentAction.fromObject(object.array[i]);
                }
            }
            return message;
        };

        DepartmentActionArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.DepartmentAction.toObject(message.array[j], options);
            }
            return object;
        };

        DepartmentActionArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        DepartmentActionArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.DepartmentActionArray";
        };

        return DepartmentActionArray;
    })();

    object_msg.LaserCuttingMaterialThickness = (function() {

        function LaserCuttingMaterialThickness(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LaserCuttingMaterialThickness.prototype.material = "";
        LaserCuttingMaterialThickness.prototype.thickness_01mm = 0;

        LaserCuttingMaterialThickness.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                writer.uint32(10).string(message.material);
            if (message.thickness_01mm != null && Object.hasOwnProperty.call(message, "thickness_01mm"))
                writer.uint32(16).int32(message.thickness_01mm);
            return writer;
        };

        LaserCuttingMaterialThickness.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LaserCuttingMaterialThickness();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.material = reader.string();
                        break;
                    }
                case 2: {
                        message.thickness_01mm = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LaserCuttingMaterialThickness.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LaserCuttingMaterialThickness)
                return object;
            let message = new $root.object_msg.LaserCuttingMaterialThickness();
            if (object.material != null)
                message.material = String(object.material);
            if (object.thickness_01mm != null)
                message.thickness_01mm = object.thickness_01mm | 0;
            return message;
        };

        LaserCuttingMaterialThickness.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.material = "";
                object.thickness_01mm = 0;
            }
            if (message.material != null && message.hasOwnProperty("material"))
                object.material = message.material;
            if (message.thickness_01mm != null && message.hasOwnProperty("thickness_01mm"))
                object.thickness_01mm = message.thickness_01mm;
            return object;
        };

        LaserCuttingMaterialThickness.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LaserCuttingMaterialThickness.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LaserCuttingMaterialThickness";
        };

        return LaserCuttingMaterialThickness;
    })();

    object_msg.LaserCuttingMaterialThicknessArray = (function() {

        function LaserCuttingMaterialThicknessArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LaserCuttingMaterialThicknessArray.prototype.array = $util.emptyArray;

        LaserCuttingMaterialThicknessArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.LaserCuttingMaterialThickness.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        LaserCuttingMaterialThicknessArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LaserCuttingMaterialThicknessArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.LaserCuttingMaterialThickness.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LaserCuttingMaterialThicknessArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LaserCuttingMaterialThicknessArray)
                return object;
            let message = new $root.object_msg.LaserCuttingMaterialThicknessArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.LaserCuttingMaterialThicknessArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.LaserCuttingMaterialThicknessArray.array: object expected");
                    message.array[i] = $root.object_msg.LaserCuttingMaterialThickness.fromObject(object.array[i]);
                }
            }
            return message;
        };

        LaserCuttingMaterialThicknessArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.LaserCuttingMaterialThickness.toObject(message.array[j], options);
            }
            return object;
        };

        LaserCuttingMaterialThicknessArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LaserCuttingMaterialThicknessArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LaserCuttingMaterialThicknessArray";
        };

        return LaserCuttingMaterialThicknessArray;
    })();

    object_msg.PlacesApiRequest = (function() {

        function PlacesApiRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlacesApiRequest.prototype.update_place_by_manager_args = null;
        PlacesApiRequest.prototype.assign_org_to_place_args = null;
        PlacesApiRequest.prototype.search_place_args = null;
        PlacesApiRequest.prototype.search_place_by_action_args = null;
        PlacesApiRequest.prototype.search_place_by_action_and_location_args = null;
        PlacesApiRequest.prototype.select_place_args = null;
        PlacesApiRequest.prototype.get_place_contact_info_args = null;
        PlacesApiRequest.prototype.select_city_action_args = null;
        PlacesApiRequest.prototype.get_city_places_by_action_args = null;
        PlacesApiRequest.prototype.search_custom_place_action_types_args = null;
        PlacesApiRequest.prototype.split_place_action_types_args = null;
        PlacesApiRequest.prototype.delete_place_action_types_args = null;
        PlacesApiRequest.prototype.add_action_type_args = null;
        PlacesApiRequest.prototype.set_action_material_args = null;
        PlacesApiRequest.prototype.reload_action_materials_args = null;
        PlacesApiRequest.prototype.get_custom_price_material_urls_args = null;
        PlacesApiRequest.prototype.get_custom_action_urls_args = null;
        PlacesApiRequest.prototype.search_laser_cutting_price_thicknesses_args = null;
        PlacesApiRequest.prototype.get_laser_cutting_price_statistics_args = null;
        PlacesApiRequest.prototype.get_local_laser_cutting_price_statistics_args = null;
        PlacesApiRequest.prototype.get_local_laser_cutting_prices_for_material_by_department_args = null;
        PlacesApiRequest.prototype.delete_system_laser_cutting_price_args = null;
        PlacesApiRequest.prototype.open_websites_by_system_laser_cutting_price_args = null;
        PlacesApiRequest.prototype.open_websites_by_department_laser_cutting_price_args = null;
        PlacesApiRequest.prototype.split_price_material_types_args = null;
        PlacesApiRequest.prototype.delete_price_material_types_args = null;
        PlacesApiRequest.prototype.unify_price_material_type_args = null;
        PlacesApiRequest.prototype.add_material_type_args = null;
        PlacesApiRequest.prototype.unify_place_machine_type_args = null;
        PlacesApiRequest.prototype.add_machine_type_args = null;
        PlacesApiRequest.prototype.unify_place_label_type_args = null;
        PlacesApiRequest.prototype.add_label_type_args = null;
        PlacesApiRequest.prototype.add_label_to_places_with_action_args = null;
        PlacesApiRequest.prototype.stage_place_info_args = null;
        PlacesApiRequest.prototype.get_stage_place_address_args = null;
        PlacesApiRequest.prototype.add_laser_cutting_material_thickness_args = null;
        PlacesApiRequest.prototype.remove_laser_cutting_material_thickness_args = null;
        PlacesApiRequest.prototype.reload_laser_cutting_material_thicknesses_args = null;
        PlacesApiRequest.prototype.create_new_departments_args = null;
        PlacesApiRequest.prototype.add_action_to_department_args = null;
        PlacesApiRequest.prototype.add_action_to_all_departments_args = null;
        PlacesApiRequest.prototype.setup_local_prices_for_action_args = null;
        PlacesApiRequest.prototype.open_addresses_in_selenium_args = null;
        PlacesApiRequest.prototype.set_department_address_args = null;

        let $oneOfFields;

        Object.defineProperty(PlacesApiRequest.prototype, "args", {
            get: $util.oneOfGetter($oneOfFields = ["update_place_by_manager_args", "assign_org_to_place_args", "search_place_args", "search_place_by_action_args", "search_place_by_action_and_location_args", "select_place_args", "get_place_contact_info_args", "select_city_action_args", "get_city_places_by_action_args", "search_custom_place_action_types_args", "split_place_action_types_args", "delete_place_action_types_args", "add_action_type_args", "set_action_material_args", "reload_action_materials_args", "get_custom_price_material_urls_args", "get_custom_action_urls_args", "search_laser_cutting_price_thicknesses_args", "get_laser_cutting_price_statistics_args", "get_local_laser_cutting_price_statistics_args", "get_local_laser_cutting_prices_for_material_by_department_args", "delete_system_laser_cutting_price_args", "open_websites_by_system_laser_cutting_price_args", "open_websites_by_department_laser_cutting_price_args", "split_price_material_types_args", "delete_price_material_types_args", "unify_price_material_type_args", "add_material_type_args", "unify_place_machine_type_args", "add_machine_type_args", "unify_place_label_type_args", "add_label_type_args", "add_label_to_places_with_action_args", "stage_place_info_args", "get_stage_place_address_args", "add_laser_cutting_material_thickness_args", "remove_laser_cutting_material_thickness_args", "reload_laser_cutting_material_thicknesses_args", "create_new_departments_args", "add_action_to_department_args", "add_action_to_all_departments_args", "setup_local_prices_for_action_args", "open_addresses_in_selenium_args", "set_department_address_args"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        PlacesApiRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.update_place_by_manager_args != null && Object.hasOwnProperty.call(message, "update_place_by_manager_args"))
                $root.object_msg.PlacesApiRequest.update_place_by_manager.encode(message.update_place_by_manager_args, writer.uint32(10).fork()).ldelim();
            if (message.assign_org_to_place_args != null && Object.hasOwnProperty.call(message, "assign_org_to_place_args"))
                $root.object_msg.PlacesApiRequest.assign_org_to_place.encode(message.assign_org_to_place_args, writer.uint32(18).fork()).ldelim();
            if (message.search_place_args != null && Object.hasOwnProperty.call(message, "search_place_args"))
                $root.object_msg.PlacesApiRequest.search_place.encode(message.search_place_args, writer.uint32(26).fork()).ldelim();
            if (message.search_place_by_action_args != null && Object.hasOwnProperty.call(message, "search_place_by_action_args"))
                $root.object_msg.PlacesApiRequest.search_place_by_action.encode(message.search_place_by_action_args, writer.uint32(34).fork()).ldelim();
            if (message.search_place_by_action_and_location_args != null && Object.hasOwnProperty.call(message, "search_place_by_action_and_location_args"))
                $root.object_msg.PlacesApiRequest.search_place_by_action_and_location.encode(message.search_place_by_action_and_location_args, writer.uint32(42).fork()).ldelim();
            if (message.select_place_args != null && Object.hasOwnProperty.call(message, "select_place_args"))
                $root.object_msg.PlacesApiRequest.select_place.encode(message.select_place_args, writer.uint32(50).fork()).ldelim();
            if (message.get_place_contact_info_args != null && Object.hasOwnProperty.call(message, "get_place_contact_info_args"))
                $root.object_msg.PlacesApiRequest.get_place_contact_info.encode(message.get_place_contact_info_args, writer.uint32(58).fork()).ldelim();
            if (message.select_city_action_args != null && Object.hasOwnProperty.call(message, "select_city_action_args"))
                $root.object_msg.PlacesApiRequest.select_city_action.encode(message.select_city_action_args, writer.uint32(66).fork()).ldelim();
            if (message.get_city_places_by_action_args != null && Object.hasOwnProperty.call(message, "get_city_places_by_action_args"))
                $root.object_msg.PlacesApiRequest.get_city_places_by_action.encode(message.get_city_places_by_action_args, writer.uint32(74).fork()).ldelim();
            if (message.search_custom_place_action_types_args != null && Object.hasOwnProperty.call(message, "search_custom_place_action_types_args"))
                $root.object_msg.PlacesApiRequest.search_custom_place_action_types.encode(message.search_custom_place_action_types_args, writer.uint32(82).fork()).ldelim();
            if (message.split_place_action_types_args != null && Object.hasOwnProperty.call(message, "split_place_action_types_args"))
                $root.object_msg.PlacesApiRequest.split_place_action_types.encode(message.split_place_action_types_args, writer.uint32(90).fork()).ldelim();
            if (message.delete_place_action_types_args != null && Object.hasOwnProperty.call(message, "delete_place_action_types_args"))
                $root.object_msg.PlacesApiRequest.delete_place_action_types.encode(message.delete_place_action_types_args, writer.uint32(98).fork()).ldelim();
            if (message.add_action_type_args != null && Object.hasOwnProperty.call(message, "add_action_type_args"))
                $root.object_msg.PlacesApiRequest.add_action_type.encode(message.add_action_type_args, writer.uint32(106).fork()).ldelim();
            if (message.set_action_material_args != null && Object.hasOwnProperty.call(message, "set_action_material_args"))
                $root.object_msg.PlacesApiRequest.set_action_material.encode(message.set_action_material_args, writer.uint32(114).fork()).ldelim();
            if (message.reload_action_materials_args != null && Object.hasOwnProperty.call(message, "reload_action_materials_args"))
                $root.object_msg.PlacesApiRequest.reload_action_materials.encode(message.reload_action_materials_args, writer.uint32(122).fork()).ldelim();
            if (message.get_custom_price_material_urls_args != null && Object.hasOwnProperty.call(message, "get_custom_price_material_urls_args"))
                $root.object_msg.PlacesApiRequest.get_custom_price_material_urls.encode(message.get_custom_price_material_urls_args, writer.uint32(130).fork()).ldelim();
            if (message.get_custom_action_urls_args != null && Object.hasOwnProperty.call(message, "get_custom_action_urls_args"))
                $root.object_msg.PlacesApiRequest.get_custom_action_urls.encode(message.get_custom_action_urls_args, writer.uint32(138).fork()).ldelim();
            if (message.search_laser_cutting_price_thicknesses_args != null && Object.hasOwnProperty.call(message, "search_laser_cutting_price_thicknesses_args"))
                $root.object_msg.PlacesApiRequest.search_laser_cutting_price_thicknesses.encode(message.search_laser_cutting_price_thicknesses_args, writer.uint32(146).fork()).ldelim();
            if (message.get_laser_cutting_price_statistics_args != null && Object.hasOwnProperty.call(message, "get_laser_cutting_price_statistics_args"))
                $root.object_msg.PlacesApiRequest.get_laser_cutting_price_statistics.encode(message.get_laser_cutting_price_statistics_args, writer.uint32(154).fork()).ldelim();
            if (message.get_local_laser_cutting_price_statistics_args != null && Object.hasOwnProperty.call(message, "get_local_laser_cutting_price_statistics_args"))
                $root.object_msg.PlacesApiRequest.get_local_laser_cutting_price_statistics.encode(message.get_local_laser_cutting_price_statistics_args, writer.uint32(162).fork()).ldelim();
            if (message.get_local_laser_cutting_prices_for_material_by_department_args != null && Object.hasOwnProperty.call(message, "get_local_laser_cutting_prices_for_material_by_department_args"))
                $root.object_msg.PlacesApiRequest.get_local_laser_cutting_prices_for_material_by_department.encode(message.get_local_laser_cutting_prices_for_material_by_department_args, writer.uint32(170).fork()).ldelim();
            if (message.delete_system_laser_cutting_price_args != null && Object.hasOwnProperty.call(message, "delete_system_laser_cutting_price_args"))
                $root.object_msg.PlacesApiRequest.delete_system_laser_cutting_price.encode(message.delete_system_laser_cutting_price_args, writer.uint32(178).fork()).ldelim();
            if (message.open_websites_by_system_laser_cutting_price_args != null && Object.hasOwnProperty.call(message, "open_websites_by_system_laser_cutting_price_args"))
                $root.object_msg.PlacesApiRequest.open_websites_by_system_laser_cutting_price.encode(message.open_websites_by_system_laser_cutting_price_args, writer.uint32(186).fork()).ldelim();
            if (message.open_websites_by_department_laser_cutting_price_args != null && Object.hasOwnProperty.call(message, "open_websites_by_department_laser_cutting_price_args"))
                $root.object_msg.PlacesApiRequest.open_websites_by_department_laser_cutting_price.encode(message.open_websites_by_department_laser_cutting_price_args, writer.uint32(194).fork()).ldelim();
            if (message.split_price_material_types_args != null && Object.hasOwnProperty.call(message, "split_price_material_types_args"))
                $root.object_msg.PlacesApiRequest.split_price_material_types.encode(message.split_price_material_types_args, writer.uint32(202).fork()).ldelim();
            if (message.delete_price_material_types_args != null && Object.hasOwnProperty.call(message, "delete_price_material_types_args"))
                $root.object_msg.PlacesApiRequest.delete_price_material_types.encode(message.delete_price_material_types_args, writer.uint32(210).fork()).ldelim();
            if (message.unify_price_material_type_args != null && Object.hasOwnProperty.call(message, "unify_price_material_type_args"))
                $root.object_msg.PlacesApiRequest.unify_price_material_type.encode(message.unify_price_material_type_args, writer.uint32(218).fork()).ldelim();
            if (message.add_material_type_args != null && Object.hasOwnProperty.call(message, "add_material_type_args"))
                $root.object_msg.PlacesApiRequest.add_material_type.encode(message.add_material_type_args, writer.uint32(226).fork()).ldelim();
            if (message.unify_place_machine_type_args != null && Object.hasOwnProperty.call(message, "unify_place_machine_type_args"))
                $root.object_msg.PlacesApiRequest.unify_place_machine_type.encode(message.unify_place_machine_type_args, writer.uint32(234).fork()).ldelim();
            if (message.add_machine_type_args != null && Object.hasOwnProperty.call(message, "add_machine_type_args"))
                $root.object_msg.PlacesApiRequest.add_machine_type.encode(message.add_machine_type_args, writer.uint32(242).fork()).ldelim();
            if (message.unify_place_label_type_args != null && Object.hasOwnProperty.call(message, "unify_place_label_type_args"))
                $root.object_msg.PlacesApiRequest.unify_place_label_type.encode(message.unify_place_label_type_args, writer.uint32(250).fork()).ldelim();
            if (message.add_label_type_args != null && Object.hasOwnProperty.call(message, "add_label_type_args"))
                $root.object_msg.PlacesApiRequest.add_label_type.encode(message.add_label_type_args, writer.uint32(258).fork()).ldelim();
            if (message.add_label_to_places_with_action_args != null && Object.hasOwnProperty.call(message, "add_label_to_places_with_action_args"))
                $root.object_msg.PlacesApiRequest.add_label_to_places_with_action.encode(message.add_label_to_places_with_action_args, writer.uint32(266).fork()).ldelim();
            if (message.stage_place_info_args != null && Object.hasOwnProperty.call(message, "stage_place_info_args"))
                $root.object_msg.PlacesApiRequest.stage_place_info.encode(message.stage_place_info_args, writer.uint32(274).fork()).ldelim();
            if (message.get_stage_place_address_args != null && Object.hasOwnProperty.call(message, "get_stage_place_address_args"))
                $root.object_msg.PlacesApiRequest.get_stage_place_address.encode(message.get_stage_place_address_args, writer.uint32(282).fork()).ldelim();
            if (message.add_laser_cutting_material_thickness_args != null && Object.hasOwnProperty.call(message, "add_laser_cutting_material_thickness_args"))
                $root.object_msg.PlacesApiRequest.add_laser_cutting_material_thickness.encode(message.add_laser_cutting_material_thickness_args, writer.uint32(290).fork()).ldelim();
            if (message.remove_laser_cutting_material_thickness_args != null && Object.hasOwnProperty.call(message, "remove_laser_cutting_material_thickness_args"))
                $root.object_msg.PlacesApiRequest.remove_laser_cutting_material_thickness.encode(message.remove_laser_cutting_material_thickness_args, writer.uint32(298).fork()).ldelim();
            if (message.reload_laser_cutting_material_thicknesses_args != null && Object.hasOwnProperty.call(message, "reload_laser_cutting_material_thicknesses_args"))
                $root.object_msg.PlacesApiRequest.reload_laser_cutting_material_thicknesses.encode(message.reload_laser_cutting_material_thicknesses_args, writer.uint32(306).fork()).ldelim();
            if (message.create_new_departments_args != null && Object.hasOwnProperty.call(message, "create_new_departments_args"))
                $root.object_msg.PlacesApiRequest.create_new_departments.encode(message.create_new_departments_args, writer.uint32(314).fork()).ldelim();
            if (message.add_action_to_department_args != null && Object.hasOwnProperty.call(message, "add_action_to_department_args"))
                $root.object_msg.PlacesApiRequest.add_action_to_department.encode(message.add_action_to_department_args, writer.uint32(322).fork()).ldelim();
            if (message.add_action_to_all_departments_args != null && Object.hasOwnProperty.call(message, "add_action_to_all_departments_args"))
                $root.object_msg.PlacesApiRequest.add_action_to_all_departments.encode(message.add_action_to_all_departments_args, writer.uint32(330).fork()).ldelim();
            if (message.setup_local_prices_for_action_args != null && Object.hasOwnProperty.call(message, "setup_local_prices_for_action_args"))
                $root.object_msg.PlacesApiRequest.setup_local_prices_for_action.encode(message.setup_local_prices_for_action_args, writer.uint32(338).fork()).ldelim();
            if (message.open_addresses_in_selenium_args != null && Object.hasOwnProperty.call(message, "open_addresses_in_selenium_args"))
                $root.object_msg.PlacesApiRequest.open_addresses_in_selenium.encode(message.open_addresses_in_selenium_args, writer.uint32(346).fork()).ldelim();
            if (message.set_department_address_args != null && Object.hasOwnProperty.call(message, "set_department_address_args"))
                $root.object_msg.PlacesApiRequest.set_department_address.encode(message.set_department_address_args, writer.uint32(354).fork()).ldelim();
            return writer;
        };

        PlacesApiRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.update_place_by_manager_args = $root.object_msg.PlacesApiRequest.update_place_by_manager.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.assign_org_to_place_args = $root.object_msg.PlacesApiRequest.assign_org_to_place.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.search_place_args = $root.object_msg.PlacesApiRequest.search_place.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.search_place_by_action_args = $root.object_msg.PlacesApiRequest.search_place_by_action.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.search_place_by_action_and_location_args = $root.object_msg.PlacesApiRequest.search_place_by_action_and_location.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.select_place_args = $root.object_msg.PlacesApiRequest.select_place.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.get_place_contact_info_args = $root.object_msg.PlacesApiRequest.get_place_contact_info.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.select_city_action_args = $root.object_msg.PlacesApiRequest.select_city_action.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.get_city_places_by_action_args = $root.object_msg.PlacesApiRequest.get_city_places_by_action.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.search_custom_place_action_types_args = $root.object_msg.PlacesApiRequest.search_custom_place_action_types.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.split_place_action_types_args = $root.object_msg.PlacesApiRequest.split_place_action_types.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.delete_place_action_types_args = $root.object_msg.PlacesApiRequest.delete_place_action_types.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        message.add_action_type_args = $root.object_msg.PlacesApiRequest.add_action_type.decode(reader, reader.uint32());
                        break;
                    }
                case 14: {
                        message.set_action_material_args = $root.object_msg.PlacesApiRequest.set_action_material.decode(reader, reader.uint32());
                        break;
                    }
                case 15: {
                        message.reload_action_materials_args = $root.object_msg.PlacesApiRequest.reload_action_materials.decode(reader, reader.uint32());
                        break;
                    }
                case 16: {
                        message.get_custom_price_material_urls_args = $root.object_msg.PlacesApiRequest.get_custom_price_material_urls.decode(reader, reader.uint32());
                        break;
                    }
                case 17: {
                        message.get_custom_action_urls_args = $root.object_msg.PlacesApiRequest.get_custom_action_urls.decode(reader, reader.uint32());
                        break;
                    }
                case 18: {
                        message.search_laser_cutting_price_thicknesses_args = $root.object_msg.PlacesApiRequest.search_laser_cutting_price_thicknesses.decode(reader, reader.uint32());
                        break;
                    }
                case 19: {
                        message.get_laser_cutting_price_statistics_args = $root.object_msg.PlacesApiRequest.get_laser_cutting_price_statistics.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.get_local_laser_cutting_price_statistics_args = $root.object_msg.PlacesApiRequest.get_local_laser_cutting_price_statistics.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        message.get_local_laser_cutting_prices_for_material_by_department_args = $root.object_msg.PlacesApiRequest.get_local_laser_cutting_prices_for_material_by_department.decode(reader, reader.uint32());
                        break;
                    }
                case 22: {
                        message.delete_system_laser_cutting_price_args = $root.object_msg.PlacesApiRequest.delete_system_laser_cutting_price.decode(reader, reader.uint32());
                        break;
                    }
                case 23: {
                        message.open_websites_by_system_laser_cutting_price_args = $root.object_msg.PlacesApiRequest.open_websites_by_system_laser_cutting_price.decode(reader, reader.uint32());
                        break;
                    }
                case 24: {
                        message.open_websites_by_department_laser_cutting_price_args = $root.object_msg.PlacesApiRequest.open_websites_by_department_laser_cutting_price.decode(reader, reader.uint32());
                        break;
                    }
                case 25: {
                        message.split_price_material_types_args = $root.object_msg.PlacesApiRequest.split_price_material_types.decode(reader, reader.uint32());
                        break;
                    }
                case 26: {
                        message.delete_price_material_types_args = $root.object_msg.PlacesApiRequest.delete_price_material_types.decode(reader, reader.uint32());
                        break;
                    }
                case 27: {
                        message.unify_price_material_type_args = $root.object_msg.PlacesApiRequest.unify_price_material_type.decode(reader, reader.uint32());
                        break;
                    }
                case 28: {
                        message.add_material_type_args = $root.object_msg.PlacesApiRequest.add_material_type.decode(reader, reader.uint32());
                        break;
                    }
                case 29: {
                        message.unify_place_machine_type_args = $root.object_msg.PlacesApiRequest.unify_place_machine_type.decode(reader, reader.uint32());
                        break;
                    }
                case 30: {
                        message.add_machine_type_args = $root.object_msg.PlacesApiRequest.add_machine_type.decode(reader, reader.uint32());
                        break;
                    }
                case 31: {
                        message.unify_place_label_type_args = $root.object_msg.PlacesApiRequest.unify_place_label_type.decode(reader, reader.uint32());
                        break;
                    }
                case 32: {
                        message.add_label_type_args = $root.object_msg.PlacesApiRequest.add_label_type.decode(reader, reader.uint32());
                        break;
                    }
                case 33: {
                        message.add_label_to_places_with_action_args = $root.object_msg.PlacesApiRequest.add_label_to_places_with_action.decode(reader, reader.uint32());
                        break;
                    }
                case 34: {
                        message.stage_place_info_args = $root.object_msg.PlacesApiRequest.stage_place_info.decode(reader, reader.uint32());
                        break;
                    }
                case 35: {
                        message.get_stage_place_address_args = $root.object_msg.PlacesApiRequest.get_stage_place_address.decode(reader, reader.uint32());
                        break;
                    }
                case 36: {
                        message.add_laser_cutting_material_thickness_args = $root.object_msg.PlacesApiRequest.add_laser_cutting_material_thickness.decode(reader, reader.uint32());
                        break;
                    }
                case 37: {
                        message.remove_laser_cutting_material_thickness_args = $root.object_msg.PlacesApiRequest.remove_laser_cutting_material_thickness.decode(reader, reader.uint32());
                        break;
                    }
                case 38: {
                        message.reload_laser_cutting_material_thicknesses_args = $root.object_msg.PlacesApiRequest.reload_laser_cutting_material_thicknesses.decode(reader, reader.uint32());
                        break;
                    }
                case 39: {
                        message.create_new_departments_args = $root.object_msg.PlacesApiRequest.create_new_departments.decode(reader, reader.uint32());
                        break;
                    }
                case 40: {
                        message.add_action_to_department_args = $root.object_msg.PlacesApiRequest.add_action_to_department.decode(reader, reader.uint32());
                        break;
                    }
                case 41: {
                        message.add_action_to_all_departments_args = $root.object_msg.PlacesApiRequest.add_action_to_all_departments.decode(reader, reader.uint32());
                        break;
                    }
                case 42: {
                        message.setup_local_prices_for_action_args = $root.object_msg.PlacesApiRequest.setup_local_prices_for_action.decode(reader, reader.uint32());
                        break;
                    }
                case 43: {
                        message.open_addresses_in_selenium_args = $root.object_msg.PlacesApiRequest.open_addresses_in_selenium.decode(reader, reader.uint32());
                        break;
                    }
                case 44: {
                        message.set_department_address_args = $root.object_msg.PlacesApiRequest.set_department_address.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlacesApiRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlacesApiRequest)
                return object;
            let message = new $root.object_msg.PlacesApiRequest();
            if (object.update_place_by_manager_args != null) {
                if (typeof object.update_place_by_manager_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.update_place_by_manager_args: object expected");
                message.update_place_by_manager_args = $root.object_msg.PlacesApiRequest.update_place_by_manager.fromObject(object.update_place_by_manager_args);
            }
            if (object.assign_org_to_place_args != null) {
                if (typeof object.assign_org_to_place_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.assign_org_to_place_args: object expected");
                message.assign_org_to_place_args = $root.object_msg.PlacesApiRequest.assign_org_to_place.fromObject(object.assign_org_to_place_args);
            }
            if (object.search_place_args != null) {
                if (typeof object.search_place_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.search_place_args: object expected");
                message.search_place_args = $root.object_msg.PlacesApiRequest.search_place.fromObject(object.search_place_args);
            }
            if (object.search_place_by_action_args != null) {
                if (typeof object.search_place_by_action_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.search_place_by_action_args: object expected");
                message.search_place_by_action_args = $root.object_msg.PlacesApiRequest.search_place_by_action.fromObject(object.search_place_by_action_args);
            }
            if (object.search_place_by_action_and_location_args != null) {
                if (typeof object.search_place_by_action_and_location_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.search_place_by_action_and_location_args: object expected");
                message.search_place_by_action_and_location_args = $root.object_msg.PlacesApiRequest.search_place_by_action_and_location.fromObject(object.search_place_by_action_and_location_args);
            }
            if (object.select_place_args != null) {
                if (typeof object.select_place_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.select_place_args: object expected");
                message.select_place_args = $root.object_msg.PlacesApiRequest.select_place.fromObject(object.select_place_args);
            }
            if (object.get_place_contact_info_args != null) {
                if (typeof object.get_place_contact_info_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.get_place_contact_info_args: object expected");
                message.get_place_contact_info_args = $root.object_msg.PlacesApiRequest.get_place_contact_info.fromObject(object.get_place_contact_info_args);
            }
            if (object.select_city_action_args != null) {
                if (typeof object.select_city_action_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.select_city_action_args: object expected");
                message.select_city_action_args = $root.object_msg.PlacesApiRequest.select_city_action.fromObject(object.select_city_action_args);
            }
            if (object.get_city_places_by_action_args != null) {
                if (typeof object.get_city_places_by_action_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.get_city_places_by_action_args: object expected");
                message.get_city_places_by_action_args = $root.object_msg.PlacesApiRequest.get_city_places_by_action.fromObject(object.get_city_places_by_action_args);
            }
            if (object.search_custom_place_action_types_args != null) {
                if (typeof object.search_custom_place_action_types_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.search_custom_place_action_types_args: object expected");
                message.search_custom_place_action_types_args = $root.object_msg.PlacesApiRequest.search_custom_place_action_types.fromObject(object.search_custom_place_action_types_args);
            }
            if (object.split_place_action_types_args != null) {
                if (typeof object.split_place_action_types_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.split_place_action_types_args: object expected");
                message.split_place_action_types_args = $root.object_msg.PlacesApiRequest.split_place_action_types.fromObject(object.split_place_action_types_args);
            }
            if (object.delete_place_action_types_args != null) {
                if (typeof object.delete_place_action_types_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.delete_place_action_types_args: object expected");
                message.delete_place_action_types_args = $root.object_msg.PlacesApiRequest.delete_place_action_types.fromObject(object.delete_place_action_types_args);
            }
            if (object.add_action_type_args != null) {
                if (typeof object.add_action_type_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.add_action_type_args: object expected");
                message.add_action_type_args = $root.object_msg.PlacesApiRequest.add_action_type.fromObject(object.add_action_type_args);
            }
            if (object.set_action_material_args != null) {
                if (typeof object.set_action_material_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.set_action_material_args: object expected");
                message.set_action_material_args = $root.object_msg.PlacesApiRequest.set_action_material.fromObject(object.set_action_material_args);
            }
            if (object.reload_action_materials_args != null) {
                if (typeof object.reload_action_materials_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.reload_action_materials_args: object expected");
                message.reload_action_materials_args = $root.object_msg.PlacesApiRequest.reload_action_materials.fromObject(object.reload_action_materials_args);
            }
            if (object.get_custom_price_material_urls_args != null) {
                if (typeof object.get_custom_price_material_urls_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.get_custom_price_material_urls_args: object expected");
                message.get_custom_price_material_urls_args = $root.object_msg.PlacesApiRequest.get_custom_price_material_urls.fromObject(object.get_custom_price_material_urls_args);
            }
            if (object.get_custom_action_urls_args != null) {
                if (typeof object.get_custom_action_urls_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.get_custom_action_urls_args: object expected");
                message.get_custom_action_urls_args = $root.object_msg.PlacesApiRequest.get_custom_action_urls.fromObject(object.get_custom_action_urls_args);
            }
            if (object.search_laser_cutting_price_thicknesses_args != null) {
                if (typeof object.search_laser_cutting_price_thicknesses_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.search_laser_cutting_price_thicknesses_args: object expected");
                message.search_laser_cutting_price_thicknesses_args = $root.object_msg.PlacesApiRequest.search_laser_cutting_price_thicknesses.fromObject(object.search_laser_cutting_price_thicknesses_args);
            }
            if (object.get_laser_cutting_price_statistics_args != null) {
                if (typeof object.get_laser_cutting_price_statistics_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.get_laser_cutting_price_statistics_args: object expected");
                message.get_laser_cutting_price_statistics_args = $root.object_msg.PlacesApiRequest.get_laser_cutting_price_statistics.fromObject(object.get_laser_cutting_price_statistics_args);
            }
            if (object.get_local_laser_cutting_price_statistics_args != null) {
                if (typeof object.get_local_laser_cutting_price_statistics_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.get_local_laser_cutting_price_statistics_args: object expected");
                message.get_local_laser_cutting_price_statistics_args = $root.object_msg.PlacesApiRequest.get_local_laser_cutting_price_statistics.fromObject(object.get_local_laser_cutting_price_statistics_args);
            }
            if (object.get_local_laser_cutting_prices_for_material_by_department_args != null) {
                if (typeof object.get_local_laser_cutting_prices_for_material_by_department_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.get_local_laser_cutting_prices_for_material_by_department_args: object expected");
                message.get_local_laser_cutting_prices_for_material_by_department_args = $root.object_msg.PlacesApiRequest.get_local_laser_cutting_prices_for_material_by_department.fromObject(object.get_local_laser_cutting_prices_for_material_by_department_args);
            }
            if (object.delete_system_laser_cutting_price_args != null) {
                if (typeof object.delete_system_laser_cutting_price_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.delete_system_laser_cutting_price_args: object expected");
                message.delete_system_laser_cutting_price_args = $root.object_msg.PlacesApiRequest.delete_system_laser_cutting_price.fromObject(object.delete_system_laser_cutting_price_args);
            }
            if (object.open_websites_by_system_laser_cutting_price_args != null) {
                if (typeof object.open_websites_by_system_laser_cutting_price_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.open_websites_by_system_laser_cutting_price_args: object expected");
                message.open_websites_by_system_laser_cutting_price_args = $root.object_msg.PlacesApiRequest.open_websites_by_system_laser_cutting_price.fromObject(object.open_websites_by_system_laser_cutting_price_args);
            }
            if (object.open_websites_by_department_laser_cutting_price_args != null) {
                if (typeof object.open_websites_by_department_laser_cutting_price_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.open_websites_by_department_laser_cutting_price_args: object expected");
                message.open_websites_by_department_laser_cutting_price_args = $root.object_msg.PlacesApiRequest.open_websites_by_department_laser_cutting_price.fromObject(object.open_websites_by_department_laser_cutting_price_args);
            }
            if (object.split_price_material_types_args != null) {
                if (typeof object.split_price_material_types_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.split_price_material_types_args: object expected");
                message.split_price_material_types_args = $root.object_msg.PlacesApiRequest.split_price_material_types.fromObject(object.split_price_material_types_args);
            }
            if (object.delete_price_material_types_args != null) {
                if (typeof object.delete_price_material_types_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.delete_price_material_types_args: object expected");
                message.delete_price_material_types_args = $root.object_msg.PlacesApiRequest.delete_price_material_types.fromObject(object.delete_price_material_types_args);
            }
            if (object.unify_price_material_type_args != null) {
                if (typeof object.unify_price_material_type_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.unify_price_material_type_args: object expected");
                message.unify_price_material_type_args = $root.object_msg.PlacesApiRequest.unify_price_material_type.fromObject(object.unify_price_material_type_args);
            }
            if (object.add_material_type_args != null) {
                if (typeof object.add_material_type_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.add_material_type_args: object expected");
                message.add_material_type_args = $root.object_msg.PlacesApiRequest.add_material_type.fromObject(object.add_material_type_args);
            }
            if (object.unify_place_machine_type_args != null) {
                if (typeof object.unify_place_machine_type_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.unify_place_machine_type_args: object expected");
                message.unify_place_machine_type_args = $root.object_msg.PlacesApiRequest.unify_place_machine_type.fromObject(object.unify_place_machine_type_args);
            }
            if (object.add_machine_type_args != null) {
                if (typeof object.add_machine_type_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.add_machine_type_args: object expected");
                message.add_machine_type_args = $root.object_msg.PlacesApiRequest.add_machine_type.fromObject(object.add_machine_type_args);
            }
            if (object.unify_place_label_type_args != null) {
                if (typeof object.unify_place_label_type_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.unify_place_label_type_args: object expected");
                message.unify_place_label_type_args = $root.object_msg.PlacesApiRequest.unify_place_label_type.fromObject(object.unify_place_label_type_args);
            }
            if (object.add_label_type_args != null) {
                if (typeof object.add_label_type_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.add_label_type_args: object expected");
                message.add_label_type_args = $root.object_msg.PlacesApiRequest.add_label_type.fromObject(object.add_label_type_args);
            }
            if (object.add_label_to_places_with_action_args != null) {
                if (typeof object.add_label_to_places_with_action_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.add_label_to_places_with_action_args: object expected");
                message.add_label_to_places_with_action_args = $root.object_msg.PlacesApiRequest.add_label_to_places_with_action.fromObject(object.add_label_to_places_with_action_args);
            }
            if (object.stage_place_info_args != null) {
                if (typeof object.stage_place_info_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.stage_place_info_args: object expected");
                message.stage_place_info_args = $root.object_msg.PlacesApiRequest.stage_place_info.fromObject(object.stage_place_info_args);
            }
            if (object.get_stage_place_address_args != null) {
                if (typeof object.get_stage_place_address_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.get_stage_place_address_args: object expected");
                message.get_stage_place_address_args = $root.object_msg.PlacesApiRequest.get_stage_place_address.fromObject(object.get_stage_place_address_args);
            }
            if (object.add_laser_cutting_material_thickness_args != null) {
                if (typeof object.add_laser_cutting_material_thickness_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.add_laser_cutting_material_thickness_args: object expected");
                message.add_laser_cutting_material_thickness_args = $root.object_msg.PlacesApiRequest.add_laser_cutting_material_thickness.fromObject(object.add_laser_cutting_material_thickness_args);
            }
            if (object.remove_laser_cutting_material_thickness_args != null) {
                if (typeof object.remove_laser_cutting_material_thickness_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.remove_laser_cutting_material_thickness_args: object expected");
                message.remove_laser_cutting_material_thickness_args = $root.object_msg.PlacesApiRequest.remove_laser_cutting_material_thickness.fromObject(object.remove_laser_cutting_material_thickness_args);
            }
            if (object.reload_laser_cutting_material_thicknesses_args != null) {
                if (typeof object.reload_laser_cutting_material_thicknesses_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.reload_laser_cutting_material_thicknesses_args: object expected");
                message.reload_laser_cutting_material_thicknesses_args = $root.object_msg.PlacesApiRequest.reload_laser_cutting_material_thicknesses.fromObject(object.reload_laser_cutting_material_thicknesses_args);
            }
            if (object.create_new_departments_args != null) {
                if (typeof object.create_new_departments_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.create_new_departments_args: object expected");
                message.create_new_departments_args = $root.object_msg.PlacesApiRequest.create_new_departments.fromObject(object.create_new_departments_args);
            }
            if (object.add_action_to_department_args != null) {
                if (typeof object.add_action_to_department_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.add_action_to_department_args: object expected");
                message.add_action_to_department_args = $root.object_msg.PlacesApiRequest.add_action_to_department.fromObject(object.add_action_to_department_args);
            }
            if (object.add_action_to_all_departments_args != null) {
                if (typeof object.add_action_to_all_departments_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.add_action_to_all_departments_args: object expected");
                message.add_action_to_all_departments_args = $root.object_msg.PlacesApiRequest.add_action_to_all_departments.fromObject(object.add_action_to_all_departments_args);
            }
            if (object.setup_local_prices_for_action_args != null) {
                if (typeof object.setup_local_prices_for_action_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.setup_local_prices_for_action_args: object expected");
                message.setup_local_prices_for_action_args = $root.object_msg.PlacesApiRequest.setup_local_prices_for_action.fromObject(object.setup_local_prices_for_action_args);
            }
            if (object.open_addresses_in_selenium_args != null) {
                if (typeof object.open_addresses_in_selenium_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.open_addresses_in_selenium_args: object expected");
                message.open_addresses_in_selenium_args = $root.object_msg.PlacesApiRequest.open_addresses_in_selenium.fromObject(object.open_addresses_in_selenium_args);
            }
            if (object.set_department_address_args != null) {
                if (typeof object.set_department_address_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.set_department_address_args: object expected");
                message.set_department_address_args = $root.object_msg.PlacesApiRequest.set_department_address.fromObject(object.set_department_address_args);
            }
            return message;
        };

        PlacesApiRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.update_place_by_manager_args != null && message.hasOwnProperty("update_place_by_manager_args")) {
                object.update_place_by_manager_args = $root.object_msg.PlacesApiRequest.update_place_by_manager.toObject(message.update_place_by_manager_args, options);
                if (options.oneofs)
                    object.args = "update_place_by_manager_args";
            }
            if (message.assign_org_to_place_args != null && message.hasOwnProperty("assign_org_to_place_args")) {
                object.assign_org_to_place_args = $root.object_msg.PlacesApiRequest.assign_org_to_place.toObject(message.assign_org_to_place_args, options);
                if (options.oneofs)
                    object.args = "assign_org_to_place_args";
            }
            if (message.search_place_args != null && message.hasOwnProperty("search_place_args")) {
                object.search_place_args = $root.object_msg.PlacesApiRequest.search_place.toObject(message.search_place_args, options);
                if (options.oneofs)
                    object.args = "search_place_args";
            }
            if (message.search_place_by_action_args != null && message.hasOwnProperty("search_place_by_action_args")) {
                object.search_place_by_action_args = $root.object_msg.PlacesApiRequest.search_place_by_action.toObject(message.search_place_by_action_args, options);
                if (options.oneofs)
                    object.args = "search_place_by_action_args";
            }
            if (message.search_place_by_action_and_location_args != null && message.hasOwnProperty("search_place_by_action_and_location_args")) {
                object.search_place_by_action_and_location_args = $root.object_msg.PlacesApiRequest.search_place_by_action_and_location.toObject(message.search_place_by_action_and_location_args, options);
                if (options.oneofs)
                    object.args = "search_place_by_action_and_location_args";
            }
            if (message.select_place_args != null && message.hasOwnProperty("select_place_args")) {
                object.select_place_args = $root.object_msg.PlacesApiRequest.select_place.toObject(message.select_place_args, options);
                if (options.oneofs)
                    object.args = "select_place_args";
            }
            if (message.get_place_contact_info_args != null && message.hasOwnProperty("get_place_contact_info_args")) {
                object.get_place_contact_info_args = $root.object_msg.PlacesApiRequest.get_place_contact_info.toObject(message.get_place_contact_info_args, options);
                if (options.oneofs)
                    object.args = "get_place_contact_info_args";
            }
            if (message.select_city_action_args != null && message.hasOwnProperty("select_city_action_args")) {
                object.select_city_action_args = $root.object_msg.PlacesApiRequest.select_city_action.toObject(message.select_city_action_args, options);
                if (options.oneofs)
                    object.args = "select_city_action_args";
            }
            if (message.get_city_places_by_action_args != null && message.hasOwnProperty("get_city_places_by_action_args")) {
                object.get_city_places_by_action_args = $root.object_msg.PlacesApiRequest.get_city_places_by_action.toObject(message.get_city_places_by_action_args, options);
                if (options.oneofs)
                    object.args = "get_city_places_by_action_args";
            }
            if (message.search_custom_place_action_types_args != null && message.hasOwnProperty("search_custom_place_action_types_args")) {
                object.search_custom_place_action_types_args = $root.object_msg.PlacesApiRequest.search_custom_place_action_types.toObject(message.search_custom_place_action_types_args, options);
                if (options.oneofs)
                    object.args = "search_custom_place_action_types_args";
            }
            if (message.split_place_action_types_args != null && message.hasOwnProperty("split_place_action_types_args")) {
                object.split_place_action_types_args = $root.object_msg.PlacesApiRequest.split_place_action_types.toObject(message.split_place_action_types_args, options);
                if (options.oneofs)
                    object.args = "split_place_action_types_args";
            }
            if (message.delete_place_action_types_args != null && message.hasOwnProperty("delete_place_action_types_args")) {
                object.delete_place_action_types_args = $root.object_msg.PlacesApiRequest.delete_place_action_types.toObject(message.delete_place_action_types_args, options);
                if (options.oneofs)
                    object.args = "delete_place_action_types_args";
            }
            if (message.add_action_type_args != null && message.hasOwnProperty("add_action_type_args")) {
                object.add_action_type_args = $root.object_msg.PlacesApiRequest.add_action_type.toObject(message.add_action_type_args, options);
                if (options.oneofs)
                    object.args = "add_action_type_args";
            }
            if (message.set_action_material_args != null && message.hasOwnProperty("set_action_material_args")) {
                object.set_action_material_args = $root.object_msg.PlacesApiRequest.set_action_material.toObject(message.set_action_material_args, options);
                if (options.oneofs)
                    object.args = "set_action_material_args";
            }
            if (message.reload_action_materials_args != null && message.hasOwnProperty("reload_action_materials_args")) {
                object.reload_action_materials_args = $root.object_msg.PlacesApiRequest.reload_action_materials.toObject(message.reload_action_materials_args, options);
                if (options.oneofs)
                    object.args = "reload_action_materials_args";
            }
            if (message.get_custom_price_material_urls_args != null && message.hasOwnProperty("get_custom_price_material_urls_args")) {
                object.get_custom_price_material_urls_args = $root.object_msg.PlacesApiRequest.get_custom_price_material_urls.toObject(message.get_custom_price_material_urls_args, options);
                if (options.oneofs)
                    object.args = "get_custom_price_material_urls_args";
            }
            if (message.get_custom_action_urls_args != null && message.hasOwnProperty("get_custom_action_urls_args")) {
                object.get_custom_action_urls_args = $root.object_msg.PlacesApiRequest.get_custom_action_urls.toObject(message.get_custom_action_urls_args, options);
                if (options.oneofs)
                    object.args = "get_custom_action_urls_args";
            }
            if (message.search_laser_cutting_price_thicknesses_args != null && message.hasOwnProperty("search_laser_cutting_price_thicknesses_args")) {
                object.search_laser_cutting_price_thicknesses_args = $root.object_msg.PlacesApiRequest.search_laser_cutting_price_thicknesses.toObject(message.search_laser_cutting_price_thicknesses_args, options);
                if (options.oneofs)
                    object.args = "search_laser_cutting_price_thicknesses_args";
            }
            if (message.get_laser_cutting_price_statistics_args != null && message.hasOwnProperty("get_laser_cutting_price_statistics_args")) {
                object.get_laser_cutting_price_statistics_args = $root.object_msg.PlacesApiRequest.get_laser_cutting_price_statistics.toObject(message.get_laser_cutting_price_statistics_args, options);
                if (options.oneofs)
                    object.args = "get_laser_cutting_price_statistics_args";
            }
            if (message.get_local_laser_cutting_price_statistics_args != null && message.hasOwnProperty("get_local_laser_cutting_price_statistics_args")) {
                object.get_local_laser_cutting_price_statistics_args = $root.object_msg.PlacesApiRequest.get_local_laser_cutting_price_statistics.toObject(message.get_local_laser_cutting_price_statistics_args, options);
                if (options.oneofs)
                    object.args = "get_local_laser_cutting_price_statistics_args";
            }
            if (message.get_local_laser_cutting_prices_for_material_by_department_args != null && message.hasOwnProperty("get_local_laser_cutting_prices_for_material_by_department_args")) {
                object.get_local_laser_cutting_prices_for_material_by_department_args = $root.object_msg.PlacesApiRequest.get_local_laser_cutting_prices_for_material_by_department.toObject(message.get_local_laser_cutting_prices_for_material_by_department_args, options);
                if (options.oneofs)
                    object.args = "get_local_laser_cutting_prices_for_material_by_department_args";
            }
            if (message.delete_system_laser_cutting_price_args != null && message.hasOwnProperty("delete_system_laser_cutting_price_args")) {
                object.delete_system_laser_cutting_price_args = $root.object_msg.PlacesApiRequest.delete_system_laser_cutting_price.toObject(message.delete_system_laser_cutting_price_args, options);
                if (options.oneofs)
                    object.args = "delete_system_laser_cutting_price_args";
            }
            if (message.open_websites_by_system_laser_cutting_price_args != null && message.hasOwnProperty("open_websites_by_system_laser_cutting_price_args")) {
                object.open_websites_by_system_laser_cutting_price_args = $root.object_msg.PlacesApiRequest.open_websites_by_system_laser_cutting_price.toObject(message.open_websites_by_system_laser_cutting_price_args, options);
                if (options.oneofs)
                    object.args = "open_websites_by_system_laser_cutting_price_args";
            }
            if (message.open_websites_by_department_laser_cutting_price_args != null && message.hasOwnProperty("open_websites_by_department_laser_cutting_price_args")) {
                object.open_websites_by_department_laser_cutting_price_args = $root.object_msg.PlacesApiRequest.open_websites_by_department_laser_cutting_price.toObject(message.open_websites_by_department_laser_cutting_price_args, options);
                if (options.oneofs)
                    object.args = "open_websites_by_department_laser_cutting_price_args";
            }
            if (message.split_price_material_types_args != null && message.hasOwnProperty("split_price_material_types_args")) {
                object.split_price_material_types_args = $root.object_msg.PlacesApiRequest.split_price_material_types.toObject(message.split_price_material_types_args, options);
                if (options.oneofs)
                    object.args = "split_price_material_types_args";
            }
            if (message.delete_price_material_types_args != null && message.hasOwnProperty("delete_price_material_types_args")) {
                object.delete_price_material_types_args = $root.object_msg.PlacesApiRequest.delete_price_material_types.toObject(message.delete_price_material_types_args, options);
                if (options.oneofs)
                    object.args = "delete_price_material_types_args";
            }
            if (message.unify_price_material_type_args != null && message.hasOwnProperty("unify_price_material_type_args")) {
                object.unify_price_material_type_args = $root.object_msg.PlacesApiRequest.unify_price_material_type.toObject(message.unify_price_material_type_args, options);
                if (options.oneofs)
                    object.args = "unify_price_material_type_args";
            }
            if (message.add_material_type_args != null && message.hasOwnProperty("add_material_type_args")) {
                object.add_material_type_args = $root.object_msg.PlacesApiRequest.add_material_type.toObject(message.add_material_type_args, options);
                if (options.oneofs)
                    object.args = "add_material_type_args";
            }
            if (message.unify_place_machine_type_args != null && message.hasOwnProperty("unify_place_machine_type_args")) {
                object.unify_place_machine_type_args = $root.object_msg.PlacesApiRequest.unify_place_machine_type.toObject(message.unify_place_machine_type_args, options);
                if (options.oneofs)
                    object.args = "unify_place_machine_type_args";
            }
            if (message.add_machine_type_args != null && message.hasOwnProperty("add_machine_type_args")) {
                object.add_machine_type_args = $root.object_msg.PlacesApiRequest.add_machine_type.toObject(message.add_machine_type_args, options);
                if (options.oneofs)
                    object.args = "add_machine_type_args";
            }
            if (message.unify_place_label_type_args != null && message.hasOwnProperty("unify_place_label_type_args")) {
                object.unify_place_label_type_args = $root.object_msg.PlacesApiRequest.unify_place_label_type.toObject(message.unify_place_label_type_args, options);
                if (options.oneofs)
                    object.args = "unify_place_label_type_args";
            }
            if (message.add_label_type_args != null && message.hasOwnProperty("add_label_type_args")) {
                object.add_label_type_args = $root.object_msg.PlacesApiRequest.add_label_type.toObject(message.add_label_type_args, options);
                if (options.oneofs)
                    object.args = "add_label_type_args";
            }
            if (message.add_label_to_places_with_action_args != null && message.hasOwnProperty("add_label_to_places_with_action_args")) {
                object.add_label_to_places_with_action_args = $root.object_msg.PlacesApiRequest.add_label_to_places_with_action.toObject(message.add_label_to_places_with_action_args, options);
                if (options.oneofs)
                    object.args = "add_label_to_places_with_action_args";
            }
            if (message.stage_place_info_args != null && message.hasOwnProperty("stage_place_info_args")) {
                object.stage_place_info_args = $root.object_msg.PlacesApiRequest.stage_place_info.toObject(message.stage_place_info_args, options);
                if (options.oneofs)
                    object.args = "stage_place_info_args";
            }
            if (message.get_stage_place_address_args != null && message.hasOwnProperty("get_stage_place_address_args")) {
                object.get_stage_place_address_args = $root.object_msg.PlacesApiRequest.get_stage_place_address.toObject(message.get_stage_place_address_args, options);
                if (options.oneofs)
                    object.args = "get_stage_place_address_args";
            }
            if (message.add_laser_cutting_material_thickness_args != null && message.hasOwnProperty("add_laser_cutting_material_thickness_args")) {
                object.add_laser_cutting_material_thickness_args = $root.object_msg.PlacesApiRequest.add_laser_cutting_material_thickness.toObject(message.add_laser_cutting_material_thickness_args, options);
                if (options.oneofs)
                    object.args = "add_laser_cutting_material_thickness_args";
            }
            if (message.remove_laser_cutting_material_thickness_args != null && message.hasOwnProperty("remove_laser_cutting_material_thickness_args")) {
                object.remove_laser_cutting_material_thickness_args = $root.object_msg.PlacesApiRequest.remove_laser_cutting_material_thickness.toObject(message.remove_laser_cutting_material_thickness_args, options);
                if (options.oneofs)
                    object.args = "remove_laser_cutting_material_thickness_args";
            }
            if (message.reload_laser_cutting_material_thicknesses_args != null && message.hasOwnProperty("reload_laser_cutting_material_thicknesses_args")) {
                object.reload_laser_cutting_material_thicknesses_args = $root.object_msg.PlacesApiRequest.reload_laser_cutting_material_thicknesses.toObject(message.reload_laser_cutting_material_thicknesses_args, options);
                if (options.oneofs)
                    object.args = "reload_laser_cutting_material_thicknesses_args";
            }
            if (message.create_new_departments_args != null && message.hasOwnProperty("create_new_departments_args")) {
                object.create_new_departments_args = $root.object_msg.PlacesApiRequest.create_new_departments.toObject(message.create_new_departments_args, options);
                if (options.oneofs)
                    object.args = "create_new_departments_args";
            }
            if (message.add_action_to_department_args != null && message.hasOwnProperty("add_action_to_department_args")) {
                object.add_action_to_department_args = $root.object_msg.PlacesApiRequest.add_action_to_department.toObject(message.add_action_to_department_args, options);
                if (options.oneofs)
                    object.args = "add_action_to_department_args";
            }
            if (message.add_action_to_all_departments_args != null && message.hasOwnProperty("add_action_to_all_departments_args")) {
                object.add_action_to_all_departments_args = $root.object_msg.PlacesApiRequest.add_action_to_all_departments.toObject(message.add_action_to_all_departments_args, options);
                if (options.oneofs)
                    object.args = "add_action_to_all_departments_args";
            }
            if (message.setup_local_prices_for_action_args != null && message.hasOwnProperty("setup_local_prices_for_action_args")) {
                object.setup_local_prices_for_action_args = $root.object_msg.PlacesApiRequest.setup_local_prices_for_action.toObject(message.setup_local_prices_for_action_args, options);
                if (options.oneofs)
                    object.args = "setup_local_prices_for_action_args";
            }
            if (message.open_addresses_in_selenium_args != null && message.hasOwnProperty("open_addresses_in_selenium_args")) {
                object.open_addresses_in_selenium_args = $root.object_msg.PlacesApiRequest.open_addresses_in_selenium.toObject(message.open_addresses_in_selenium_args, options);
                if (options.oneofs)
                    object.args = "open_addresses_in_selenium_args";
            }
            if (message.set_department_address_args != null && message.hasOwnProperty("set_department_address_args")) {
                object.set_department_address_args = $root.object_msg.PlacesApiRequest.set_department_address.toObject(message.set_department_address_args, options);
                if (options.oneofs)
                    object.args = "set_department_address_args";
            }
            return object;
        };

        PlacesApiRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlacesApiRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlacesApiRequest";
        };

        PlacesApiRequest.Method = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "unknownMethod"] = 0;
            values[valuesById[1] = "updatePlaceByManager"] = 1;
            values[valuesById[2] = "assignOrgToPlace"] = 2;
            values[valuesById[3] = "searchPlace"] = 3;
            values[valuesById[4] = "searchPlaceByAction"] = 4;
            values[valuesById[5] = "searchPlaceByActionAndLocation"] = 5;
            values[valuesById[6] = "selectPlace"] = 6;
            values[valuesById[7] = "getPlaceContactInfo"] = 7;
            values[valuesById[8] = "selectCityAction"] = 8;
            values[valuesById[9] = "getCityPlacesByAction"] = 9;
            values[valuesById[10] = "searchCustomPlaceActionTypes"] = 10;
            values[valuesById[11] = "splitPlaceActionTypes"] = 11;
            values[valuesById[12] = "deletePlaceActionTypes"] = 12;
            values[valuesById[13] = "addActionType"] = 13;
            values[valuesById[14] = "setActionMaterial"] = 14;
            values[valuesById[15] = "reloadActionMaterials"] = 15;
            values[valuesById[16] = "searchCustomPriceMaterialTypes"] = 16;
            values[valuesById[17] = "getCustomPriceMaterialUrls"] = 17;
            values[valuesById[18] = "getCustomActionUrls"] = 18;
            values[valuesById[19] = "searchLaserCuttingPriceThicknesses"] = 19;
            values[valuesById[20] = "getLaserCuttingPriceStatistics"] = 20;
            values[valuesById[21] = "getLocalLaserCuttingPriceStatistics"] = 21;
            values[valuesById[22] = "getLocalLaserCuttingPricesForMaterialByDepartment"] = 22;
            values[valuesById[23] = "deleteSystemLaserCuttingPrice"] = 23;
            values[valuesById[24] = "openWebsitesBySystemLaserCuttingPrice"] = 24;
            values[valuesById[25] = "openWebsitesByDepartmentLaserCuttingPrice"] = 25;
            values[valuesById[26] = "splitPriceMaterialTypes"] = 26;
            values[valuesById[27] = "deletePriceMaterialTypes"] = 27;
            values[valuesById[28] = "unifyPriceMaterialType"] = 28;
            values[valuesById[29] = "addMaterialType"] = 29;
            values[valuesById[30] = "searchCustomPlaceMachineTypes"] = 30;
            values[valuesById[31] = "unifyPlaceMachineType"] = 31;
            values[valuesById[32] = "addMachineType"] = 32;
            values[valuesById[33] = "searchCustomPlaceLabelTypes"] = 33;
            values[valuesById[34] = "unifyPlaceLabelType"] = 34;
            values[valuesById[35] = "addLabelType"] = 35;
            values[valuesById[36] = "addLabelToPlacesWithAction"] = 36;
            values[valuesById[37] = "stagePlaceInfo"] = 37;
            values[valuesById[38] = "getStagePlaceAddress"] = 38;
            values[valuesById[39] = "addLaserCuttingMaterialThickness"] = 39;
            values[valuesById[40] = "removeLaserCuttingMaterialThickness"] = 40;
            values[valuesById[41] = "reloadLaserCuttingMaterialThicknesses"] = 41;
            values[valuesById[42] = "createNewDepartments"] = 42;
            values[valuesById[43] = "addActionToDepartment"] = 43;
            values[valuesById[44] = "addActionToAllDepartments"] = 44;
            values[valuesById[45] = "setupLocalPricesForAction"] = 45;
            values[valuesById[46] = "openAddressesInSelenium"] = 46;
            values[valuesById[47] = "setDepartmentAddress"] = 47;
            return values;
        })();

        PlacesApiRequest.update_place_by_manager = (function() {

            function update_place_by_manager(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            update_place_by_manager.prototype.place = $util.newBuffer([]);
            update_place_by_manager.prototype.place_actions = $util.newBuffer([]);
            update_place_by_manager.prototype.laser_cutting_prices = $util.newBuffer([]);
            update_place_by_manager.prototype.laser_engraving_prices = $util.newBuffer([]);
            update_place_by_manager.prototype.place_phones = $util.newBuffer([]);
            update_place_by_manager.prototype.place_emails = $util.newBuffer([]);
            update_place_by_manager.prototype.place_machines = $util.newBuffer([]);
            update_place_by_manager.prototype.place_labels = $util.newBuffer([]);
            update_place_by_manager.prototype.plcace_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            update_place_by_manager.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.place != null && Object.hasOwnProperty.call(message, "place"))
                    writer.uint32(10).bytes(message.place);
                if (message.place_actions != null && Object.hasOwnProperty.call(message, "place_actions"))
                    writer.uint32(18).bytes(message.place_actions);
                if (message.laser_cutting_prices != null && Object.hasOwnProperty.call(message, "laser_cutting_prices"))
                    writer.uint32(26).bytes(message.laser_cutting_prices);
                if (message.laser_engraving_prices != null && Object.hasOwnProperty.call(message, "laser_engraving_prices"))
                    writer.uint32(34).bytes(message.laser_engraving_prices);
                if (message.place_phones != null && Object.hasOwnProperty.call(message, "place_phones"))
                    writer.uint32(42).bytes(message.place_phones);
                if (message.place_emails != null && Object.hasOwnProperty.call(message, "place_emails"))
                    writer.uint32(50).bytes(message.place_emails);
                if (message.place_machines != null && Object.hasOwnProperty.call(message, "place_machines"))
                    writer.uint32(58).bytes(message.place_machines);
                if (message.place_labels != null && Object.hasOwnProperty.call(message, "place_labels"))
                    writer.uint32(66).bytes(message.place_labels);
                if (message.plcace_id != null && Object.hasOwnProperty.call(message, "plcace_id"))
                    writer.uint32(72).uint64(message.plcace_id);
                return writer;
            };

            update_place_by_manager.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.update_place_by_manager();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.place = reader.bytes();
                            break;
                        }
                    case 2: {
                            message.place_actions = reader.bytes();
                            break;
                        }
                    case 3: {
                            message.laser_cutting_prices = reader.bytes();
                            break;
                        }
                    case 4: {
                            message.laser_engraving_prices = reader.bytes();
                            break;
                        }
                    case 5: {
                            message.place_phones = reader.bytes();
                            break;
                        }
                    case 6: {
                            message.place_emails = reader.bytes();
                            break;
                        }
                    case 7: {
                            message.place_machines = reader.bytes();
                            break;
                        }
                    case 8: {
                            message.place_labels = reader.bytes();
                            break;
                        }
                    case 9: {
                            message.plcace_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            update_place_by_manager.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.update_place_by_manager)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.update_place_by_manager();
                if (object.place != null)
                    if (typeof object.place === "string")
                        $util.base64.decode(object.place, message.place = $util.newBuffer($util.base64.length(object.place)), 0);
                    else if (object.place.length >= 0)
                        message.place = object.place;
                if (object.place_actions != null)
                    if (typeof object.place_actions === "string")
                        $util.base64.decode(object.place_actions, message.place_actions = $util.newBuffer($util.base64.length(object.place_actions)), 0);
                    else if (object.place_actions.length >= 0)
                        message.place_actions = object.place_actions;
                if (object.laser_cutting_prices != null)
                    if (typeof object.laser_cutting_prices === "string")
                        $util.base64.decode(object.laser_cutting_prices, message.laser_cutting_prices = $util.newBuffer($util.base64.length(object.laser_cutting_prices)), 0);
                    else if (object.laser_cutting_prices.length >= 0)
                        message.laser_cutting_prices = object.laser_cutting_prices;
                if (object.laser_engraving_prices != null)
                    if (typeof object.laser_engraving_prices === "string")
                        $util.base64.decode(object.laser_engraving_prices, message.laser_engraving_prices = $util.newBuffer($util.base64.length(object.laser_engraving_prices)), 0);
                    else if (object.laser_engraving_prices.length >= 0)
                        message.laser_engraving_prices = object.laser_engraving_prices;
                if (object.place_phones != null)
                    if (typeof object.place_phones === "string")
                        $util.base64.decode(object.place_phones, message.place_phones = $util.newBuffer($util.base64.length(object.place_phones)), 0);
                    else if (object.place_phones.length >= 0)
                        message.place_phones = object.place_phones;
                if (object.place_emails != null)
                    if (typeof object.place_emails === "string")
                        $util.base64.decode(object.place_emails, message.place_emails = $util.newBuffer($util.base64.length(object.place_emails)), 0);
                    else if (object.place_emails.length >= 0)
                        message.place_emails = object.place_emails;
                if (object.place_machines != null)
                    if (typeof object.place_machines === "string")
                        $util.base64.decode(object.place_machines, message.place_machines = $util.newBuffer($util.base64.length(object.place_machines)), 0);
                    else if (object.place_machines.length >= 0)
                        message.place_machines = object.place_machines;
                if (object.place_labels != null)
                    if (typeof object.place_labels === "string")
                        $util.base64.decode(object.place_labels, message.place_labels = $util.newBuffer($util.base64.length(object.place_labels)), 0);
                    else if (object.place_labels.length >= 0)
                        message.place_labels = object.place_labels;
                if (object.plcace_id != null)
                    if ($util.Long)
                        (message.plcace_id = $util.Long.fromValue(object.plcace_id)).unsigned = true;
                    else if (typeof object.plcace_id === "string")
                        message.plcace_id = parseInt(object.plcace_id, 10);
                    else if (typeof object.plcace_id === "number")
                        message.plcace_id = object.plcace_id;
                    else if (typeof object.plcace_id === "object")
                        message.plcace_id = new $util.LongBits(object.plcace_id.low >>> 0, object.plcace_id.high >>> 0).toNumber(true);
                return message;
            };

            update_place_by_manager.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.place = "";
                    else {
                        object.place = [];
                        if (options.bytes !== Array)
                            object.place = $util.newBuffer(object.place);
                    }
                    if (options.bytes === String)
                        object.place_actions = "";
                    else {
                        object.place_actions = [];
                        if (options.bytes !== Array)
                            object.place_actions = $util.newBuffer(object.place_actions);
                    }
                    if (options.bytes === String)
                        object.laser_cutting_prices = "";
                    else {
                        object.laser_cutting_prices = [];
                        if (options.bytes !== Array)
                            object.laser_cutting_prices = $util.newBuffer(object.laser_cutting_prices);
                    }
                    if (options.bytes === String)
                        object.laser_engraving_prices = "";
                    else {
                        object.laser_engraving_prices = [];
                        if (options.bytes !== Array)
                            object.laser_engraving_prices = $util.newBuffer(object.laser_engraving_prices);
                    }
                    if (options.bytes === String)
                        object.place_phones = "";
                    else {
                        object.place_phones = [];
                        if (options.bytes !== Array)
                            object.place_phones = $util.newBuffer(object.place_phones);
                    }
                    if (options.bytes === String)
                        object.place_emails = "";
                    else {
                        object.place_emails = [];
                        if (options.bytes !== Array)
                            object.place_emails = $util.newBuffer(object.place_emails);
                    }
                    if (options.bytes === String)
                        object.place_machines = "";
                    else {
                        object.place_machines = [];
                        if (options.bytes !== Array)
                            object.place_machines = $util.newBuffer(object.place_machines);
                    }
                    if (options.bytes === String)
                        object.place_labels = "";
                    else {
                        object.place_labels = [];
                        if (options.bytes !== Array)
                            object.place_labels = $util.newBuffer(object.place_labels);
                    }
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.plcace_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.plcace_id = options.longs === String ? "0" : 0;
                }
                if (message.place != null && message.hasOwnProperty("place"))
                    object.place = options.bytes === String ? $util.base64.encode(message.place, 0, message.place.length) : options.bytes === Array ? Array.prototype.slice.call(message.place) : message.place;
                if (message.place_actions != null && message.hasOwnProperty("place_actions"))
                    object.place_actions = options.bytes === String ? $util.base64.encode(message.place_actions, 0, message.place_actions.length) : options.bytes === Array ? Array.prototype.slice.call(message.place_actions) : message.place_actions;
                if (message.laser_cutting_prices != null && message.hasOwnProperty("laser_cutting_prices"))
                    object.laser_cutting_prices = options.bytes === String ? $util.base64.encode(message.laser_cutting_prices, 0, message.laser_cutting_prices.length) : options.bytes === Array ? Array.prototype.slice.call(message.laser_cutting_prices) : message.laser_cutting_prices;
                if (message.laser_engraving_prices != null && message.hasOwnProperty("laser_engraving_prices"))
                    object.laser_engraving_prices = options.bytes === String ? $util.base64.encode(message.laser_engraving_prices, 0, message.laser_engraving_prices.length) : options.bytes === Array ? Array.prototype.slice.call(message.laser_engraving_prices) : message.laser_engraving_prices;
                if (message.place_phones != null && message.hasOwnProperty("place_phones"))
                    object.place_phones = options.bytes === String ? $util.base64.encode(message.place_phones, 0, message.place_phones.length) : options.bytes === Array ? Array.prototype.slice.call(message.place_phones) : message.place_phones;
                if (message.place_emails != null && message.hasOwnProperty("place_emails"))
                    object.place_emails = options.bytes === String ? $util.base64.encode(message.place_emails, 0, message.place_emails.length) : options.bytes === Array ? Array.prototype.slice.call(message.place_emails) : message.place_emails;
                if (message.place_machines != null && message.hasOwnProperty("place_machines"))
                    object.place_machines = options.bytes === String ? $util.base64.encode(message.place_machines, 0, message.place_machines.length) : options.bytes === Array ? Array.prototype.slice.call(message.place_machines) : message.place_machines;
                if (message.place_labels != null && message.hasOwnProperty("place_labels"))
                    object.place_labels = options.bytes === String ? $util.base64.encode(message.place_labels, 0, message.place_labels.length) : options.bytes === Array ? Array.prototype.slice.call(message.place_labels) : message.place_labels;
                if (message.plcace_id != null && message.hasOwnProperty("plcace_id"))
                    if (typeof message.plcace_id === "number")
                        object.plcace_id = options.longs === String ? String(message.plcace_id) : message.plcace_id;
                    else
                        object.plcace_id = options.longs === String ? $util.Long.prototype.toString.call(message.plcace_id) : options.longs === Number ? new $util.LongBits(message.plcace_id.low >>> 0, message.plcace_id.high >>> 0).toNumber(true) : message.plcace_id;
                return object;
            };

            update_place_by_manager.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            update_place_by_manager.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.update_place_by_manager";
            };

            return update_place_by_manager;
        })();

        PlacesApiRequest.assign_org_to_place = (function() {

            function assign_org_to_place(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            assign_org_to_place.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            assign_org_to_place.prototype.org_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            assign_org_to_place.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                    writer.uint32(8).uint64(message.place_id);
                if (message.org_id != null && Object.hasOwnProperty.call(message, "org_id"))
                    writer.uint32(16).uint64(message.org_id);
                return writer;
            };

            assign_org_to_place.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.assign_org_to_place();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.place_id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.org_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            assign_org_to_place.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.assign_org_to_place)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.assign_org_to_place();
                if (object.place_id != null)
                    if ($util.Long)
                        (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                    else if (typeof object.place_id === "string")
                        message.place_id = parseInt(object.place_id, 10);
                    else if (typeof object.place_id === "number")
                        message.place_id = object.place_id;
                    else if (typeof object.place_id === "object")
                        message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
                if (object.org_id != null)
                    if ($util.Long)
                        (message.org_id = $util.Long.fromValue(object.org_id)).unsigned = true;
                    else if (typeof object.org_id === "string")
                        message.org_id = parseInt(object.org_id, 10);
                    else if (typeof object.org_id === "number")
                        message.org_id = object.org_id;
                    else if (typeof object.org_id === "object")
                        message.org_id = new $util.LongBits(object.org_id.low >>> 0, object.org_id.high >>> 0).toNumber(true);
                return message;
            };

            assign_org_to_place.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.place_id = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.org_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.org_id = options.longs === String ? "0" : 0;
                }
                if (message.place_id != null && message.hasOwnProperty("place_id"))
                    if (typeof message.place_id === "number")
                        object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                    else
                        object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
                if (message.org_id != null && message.hasOwnProperty("org_id"))
                    if (typeof message.org_id === "number")
                        object.org_id = options.longs === String ? String(message.org_id) : message.org_id;
                    else
                        object.org_id = options.longs === String ? $util.Long.prototype.toString.call(message.org_id) : options.longs === Number ? new $util.LongBits(message.org_id.low >>> 0, message.org_id.high >>> 0).toNumber(true) : message.org_id;
                return object;
            };

            assign_org_to_place.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            assign_org_to_place.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.assign_org_to_place";
            };

            return assign_org_to_place;
        })();

        PlacesApiRequest.search_place = (function() {

            function search_place(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            search_place.prototype.place_name = "";

            search_place.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.place_name != null && Object.hasOwnProperty.call(message, "place_name"))
                    writer.uint32(10).string(message.place_name);
                return writer;
            };

            search_place.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.search_place();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.place_name = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            search_place.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.search_place)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.search_place();
                if (object.place_name != null)
                    message.place_name = String(object.place_name);
                return message;
            };

            search_place.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.place_name = "";
                if (message.place_name != null && message.hasOwnProperty("place_name"))
                    object.place_name = message.place_name;
                return object;
            };

            search_place.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            search_place.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.search_place";
            };

            return search_place;
        })();

        PlacesApiRequest.search_place_by_action = (function() {

            function search_place_by_action(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            search_place_by_action.prototype.action = "";

            search_place_by_action.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                    writer.uint32(10).string(message.action);
                return writer;
            };

            search_place_by_action.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.search_place_by_action();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.action = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            search_place_by_action.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.search_place_by_action)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.search_place_by_action();
                if (object.action != null)
                    message.action = String(object.action);
                return message;
            };

            search_place_by_action.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.action = "";
                if (message.action != null && message.hasOwnProperty("action"))
                    object.action = message.action;
                return object;
            };

            search_place_by_action.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            search_place_by_action.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.search_place_by_action";
            };

            return search_place_by_action;
        })();

        PlacesApiRequest.search_place_by_action_and_location = (function() {

            function search_place_by_action_and_location(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            search_place_by_action_and_location.prototype.action = "";
            search_place_by_action_and_location.prototype.delivery_profile_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            search_place_by_action_and_location.prototype.radius_m = 0;
            search_place_by_action_and_location.prototype.fuzzy_search = false;

            search_place_by_action_and_location.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                    writer.uint32(10).string(message.action);
                if (message.delivery_profile_id != null && Object.hasOwnProperty.call(message, "delivery_profile_id"))
                    writer.uint32(16).uint64(message.delivery_profile_id);
                if (message.radius_m != null && Object.hasOwnProperty.call(message, "radius_m"))
                    writer.uint32(24).int32(message.radius_m);
                if (message.fuzzy_search != null && Object.hasOwnProperty.call(message, "fuzzy_search"))
                    writer.uint32(32).bool(message.fuzzy_search);
                return writer;
            };

            search_place_by_action_and_location.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.search_place_by_action_and_location();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.action = reader.string();
                            break;
                        }
                    case 2: {
                            message.delivery_profile_id = reader.uint64();
                            break;
                        }
                    case 3: {
                            message.radius_m = reader.int32();
                            break;
                        }
                    case 4: {
                            message.fuzzy_search = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            search_place_by_action_and_location.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.search_place_by_action_and_location)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.search_place_by_action_and_location();
                if (object.action != null)
                    message.action = String(object.action);
                if (object.delivery_profile_id != null)
                    if ($util.Long)
                        (message.delivery_profile_id = $util.Long.fromValue(object.delivery_profile_id)).unsigned = true;
                    else if (typeof object.delivery_profile_id === "string")
                        message.delivery_profile_id = parseInt(object.delivery_profile_id, 10);
                    else if (typeof object.delivery_profile_id === "number")
                        message.delivery_profile_id = object.delivery_profile_id;
                    else if (typeof object.delivery_profile_id === "object")
                        message.delivery_profile_id = new $util.LongBits(object.delivery_profile_id.low >>> 0, object.delivery_profile_id.high >>> 0).toNumber(true);
                if (object.radius_m != null)
                    message.radius_m = object.radius_m | 0;
                if (object.fuzzy_search != null)
                    message.fuzzy_search = Boolean(object.fuzzy_search);
                return message;
            };

            search_place_by_action_and_location.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.action = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.delivery_profile_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.delivery_profile_id = options.longs === String ? "0" : 0;
                    object.radius_m = 0;
                    object.fuzzy_search = false;
                }
                if (message.action != null && message.hasOwnProperty("action"))
                    object.action = message.action;
                if (message.delivery_profile_id != null && message.hasOwnProperty("delivery_profile_id"))
                    if (typeof message.delivery_profile_id === "number")
                        object.delivery_profile_id = options.longs === String ? String(message.delivery_profile_id) : message.delivery_profile_id;
                    else
                        object.delivery_profile_id = options.longs === String ? $util.Long.prototype.toString.call(message.delivery_profile_id) : options.longs === Number ? new $util.LongBits(message.delivery_profile_id.low >>> 0, message.delivery_profile_id.high >>> 0).toNumber(true) : message.delivery_profile_id;
                if (message.radius_m != null && message.hasOwnProperty("radius_m"))
                    object.radius_m = message.radius_m;
                if (message.fuzzy_search != null && message.hasOwnProperty("fuzzy_search"))
                    object.fuzzy_search = message.fuzzy_search;
                return object;
            };

            search_place_by_action_and_location.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            search_place_by_action_and_location.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.search_place_by_action_and_location";
            };

            return search_place_by_action_and_location;
        })();

        PlacesApiRequest.select_place = (function() {

            function select_place(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            select_place.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            select_place.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                    writer.uint32(8).uint64(message.place_id);
                return writer;
            };

            select_place.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.select_place();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.place_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            select_place.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.select_place)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.select_place();
                if (object.place_id != null)
                    if ($util.Long)
                        (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                    else if (typeof object.place_id === "string")
                        message.place_id = parseInt(object.place_id, 10);
                    else if (typeof object.place_id === "number")
                        message.place_id = object.place_id;
                    else if (typeof object.place_id === "object")
                        message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
                return message;
            };

            select_place.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.place_id = options.longs === String ? "0" : 0;
                if (message.place_id != null && message.hasOwnProperty("place_id"))
                    if (typeof message.place_id === "number")
                        object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                    else
                        object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
                return object;
            };

            select_place.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            select_place.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.select_place";
            };

            return select_place;
        })();

        PlacesApiRequest.get_place_contact_info = (function() {

            function get_place_contact_info(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_place_contact_info.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            get_place_contact_info.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                    writer.uint32(8).uint64(message.place_id);
                return writer;
            };

            get_place_contact_info.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.get_place_contact_info();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.place_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_place_contact_info.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.get_place_contact_info)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.get_place_contact_info();
                if (object.place_id != null)
                    if ($util.Long)
                        (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                    else if (typeof object.place_id === "string")
                        message.place_id = parseInt(object.place_id, 10);
                    else if (typeof object.place_id === "number")
                        message.place_id = object.place_id;
                    else if (typeof object.place_id === "object")
                        message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
                return message;
            };

            get_place_contact_info.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.place_id = options.longs === String ? "0" : 0;
                if (message.place_id != null && message.hasOwnProperty("place_id"))
                    if (typeof message.place_id === "number")
                        object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                    else
                        object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
                return object;
            };

            get_place_contact_info.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_place_contact_info.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.get_place_contact_info";
            };

            return get_place_contact_info;
        })();

        PlacesApiRequest.select_city_action = (function() {

            function select_city_action(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            select_city_action.prototype.action = "";

            select_city_action.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                    writer.uint32(10).string(message.action);
                return writer;
            };

            select_city_action.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.select_city_action();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.action = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            select_city_action.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.select_city_action)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.select_city_action();
                if (object.action != null)
                    message.action = String(object.action);
                return message;
            };

            select_city_action.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.action = "";
                if (message.action != null && message.hasOwnProperty("action"))
                    object.action = message.action;
                return object;
            };

            select_city_action.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            select_city_action.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.select_city_action";
            };

            return select_city_action;
        })();

        PlacesApiRequest.get_city_places_by_action = (function() {

            function get_city_places_by_action(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_city_places_by_action.prototype.action = "";
            get_city_places_by_action.prototype.city_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            get_city_places_by_action.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                    writer.uint32(10).string(message.action);
                if (message.city_id != null && Object.hasOwnProperty.call(message, "city_id"))
                    writer.uint32(16).uint64(message.city_id);
                return writer;
            };

            get_city_places_by_action.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.get_city_places_by_action();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.action = reader.string();
                            break;
                        }
                    case 2: {
                            message.city_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_city_places_by_action.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.get_city_places_by_action)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.get_city_places_by_action();
                if (object.action != null)
                    message.action = String(object.action);
                if (object.city_id != null)
                    if ($util.Long)
                        (message.city_id = $util.Long.fromValue(object.city_id)).unsigned = true;
                    else if (typeof object.city_id === "string")
                        message.city_id = parseInt(object.city_id, 10);
                    else if (typeof object.city_id === "number")
                        message.city_id = object.city_id;
                    else if (typeof object.city_id === "object")
                        message.city_id = new $util.LongBits(object.city_id.low >>> 0, object.city_id.high >>> 0).toNumber(true);
                return message;
            };

            get_city_places_by_action.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.action = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.city_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.city_id = options.longs === String ? "0" : 0;
                }
                if (message.action != null && message.hasOwnProperty("action"))
                    object.action = message.action;
                if (message.city_id != null && message.hasOwnProperty("city_id"))
                    if (typeof message.city_id === "number")
                        object.city_id = options.longs === String ? String(message.city_id) : message.city_id;
                    else
                        object.city_id = options.longs === String ? $util.Long.prototype.toString.call(message.city_id) : options.longs === Number ? new $util.LongBits(message.city_id.low >>> 0, message.city_id.high >>> 0).toNumber(true) : message.city_id;
                return object;
            };

            get_city_places_by_action.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_city_places_by_action.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.get_city_places_by_action";
            };

            return get_city_places_by_action;
        })();

        PlacesApiRequest.search_custom_place_action_types = (function() {

            function search_custom_place_action_types(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            search_custom_place_action_types.prototype.action = "";

            search_custom_place_action_types.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                    writer.uint32(10).string(message.action);
                return writer;
            };

            search_custom_place_action_types.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.search_custom_place_action_types();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.action = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            search_custom_place_action_types.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.search_custom_place_action_types)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.search_custom_place_action_types();
                if (object.action != null)
                    message.action = String(object.action);
                return message;
            };

            search_custom_place_action_types.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.action = "";
                if (message.action != null && message.hasOwnProperty("action"))
                    object.action = message.action;
                return object;
            };

            search_custom_place_action_types.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            search_custom_place_action_types.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.search_custom_place_action_types";
            };

            return search_custom_place_action_types;
        })();

        PlacesApiRequest.split_place_action_types = (function() {

            function split_place_action_types(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            split_place_action_types.prototype.old_value = "";
            split_place_action_types.prototype.new_values = $util.newBuffer([]);

            split_place_action_types.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.old_value != null && Object.hasOwnProperty.call(message, "old_value"))
                    writer.uint32(10).string(message.old_value);
                if (message.new_values != null && Object.hasOwnProperty.call(message, "new_values"))
                    writer.uint32(18).bytes(message.new_values);
                return writer;
            };

            split_place_action_types.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.split_place_action_types();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.old_value = reader.string();
                            break;
                        }
                    case 2: {
                            message.new_values = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            split_place_action_types.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.split_place_action_types)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.split_place_action_types();
                if (object.old_value != null)
                    message.old_value = String(object.old_value);
                if (object.new_values != null)
                    if (typeof object.new_values === "string")
                        $util.base64.decode(object.new_values, message.new_values = $util.newBuffer($util.base64.length(object.new_values)), 0);
                    else if (object.new_values.length >= 0)
                        message.new_values = object.new_values;
                return message;
            };

            split_place_action_types.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.old_value = "";
                    if (options.bytes === String)
                        object.new_values = "";
                    else {
                        object.new_values = [];
                        if (options.bytes !== Array)
                            object.new_values = $util.newBuffer(object.new_values);
                    }
                }
                if (message.old_value != null && message.hasOwnProperty("old_value"))
                    object.old_value = message.old_value;
                if (message.new_values != null && message.hasOwnProperty("new_values"))
                    object.new_values = options.bytes === String ? $util.base64.encode(message.new_values, 0, message.new_values.length) : options.bytes === Array ? Array.prototype.slice.call(message.new_values) : message.new_values;
                return object;
            };

            split_place_action_types.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            split_place_action_types.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.split_place_action_types";
            };

            return split_place_action_types;
        })();

        PlacesApiRequest.delete_place_action_types = (function() {

            function delete_place_action_types(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            delete_place_action_types.prototype.old_value = "";

            delete_place_action_types.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.old_value != null && Object.hasOwnProperty.call(message, "old_value"))
                    writer.uint32(10).string(message.old_value);
                return writer;
            };

            delete_place_action_types.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.delete_place_action_types();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.old_value = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            delete_place_action_types.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.delete_place_action_types)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.delete_place_action_types();
                if (object.old_value != null)
                    message.old_value = String(object.old_value);
                return message;
            };

            delete_place_action_types.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.old_value = "";
                if (message.old_value != null && message.hasOwnProperty("old_value"))
                    object.old_value = message.old_value;
                return object;
            };

            delete_place_action_types.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            delete_place_action_types.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.delete_place_action_types";
            };

            return delete_place_action_types;
        })();

        PlacesApiRequest.add_action_type = (function() {

            function add_action_type(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            add_action_type.prototype.action = "";
            add_action_type.prototype.url_name = "";

            add_action_type.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                    writer.uint32(10).string(message.action);
                if (message.url_name != null && Object.hasOwnProperty.call(message, "url_name"))
                    writer.uint32(18).string(message.url_name);
                return writer;
            };

            add_action_type.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.add_action_type();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.action = reader.string();
                            break;
                        }
                    case 2: {
                            message.url_name = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            add_action_type.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.add_action_type)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.add_action_type();
                if (object.action != null)
                    message.action = String(object.action);
                if (object.url_name != null)
                    message.url_name = String(object.url_name);
                return message;
            };

            add_action_type.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.action = "";
                    object.url_name = "";
                }
                if (message.action != null && message.hasOwnProperty("action"))
                    object.action = message.action;
                if (message.url_name != null && message.hasOwnProperty("url_name"))
                    object.url_name = message.url_name;
                return object;
            };

            add_action_type.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            add_action_type.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.add_action_type";
            };

            return add_action_type;
        })();

        PlacesApiRequest.set_action_material = (function() {

            function set_action_material(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            set_action_material.prototype.action = "";
            set_action_material.prototype.material = "";
            set_action_material.prototype.enabled = false;

            set_action_material.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                    writer.uint32(10).string(message.action);
                if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                    writer.uint32(18).string(message.material);
                if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                    writer.uint32(24).bool(message.enabled);
                return writer;
            };

            set_action_material.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.set_action_material();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.action = reader.string();
                            break;
                        }
                    case 2: {
                            message.material = reader.string();
                            break;
                        }
                    case 3: {
                            message.enabled = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            set_action_material.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.set_action_material)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.set_action_material();
                if (object.action != null)
                    message.action = String(object.action);
                if (object.material != null)
                    message.material = String(object.material);
                if (object.enabled != null)
                    message.enabled = Boolean(object.enabled);
                return message;
            };

            set_action_material.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.action = "";
                    object.material = "";
                    object.enabled = false;
                }
                if (message.action != null && message.hasOwnProperty("action"))
                    object.action = message.action;
                if (message.material != null && message.hasOwnProperty("material"))
                    object.material = message.material;
                if (message.enabled != null && message.hasOwnProperty("enabled"))
                    object.enabled = message.enabled;
                return object;
            };

            set_action_material.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            set_action_material.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.set_action_material";
            };

            return set_action_material;
        })();

        PlacesApiRequest.reload_action_materials = (function() {

            function reload_action_materials(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            reload_action_materials.prototype.action = "";
            reload_action_materials.prototype.auto_update = false;
            reload_action_materials.prototype.unload_model = false;

            reload_action_materials.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                    writer.uint32(10).string(message.action);
                if (message.auto_update != null && Object.hasOwnProperty.call(message, "auto_update"))
                    writer.uint32(16).bool(message.auto_update);
                if (message.unload_model != null && Object.hasOwnProperty.call(message, "unload_model"))
                    writer.uint32(24).bool(message.unload_model);
                return writer;
            };

            reload_action_materials.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.reload_action_materials();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.action = reader.string();
                            break;
                        }
                    case 2: {
                            message.auto_update = reader.bool();
                            break;
                        }
                    case 3: {
                            message.unload_model = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            reload_action_materials.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.reload_action_materials)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.reload_action_materials();
                if (object.action != null)
                    message.action = String(object.action);
                if (object.auto_update != null)
                    message.auto_update = Boolean(object.auto_update);
                if (object.unload_model != null)
                    message.unload_model = Boolean(object.unload_model);
                return message;
            };

            reload_action_materials.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.action = "";
                    object.auto_update = false;
                    object.unload_model = false;
                }
                if (message.action != null && message.hasOwnProperty("action"))
                    object.action = message.action;
                if (message.auto_update != null && message.hasOwnProperty("auto_update"))
                    object.auto_update = message.auto_update;
                if (message.unload_model != null && message.hasOwnProperty("unload_model"))
                    object.unload_model = message.unload_model;
                return object;
            };

            reload_action_materials.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            reload_action_materials.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.reload_action_materials";
            };

            return reload_action_materials;
        })();

        PlacesApiRequest.get_custom_price_material_urls = (function() {

            function get_custom_price_material_urls(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_custom_price_material_urls.prototype.material = "";

            get_custom_price_material_urls.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                    writer.uint32(10).string(message.material);
                return writer;
            };

            get_custom_price_material_urls.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.get_custom_price_material_urls();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.material = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_custom_price_material_urls.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.get_custom_price_material_urls)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.get_custom_price_material_urls();
                if (object.material != null)
                    message.material = String(object.material);
                return message;
            };

            get_custom_price_material_urls.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.material = "";
                if (message.material != null && message.hasOwnProperty("material"))
                    object.material = message.material;
                return object;
            };

            get_custom_price_material_urls.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_custom_price_material_urls.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.get_custom_price_material_urls";
            };

            return get_custom_price_material_urls;
        })();

        PlacesApiRequest.get_custom_action_urls = (function() {

            function get_custom_action_urls(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_custom_action_urls.prototype.action = "";

            get_custom_action_urls.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                    writer.uint32(10).string(message.action);
                return writer;
            };

            get_custom_action_urls.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.get_custom_action_urls();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.action = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_custom_action_urls.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.get_custom_action_urls)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.get_custom_action_urls();
                if (object.action != null)
                    message.action = String(object.action);
                return message;
            };

            get_custom_action_urls.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.action = "";
                if (message.action != null && message.hasOwnProperty("action"))
                    object.action = message.action;
                return object;
            };

            get_custom_action_urls.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_custom_action_urls.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.get_custom_action_urls";
            };

            return get_custom_action_urls;
        })();

        PlacesApiRequest.search_laser_cutting_price_thicknesses = (function() {

            function search_laser_cutting_price_thicknesses(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            search_laser_cutting_price_thicknesses.prototype.material = "";

            search_laser_cutting_price_thicknesses.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                    writer.uint32(10).string(message.material);
                return writer;
            };

            search_laser_cutting_price_thicknesses.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.search_laser_cutting_price_thicknesses();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.material = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            search_laser_cutting_price_thicknesses.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.search_laser_cutting_price_thicknesses)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.search_laser_cutting_price_thicknesses();
                if (object.material != null)
                    message.material = String(object.material);
                return message;
            };

            search_laser_cutting_price_thicknesses.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.material = "";
                if (message.material != null && message.hasOwnProperty("material"))
                    object.material = message.material;
                return object;
            };

            search_laser_cutting_price_thicknesses.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            search_laser_cutting_price_thicknesses.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.search_laser_cutting_price_thicknesses";
            };

            return search_laser_cutting_price_thicknesses;
        })();

        PlacesApiRequest.get_laser_cutting_price_statistics = (function() {

            function get_laser_cutting_price_statistics(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_laser_cutting_price_statistics.prototype.material = "";
            get_laser_cutting_price_statistics.prototype.thickness = 0;
            get_laser_cutting_price_statistics.prototype.unload_model = false;

            get_laser_cutting_price_statistics.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                    writer.uint32(10).string(message.material);
                if (message.thickness != null && Object.hasOwnProperty.call(message, "thickness"))
                    writer.uint32(16).int32(message.thickness);
                if (message.unload_model != null && Object.hasOwnProperty.call(message, "unload_model"))
                    writer.uint32(24).bool(message.unload_model);
                return writer;
            };

            get_laser_cutting_price_statistics.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.get_laser_cutting_price_statistics();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.material = reader.string();
                            break;
                        }
                    case 2: {
                            message.thickness = reader.int32();
                            break;
                        }
                    case 3: {
                            message.unload_model = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_laser_cutting_price_statistics.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.get_laser_cutting_price_statistics)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.get_laser_cutting_price_statistics();
                if (object.material != null)
                    message.material = String(object.material);
                if (object.thickness != null)
                    message.thickness = object.thickness | 0;
                if (object.unload_model != null)
                    message.unload_model = Boolean(object.unload_model);
                return message;
            };

            get_laser_cutting_price_statistics.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.material = "";
                    object.thickness = 0;
                    object.unload_model = false;
                }
                if (message.material != null && message.hasOwnProperty("material"))
                    object.material = message.material;
                if (message.thickness != null && message.hasOwnProperty("thickness"))
                    object.thickness = message.thickness;
                if (message.unload_model != null && message.hasOwnProperty("unload_model"))
                    object.unload_model = message.unload_model;
                return object;
            };

            get_laser_cutting_price_statistics.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_laser_cutting_price_statistics.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.get_laser_cutting_price_statistics";
            };

            return get_laser_cutting_price_statistics;
        })();

        PlacesApiRequest.get_local_laser_cutting_price_statistics = (function() {

            function get_local_laser_cutting_price_statistics(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_local_laser_cutting_price_statistics.prototype.material = "";
            get_local_laser_cutting_price_statistics.prototype.thickness = 0;
            get_local_laser_cutting_price_statistics.prototype.unload_model = false;

            get_local_laser_cutting_price_statistics.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                    writer.uint32(10).string(message.material);
                if (message.thickness != null && Object.hasOwnProperty.call(message, "thickness"))
                    writer.uint32(16).int32(message.thickness);
                if (message.unload_model != null && Object.hasOwnProperty.call(message, "unload_model"))
                    writer.uint32(24).bool(message.unload_model);
                return writer;
            };

            get_local_laser_cutting_price_statistics.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.get_local_laser_cutting_price_statistics();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.material = reader.string();
                            break;
                        }
                    case 2: {
                            message.thickness = reader.int32();
                            break;
                        }
                    case 3: {
                            message.unload_model = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_local_laser_cutting_price_statistics.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.get_local_laser_cutting_price_statistics)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.get_local_laser_cutting_price_statistics();
                if (object.material != null)
                    message.material = String(object.material);
                if (object.thickness != null)
                    message.thickness = object.thickness | 0;
                if (object.unload_model != null)
                    message.unload_model = Boolean(object.unload_model);
                return message;
            };

            get_local_laser_cutting_price_statistics.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.material = "";
                    object.thickness = 0;
                    object.unload_model = false;
                }
                if (message.material != null && message.hasOwnProperty("material"))
                    object.material = message.material;
                if (message.thickness != null && message.hasOwnProperty("thickness"))
                    object.thickness = message.thickness;
                if (message.unload_model != null && message.hasOwnProperty("unload_model"))
                    object.unload_model = message.unload_model;
                return object;
            };

            get_local_laser_cutting_price_statistics.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_local_laser_cutting_price_statistics.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.get_local_laser_cutting_price_statistics";
            };

            return get_local_laser_cutting_price_statistics;
        })();

        PlacesApiRequest.get_local_laser_cutting_prices_for_material_by_department = (function() {

            function get_local_laser_cutting_prices_for_material_by_department(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_local_laser_cutting_prices_for_material_by_department.prototype.material = "";
            get_local_laser_cutting_prices_for_material_by_department.prototype.department_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            get_local_laser_cutting_prices_for_material_by_department.prototype.unload_model = false;

            get_local_laser_cutting_prices_for_material_by_department.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                    writer.uint32(10).string(message.material);
                if (message.department_id != null && Object.hasOwnProperty.call(message, "department_id"))
                    writer.uint32(16).uint64(message.department_id);
                if (message.unload_model != null && Object.hasOwnProperty.call(message, "unload_model"))
                    writer.uint32(24).bool(message.unload_model);
                return writer;
            };

            get_local_laser_cutting_prices_for_material_by_department.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.get_local_laser_cutting_prices_for_material_by_department();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.material = reader.string();
                            break;
                        }
                    case 2: {
                            message.department_id = reader.uint64();
                            break;
                        }
                    case 3: {
                            message.unload_model = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_local_laser_cutting_prices_for_material_by_department.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.get_local_laser_cutting_prices_for_material_by_department)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.get_local_laser_cutting_prices_for_material_by_department();
                if (object.material != null)
                    message.material = String(object.material);
                if (object.department_id != null)
                    if ($util.Long)
                        (message.department_id = $util.Long.fromValue(object.department_id)).unsigned = true;
                    else if (typeof object.department_id === "string")
                        message.department_id = parseInt(object.department_id, 10);
                    else if (typeof object.department_id === "number")
                        message.department_id = object.department_id;
                    else if (typeof object.department_id === "object")
                        message.department_id = new $util.LongBits(object.department_id.low >>> 0, object.department_id.high >>> 0).toNumber(true);
                if (object.unload_model != null)
                    message.unload_model = Boolean(object.unload_model);
                return message;
            };

            get_local_laser_cutting_prices_for_material_by_department.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.material = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.department_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.department_id = options.longs === String ? "0" : 0;
                    object.unload_model = false;
                }
                if (message.material != null && message.hasOwnProperty("material"))
                    object.material = message.material;
                if (message.department_id != null && message.hasOwnProperty("department_id"))
                    if (typeof message.department_id === "number")
                        object.department_id = options.longs === String ? String(message.department_id) : message.department_id;
                    else
                        object.department_id = options.longs === String ? $util.Long.prototype.toString.call(message.department_id) : options.longs === Number ? new $util.LongBits(message.department_id.low >>> 0, message.department_id.high >>> 0).toNumber(true) : message.department_id;
                if (message.unload_model != null && message.hasOwnProperty("unload_model"))
                    object.unload_model = message.unload_model;
                return object;
            };

            get_local_laser_cutting_prices_for_material_by_department.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_local_laser_cutting_prices_for_material_by_department.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.get_local_laser_cutting_prices_for_material_by_department";
            };

            return get_local_laser_cutting_prices_for_material_by_department;
        })();

        PlacesApiRequest.delete_system_laser_cutting_price = (function() {

            function delete_system_laser_cutting_price(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            delete_system_laser_cutting_price.prototype.material = "";
            delete_system_laser_cutting_price.prototype.thickness01mm = 0;
            delete_system_laser_cutting_price.prototype.price = 0;

            delete_system_laser_cutting_price.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                    writer.uint32(10).string(message.material);
                if (message.thickness01mm != null && Object.hasOwnProperty.call(message, "thickness01mm"))
                    writer.uint32(16).int32(message.thickness01mm);
                if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                    writer.uint32(29).float(message.price);
                return writer;
            };

            delete_system_laser_cutting_price.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.delete_system_laser_cutting_price();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.material = reader.string();
                            break;
                        }
                    case 2: {
                            message.thickness01mm = reader.int32();
                            break;
                        }
                    case 3: {
                            message.price = reader.float();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            delete_system_laser_cutting_price.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.delete_system_laser_cutting_price)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.delete_system_laser_cutting_price();
                if (object.material != null)
                    message.material = String(object.material);
                if (object.thickness01mm != null)
                    message.thickness01mm = object.thickness01mm | 0;
                if (object.price != null)
                    message.price = Number(object.price);
                return message;
            };

            delete_system_laser_cutting_price.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.material = "";
                    object.thickness01mm = 0;
                    object.price = 0;
                }
                if (message.material != null && message.hasOwnProperty("material"))
                    object.material = message.material;
                if (message.thickness01mm != null && message.hasOwnProperty("thickness01mm"))
                    object.thickness01mm = message.thickness01mm;
                if (message.price != null && message.hasOwnProperty("price"))
                    object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
                return object;
            };

            delete_system_laser_cutting_price.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            delete_system_laser_cutting_price.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.delete_system_laser_cutting_price";
            };

            return delete_system_laser_cutting_price;
        })();

        PlacesApiRequest.open_websites_by_system_laser_cutting_price = (function() {

            function open_websites_by_system_laser_cutting_price(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            open_websites_by_system_laser_cutting_price.prototype.material = "";
            open_websites_by_system_laser_cutting_price.prototype.thickness01mm = 0;
            open_websites_by_system_laser_cutting_price.prototype.price = 0;

            open_websites_by_system_laser_cutting_price.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                    writer.uint32(10).string(message.material);
                if (message.thickness01mm != null && Object.hasOwnProperty.call(message, "thickness01mm"))
                    writer.uint32(16).int32(message.thickness01mm);
                if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                    writer.uint32(29).float(message.price);
                return writer;
            };

            open_websites_by_system_laser_cutting_price.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.open_websites_by_system_laser_cutting_price();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.material = reader.string();
                            break;
                        }
                    case 2: {
                            message.thickness01mm = reader.int32();
                            break;
                        }
                    case 3: {
                            message.price = reader.float();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            open_websites_by_system_laser_cutting_price.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.open_websites_by_system_laser_cutting_price)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.open_websites_by_system_laser_cutting_price();
                if (object.material != null)
                    message.material = String(object.material);
                if (object.thickness01mm != null)
                    message.thickness01mm = object.thickness01mm | 0;
                if (object.price != null)
                    message.price = Number(object.price);
                return message;
            };

            open_websites_by_system_laser_cutting_price.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.material = "";
                    object.thickness01mm = 0;
                    object.price = 0;
                }
                if (message.material != null && message.hasOwnProperty("material"))
                    object.material = message.material;
                if (message.thickness01mm != null && message.hasOwnProperty("thickness01mm"))
                    object.thickness01mm = message.thickness01mm;
                if (message.price != null && message.hasOwnProperty("price"))
                    object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
                return object;
            };

            open_websites_by_system_laser_cutting_price.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            open_websites_by_system_laser_cutting_price.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.open_websites_by_system_laser_cutting_price";
            };

            return open_websites_by_system_laser_cutting_price;
        })();

        PlacesApiRequest.open_websites_by_department_laser_cutting_price = (function() {

            function open_websites_by_department_laser_cutting_price(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            open_websites_by_department_laser_cutting_price.prototype.material = "";
            open_websites_by_department_laser_cutting_price.prototype.thickness01mm = 0;
            open_websites_by_department_laser_cutting_price.prototype.price = 0;

            open_websites_by_department_laser_cutting_price.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                    writer.uint32(10).string(message.material);
                if (message.thickness01mm != null && Object.hasOwnProperty.call(message, "thickness01mm"))
                    writer.uint32(16).int32(message.thickness01mm);
                if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                    writer.uint32(29).float(message.price);
                return writer;
            };

            open_websites_by_department_laser_cutting_price.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.open_websites_by_department_laser_cutting_price();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.material = reader.string();
                            break;
                        }
                    case 2: {
                            message.thickness01mm = reader.int32();
                            break;
                        }
                    case 3: {
                            message.price = reader.float();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            open_websites_by_department_laser_cutting_price.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.open_websites_by_department_laser_cutting_price)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.open_websites_by_department_laser_cutting_price();
                if (object.material != null)
                    message.material = String(object.material);
                if (object.thickness01mm != null)
                    message.thickness01mm = object.thickness01mm | 0;
                if (object.price != null)
                    message.price = Number(object.price);
                return message;
            };

            open_websites_by_department_laser_cutting_price.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.material = "";
                    object.thickness01mm = 0;
                    object.price = 0;
                }
                if (message.material != null && message.hasOwnProperty("material"))
                    object.material = message.material;
                if (message.thickness01mm != null && message.hasOwnProperty("thickness01mm"))
                    object.thickness01mm = message.thickness01mm;
                if (message.price != null && message.hasOwnProperty("price"))
                    object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
                return object;
            };

            open_websites_by_department_laser_cutting_price.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            open_websites_by_department_laser_cutting_price.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.open_websites_by_department_laser_cutting_price";
            };

            return open_websites_by_department_laser_cutting_price;
        })();

        PlacesApiRequest.split_price_material_types = (function() {

            function split_price_material_types(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            split_price_material_types.prototype.old_value = "";
            split_price_material_types.prototype.new_values = $util.newBuffer([]);

            split_price_material_types.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.old_value != null && Object.hasOwnProperty.call(message, "old_value"))
                    writer.uint32(10).string(message.old_value);
                if (message.new_values != null && Object.hasOwnProperty.call(message, "new_values"))
                    writer.uint32(18).bytes(message.new_values);
                return writer;
            };

            split_price_material_types.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.split_price_material_types();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.old_value = reader.string();
                            break;
                        }
                    case 2: {
                            message.new_values = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            split_price_material_types.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.split_price_material_types)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.split_price_material_types();
                if (object.old_value != null)
                    message.old_value = String(object.old_value);
                if (object.new_values != null)
                    if (typeof object.new_values === "string")
                        $util.base64.decode(object.new_values, message.new_values = $util.newBuffer($util.base64.length(object.new_values)), 0);
                    else if (object.new_values.length >= 0)
                        message.new_values = object.new_values;
                return message;
            };

            split_price_material_types.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.old_value = "";
                    if (options.bytes === String)
                        object.new_values = "";
                    else {
                        object.new_values = [];
                        if (options.bytes !== Array)
                            object.new_values = $util.newBuffer(object.new_values);
                    }
                }
                if (message.old_value != null && message.hasOwnProperty("old_value"))
                    object.old_value = message.old_value;
                if (message.new_values != null && message.hasOwnProperty("new_values"))
                    object.new_values = options.bytes === String ? $util.base64.encode(message.new_values, 0, message.new_values.length) : options.bytes === Array ? Array.prototype.slice.call(message.new_values) : message.new_values;
                return object;
            };

            split_price_material_types.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            split_price_material_types.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.split_price_material_types";
            };

            return split_price_material_types;
        })();

        PlacesApiRequest.delete_price_material_types = (function() {

            function delete_price_material_types(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            delete_price_material_types.prototype.old_value = "";

            delete_price_material_types.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.old_value != null && Object.hasOwnProperty.call(message, "old_value"))
                    writer.uint32(10).string(message.old_value);
                return writer;
            };

            delete_price_material_types.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.delete_price_material_types();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.old_value = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            delete_price_material_types.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.delete_price_material_types)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.delete_price_material_types();
                if (object.old_value != null)
                    message.old_value = String(object.old_value);
                return message;
            };

            delete_price_material_types.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.old_value = "";
                if (message.old_value != null && message.hasOwnProperty("old_value"))
                    object.old_value = message.old_value;
                return object;
            };

            delete_price_material_types.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            delete_price_material_types.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.delete_price_material_types";
            };

            return delete_price_material_types;
        })();

        PlacesApiRequest.unify_price_material_type = (function() {

            function unify_price_material_type(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            unify_price_material_type.prototype.old_value = "";
            unify_price_material_type.prototype.new_value = "";

            unify_price_material_type.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.old_value != null && Object.hasOwnProperty.call(message, "old_value"))
                    writer.uint32(10).string(message.old_value);
                if (message.new_value != null && Object.hasOwnProperty.call(message, "new_value"))
                    writer.uint32(18).string(message.new_value);
                return writer;
            };

            unify_price_material_type.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.unify_price_material_type();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.old_value = reader.string();
                            break;
                        }
                    case 2: {
                            message.new_value = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            unify_price_material_type.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.unify_price_material_type)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.unify_price_material_type();
                if (object.old_value != null)
                    message.old_value = String(object.old_value);
                if (object.new_value != null)
                    message.new_value = String(object.new_value);
                return message;
            };

            unify_price_material_type.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.old_value = "";
                    object.new_value = "";
                }
                if (message.old_value != null && message.hasOwnProperty("old_value"))
                    object.old_value = message.old_value;
                if (message.new_value != null && message.hasOwnProperty("new_value"))
                    object.new_value = message.new_value;
                return object;
            };

            unify_price_material_type.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            unify_price_material_type.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.unify_price_material_type";
            };

            return unify_price_material_type;
        })();

        PlacesApiRequest.add_material_type = (function() {

            function add_material_type(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            add_material_type.prototype.value = "";

            add_material_type.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(10).string(message.value);
                return writer;
            };

            add_material_type.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.add_material_type();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            add_material_type.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.add_material_type)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.add_material_type();
                if (object.value != null)
                    message.value = String(object.value);
                return message;
            };

            add_material_type.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.value = "";
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            add_material_type.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            add_material_type.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.add_material_type";
            };

            return add_material_type;
        })();

        PlacesApiRequest.unify_place_machine_type = (function() {

            function unify_place_machine_type(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            unify_place_machine_type.prototype.old_value = "";
            unify_place_machine_type.prototype.new_value = "";

            unify_place_machine_type.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.old_value != null && Object.hasOwnProperty.call(message, "old_value"))
                    writer.uint32(10).string(message.old_value);
                if (message.new_value != null && Object.hasOwnProperty.call(message, "new_value"))
                    writer.uint32(18).string(message.new_value);
                return writer;
            };

            unify_place_machine_type.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.unify_place_machine_type();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.old_value = reader.string();
                            break;
                        }
                    case 2: {
                            message.new_value = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            unify_place_machine_type.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.unify_place_machine_type)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.unify_place_machine_type();
                if (object.old_value != null)
                    message.old_value = String(object.old_value);
                if (object.new_value != null)
                    message.new_value = String(object.new_value);
                return message;
            };

            unify_place_machine_type.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.old_value = "";
                    object.new_value = "";
                }
                if (message.old_value != null && message.hasOwnProperty("old_value"))
                    object.old_value = message.old_value;
                if (message.new_value != null && message.hasOwnProperty("new_value"))
                    object.new_value = message.new_value;
                return object;
            };

            unify_place_machine_type.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            unify_place_machine_type.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.unify_place_machine_type";
            };

            return unify_place_machine_type;
        })();

        PlacesApiRequest.add_machine_type = (function() {

            function add_machine_type(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            add_machine_type.prototype.value = "";

            add_machine_type.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(10).string(message.value);
                return writer;
            };

            add_machine_type.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.add_machine_type();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            add_machine_type.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.add_machine_type)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.add_machine_type();
                if (object.value != null)
                    message.value = String(object.value);
                return message;
            };

            add_machine_type.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.value = "";
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            add_machine_type.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            add_machine_type.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.add_machine_type";
            };

            return add_machine_type;
        })();

        PlacesApiRequest.unify_place_label_type = (function() {

            function unify_place_label_type(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            unify_place_label_type.prototype.old_key = "";
            unify_place_label_type.prototype.new_key = "";
            unify_place_label_type.prototype.old_value = "";
            unify_place_label_type.prototype.new_value = "";

            unify_place_label_type.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.old_key != null && Object.hasOwnProperty.call(message, "old_key"))
                    writer.uint32(10).string(message.old_key);
                if (message.new_key != null && Object.hasOwnProperty.call(message, "new_key"))
                    writer.uint32(18).string(message.new_key);
                if (message.old_value != null && Object.hasOwnProperty.call(message, "old_value"))
                    writer.uint32(26).string(message.old_value);
                if (message.new_value != null && Object.hasOwnProperty.call(message, "new_value"))
                    writer.uint32(34).string(message.new_value);
                return writer;
            };

            unify_place_label_type.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.unify_place_label_type();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.old_key = reader.string();
                            break;
                        }
                    case 2: {
                            message.new_key = reader.string();
                            break;
                        }
                    case 3: {
                            message.old_value = reader.string();
                            break;
                        }
                    case 4: {
                            message.new_value = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            unify_place_label_type.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.unify_place_label_type)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.unify_place_label_type();
                if (object.old_key != null)
                    message.old_key = String(object.old_key);
                if (object.new_key != null)
                    message.new_key = String(object.new_key);
                if (object.old_value != null)
                    message.old_value = String(object.old_value);
                if (object.new_value != null)
                    message.new_value = String(object.new_value);
                return message;
            };

            unify_place_label_type.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.old_key = "";
                    object.new_key = "";
                    object.old_value = "";
                    object.new_value = "";
                }
                if (message.old_key != null && message.hasOwnProperty("old_key"))
                    object.old_key = message.old_key;
                if (message.new_key != null && message.hasOwnProperty("new_key"))
                    object.new_key = message.new_key;
                if (message.old_value != null && message.hasOwnProperty("old_value"))
                    object.old_value = message.old_value;
                if (message.new_value != null && message.hasOwnProperty("new_value"))
                    object.new_value = message.new_value;
                return object;
            };

            unify_place_label_type.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            unify_place_label_type.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.unify_place_label_type";
            };

            return unify_place_label_type;
        })();

        PlacesApiRequest.add_label_type = (function() {

            function add_label_type(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            add_label_type.prototype.key = "";
            add_label_type.prototype.value = "";

            add_label_type.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                    writer.uint32(10).string(message.key);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(18).string(message.value);
                return writer;
            };

            add_label_type.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.add_label_type();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.key = reader.string();
                            break;
                        }
                    case 2: {
                            message.value = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            add_label_type.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.add_label_type)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.add_label_type();
                if (object.key != null)
                    message.key = String(object.key);
                if (object.value != null)
                    message.value = String(object.value);
                return message;
            };

            add_label_type.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.key = "";
                    object.value = "";
                }
                if (message.key != null && message.hasOwnProperty("key"))
                    object.key = message.key;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            add_label_type.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            add_label_type.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.add_label_type";
            };

            return add_label_type;
        })();

        PlacesApiRequest.add_label_to_places_with_action = (function() {

            function add_label_to_places_with_action(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            add_label_to_places_with_action.prototype.action = "";
            add_label_to_places_with_action.prototype.key = "";
            add_label_to_places_with_action.prototype.value = "";

            add_label_to_places_with_action.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                    writer.uint32(10).string(message.action);
                if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                    writer.uint32(18).string(message.key);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(26).string(message.value);
                return writer;
            };

            add_label_to_places_with_action.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.add_label_to_places_with_action();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.action = reader.string();
                            break;
                        }
                    case 2: {
                            message.key = reader.string();
                            break;
                        }
                    case 3: {
                            message.value = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            add_label_to_places_with_action.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.add_label_to_places_with_action)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.add_label_to_places_with_action();
                if (object.action != null)
                    message.action = String(object.action);
                if (object.key != null)
                    message.key = String(object.key);
                if (object.value != null)
                    message.value = String(object.value);
                return message;
            };

            add_label_to_places_with_action.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.action = "";
                    object.key = "";
                    object.value = "";
                }
                if (message.action != null && message.hasOwnProperty("action"))
                    object.action = message.action;
                if (message.key != null && message.hasOwnProperty("key"))
                    object.key = message.key;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            add_label_to_places_with_action.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            add_label_to_places_with_action.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.add_label_to_places_with_action";
            };

            return add_label_to_places_with_action;
        })();

        PlacesApiRequest.stage_place_info = (function() {

            function stage_place_info(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            stage_place_info.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            stage_place_info.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                    writer.uint32(8).uint64(message.place_id);
                return writer;
            };

            stage_place_info.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.stage_place_info();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.place_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            stage_place_info.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.stage_place_info)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.stage_place_info();
                if (object.place_id != null)
                    if ($util.Long)
                        (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                    else if (typeof object.place_id === "string")
                        message.place_id = parseInt(object.place_id, 10);
                    else if (typeof object.place_id === "number")
                        message.place_id = object.place_id;
                    else if (typeof object.place_id === "object")
                        message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
                return message;
            };

            stage_place_info.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.place_id = options.longs === String ? "0" : 0;
                if (message.place_id != null && message.hasOwnProperty("place_id"))
                    if (typeof message.place_id === "number")
                        object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                    else
                        object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
                return object;
            };

            stage_place_info.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            stage_place_info.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.stage_place_info";
            };

            return stage_place_info;
        })();

        PlacesApiRequest.get_stage_place_address = (function() {

            function get_stage_place_address(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_stage_place_address.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            get_stage_place_address.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                    writer.uint32(8).uint64(message.place_id);
                return writer;
            };

            get_stage_place_address.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.get_stage_place_address();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.place_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_stage_place_address.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.get_stage_place_address)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.get_stage_place_address();
                if (object.place_id != null)
                    if ($util.Long)
                        (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                    else if (typeof object.place_id === "string")
                        message.place_id = parseInt(object.place_id, 10);
                    else if (typeof object.place_id === "number")
                        message.place_id = object.place_id;
                    else if (typeof object.place_id === "object")
                        message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
                return message;
            };

            get_stage_place_address.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.place_id = options.longs === String ? "0" : 0;
                if (message.place_id != null && message.hasOwnProperty("place_id"))
                    if (typeof message.place_id === "number")
                        object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                    else
                        object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
                return object;
            };

            get_stage_place_address.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_stage_place_address.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.get_stage_place_address";
            };

            return get_stage_place_address;
        })();

        PlacesApiRequest.add_laser_cutting_material_thickness = (function() {

            function add_laser_cutting_material_thickness(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            add_laser_cutting_material_thickness.prototype.material = "";
            add_laser_cutting_material_thickness.prototype.thickness_01mm = 0;

            add_laser_cutting_material_thickness.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                    writer.uint32(10).string(message.material);
                if (message.thickness_01mm != null && Object.hasOwnProperty.call(message, "thickness_01mm"))
                    writer.uint32(16).int32(message.thickness_01mm);
                return writer;
            };

            add_laser_cutting_material_thickness.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.add_laser_cutting_material_thickness();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.material = reader.string();
                            break;
                        }
                    case 2: {
                            message.thickness_01mm = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            add_laser_cutting_material_thickness.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.add_laser_cutting_material_thickness)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.add_laser_cutting_material_thickness();
                if (object.material != null)
                    message.material = String(object.material);
                if (object.thickness_01mm != null)
                    message.thickness_01mm = object.thickness_01mm | 0;
                return message;
            };

            add_laser_cutting_material_thickness.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.material = "";
                    object.thickness_01mm = 0;
                }
                if (message.material != null && message.hasOwnProperty("material"))
                    object.material = message.material;
                if (message.thickness_01mm != null && message.hasOwnProperty("thickness_01mm"))
                    object.thickness_01mm = message.thickness_01mm;
                return object;
            };

            add_laser_cutting_material_thickness.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            add_laser_cutting_material_thickness.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.add_laser_cutting_material_thickness";
            };

            return add_laser_cutting_material_thickness;
        })();

        PlacesApiRequest.remove_laser_cutting_material_thickness = (function() {

            function remove_laser_cutting_material_thickness(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            remove_laser_cutting_material_thickness.prototype.material = "";
            remove_laser_cutting_material_thickness.prototype.thickness_01mm = 0;

            remove_laser_cutting_material_thickness.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                    writer.uint32(10).string(message.material);
                if (message.thickness_01mm != null && Object.hasOwnProperty.call(message, "thickness_01mm"))
                    writer.uint32(16).int32(message.thickness_01mm);
                return writer;
            };

            remove_laser_cutting_material_thickness.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.remove_laser_cutting_material_thickness();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.material = reader.string();
                            break;
                        }
                    case 2: {
                            message.thickness_01mm = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            remove_laser_cutting_material_thickness.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.remove_laser_cutting_material_thickness)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.remove_laser_cutting_material_thickness();
                if (object.material != null)
                    message.material = String(object.material);
                if (object.thickness_01mm != null)
                    message.thickness_01mm = object.thickness_01mm | 0;
                return message;
            };

            remove_laser_cutting_material_thickness.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.material = "";
                    object.thickness_01mm = 0;
                }
                if (message.material != null && message.hasOwnProperty("material"))
                    object.material = message.material;
                if (message.thickness_01mm != null && message.hasOwnProperty("thickness_01mm"))
                    object.thickness_01mm = message.thickness_01mm;
                return object;
            };

            remove_laser_cutting_material_thickness.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            remove_laser_cutting_material_thickness.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.remove_laser_cutting_material_thickness";
            };

            return remove_laser_cutting_material_thickness;
        })();

        PlacesApiRequest.reload_laser_cutting_material_thicknesses = (function() {

            function reload_laser_cutting_material_thicknesses(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            reload_laser_cutting_material_thicknesses.prototype.material = "";
            reload_laser_cutting_material_thicknesses.prototype.auto_update = false;
            reload_laser_cutting_material_thicknesses.prototype.unload_model = false;

            reload_laser_cutting_material_thicknesses.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                    writer.uint32(10).string(message.material);
                if (message.auto_update != null && Object.hasOwnProperty.call(message, "auto_update"))
                    writer.uint32(16).bool(message.auto_update);
                if (message.unload_model != null && Object.hasOwnProperty.call(message, "unload_model"))
                    writer.uint32(24).bool(message.unload_model);
                return writer;
            };

            reload_laser_cutting_material_thicknesses.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.reload_laser_cutting_material_thicknesses();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.material = reader.string();
                            break;
                        }
                    case 2: {
                            message.auto_update = reader.bool();
                            break;
                        }
                    case 3: {
                            message.unload_model = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            reload_laser_cutting_material_thicknesses.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.reload_laser_cutting_material_thicknesses)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.reload_laser_cutting_material_thicknesses();
                if (object.material != null)
                    message.material = String(object.material);
                if (object.auto_update != null)
                    message.auto_update = Boolean(object.auto_update);
                if (object.unload_model != null)
                    message.unload_model = Boolean(object.unload_model);
                return message;
            };

            reload_laser_cutting_material_thicknesses.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.material = "";
                    object.auto_update = false;
                    object.unload_model = false;
                }
                if (message.material != null && message.hasOwnProperty("material"))
                    object.material = message.material;
                if (message.auto_update != null && message.hasOwnProperty("auto_update"))
                    object.auto_update = message.auto_update;
                if (message.unload_model != null && message.hasOwnProperty("unload_model"))
                    object.unload_model = message.unload_model;
                return object;
            };

            reload_laser_cutting_material_thicknesses.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            reload_laser_cutting_material_thicknesses.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.reload_laser_cutting_material_thicknesses";
            };

            return reload_laser_cutting_material_thicknesses;
        })();

        PlacesApiRequest.create_new_departments = (function() {

            function create_new_departments(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            create_new_departments.prototype.minimal_city_population = 0;
            create_new_departments.prototype.places_action_to_search = "";

            create_new_departments.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.minimal_city_population != null && Object.hasOwnProperty.call(message, "minimal_city_population"))
                    writer.uint32(8).int32(message.minimal_city_population);
                if (message.places_action_to_search != null && Object.hasOwnProperty.call(message, "places_action_to_search"))
                    writer.uint32(18).string(message.places_action_to_search);
                return writer;
            };

            create_new_departments.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.create_new_departments();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.minimal_city_population = reader.int32();
                            break;
                        }
                    case 2: {
                            message.places_action_to_search = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            create_new_departments.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.create_new_departments)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.create_new_departments();
                if (object.minimal_city_population != null)
                    message.minimal_city_population = object.minimal_city_population | 0;
                if (object.places_action_to_search != null)
                    message.places_action_to_search = String(object.places_action_to_search);
                return message;
            };

            create_new_departments.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.minimal_city_population = 0;
                    object.places_action_to_search = "";
                }
                if (message.minimal_city_population != null && message.hasOwnProperty("minimal_city_population"))
                    object.minimal_city_population = message.minimal_city_population;
                if (message.places_action_to_search != null && message.hasOwnProperty("places_action_to_search"))
                    object.places_action_to_search = message.places_action_to_search;
                return object;
            };

            create_new_departments.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            create_new_departments.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.create_new_departments";
            };

            return create_new_departments;
        })();

        PlacesApiRequest.add_action_to_department = (function() {

            function add_action_to_department(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            add_action_to_department.prototype.department_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            add_action_to_department.prototype.action = "";
            add_action_to_department.prototype.markup_percentage = 0;

            add_action_to_department.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.department_id != null && Object.hasOwnProperty.call(message, "department_id"))
                    writer.uint32(8).uint64(message.department_id);
                if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                    writer.uint32(18).string(message.action);
                if (message.markup_percentage != null && Object.hasOwnProperty.call(message, "markup_percentage"))
                    writer.uint32(24).int32(message.markup_percentage);
                return writer;
            };

            add_action_to_department.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.add_action_to_department();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.department_id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.action = reader.string();
                            break;
                        }
                    case 3: {
                            message.markup_percentage = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            add_action_to_department.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.add_action_to_department)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.add_action_to_department();
                if (object.department_id != null)
                    if ($util.Long)
                        (message.department_id = $util.Long.fromValue(object.department_id)).unsigned = true;
                    else if (typeof object.department_id === "string")
                        message.department_id = parseInt(object.department_id, 10);
                    else if (typeof object.department_id === "number")
                        message.department_id = object.department_id;
                    else if (typeof object.department_id === "object")
                        message.department_id = new $util.LongBits(object.department_id.low >>> 0, object.department_id.high >>> 0).toNumber(true);
                if (object.action != null)
                    message.action = String(object.action);
                if (object.markup_percentage != null)
                    message.markup_percentage = object.markup_percentage | 0;
                return message;
            };

            add_action_to_department.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.department_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.department_id = options.longs === String ? "0" : 0;
                    object.action = "";
                    object.markup_percentage = 0;
                }
                if (message.department_id != null && message.hasOwnProperty("department_id"))
                    if (typeof message.department_id === "number")
                        object.department_id = options.longs === String ? String(message.department_id) : message.department_id;
                    else
                        object.department_id = options.longs === String ? $util.Long.prototype.toString.call(message.department_id) : options.longs === Number ? new $util.LongBits(message.department_id.low >>> 0, message.department_id.high >>> 0).toNumber(true) : message.department_id;
                if (message.action != null && message.hasOwnProperty("action"))
                    object.action = message.action;
                if (message.markup_percentage != null && message.hasOwnProperty("markup_percentage"))
                    object.markup_percentage = message.markup_percentage;
                return object;
            };

            add_action_to_department.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            add_action_to_department.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.add_action_to_department";
            };

            return add_action_to_department;
        })();

        PlacesApiRequest.add_action_to_all_departments = (function() {

            function add_action_to_all_departments(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            add_action_to_all_departments.prototype.action = "";
            add_action_to_all_departments.prototype.markup_percentage = 0;

            add_action_to_all_departments.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                    writer.uint32(10).string(message.action);
                if (message.markup_percentage != null && Object.hasOwnProperty.call(message, "markup_percentage"))
                    writer.uint32(16).int32(message.markup_percentage);
                return writer;
            };

            add_action_to_all_departments.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.add_action_to_all_departments();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.action = reader.string();
                            break;
                        }
                    case 2: {
                            message.markup_percentage = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            add_action_to_all_departments.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.add_action_to_all_departments)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.add_action_to_all_departments();
                if (object.action != null)
                    message.action = String(object.action);
                if (object.markup_percentage != null)
                    message.markup_percentage = object.markup_percentage | 0;
                return message;
            };

            add_action_to_all_departments.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.action = "";
                    object.markup_percentage = 0;
                }
                if (message.action != null && message.hasOwnProperty("action"))
                    object.action = message.action;
                if (message.markup_percentage != null && message.hasOwnProperty("markup_percentage"))
                    object.markup_percentage = message.markup_percentage;
                return object;
            };

            add_action_to_all_departments.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            add_action_to_all_departments.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.add_action_to_all_departments";
            };

            return add_action_to_all_departments;
        })();

        PlacesApiRequest.setup_local_prices_for_action = (function() {

            function setup_local_prices_for_action(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            setup_local_prices_for_action.prototype.action = "";

            setup_local_prices_for_action.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                    writer.uint32(10).string(message.action);
                return writer;
            };

            setup_local_prices_for_action.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.setup_local_prices_for_action();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.action = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            setup_local_prices_for_action.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.setup_local_prices_for_action)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.setup_local_prices_for_action();
                if (object.action != null)
                    message.action = String(object.action);
                return message;
            };

            setup_local_prices_for_action.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.action = "";
                if (message.action != null && message.hasOwnProperty("action"))
                    object.action = message.action;
                return object;
            };

            setup_local_prices_for_action.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            setup_local_prices_for_action.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.setup_local_prices_for_action";
            };

            return setup_local_prices_for_action;
        })();

        PlacesApiRequest.open_addresses_in_selenium = (function() {

            function open_addresses_in_selenium(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            open_addresses_in_selenium.prototype.addresses = "";

            open_addresses_in_selenium.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.addresses != null && Object.hasOwnProperty.call(message, "addresses"))
                    writer.uint32(10).string(message.addresses);
                return writer;
            };

            open_addresses_in_selenium.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.open_addresses_in_selenium();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.addresses = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            open_addresses_in_selenium.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.open_addresses_in_selenium)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.open_addresses_in_selenium();
                if (object.addresses != null)
                    message.addresses = String(object.addresses);
                return message;
            };

            open_addresses_in_selenium.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.addresses = "";
                if (message.addresses != null && message.hasOwnProperty("addresses"))
                    object.addresses = message.addresses;
                return object;
            };

            open_addresses_in_selenium.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            open_addresses_in_selenium.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.open_addresses_in_selenium";
            };

            return open_addresses_in_selenium;
        })();

        PlacesApiRequest.set_department_address = (function() {

            function set_department_address(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            set_department_address.prototype.department_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            set_department_address.prototype.address = "";
            set_department_address.prototype.latitude = 0;
            set_department_address.prototype.longitude = 0;

            set_department_address.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.department_id != null && Object.hasOwnProperty.call(message, "department_id"))
                    writer.uint32(8).uint64(message.department_id);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(18).string(message.address);
                if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                    writer.uint32(29).float(message.latitude);
                if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                    writer.uint32(37).float(message.longitude);
                return writer;
            };

            set_department_address.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.set_department_address();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.department_id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.address = reader.string();
                            break;
                        }
                    case 3: {
                            message.latitude = reader.float();
                            break;
                        }
                    case 4: {
                            message.longitude = reader.float();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            set_department_address.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.set_department_address)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.set_department_address();
                if (object.department_id != null)
                    if ($util.Long)
                        (message.department_id = $util.Long.fromValue(object.department_id)).unsigned = true;
                    else if (typeof object.department_id === "string")
                        message.department_id = parseInt(object.department_id, 10);
                    else if (typeof object.department_id === "number")
                        message.department_id = object.department_id;
                    else if (typeof object.department_id === "object")
                        message.department_id = new $util.LongBits(object.department_id.low >>> 0, object.department_id.high >>> 0).toNumber(true);
                if (object.address != null)
                    message.address = String(object.address);
                if (object.latitude != null)
                    message.latitude = Number(object.latitude);
                if (object.longitude != null)
                    message.longitude = Number(object.longitude);
                return message;
            };

            set_department_address.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.department_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.department_id = options.longs === String ? "0" : 0;
                    object.address = "";
                    object.latitude = 0;
                    object.longitude = 0;
                }
                if (message.department_id != null && message.hasOwnProperty("department_id"))
                    if (typeof message.department_id === "number")
                        object.department_id = options.longs === String ? String(message.department_id) : message.department_id;
                    else
                        object.department_id = options.longs === String ? $util.Long.prototype.toString.call(message.department_id) : options.longs === Number ? new $util.LongBits(message.department_id.low >>> 0, message.department_id.high >>> 0).toNumber(true) : message.department_id;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                if (message.latitude != null && message.hasOwnProperty("latitude"))
                    object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
                if (message.longitude != null && message.hasOwnProperty("longitude"))
                    object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
                return object;
            };

            set_department_address.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            set_department_address.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.set_department_address";
            };

            return set_department_address;
        })();

        return PlacesApiRequest;
    })();

    object_msg.search_place_return_msg = (function() {

        function search_place_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        search_place_return_msg.prototype.items = $util.emptyArray;

        search_place_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.object_msg.search_place_return_msg.ItemWithId.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        search_place_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_place_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.object_msg.search_place_return_msg.ItemWithId.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        search_place_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.search_place_return_msg)
                return object;
            let message = new $root.object_msg.search_place_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.search_place_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".object_msg.search_place_return_msg.items: object expected");
                    message.items[i] = $root.object_msg.search_place_return_msg.ItemWithId.fromObject(object.items[i]);
                }
            }
            return message;
        };

        search_place_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.object_msg.search_place_return_msg.ItemWithId.toObject(message.items[j], options);
            }
            return object;
        };

        search_place_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        search_place_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.search_place_return_msg";
        };

        search_place_return_msg.ItemWithId = (function() {

            function ItemWithId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            ItemWithId.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            ItemWithId.prototype.item = "";
            ItemWithId.prototype.owner_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            ItemWithId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(8).uint64(message.id);
                if (message.item != null && Object.hasOwnProperty.call(message, "item"))
                    writer.uint32(18).string(message.item);
                if (message.owner_id != null && Object.hasOwnProperty.call(message, "owner_id"))
                    writer.uint32(24).uint64(message.owner_id);
                return writer;
            };

            ItemWithId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_place_return_msg.ItemWithId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.item = reader.string();
                            break;
                        }
                    case 3: {
                            message.owner_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            ItemWithId.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.search_place_return_msg.ItemWithId)
                    return object;
                let message = new $root.object_msg.search_place_return_msg.ItemWithId();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.item != null)
                    message.item = String(object.item);
                if (object.owner_id != null)
                    if ($util.Long)
                        (message.owner_id = $util.Long.fromValue(object.owner_id)).unsigned = true;
                    else if (typeof object.owner_id === "string")
                        message.owner_id = parseInt(object.owner_id, 10);
                    else if (typeof object.owner_id === "number")
                        message.owner_id = object.owner_id;
                    else if (typeof object.owner_id === "object")
                        message.owner_id = new $util.LongBits(object.owner_id.low >>> 0, object.owner_id.high >>> 0).toNumber(true);
                return message;
            };

            ItemWithId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.item = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.owner_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.owner_id = options.longs === String ? "0" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.item != null && message.hasOwnProperty("item"))
                    object.item = message.item;
                if (message.owner_id != null && message.hasOwnProperty("owner_id"))
                    if (typeof message.owner_id === "number")
                        object.owner_id = options.longs === String ? String(message.owner_id) : message.owner_id;
                    else
                        object.owner_id = options.longs === String ? $util.Long.prototype.toString.call(message.owner_id) : options.longs === Number ? new $util.LongBits(message.owner_id.low >>> 0, message.owner_id.high >>> 0).toNumber(true) : message.owner_id;
                return object;
            };

            ItemWithId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            ItemWithId.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.search_place_return_msg.ItemWithId";
            };

            return ItemWithId;
        })();

        return search_place_return_msg;
    })();

    object_msg.search_place_by_action_return_msg = (function() {

        function search_place_by_action_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        search_place_by_action_return_msg.prototype.items = $util.emptyArray;

        search_place_by_action_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.object_msg.search_place_by_action_return_msg.ItemWithId.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        search_place_by_action_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_place_by_action_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.object_msg.search_place_by_action_return_msg.ItemWithId.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        search_place_by_action_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.search_place_by_action_return_msg)
                return object;
            let message = new $root.object_msg.search_place_by_action_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.search_place_by_action_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".object_msg.search_place_by_action_return_msg.items: object expected");
                    message.items[i] = $root.object_msg.search_place_by_action_return_msg.ItemWithId.fromObject(object.items[i]);
                }
            }
            return message;
        };

        search_place_by_action_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.object_msg.search_place_by_action_return_msg.ItemWithId.toObject(message.items[j], options);
            }
            return object;
        };

        search_place_by_action_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        search_place_by_action_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.search_place_by_action_return_msg";
        };

        search_place_by_action_return_msg.ItemWithId = (function() {

            function ItemWithId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            ItemWithId.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            ItemWithId.prototype.item = "";

            ItemWithId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(8).uint64(message.id);
                if (message.item != null && Object.hasOwnProperty.call(message, "item"))
                    writer.uint32(18).string(message.item);
                return writer;
            };

            ItemWithId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_place_by_action_return_msg.ItemWithId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.item = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            ItemWithId.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.search_place_by_action_return_msg.ItemWithId)
                    return object;
                let message = new $root.object_msg.search_place_by_action_return_msg.ItemWithId();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.item != null)
                    message.item = String(object.item);
                return message;
            };

            ItemWithId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.item = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.item != null && message.hasOwnProperty("item"))
                    object.item = message.item;
                return object;
            };

            ItemWithId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            ItemWithId.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.search_place_by_action_return_msg.ItemWithId";
            };

            return ItemWithId;
        })();

        return search_place_by_action_return_msg;
    })();

    object_msg.search_place_by_action_and_location_return_msg = (function() {

        function search_place_by_action_and_location_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        search_place_by_action_and_location_return_msg.prototype.items = $util.emptyArray;
        search_place_by_action_and_location_return_msg.prototype.not_found_with_requirements = false;

        search_place_by_action_and_location_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.object_msg.search_place_by_action_and_location_return_msg.PlaceInfo.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            if (message.not_found_with_requirements != null && Object.hasOwnProperty.call(message, "not_found_with_requirements"))
                writer.uint32(16).bool(message.not_found_with_requirements);
            return writer;
        };

        search_place_by_action_and_location_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_place_by_action_and_location_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.object_msg.search_place_by_action_and_location_return_msg.PlaceInfo.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.not_found_with_requirements = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        search_place_by_action_and_location_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.search_place_by_action_and_location_return_msg)
                return object;
            let message = new $root.object_msg.search_place_by_action_and_location_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.search_place_by_action_and_location_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".object_msg.search_place_by_action_and_location_return_msg.items: object expected");
                    message.items[i] = $root.object_msg.search_place_by_action_and_location_return_msg.PlaceInfo.fromObject(object.items[i]);
                }
            }
            if (object.not_found_with_requirements != null)
                message.not_found_with_requirements = Boolean(object.not_found_with_requirements);
            return message;
        };

        search_place_by_action_and_location_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.not_found_with_requirements = false;
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.object_msg.search_place_by_action_and_location_return_msg.PlaceInfo.toObject(message.items[j], options);
            }
            if (message.not_found_with_requirements != null && message.hasOwnProperty("not_found_with_requirements"))
                object.not_found_with_requirements = message.not_found_with_requirements;
            return object;
        };

        search_place_by_action_and_location_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        search_place_by_action_and_location_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.search_place_by_action_and_location_return_msg";
        };

        search_place_by_action_and_location_return_msg.PlaceLabels = (function() {

            function PlaceLabels(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            PlaceLabels.prototype.key = "";
            PlaceLabels.prototype.value = "";

            PlaceLabels.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                    writer.uint32(10).string(message.key);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(18).string(message.value);
                return writer;
            };

            PlaceLabels.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_place_by_action_and_location_return_msg.PlaceLabels();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.key = reader.string();
                            break;
                        }
                    case 2: {
                            message.value = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            PlaceLabels.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.search_place_by_action_and_location_return_msg.PlaceLabels)
                    return object;
                let message = new $root.object_msg.search_place_by_action_and_location_return_msg.PlaceLabels();
                if (object.key != null)
                    message.key = String(object.key);
                if (object.value != null)
                    message.value = String(object.value);
                return message;
            };

            PlaceLabels.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.key = "";
                    object.value = "";
                }
                if (message.key != null && message.hasOwnProperty("key"))
                    object.key = message.key;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            PlaceLabels.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            PlaceLabels.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.search_place_by_action_and_location_return_msg.PlaceLabels";
            };

            return PlaceLabels;
        })();

        search_place_by_action_and_location_return_msg.ContactInfo = (function() {

            function ContactInfo(properties) {
                this.communication_types = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            ContactInfo.prototype.receiver = "";
            ContactInfo.prototype.description = "";
            ContactInfo.prototype.main = false;
            ContactInfo.prototype.communication_types = $util.emptyArray;

            ContactInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.receiver != null && Object.hasOwnProperty.call(message, "receiver"))
                    writer.uint32(10).string(message.receiver);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(26).string(message.description);
                if (message.main != null && Object.hasOwnProperty.call(message, "main"))
                    writer.uint32(32).bool(message.main);
                if (message.communication_types != null && message.communication_types.length) {
                    writer.uint32(42).fork();
                    for (let i = 0; i < message.communication_types.length; ++i)
                        writer.int32(message.communication_types[i]);
                    writer.ldelim();
                }
                return writer;
            };

            ContactInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_place_by_action_and_location_return_msg.ContactInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.receiver = reader.string();
                            break;
                        }
                    case 3: {
                            message.description = reader.string();
                            break;
                        }
                    case 4: {
                            message.main = reader.bool();
                            break;
                        }
                    case 5: {
                            if (!(message.communication_types && message.communication_types.length))
                                message.communication_types = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.communication_types.push(reader.int32());
                            } else
                                message.communication_types.push(reader.int32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            ContactInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.search_place_by_action_and_location_return_msg.ContactInfo)
                    return object;
                let message = new $root.object_msg.search_place_by_action_and_location_return_msg.ContactInfo();
                if (object.receiver != null)
                    message.receiver = String(object.receiver);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.main != null)
                    message.main = Boolean(object.main);
                if (object.communication_types) {
                    if (!Array.isArray(object.communication_types))
                        throw TypeError(".object_msg.search_place_by_action_and_location_return_msg.ContactInfo.communication_types: array expected");
                    message.communication_types = [];
                    for (let i = 0; i < object.communication_types.length; ++i)
                        message.communication_types[i] = object.communication_types[i] | 0;
                }
                return message;
            };

            ContactInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.communication_types = [];
                if (options.defaults) {
                    object.receiver = "";
                    object.description = "";
                    object.main = false;
                }
                if (message.receiver != null && message.hasOwnProperty("receiver"))
                    object.receiver = message.receiver;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.main != null && message.hasOwnProperty("main"))
                    object.main = message.main;
                if (message.communication_types && message.communication_types.length) {
                    object.communication_types = [];
                    for (let j = 0; j < message.communication_types.length; ++j)
                        object.communication_types[j] = message.communication_types[j];
                }
                return object;
            };

            ContactInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            ContactInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.search_place_by_action_and_location_return_msg.ContactInfo";
            };

            return ContactInfo;
        })();

        search_place_by_action_and_location_return_msg.PlaceInfo = (function() {

            function PlaceInfo(properties) {
                this.contact_info = [];
                this.place_labels = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            PlaceInfo.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            PlaceInfo.prototype.distance = 0;
            PlaceInfo.prototype.name = "";
            PlaceInfo.prototype.contact_info = $util.emptyArray;
            PlaceInfo.prototype.place_labels = $util.emptyArray;
            PlaceInfo.prototype.org_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            PlaceInfo.prototype.address_quality = 0;

            PlaceInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(8).uint64(message.id);
                if (message.distance != null && Object.hasOwnProperty.call(message, "distance"))
                    writer.uint32(17).double(message.distance);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(26).string(message.name);
                if (message.contact_info != null && message.contact_info.length)
                    for (let i = 0; i < message.contact_info.length; ++i)
                        $root.object_msg.search_place_by_action_and_location_return_msg.ContactInfo.encode(message.contact_info[i], writer.uint32(34).fork()).ldelim();
                if (message.place_labels != null && message.place_labels.length)
                    for (let i = 0; i < message.place_labels.length; ++i)
                        $root.object_msg.search_place_by_action_and_location_return_msg.PlaceLabels.encode(message.place_labels[i], writer.uint32(42).fork()).ldelim();
                if (message.org_id != null && Object.hasOwnProperty.call(message, "org_id"))
                    writer.uint32(48).uint64(message.org_id);
                if (message.address_quality != null && Object.hasOwnProperty.call(message, "address_quality"))
                    writer.uint32(56).int32(message.address_quality);
                return writer;
            };

            PlaceInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_place_by_action_and_location_return_msg.PlaceInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.distance = reader.double();
                            break;
                        }
                    case 3: {
                            message.name = reader.string();
                            break;
                        }
                    case 4: {
                            if (!(message.contact_info && message.contact_info.length))
                                message.contact_info = [];
                            message.contact_info.push($root.object_msg.search_place_by_action_and_location_return_msg.ContactInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 5: {
                            if (!(message.place_labels && message.place_labels.length))
                                message.place_labels = [];
                            message.place_labels.push($root.object_msg.search_place_by_action_and_location_return_msg.PlaceLabels.decode(reader, reader.uint32()));
                            break;
                        }
                    case 6: {
                            message.org_id = reader.uint64();
                            break;
                        }
                    case 7: {
                            message.address_quality = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            PlaceInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.search_place_by_action_and_location_return_msg.PlaceInfo)
                    return object;
                let message = new $root.object_msg.search_place_by_action_and_location_return_msg.PlaceInfo();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.distance != null)
                    message.distance = Number(object.distance);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.contact_info) {
                    if (!Array.isArray(object.contact_info))
                        throw TypeError(".object_msg.search_place_by_action_and_location_return_msg.PlaceInfo.contact_info: array expected");
                    message.contact_info = [];
                    for (let i = 0; i < object.contact_info.length; ++i) {
                        if (typeof object.contact_info[i] !== "object")
                            throw TypeError(".object_msg.search_place_by_action_and_location_return_msg.PlaceInfo.contact_info: object expected");
                        message.contact_info[i] = $root.object_msg.search_place_by_action_and_location_return_msg.ContactInfo.fromObject(object.contact_info[i]);
                    }
                }
                if (object.place_labels) {
                    if (!Array.isArray(object.place_labels))
                        throw TypeError(".object_msg.search_place_by_action_and_location_return_msg.PlaceInfo.place_labels: array expected");
                    message.place_labels = [];
                    for (let i = 0; i < object.place_labels.length; ++i) {
                        if (typeof object.place_labels[i] !== "object")
                            throw TypeError(".object_msg.search_place_by_action_and_location_return_msg.PlaceInfo.place_labels: object expected");
                        message.place_labels[i] = $root.object_msg.search_place_by_action_and_location_return_msg.PlaceLabels.fromObject(object.place_labels[i]);
                    }
                }
                if (object.org_id != null)
                    if ($util.Long)
                        (message.org_id = $util.Long.fromValue(object.org_id)).unsigned = true;
                    else if (typeof object.org_id === "string")
                        message.org_id = parseInt(object.org_id, 10);
                    else if (typeof object.org_id === "number")
                        message.org_id = object.org_id;
                    else if (typeof object.org_id === "object")
                        message.org_id = new $util.LongBits(object.org_id.low >>> 0, object.org_id.high >>> 0).toNumber(true);
                if (object.address_quality != null)
                    message.address_quality = object.address_quality | 0;
                return message;
            };

            PlaceInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.contact_info = [];
                    object.place_labels = [];
                }
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.distance = 0;
                    object.name = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.org_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.org_id = options.longs === String ? "0" : 0;
                    object.address_quality = 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.distance != null && message.hasOwnProperty("distance"))
                    object.distance = options.json && !isFinite(message.distance) ? String(message.distance) : message.distance;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.contact_info && message.contact_info.length) {
                    object.contact_info = [];
                    for (let j = 0; j < message.contact_info.length; ++j)
                        object.contact_info[j] = $root.object_msg.search_place_by_action_and_location_return_msg.ContactInfo.toObject(message.contact_info[j], options);
                }
                if (message.place_labels && message.place_labels.length) {
                    object.place_labels = [];
                    for (let j = 0; j < message.place_labels.length; ++j)
                        object.place_labels[j] = $root.object_msg.search_place_by_action_and_location_return_msg.PlaceLabels.toObject(message.place_labels[j], options);
                }
                if (message.org_id != null && message.hasOwnProperty("org_id"))
                    if (typeof message.org_id === "number")
                        object.org_id = options.longs === String ? String(message.org_id) : message.org_id;
                    else
                        object.org_id = options.longs === String ? $util.Long.prototype.toString.call(message.org_id) : options.longs === Number ? new $util.LongBits(message.org_id.low >>> 0, message.org_id.high >>> 0).toNumber(true) : message.org_id;
                if (message.address_quality != null && message.hasOwnProperty("address_quality"))
                    object.address_quality = message.address_quality;
                return object;
            };

            PlaceInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            PlaceInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.search_place_by_action_and_location_return_msg.PlaceInfo";
            };

            return PlaceInfo;
        })();

        return search_place_by_action_and_location_return_msg;
    })();

    object_msg.get_city_places_by_action_return_msg = (function() {

        function get_city_places_by_action_return_msg(properties) {
            this.places = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        get_city_places_by_action_return_msg.prototype.places = $util.emptyArray;

        get_city_places_by_action_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.places != null && message.places.length)
                for (let i = 0; i < message.places.length; ++i)
                    writer.uint32(10).string(message.places[i]);
            return writer;
        };

        get_city_places_by_action_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_city_places_by_action_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.places && message.places.length))
                            message.places = [];
                        message.places.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        get_city_places_by_action_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.get_city_places_by_action_return_msg)
                return object;
            let message = new $root.object_msg.get_city_places_by_action_return_msg();
            if (object.places) {
                if (!Array.isArray(object.places))
                    throw TypeError(".object_msg.get_city_places_by_action_return_msg.places: array expected");
                message.places = [];
                for (let i = 0; i < object.places.length; ++i)
                    message.places[i] = String(object.places[i]);
            }
            return message;
        };

        get_city_places_by_action_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.places = [];
            if (message.places && message.places.length) {
                object.places = [];
                for (let j = 0; j < message.places.length; ++j)
                    object.places[j] = message.places[j];
            }
            return object;
        };

        get_city_places_by_action_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        get_city_places_by_action_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.get_city_places_by_action_return_msg";
        };

        return get_city_places_by_action_return_msg;
    })();

    object_msg.search_custom_place_action_types_return_msg = (function() {

        function search_custom_place_action_types_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        search_custom_place_action_types_return_msg.prototype.items = $util.emptyArray;

        search_custom_place_action_types_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    writer.uint32(10).string(message.items[i]);
            return writer;
        };

        search_custom_place_action_types_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_custom_place_action_types_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        search_custom_place_action_types_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.search_custom_place_action_types_return_msg)
                return object;
            let message = new $root.object_msg.search_custom_place_action_types_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.search_custom_place_action_types_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i)
                    message.items[i] = String(object.items[i]);
            }
            return message;
        };

        search_custom_place_action_types_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = message.items[j];
            }
            return object;
        };

        search_custom_place_action_types_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        search_custom_place_action_types_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.search_custom_place_action_types_return_msg";
        };

        return search_custom_place_action_types_return_msg;
    })();

    object_msg.search_custom_price_material_types_return_msg = (function() {

        function search_custom_price_material_types_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        search_custom_price_material_types_return_msg.prototype.items = $util.emptyArray;

        search_custom_price_material_types_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    writer.uint32(10).string(message.items[i]);
            return writer;
        };

        search_custom_price_material_types_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_custom_price_material_types_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        search_custom_price_material_types_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.search_custom_price_material_types_return_msg)
                return object;
            let message = new $root.object_msg.search_custom_price_material_types_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.search_custom_price_material_types_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i)
                    message.items[i] = String(object.items[i]);
            }
            return message;
        };

        search_custom_price_material_types_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = message.items[j];
            }
            return object;
        };

        search_custom_price_material_types_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        search_custom_price_material_types_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.search_custom_price_material_types_return_msg";
        };

        return search_custom_price_material_types_return_msg;
    })();

    object_msg.get_custom_price_material_urls_return_msg = (function() {

        function get_custom_price_material_urls_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        get_custom_price_material_urls_return_msg.prototype.items = $util.emptyArray;

        get_custom_price_material_urls_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.object_msg.get_custom_price_material_urls_return_msg.PriceMaterialUrls.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        get_custom_price_material_urls_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_custom_price_material_urls_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.object_msg.get_custom_price_material_urls_return_msg.PriceMaterialUrls.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        get_custom_price_material_urls_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.get_custom_price_material_urls_return_msg)
                return object;
            let message = new $root.object_msg.get_custom_price_material_urls_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.get_custom_price_material_urls_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".object_msg.get_custom_price_material_urls_return_msg.items: object expected");
                    message.items[i] = $root.object_msg.get_custom_price_material_urls_return_msg.PriceMaterialUrls.fromObject(object.items[i]);
                }
            }
            return message;
        };

        get_custom_price_material_urls_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.object_msg.get_custom_price_material_urls_return_msg.PriceMaterialUrls.toObject(message.items[j], options);
            }
            return object;
        };

        get_custom_price_material_urls_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        get_custom_price_material_urls_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.get_custom_price_material_urls_return_msg";
        };

        get_custom_price_material_urls_return_msg.UrlWithPrice = (function() {

            function UrlWithPrice(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            UrlWithPrice.prototype.url = "";
            UrlWithPrice.prototype.price = 0;

            UrlWithPrice.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                    writer.uint32(10).string(message.url);
                if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                    writer.uint32(21).float(message.price);
                return writer;
            };

            UrlWithPrice.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_custom_price_material_urls_return_msg.UrlWithPrice();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.url = reader.string();
                            break;
                        }
                    case 2: {
                            message.price = reader.float();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            UrlWithPrice.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.get_custom_price_material_urls_return_msg.UrlWithPrice)
                    return object;
                let message = new $root.object_msg.get_custom_price_material_urls_return_msg.UrlWithPrice();
                if (object.url != null)
                    message.url = String(object.url);
                if (object.price != null)
                    message.price = Number(object.price);
                return message;
            };

            UrlWithPrice.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.url = "";
                    object.price = 0;
                }
                if (message.url != null && message.hasOwnProperty("url"))
                    object.url = message.url;
                if (message.price != null && message.hasOwnProperty("price"))
                    object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
                return object;
            };

            UrlWithPrice.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            UrlWithPrice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.get_custom_price_material_urls_return_msg.UrlWithPrice";
            };

            return UrlWithPrice;
        })();

        get_custom_price_material_urls_return_msg.PriceMaterialUrls = (function() {

            function PriceMaterialUrls(properties) {
                this.pending_urls = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            PriceMaterialUrls.prototype.main_url = "";
            PriceMaterialUrls.prototype.pending_urls = $util.emptyArray;

            PriceMaterialUrls.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.main_url != null && Object.hasOwnProperty.call(message, "main_url"))
                    writer.uint32(10).string(message.main_url);
                if (message.pending_urls != null && message.pending_urls.length)
                    for (let i = 0; i < message.pending_urls.length; ++i)
                        $root.object_msg.get_custom_price_material_urls_return_msg.UrlWithPrice.encode(message.pending_urls[i], writer.uint32(18).fork()).ldelim();
                return writer;
            };

            PriceMaterialUrls.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_custom_price_material_urls_return_msg.PriceMaterialUrls();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.main_url = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.pending_urls && message.pending_urls.length))
                                message.pending_urls = [];
                            message.pending_urls.push($root.object_msg.get_custom_price_material_urls_return_msg.UrlWithPrice.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            PriceMaterialUrls.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.get_custom_price_material_urls_return_msg.PriceMaterialUrls)
                    return object;
                let message = new $root.object_msg.get_custom_price_material_urls_return_msg.PriceMaterialUrls();
                if (object.main_url != null)
                    message.main_url = String(object.main_url);
                if (object.pending_urls) {
                    if (!Array.isArray(object.pending_urls))
                        throw TypeError(".object_msg.get_custom_price_material_urls_return_msg.PriceMaterialUrls.pending_urls: array expected");
                    message.pending_urls = [];
                    for (let i = 0; i < object.pending_urls.length; ++i) {
                        if (typeof object.pending_urls[i] !== "object")
                            throw TypeError(".object_msg.get_custom_price_material_urls_return_msg.PriceMaterialUrls.pending_urls: object expected");
                        message.pending_urls[i] = $root.object_msg.get_custom_price_material_urls_return_msg.UrlWithPrice.fromObject(object.pending_urls[i]);
                    }
                }
                return message;
            };

            PriceMaterialUrls.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.pending_urls = [];
                if (options.defaults)
                    object.main_url = "";
                if (message.main_url != null && message.hasOwnProperty("main_url"))
                    object.main_url = message.main_url;
                if (message.pending_urls && message.pending_urls.length) {
                    object.pending_urls = [];
                    for (let j = 0; j < message.pending_urls.length; ++j)
                        object.pending_urls[j] = $root.object_msg.get_custom_price_material_urls_return_msg.UrlWithPrice.toObject(message.pending_urls[j], options);
                }
                return object;
            };

            PriceMaterialUrls.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            PriceMaterialUrls.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.get_custom_price_material_urls_return_msg.PriceMaterialUrls";
            };

            return PriceMaterialUrls;
        })();

        return get_custom_price_material_urls_return_msg;
    })();

    object_msg.get_custom_action_urls_return_msg = (function() {

        function get_custom_action_urls_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        get_custom_action_urls_return_msg.prototype.items = $util.emptyArray;

        get_custom_action_urls_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.object_msg.get_custom_action_urls_return_msg.ActionUrls.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        get_custom_action_urls_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_custom_action_urls_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.object_msg.get_custom_action_urls_return_msg.ActionUrls.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        get_custom_action_urls_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.get_custom_action_urls_return_msg)
                return object;
            let message = new $root.object_msg.get_custom_action_urls_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.get_custom_action_urls_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".object_msg.get_custom_action_urls_return_msg.items: object expected");
                    message.items[i] = $root.object_msg.get_custom_action_urls_return_msg.ActionUrls.fromObject(object.items[i]);
                }
            }
            return message;
        };

        get_custom_action_urls_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.object_msg.get_custom_action_urls_return_msg.ActionUrls.toObject(message.items[j], options);
            }
            return object;
        };

        get_custom_action_urls_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        get_custom_action_urls_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.get_custom_action_urls_return_msg";
        };

        get_custom_action_urls_return_msg.ActionUrls = (function() {

            function ActionUrls(properties) {
                this.pending_urls = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            ActionUrls.prototype.main_url = "";
            ActionUrls.prototype.pending_urls = $util.emptyArray;

            ActionUrls.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.main_url != null && Object.hasOwnProperty.call(message, "main_url"))
                    writer.uint32(10).string(message.main_url);
                if (message.pending_urls != null && message.pending_urls.length)
                    for (let i = 0; i < message.pending_urls.length; ++i)
                        writer.uint32(18).string(message.pending_urls[i]);
                return writer;
            };

            ActionUrls.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_custom_action_urls_return_msg.ActionUrls();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.main_url = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.pending_urls && message.pending_urls.length))
                                message.pending_urls = [];
                            message.pending_urls.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            ActionUrls.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.get_custom_action_urls_return_msg.ActionUrls)
                    return object;
                let message = new $root.object_msg.get_custom_action_urls_return_msg.ActionUrls();
                if (object.main_url != null)
                    message.main_url = String(object.main_url);
                if (object.pending_urls) {
                    if (!Array.isArray(object.pending_urls))
                        throw TypeError(".object_msg.get_custom_action_urls_return_msg.ActionUrls.pending_urls: array expected");
                    message.pending_urls = [];
                    for (let i = 0; i < object.pending_urls.length; ++i)
                        message.pending_urls[i] = String(object.pending_urls[i]);
                }
                return message;
            };

            ActionUrls.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.pending_urls = [];
                if (options.defaults)
                    object.main_url = "";
                if (message.main_url != null && message.hasOwnProperty("main_url"))
                    object.main_url = message.main_url;
                if (message.pending_urls && message.pending_urls.length) {
                    object.pending_urls = [];
                    for (let j = 0; j < message.pending_urls.length; ++j)
                        object.pending_urls[j] = message.pending_urls[j];
                }
                return object;
            };

            ActionUrls.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            ActionUrls.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.get_custom_action_urls_return_msg.ActionUrls";
            };

            return ActionUrls;
        })();

        return get_custom_action_urls_return_msg;
    })();

    object_msg.search_laser_cutting_price_thicknesses_return_msg = (function() {

        function search_laser_cutting_price_thicknesses_return_msg(properties) {
            this.thicknesses = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        search_laser_cutting_price_thicknesses_return_msg.prototype.thicknesses = $util.emptyArray;

        search_laser_cutting_price_thicknesses_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.thicknesses != null && message.thicknesses.length) {
                writer.uint32(10).fork();
                for (let i = 0; i < message.thicknesses.length; ++i)
                    writer.int32(message.thicknesses[i]);
                writer.ldelim();
            }
            return writer;
        };

        search_laser_cutting_price_thicknesses_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_laser_cutting_price_thicknesses_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.thicknesses && message.thicknesses.length))
                            message.thicknesses = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.thicknesses.push(reader.int32());
                        } else
                            message.thicknesses.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        search_laser_cutting_price_thicknesses_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.search_laser_cutting_price_thicknesses_return_msg)
                return object;
            let message = new $root.object_msg.search_laser_cutting_price_thicknesses_return_msg();
            if (object.thicknesses) {
                if (!Array.isArray(object.thicknesses))
                    throw TypeError(".object_msg.search_laser_cutting_price_thicknesses_return_msg.thicknesses: array expected");
                message.thicknesses = [];
                for (let i = 0; i < object.thicknesses.length; ++i)
                    message.thicknesses[i] = object.thicknesses[i] | 0;
            }
            return message;
        };

        search_laser_cutting_price_thicknesses_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.thicknesses = [];
            if (message.thicknesses && message.thicknesses.length) {
                object.thicknesses = [];
                for (let j = 0; j < message.thicknesses.length; ++j)
                    object.thicknesses[j] = message.thicknesses[j];
            }
            return object;
        };

        search_laser_cutting_price_thicknesses_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        search_laser_cutting_price_thicknesses_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.search_laser_cutting_price_thicknesses_return_msg";
        };

        return search_laser_cutting_price_thicknesses_return_msg;
    })();

    object_msg.get_local_laser_cutting_prices_for_material_by_department_return_msg = (function() {

        function get_local_laser_cutting_prices_for_material_by_department_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        get_local_laser_cutting_prices_for_material_by_department_return_msg.prototype.items = $util.emptyArray;

        get_local_laser_cutting_prices_for_material_by_department_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.object_msg.get_local_laser_cutting_prices_for_material_by_department_return_msg.ThicknessPrice.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        get_local_laser_cutting_prices_for_material_by_department_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_local_laser_cutting_prices_for_material_by_department_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.object_msg.get_local_laser_cutting_prices_for_material_by_department_return_msg.ThicknessPrice.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        get_local_laser_cutting_prices_for_material_by_department_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.get_local_laser_cutting_prices_for_material_by_department_return_msg)
                return object;
            let message = new $root.object_msg.get_local_laser_cutting_prices_for_material_by_department_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.get_local_laser_cutting_prices_for_material_by_department_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".object_msg.get_local_laser_cutting_prices_for_material_by_department_return_msg.items: object expected");
                    message.items[i] = $root.object_msg.get_local_laser_cutting_prices_for_material_by_department_return_msg.ThicknessPrice.fromObject(object.items[i]);
                }
            }
            return message;
        };

        get_local_laser_cutting_prices_for_material_by_department_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.object_msg.get_local_laser_cutting_prices_for_material_by_department_return_msg.ThicknessPrice.toObject(message.items[j], options);
            }
            return object;
        };

        get_local_laser_cutting_prices_for_material_by_department_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        get_local_laser_cutting_prices_for_material_by_department_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.get_local_laser_cutting_prices_for_material_by_department_return_msg";
        };

        get_local_laser_cutting_prices_for_material_by_department_return_msg.ThicknessPrice = (function() {

            function ThicknessPrice(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            ThicknessPrice.prototype.thickness_01mm = 0;
            ThicknessPrice.prototype.price = 0;

            ThicknessPrice.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.thickness_01mm != null && Object.hasOwnProperty.call(message, "thickness_01mm"))
                    writer.uint32(8).int32(message.thickness_01mm);
                if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                    writer.uint32(21).float(message.price);
                return writer;
            };

            ThicknessPrice.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_local_laser_cutting_prices_for_material_by_department_return_msg.ThicknessPrice();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.thickness_01mm = reader.int32();
                            break;
                        }
                    case 2: {
                            message.price = reader.float();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            ThicknessPrice.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.get_local_laser_cutting_prices_for_material_by_department_return_msg.ThicknessPrice)
                    return object;
                let message = new $root.object_msg.get_local_laser_cutting_prices_for_material_by_department_return_msg.ThicknessPrice();
                if (object.thickness_01mm != null)
                    message.thickness_01mm = object.thickness_01mm | 0;
                if (object.price != null)
                    message.price = Number(object.price);
                return message;
            };

            ThicknessPrice.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.thickness_01mm = 0;
                    object.price = 0;
                }
                if (message.thickness_01mm != null && message.hasOwnProperty("thickness_01mm"))
                    object.thickness_01mm = message.thickness_01mm;
                if (message.price != null && message.hasOwnProperty("price"))
                    object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
                return object;
            };

            ThicknessPrice.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            ThicknessPrice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.get_local_laser_cutting_prices_for_material_by_department_return_msg.ThicknessPrice";
            };

            return ThicknessPrice;
        })();

        return get_local_laser_cutting_prices_for_material_by_department_return_msg;
    })();

    object_msg.search_custom_place_machine_types_return_msg = (function() {

        function search_custom_place_machine_types_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        search_custom_place_machine_types_return_msg.prototype.items = $util.emptyArray;

        search_custom_place_machine_types_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    writer.uint32(10).string(message.items[i]);
            return writer;
        };

        search_custom_place_machine_types_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_custom_place_machine_types_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        search_custom_place_machine_types_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.search_custom_place_machine_types_return_msg)
                return object;
            let message = new $root.object_msg.search_custom_place_machine_types_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.search_custom_place_machine_types_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i)
                    message.items[i] = String(object.items[i]);
            }
            return message;
        };

        search_custom_place_machine_types_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = message.items[j];
            }
            return object;
        };

        search_custom_place_machine_types_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        search_custom_place_machine_types_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.search_custom_place_machine_types_return_msg";
        };

        return search_custom_place_machine_types_return_msg;
    })();

    object_msg.search_custom_place_label_types_return_msg = (function() {

        function search_custom_place_label_types_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        search_custom_place_label_types_return_msg.prototype.items = $util.emptyArray;

        search_custom_place_label_types_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.object_msg.search_custom_place_label_types_return_msg.KeyValue.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        search_custom_place_label_types_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_custom_place_label_types_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.object_msg.search_custom_place_label_types_return_msg.KeyValue.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        search_custom_place_label_types_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.search_custom_place_label_types_return_msg)
                return object;
            let message = new $root.object_msg.search_custom_place_label_types_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.search_custom_place_label_types_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".object_msg.search_custom_place_label_types_return_msg.items: object expected");
                    message.items[i] = $root.object_msg.search_custom_place_label_types_return_msg.KeyValue.fromObject(object.items[i]);
                }
            }
            return message;
        };

        search_custom_place_label_types_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.object_msg.search_custom_place_label_types_return_msg.KeyValue.toObject(message.items[j], options);
            }
            return object;
        };

        search_custom_place_label_types_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        search_custom_place_label_types_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.search_custom_place_label_types_return_msg";
        };

        search_custom_place_label_types_return_msg.KeyValue = (function() {

            function KeyValue(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            KeyValue.prototype.key = "";
            KeyValue.prototype.value = "";

            KeyValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                    writer.uint32(10).string(message.key);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(18).string(message.value);
                return writer;
            };

            KeyValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_custom_place_label_types_return_msg.KeyValue();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.key = reader.string();
                            break;
                        }
                    case 2: {
                            message.value = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            KeyValue.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.search_custom_place_label_types_return_msg.KeyValue)
                    return object;
                let message = new $root.object_msg.search_custom_place_label_types_return_msg.KeyValue();
                if (object.key != null)
                    message.key = String(object.key);
                if (object.value != null)
                    message.value = String(object.value);
                return message;
            };

            KeyValue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.key = "";
                    object.value = "";
                }
                if (message.key != null && message.hasOwnProperty("key"))
                    object.key = message.key;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            KeyValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            KeyValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.search_custom_place_label_types_return_msg.KeyValue";
            };

            return KeyValue;
        })();

        return search_custom_place_label_types_return_msg;
    })();

    object_msg.stage_place_info_return_msg = (function() {

        function stage_place_info_return_msg(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        stage_place_info_return_msg.prototype.org_owner = false;
        stage_place_info_return_msg.prototype.place_owner = false;
        stage_place_info_return_msg.prototype.place_verified = false;
        stage_place_info_return_msg.prototype.place_org_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        stage_place_info_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.org_owner != null && Object.hasOwnProperty.call(message, "org_owner"))
                writer.uint32(8).bool(message.org_owner);
            if (message.place_owner != null && Object.hasOwnProperty.call(message, "place_owner"))
                writer.uint32(16).bool(message.place_owner);
            if (message.place_verified != null && Object.hasOwnProperty.call(message, "place_verified"))
                writer.uint32(24).bool(message.place_verified);
            if (message.place_org_id != null && Object.hasOwnProperty.call(message, "place_org_id"))
                writer.uint32(32).uint64(message.place_org_id);
            return writer;
        };

        stage_place_info_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.stage_place_info_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.org_owner = reader.bool();
                        break;
                    }
                case 2: {
                        message.place_owner = reader.bool();
                        break;
                    }
                case 3: {
                        message.place_verified = reader.bool();
                        break;
                    }
                case 4: {
                        message.place_org_id = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        stage_place_info_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.stage_place_info_return_msg)
                return object;
            let message = new $root.object_msg.stage_place_info_return_msg();
            if (object.org_owner != null)
                message.org_owner = Boolean(object.org_owner);
            if (object.place_owner != null)
                message.place_owner = Boolean(object.place_owner);
            if (object.place_verified != null)
                message.place_verified = Boolean(object.place_verified);
            if (object.place_org_id != null)
                if ($util.Long)
                    (message.place_org_id = $util.Long.fromValue(object.place_org_id)).unsigned = true;
                else if (typeof object.place_org_id === "string")
                    message.place_org_id = parseInt(object.place_org_id, 10);
                else if (typeof object.place_org_id === "number")
                    message.place_org_id = object.place_org_id;
                else if (typeof object.place_org_id === "object")
                    message.place_org_id = new $util.LongBits(object.place_org_id.low >>> 0, object.place_org_id.high >>> 0).toNumber(true);
            return message;
        };

        stage_place_info_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.org_owner = false;
                object.place_owner = false;
                object.place_verified = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.place_org_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.place_org_id = options.longs === String ? "0" : 0;
            }
            if (message.org_owner != null && message.hasOwnProperty("org_owner"))
                object.org_owner = message.org_owner;
            if (message.place_owner != null && message.hasOwnProperty("place_owner"))
                object.place_owner = message.place_owner;
            if (message.place_verified != null && message.hasOwnProperty("place_verified"))
                object.place_verified = message.place_verified;
            if (message.place_org_id != null && message.hasOwnProperty("place_org_id"))
                if (typeof message.place_org_id === "number")
                    object.place_org_id = options.longs === String ? String(message.place_org_id) : message.place_org_id;
                else
                    object.place_org_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_org_id) : options.longs === Number ? new $util.LongBits(message.place_org_id.low >>> 0, message.place_org_id.high >>> 0).toNumber(true) : message.place_org_id;
            return object;
        };

        stage_place_info_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        stage_place_info_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.stage_place_info_return_msg";
        };

        return stage_place_info_return_msg;
    })();

    object_msg.OrganizationsApiRequest = (function() {

        function OrganizationsApiRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrganizationsApiRequest.prototype.update_organization_by_manager_args = null;
        OrganizationsApiRequest.prototype.get_or_add_bank_args = null;
        OrganizationsApiRequest.prototype.get_organization_suggestion_args = null;
        OrganizationsApiRequest.prototype.search_organization_args = null;
        OrganizationsApiRequest.prototype.select_organization_args = null;
        OrganizationsApiRequest.prototype.reload_user_organizations_info_args = null;
        OrganizationsApiRequest.prototype.archive_user_organizaton_args = null;
        OrganizationsApiRequest.prototype.does_user_have_organization_args = null;

        let $oneOfFields;

        Object.defineProperty(OrganizationsApiRequest.prototype, "args", {
            get: $util.oneOfGetter($oneOfFields = ["update_organization_by_manager_args", "get_or_add_bank_args", "get_organization_suggestion_args", "search_organization_args", "select_organization_args", "reload_user_organizations_info_args", "archive_user_organizaton_args", "does_user_have_organization_args"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        OrganizationsApiRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.update_organization_by_manager_args != null && Object.hasOwnProperty.call(message, "update_organization_by_manager_args"))
                $root.object_msg.OrganizationsApiRequest.update_organization_by_manager.encode(message.update_organization_by_manager_args, writer.uint32(10).fork()).ldelim();
            if (message.get_or_add_bank_args != null && Object.hasOwnProperty.call(message, "get_or_add_bank_args"))
                $root.object_msg.OrganizationsApiRequest.get_or_add_bank.encode(message.get_or_add_bank_args, writer.uint32(18).fork()).ldelim();
            if (message.get_organization_suggestion_args != null && Object.hasOwnProperty.call(message, "get_organization_suggestion_args"))
                $root.object_msg.OrganizationsApiRequest.get_organization_suggestion.encode(message.get_organization_suggestion_args, writer.uint32(26).fork()).ldelim();
            if (message.search_organization_args != null && Object.hasOwnProperty.call(message, "search_organization_args"))
                $root.object_msg.OrganizationsApiRequest.search_organization.encode(message.search_organization_args, writer.uint32(34).fork()).ldelim();
            if (message.select_organization_args != null && Object.hasOwnProperty.call(message, "select_organization_args"))
                $root.object_msg.OrganizationsApiRequest.select_organization.encode(message.select_organization_args, writer.uint32(42).fork()).ldelim();
            if (message.reload_user_organizations_info_args != null && Object.hasOwnProperty.call(message, "reload_user_organizations_info_args"))
                $root.object_msg.OrganizationsApiRequest.reload_user_organizations_info.encode(message.reload_user_organizations_info_args, writer.uint32(50).fork()).ldelim();
            if (message.archive_user_organizaton_args != null && Object.hasOwnProperty.call(message, "archive_user_organizaton_args"))
                $root.object_msg.OrganizationsApiRequest.archive_user_organizaton.encode(message.archive_user_organizaton_args, writer.uint32(58).fork()).ldelim();
            if (message.does_user_have_organization_args != null && Object.hasOwnProperty.call(message, "does_user_have_organization_args"))
                $root.object_msg.OrganizationsApiRequest.does_user_have_organization.encode(message.does_user_have_organization_args, writer.uint32(66).fork()).ldelim();
            return writer;
        };

        OrganizationsApiRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationsApiRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.update_organization_by_manager_args = $root.object_msg.OrganizationsApiRequest.update_organization_by_manager.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.get_or_add_bank_args = $root.object_msg.OrganizationsApiRequest.get_or_add_bank.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.get_organization_suggestion_args = $root.object_msg.OrganizationsApiRequest.get_organization_suggestion.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.search_organization_args = $root.object_msg.OrganizationsApiRequest.search_organization.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.select_organization_args = $root.object_msg.OrganizationsApiRequest.select_organization.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.reload_user_organizations_info_args = $root.object_msg.OrganizationsApiRequest.reload_user_organizations_info.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.archive_user_organizaton_args = $root.object_msg.OrganizationsApiRequest.archive_user_organizaton.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.does_user_have_organization_args = $root.object_msg.OrganizationsApiRequest.does_user_have_organization.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrganizationsApiRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrganizationsApiRequest)
                return object;
            let message = new $root.object_msg.OrganizationsApiRequest();
            if (object.update_organization_by_manager_args != null) {
                if (typeof object.update_organization_by_manager_args !== "object")
                    throw TypeError(".object_msg.OrganizationsApiRequest.update_organization_by_manager_args: object expected");
                message.update_organization_by_manager_args = $root.object_msg.OrganizationsApiRequest.update_organization_by_manager.fromObject(object.update_organization_by_manager_args);
            }
            if (object.get_or_add_bank_args != null) {
                if (typeof object.get_or_add_bank_args !== "object")
                    throw TypeError(".object_msg.OrganizationsApiRequest.get_or_add_bank_args: object expected");
                message.get_or_add_bank_args = $root.object_msg.OrganizationsApiRequest.get_or_add_bank.fromObject(object.get_or_add_bank_args);
            }
            if (object.get_organization_suggestion_args != null) {
                if (typeof object.get_organization_suggestion_args !== "object")
                    throw TypeError(".object_msg.OrganizationsApiRequest.get_organization_suggestion_args: object expected");
                message.get_organization_suggestion_args = $root.object_msg.OrganizationsApiRequest.get_organization_suggestion.fromObject(object.get_organization_suggestion_args);
            }
            if (object.search_organization_args != null) {
                if (typeof object.search_organization_args !== "object")
                    throw TypeError(".object_msg.OrganizationsApiRequest.search_organization_args: object expected");
                message.search_organization_args = $root.object_msg.OrganizationsApiRequest.search_organization.fromObject(object.search_organization_args);
            }
            if (object.select_organization_args != null) {
                if (typeof object.select_organization_args !== "object")
                    throw TypeError(".object_msg.OrganizationsApiRequest.select_organization_args: object expected");
                message.select_organization_args = $root.object_msg.OrganizationsApiRequest.select_organization.fromObject(object.select_organization_args);
            }
            if (object.reload_user_organizations_info_args != null) {
                if (typeof object.reload_user_organizations_info_args !== "object")
                    throw TypeError(".object_msg.OrganizationsApiRequest.reload_user_organizations_info_args: object expected");
                message.reload_user_organizations_info_args = $root.object_msg.OrganizationsApiRequest.reload_user_organizations_info.fromObject(object.reload_user_organizations_info_args);
            }
            if (object.archive_user_organizaton_args != null) {
                if (typeof object.archive_user_organizaton_args !== "object")
                    throw TypeError(".object_msg.OrganizationsApiRequest.archive_user_organizaton_args: object expected");
                message.archive_user_organizaton_args = $root.object_msg.OrganizationsApiRequest.archive_user_organizaton.fromObject(object.archive_user_organizaton_args);
            }
            if (object.does_user_have_organization_args != null) {
                if (typeof object.does_user_have_organization_args !== "object")
                    throw TypeError(".object_msg.OrganizationsApiRequest.does_user_have_organization_args: object expected");
                message.does_user_have_organization_args = $root.object_msg.OrganizationsApiRequest.does_user_have_organization.fromObject(object.does_user_have_organization_args);
            }
            return message;
        };

        OrganizationsApiRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.update_organization_by_manager_args != null && message.hasOwnProperty("update_organization_by_manager_args")) {
                object.update_organization_by_manager_args = $root.object_msg.OrganizationsApiRequest.update_organization_by_manager.toObject(message.update_organization_by_manager_args, options);
                if (options.oneofs)
                    object.args = "update_organization_by_manager_args";
            }
            if (message.get_or_add_bank_args != null && message.hasOwnProperty("get_or_add_bank_args")) {
                object.get_or_add_bank_args = $root.object_msg.OrganizationsApiRequest.get_or_add_bank.toObject(message.get_or_add_bank_args, options);
                if (options.oneofs)
                    object.args = "get_or_add_bank_args";
            }
            if (message.get_organization_suggestion_args != null && message.hasOwnProperty("get_organization_suggestion_args")) {
                object.get_organization_suggestion_args = $root.object_msg.OrganizationsApiRequest.get_organization_suggestion.toObject(message.get_organization_suggestion_args, options);
                if (options.oneofs)
                    object.args = "get_organization_suggestion_args";
            }
            if (message.search_organization_args != null && message.hasOwnProperty("search_organization_args")) {
                object.search_organization_args = $root.object_msg.OrganizationsApiRequest.search_organization.toObject(message.search_organization_args, options);
                if (options.oneofs)
                    object.args = "search_organization_args";
            }
            if (message.select_organization_args != null && message.hasOwnProperty("select_organization_args")) {
                object.select_organization_args = $root.object_msg.OrganizationsApiRequest.select_organization.toObject(message.select_organization_args, options);
                if (options.oneofs)
                    object.args = "select_organization_args";
            }
            if (message.reload_user_organizations_info_args != null && message.hasOwnProperty("reload_user_organizations_info_args")) {
                object.reload_user_organizations_info_args = $root.object_msg.OrganizationsApiRequest.reload_user_organizations_info.toObject(message.reload_user_organizations_info_args, options);
                if (options.oneofs)
                    object.args = "reload_user_organizations_info_args";
            }
            if (message.archive_user_organizaton_args != null && message.hasOwnProperty("archive_user_organizaton_args")) {
                object.archive_user_organizaton_args = $root.object_msg.OrganizationsApiRequest.archive_user_organizaton.toObject(message.archive_user_organizaton_args, options);
                if (options.oneofs)
                    object.args = "archive_user_organizaton_args";
            }
            if (message.does_user_have_organization_args != null && message.hasOwnProperty("does_user_have_organization_args")) {
                object.does_user_have_organization_args = $root.object_msg.OrganizationsApiRequest.does_user_have_organization.toObject(message.does_user_have_organization_args, options);
                if (options.oneofs)
                    object.args = "does_user_have_organization_args";
            }
            return object;
        };

        OrganizationsApiRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrganizationsApiRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrganizationsApiRequest";
        };

        OrganizationsApiRequest.Method = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "unknownMethod"] = 0;
            values[valuesById[1] = "updateOrganizationByManager"] = 1;
            values[valuesById[2] = "getOrAddBank"] = 2;
            values[valuesById[3] = "getOrganizationSuggestion"] = 3;
            values[valuesById[4] = "searchOrganization"] = 4;
            values[valuesById[5] = "selectOrganization"] = 5;
            values[valuesById[6] = "reloadUserOrganizationsInfo"] = 6;
            values[valuesById[7] = "archiveUserOrganizaton"] = 7;
            values[valuesById[8] = "doesUserHaveOrganization"] = 8;
            return values;
        })();

        OrganizationsApiRequest.update_organization_by_manager = (function() {

            function update_organization_by_manager(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            update_organization_by_manager.prototype.organizations = $util.newBuffer([]);
            update_organization_by_manager.prototype.org_id_for_archive = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            update_organization_by_manager.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            update_organization_by_manager.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.organizations != null && Object.hasOwnProperty.call(message, "organizations"))
                    writer.uint32(10).bytes(message.organizations);
                if (message.org_id_for_archive != null && Object.hasOwnProperty.call(message, "org_id_for_archive"))
                    writer.uint32(16).uint64(message.org_id_for_archive);
                if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                    writer.uint32(24).uint64(message.user_id);
                return writer;
            };

            update_organization_by_manager.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationsApiRequest.update_organization_by_manager();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.organizations = reader.bytes();
                            break;
                        }
                    case 2: {
                            message.org_id_for_archive = reader.uint64();
                            break;
                        }
                    case 3: {
                            message.user_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            update_organization_by_manager.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrganizationsApiRequest.update_organization_by_manager)
                    return object;
                let message = new $root.object_msg.OrganizationsApiRequest.update_organization_by_manager();
                if (object.organizations != null)
                    if (typeof object.organizations === "string")
                        $util.base64.decode(object.organizations, message.organizations = $util.newBuffer($util.base64.length(object.organizations)), 0);
                    else if (object.organizations.length >= 0)
                        message.organizations = object.organizations;
                if (object.org_id_for_archive != null)
                    if ($util.Long)
                        (message.org_id_for_archive = $util.Long.fromValue(object.org_id_for_archive)).unsigned = true;
                    else if (typeof object.org_id_for_archive === "string")
                        message.org_id_for_archive = parseInt(object.org_id_for_archive, 10);
                    else if (typeof object.org_id_for_archive === "number")
                        message.org_id_for_archive = object.org_id_for_archive;
                    else if (typeof object.org_id_for_archive === "object")
                        message.org_id_for_archive = new $util.LongBits(object.org_id_for_archive.low >>> 0, object.org_id_for_archive.high >>> 0).toNumber(true);
                if (object.user_id != null)
                    if ($util.Long)
                        (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = true;
                    else if (typeof object.user_id === "string")
                        message.user_id = parseInt(object.user_id, 10);
                    else if (typeof object.user_id === "number")
                        message.user_id = object.user_id;
                    else if (typeof object.user_id === "object")
                        message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber(true);
                return message;
            };

            update_organization_by_manager.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.organizations = "";
                    else {
                        object.organizations = [];
                        if (options.bytes !== Array)
                            object.organizations = $util.newBuffer(object.organizations);
                    }
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.org_id_for_archive = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.org_id_for_archive = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.user_id = options.longs === String ? "0" : 0;
                }
                if (message.organizations != null && message.hasOwnProperty("organizations"))
                    object.organizations = options.bytes === String ? $util.base64.encode(message.organizations, 0, message.organizations.length) : options.bytes === Array ? Array.prototype.slice.call(message.organizations) : message.organizations;
                if (message.org_id_for_archive != null && message.hasOwnProperty("org_id_for_archive"))
                    if (typeof message.org_id_for_archive === "number")
                        object.org_id_for_archive = options.longs === String ? String(message.org_id_for_archive) : message.org_id_for_archive;
                    else
                        object.org_id_for_archive = options.longs === String ? $util.Long.prototype.toString.call(message.org_id_for_archive) : options.longs === Number ? new $util.LongBits(message.org_id_for_archive.low >>> 0, message.org_id_for_archive.high >>> 0).toNumber(true) : message.org_id_for_archive;
                if (message.user_id != null && message.hasOwnProperty("user_id"))
                    if (typeof message.user_id === "number")
                        object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                    else
                        object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber(true) : message.user_id;
                return object;
            };

            update_organization_by_manager.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            update_organization_by_manager.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrganizationsApiRequest.update_organization_by_manager";
            };

            return update_organization_by_manager;
        })();

        OrganizationsApiRequest.get_or_add_bank = (function() {

            function get_or_add_bank(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_or_add_bank.prototype.bic = "";

            get_or_add_bank.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.bic != null && Object.hasOwnProperty.call(message, "bic"))
                    writer.uint32(10).string(message.bic);
                return writer;
            };

            get_or_add_bank.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationsApiRequest.get_or_add_bank();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.bic = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_or_add_bank.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrganizationsApiRequest.get_or_add_bank)
                    return object;
                let message = new $root.object_msg.OrganizationsApiRequest.get_or_add_bank();
                if (object.bic != null)
                    message.bic = String(object.bic);
                return message;
            };

            get_or_add_bank.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.bic = "";
                if (message.bic != null && message.hasOwnProperty("bic"))
                    object.bic = message.bic;
                return object;
            };

            get_or_add_bank.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_or_add_bank.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrganizationsApiRequest.get_or_add_bank";
            };

            return get_or_add_bank;
        })();

        OrganizationsApiRequest.get_organization_suggestion = (function() {

            function get_organization_suggestion(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_organization_suggestion.prototype.input = "";

            get_organization_suggestion.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.input != null && Object.hasOwnProperty.call(message, "input"))
                    writer.uint32(10).string(message.input);
                return writer;
            };

            get_organization_suggestion.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationsApiRequest.get_organization_suggestion();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.input = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_organization_suggestion.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrganizationsApiRequest.get_organization_suggestion)
                    return object;
                let message = new $root.object_msg.OrganizationsApiRequest.get_organization_suggestion();
                if (object.input != null)
                    message.input = String(object.input);
                return message;
            };

            get_organization_suggestion.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.input = "";
                if (message.input != null && message.hasOwnProperty("input"))
                    object.input = message.input;
                return object;
            };

            get_organization_suggestion.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_organization_suggestion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrganizationsApiRequest.get_organization_suggestion";
            };

            return get_organization_suggestion;
        })();

        OrganizationsApiRequest.search_organization = (function() {

            function search_organization(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            search_organization.prototype.organization_name_inn = "";
            search_organization.prototype.organization_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            search_organization.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.organization_name_inn != null && Object.hasOwnProperty.call(message, "organization_name_inn"))
                    writer.uint32(10).string(message.organization_name_inn);
                if (message.organization_id != null && Object.hasOwnProperty.call(message, "organization_id"))
                    writer.uint32(16).uint64(message.organization_id);
                return writer;
            };

            search_organization.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationsApiRequest.search_organization();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.organization_name_inn = reader.string();
                            break;
                        }
                    case 2: {
                            message.organization_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            search_organization.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrganizationsApiRequest.search_organization)
                    return object;
                let message = new $root.object_msg.OrganizationsApiRequest.search_organization();
                if (object.organization_name_inn != null)
                    message.organization_name_inn = String(object.organization_name_inn);
                if (object.organization_id != null)
                    if ($util.Long)
                        (message.organization_id = $util.Long.fromValue(object.organization_id)).unsigned = true;
                    else if (typeof object.organization_id === "string")
                        message.organization_id = parseInt(object.organization_id, 10);
                    else if (typeof object.organization_id === "number")
                        message.organization_id = object.organization_id;
                    else if (typeof object.organization_id === "object")
                        message.organization_id = new $util.LongBits(object.organization_id.low >>> 0, object.organization_id.high >>> 0).toNumber(true);
                return message;
            };

            search_organization.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.organization_name_inn = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.organization_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.organization_id = options.longs === String ? "0" : 0;
                }
                if (message.organization_name_inn != null && message.hasOwnProperty("organization_name_inn"))
                    object.organization_name_inn = message.organization_name_inn;
                if (message.organization_id != null && message.hasOwnProperty("organization_id"))
                    if (typeof message.organization_id === "number")
                        object.organization_id = options.longs === String ? String(message.organization_id) : message.organization_id;
                    else
                        object.organization_id = options.longs === String ? $util.Long.prototype.toString.call(message.organization_id) : options.longs === Number ? new $util.LongBits(message.organization_id.low >>> 0, message.organization_id.high >>> 0).toNumber(true) : message.organization_id;
                return object;
            };

            search_organization.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            search_organization.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrganizationsApiRequest.search_organization";
            };

            return search_organization;
        })();

        OrganizationsApiRequest.select_organization = (function() {

            function select_organization(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            select_organization.prototype.organization_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            select_organization.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.organization_id != null && Object.hasOwnProperty.call(message, "organization_id"))
                    writer.uint32(8).uint64(message.organization_id);
                return writer;
            };

            select_organization.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationsApiRequest.select_organization();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.organization_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            select_organization.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrganizationsApiRequest.select_organization)
                    return object;
                let message = new $root.object_msg.OrganizationsApiRequest.select_organization();
                if (object.organization_id != null)
                    if ($util.Long)
                        (message.organization_id = $util.Long.fromValue(object.organization_id)).unsigned = true;
                    else if (typeof object.organization_id === "string")
                        message.organization_id = parseInt(object.organization_id, 10);
                    else if (typeof object.organization_id === "number")
                        message.organization_id = object.organization_id;
                    else if (typeof object.organization_id === "object")
                        message.organization_id = new $util.LongBits(object.organization_id.low >>> 0, object.organization_id.high >>> 0).toNumber(true);
                return message;
            };

            select_organization.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.organization_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.organization_id = options.longs === String ? "0" : 0;
                if (message.organization_id != null && message.hasOwnProperty("organization_id"))
                    if (typeof message.organization_id === "number")
                        object.organization_id = options.longs === String ? String(message.organization_id) : message.organization_id;
                    else
                        object.organization_id = options.longs === String ? $util.Long.prototype.toString.call(message.organization_id) : options.longs === Number ? new $util.LongBits(message.organization_id.low >>> 0, message.organization_id.high >>> 0).toNumber(true) : message.organization_id;
                return object;
            };

            select_organization.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            select_organization.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrganizationsApiRequest.select_organization";
            };

            return select_organization;
        })();

        OrganizationsApiRequest.reload_user_organizations_info = (function() {

            function reload_user_organizations_info(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            reload_user_organizations_info.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            reload_user_organizations_info.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                    writer.uint32(8).uint64(message.user_id);
                return writer;
            };

            reload_user_organizations_info.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationsApiRequest.reload_user_organizations_info();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.user_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            reload_user_organizations_info.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrganizationsApiRequest.reload_user_organizations_info)
                    return object;
                let message = new $root.object_msg.OrganizationsApiRequest.reload_user_organizations_info();
                if (object.user_id != null)
                    if ($util.Long)
                        (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = true;
                    else if (typeof object.user_id === "string")
                        message.user_id = parseInt(object.user_id, 10);
                    else if (typeof object.user_id === "number")
                        message.user_id = object.user_id;
                    else if (typeof object.user_id === "object")
                        message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber(true);
                return message;
            };

            reload_user_organizations_info.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.user_id = options.longs === String ? "0" : 0;
                if (message.user_id != null && message.hasOwnProperty("user_id"))
                    if (typeof message.user_id === "number")
                        object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                    else
                        object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber(true) : message.user_id;
                return object;
            };

            reload_user_organizations_info.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            reload_user_organizations_info.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrganizationsApiRequest.reload_user_organizations_info";
            };

            return reload_user_organizations_info;
        })();

        OrganizationsApiRequest.archive_user_organizaton = (function() {

            function archive_user_organizaton(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            archive_user_organizaton.prototype.organization_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            archive_user_organizaton.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            archive_user_organizaton.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.organization_id != null && Object.hasOwnProperty.call(message, "organization_id"))
                    writer.uint32(8).uint64(message.organization_id);
                if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                    writer.uint32(16).uint64(message.user_id);
                return writer;
            };

            archive_user_organizaton.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationsApiRequest.archive_user_organizaton();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.organization_id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.user_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            archive_user_organizaton.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrganizationsApiRequest.archive_user_organizaton)
                    return object;
                let message = new $root.object_msg.OrganizationsApiRequest.archive_user_organizaton();
                if (object.organization_id != null)
                    if ($util.Long)
                        (message.organization_id = $util.Long.fromValue(object.organization_id)).unsigned = true;
                    else if (typeof object.organization_id === "string")
                        message.organization_id = parseInt(object.organization_id, 10);
                    else if (typeof object.organization_id === "number")
                        message.organization_id = object.organization_id;
                    else if (typeof object.organization_id === "object")
                        message.organization_id = new $util.LongBits(object.organization_id.low >>> 0, object.organization_id.high >>> 0).toNumber(true);
                if (object.user_id != null)
                    if ($util.Long)
                        (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = true;
                    else if (typeof object.user_id === "string")
                        message.user_id = parseInt(object.user_id, 10);
                    else if (typeof object.user_id === "number")
                        message.user_id = object.user_id;
                    else if (typeof object.user_id === "object")
                        message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber(true);
                return message;
            };

            archive_user_organizaton.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.organization_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.organization_id = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.user_id = options.longs === String ? "0" : 0;
                }
                if (message.organization_id != null && message.hasOwnProperty("organization_id"))
                    if (typeof message.organization_id === "number")
                        object.organization_id = options.longs === String ? String(message.organization_id) : message.organization_id;
                    else
                        object.organization_id = options.longs === String ? $util.Long.prototype.toString.call(message.organization_id) : options.longs === Number ? new $util.LongBits(message.organization_id.low >>> 0, message.organization_id.high >>> 0).toNumber(true) : message.organization_id;
                if (message.user_id != null && message.hasOwnProperty("user_id"))
                    if (typeof message.user_id === "number")
                        object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                    else
                        object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber(true) : message.user_id;
                return object;
            };

            archive_user_organizaton.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            archive_user_organizaton.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrganizationsApiRequest.archive_user_organizaton";
            };

            return archive_user_organizaton;
        })();

        OrganizationsApiRequest.does_user_have_organization = (function() {

            function does_user_have_organization(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            does_user_have_organization.prototype.inn = "";
            does_user_have_organization.prototype.kpp = "";
            does_user_have_organization.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            does_user_have_organization.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.inn != null && Object.hasOwnProperty.call(message, "inn"))
                    writer.uint32(10).string(message.inn);
                if (message.kpp != null && Object.hasOwnProperty.call(message, "kpp"))
                    writer.uint32(18).string(message.kpp);
                if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                    writer.uint32(24).uint64(message.user_id);
                return writer;
            };

            does_user_have_organization.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationsApiRequest.does_user_have_organization();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.inn = reader.string();
                            break;
                        }
                    case 2: {
                            message.kpp = reader.string();
                            break;
                        }
                    case 3: {
                            message.user_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            does_user_have_organization.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrganizationsApiRequest.does_user_have_organization)
                    return object;
                let message = new $root.object_msg.OrganizationsApiRequest.does_user_have_organization();
                if (object.inn != null)
                    message.inn = String(object.inn);
                if (object.kpp != null)
                    message.kpp = String(object.kpp);
                if (object.user_id != null)
                    if ($util.Long)
                        (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = true;
                    else if (typeof object.user_id === "string")
                        message.user_id = parseInt(object.user_id, 10);
                    else if (typeof object.user_id === "number")
                        message.user_id = object.user_id;
                    else if (typeof object.user_id === "object")
                        message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber(true);
                return message;
            };

            does_user_have_organization.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.inn = "";
                    object.kpp = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.user_id = options.longs === String ? "0" : 0;
                }
                if (message.inn != null && message.hasOwnProperty("inn"))
                    object.inn = message.inn;
                if (message.kpp != null && message.hasOwnProperty("kpp"))
                    object.kpp = message.kpp;
                if (message.user_id != null && message.hasOwnProperty("user_id"))
                    if (typeof message.user_id === "number")
                        object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                    else
                        object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber(true) : message.user_id;
                return object;
            };

            does_user_have_organization.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            does_user_have_organization.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrganizationsApiRequest.does_user_have_organization";
            };

            return does_user_have_organization;
        })();

        return OrganizationsApiRequest;
    })();

    object_msg.update_organization_by_manager_return_msg = (function() {

        function update_organization_by_manager_return_msg(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        update_organization_by_manager_return_msg.prototype.id = null;
        update_organization_by_manager_return_msg.prototype.success = false;

        let $oneOfFields;

        Object.defineProperty(update_organization_by_manager_return_msg.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        update_organization_by_manager_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).uint64(message.id);
            if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                writer.uint32(16).bool(message.success);
            return writer;
        };

        update_organization_by_manager_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.update_organization_by_manager_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.success = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        update_organization_by_manager_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.update_organization_by_manager_return_msg)
                return object;
            let message = new $root.object_msg.update_organization_by_manager_return_msg();
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.success != null)
                message.success = Boolean(object.success);
            return message;
        };

        update_organization_by_manager_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.success = false;
            if (message.id != null && message.hasOwnProperty("id")) {
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            if (message.success != null && message.hasOwnProperty("success"))
                object.success = message.success;
            return object;
        };

        update_organization_by_manager_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        update_organization_by_manager_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.update_organization_by_manager_return_msg";
        };

        return update_organization_by_manager_return_msg;
    })();

    object_msg.get_or_add_bank_return_msg = (function() {

        function get_or_add_bank_return_msg(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        get_or_add_bank_return_msg.prototype.name = "";
        get_or_add_bank_return_msg.prototype.bic = "";
        get_or_add_bank_return_msg.prototype.swift = "";
        get_or_add_bank_return_msg.prototype.inn = "";
        get_or_add_bank_return_msg.prototype.kpp = "";
        get_or_add_bank_return_msg.prototype.correspondent_account = "";
        get_or_add_bank_return_msg.prototype.legal_address = "";
        get_or_add_bank_return_msg.prototype.bank_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        get_or_add_bank_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(10).string(message.name);
            if (message.bic != null && Object.hasOwnProperty.call(message, "bic"))
                writer.uint32(18).string(message.bic);
            if (message.swift != null && Object.hasOwnProperty.call(message, "swift"))
                writer.uint32(26).string(message.swift);
            if (message.inn != null && Object.hasOwnProperty.call(message, "inn"))
                writer.uint32(34).string(message.inn);
            if (message.kpp != null && Object.hasOwnProperty.call(message, "kpp"))
                writer.uint32(42).string(message.kpp);
            if (message.correspondent_account != null && Object.hasOwnProperty.call(message, "correspondent_account"))
                writer.uint32(50).string(message.correspondent_account);
            if (message.legal_address != null && Object.hasOwnProperty.call(message, "legal_address"))
                writer.uint32(58).string(message.legal_address);
            if (message.bank_id != null && Object.hasOwnProperty.call(message, "bank_id"))
                writer.uint32(64).uint64(message.bank_id);
            return writer;
        };

        get_or_add_bank_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_or_add_bank_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.bic = reader.string();
                        break;
                    }
                case 3: {
                        message.swift = reader.string();
                        break;
                    }
                case 4: {
                        message.inn = reader.string();
                        break;
                    }
                case 5: {
                        message.kpp = reader.string();
                        break;
                    }
                case 6: {
                        message.correspondent_account = reader.string();
                        break;
                    }
                case 7: {
                        message.legal_address = reader.string();
                        break;
                    }
                case 8: {
                        message.bank_id = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        get_or_add_bank_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.get_or_add_bank_return_msg)
                return object;
            let message = new $root.object_msg.get_or_add_bank_return_msg();
            if (object.name != null)
                message.name = String(object.name);
            if (object.bic != null)
                message.bic = String(object.bic);
            if (object.swift != null)
                message.swift = String(object.swift);
            if (object.inn != null)
                message.inn = String(object.inn);
            if (object.kpp != null)
                message.kpp = String(object.kpp);
            if (object.correspondent_account != null)
                message.correspondent_account = String(object.correspondent_account);
            if (object.legal_address != null)
                message.legal_address = String(object.legal_address);
            if (object.bank_id != null)
                if ($util.Long)
                    (message.bank_id = $util.Long.fromValue(object.bank_id)).unsigned = true;
                else if (typeof object.bank_id === "string")
                    message.bank_id = parseInt(object.bank_id, 10);
                else if (typeof object.bank_id === "number")
                    message.bank_id = object.bank_id;
                else if (typeof object.bank_id === "object")
                    message.bank_id = new $util.LongBits(object.bank_id.low >>> 0, object.bank_id.high >>> 0).toNumber(true);
            return message;
        };

        get_or_add_bank_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.bic = "";
                object.swift = "";
                object.inn = "";
                object.kpp = "";
                object.correspondent_account = "";
                object.legal_address = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.bank_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.bank_id = options.longs === String ? "0" : 0;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.bic != null && message.hasOwnProperty("bic"))
                object.bic = message.bic;
            if (message.swift != null && message.hasOwnProperty("swift"))
                object.swift = message.swift;
            if (message.inn != null && message.hasOwnProperty("inn"))
                object.inn = message.inn;
            if (message.kpp != null && message.hasOwnProperty("kpp"))
                object.kpp = message.kpp;
            if (message.correspondent_account != null && message.hasOwnProperty("correspondent_account"))
                object.correspondent_account = message.correspondent_account;
            if (message.legal_address != null && message.hasOwnProperty("legal_address"))
                object.legal_address = message.legal_address;
            if (message.bank_id != null && message.hasOwnProperty("bank_id"))
                if (typeof message.bank_id === "number")
                    object.bank_id = options.longs === String ? String(message.bank_id) : message.bank_id;
                else
                    object.bank_id = options.longs === String ? $util.Long.prototype.toString.call(message.bank_id) : options.longs === Number ? new $util.LongBits(message.bank_id.low >>> 0, message.bank_id.high >>> 0).toNumber(true) : message.bank_id;
            return object;
        };

        get_or_add_bank_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        get_or_add_bank_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.get_or_add_bank_return_msg";
        };

        return get_or_add_bank_return_msg;
    })();

    object_msg.get_organization_suggestion_return_msg = (function() {

        function get_organization_suggestion_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        get_organization_suggestion_return_msg.prototype.items = $util.emptyArray;

        get_organization_suggestion_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.object_msg.get_organization_suggestion_return_msg.Organization.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        get_organization_suggestion_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_organization_suggestion_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.object_msg.get_organization_suggestion_return_msg.Organization.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        get_organization_suggestion_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.get_organization_suggestion_return_msg)
                return object;
            let message = new $root.object_msg.get_organization_suggestion_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.get_organization_suggestion_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".object_msg.get_organization_suggestion_return_msg.items: object expected");
                    message.items[i] = $root.object_msg.get_organization_suggestion_return_msg.Organization.fromObject(object.items[i]);
                }
            }
            return message;
        };

        get_organization_suggestion_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.object_msg.get_organization_suggestion_return_msg.Organization.toObject(message.items[j], options);
            }
            return object;
        };

        get_organization_suggestion_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        get_organization_suggestion_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.get_organization_suggestion_return_msg";
        };

        get_organization_suggestion_return_msg.Organization = (function() {

            function Organization(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            Organization.prototype.name = "";
            Organization.prototype.inn = "";
            Organization.prototype.kpp = "";
            Organization.prototype.ogrn = "";
            Organization.prototype.legal_address = "";
            Organization.prototype.representative_name = "";
            Organization.prototype.representative_position = "";
            Organization.prototype.organization_type = "";
            Organization.prototype.legal_address_quality = 0;

            Organization.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(10).string(message.name);
                if (message.inn != null && Object.hasOwnProperty.call(message, "inn"))
                    writer.uint32(18).string(message.inn);
                if (message.kpp != null && Object.hasOwnProperty.call(message, "kpp"))
                    writer.uint32(26).string(message.kpp);
                if (message.ogrn != null && Object.hasOwnProperty.call(message, "ogrn"))
                    writer.uint32(34).string(message.ogrn);
                if (message.legal_address != null && Object.hasOwnProperty.call(message, "legal_address"))
                    writer.uint32(42).string(message.legal_address);
                if (message.representative_name != null && Object.hasOwnProperty.call(message, "representative_name"))
                    writer.uint32(50).string(message.representative_name);
                if (message.representative_position != null && Object.hasOwnProperty.call(message, "representative_position"))
                    writer.uint32(58).string(message.representative_position);
                if (message.organization_type != null && Object.hasOwnProperty.call(message, "organization_type"))
                    writer.uint32(66).string(message.organization_type);
                if (message.legal_address_quality != null && Object.hasOwnProperty.call(message, "legal_address_quality"))
                    writer.uint32(72).int32(message.legal_address_quality);
                return writer;
            };

            Organization.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_organization_suggestion_return_msg.Organization();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.inn = reader.string();
                            break;
                        }
                    case 3: {
                            message.kpp = reader.string();
                            break;
                        }
                    case 4: {
                            message.ogrn = reader.string();
                            break;
                        }
                    case 5: {
                            message.legal_address = reader.string();
                            break;
                        }
                    case 6: {
                            message.representative_name = reader.string();
                            break;
                        }
                    case 7: {
                            message.representative_position = reader.string();
                            break;
                        }
                    case 8: {
                            message.organization_type = reader.string();
                            break;
                        }
                    case 9: {
                            message.legal_address_quality = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            Organization.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.get_organization_suggestion_return_msg.Organization)
                    return object;
                let message = new $root.object_msg.get_organization_suggestion_return_msg.Organization();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.inn != null)
                    message.inn = String(object.inn);
                if (object.kpp != null)
                    message.kpp = String(object.kpp);
                if (object.ogrn != null)
                    message.ogrn = String(object.ogrn);
                if (object.legal_address != null)
                    message.legal_address = String(object.legal_address);
                if (object.representative_name != null)
                    message.representative_name = String(object.representative_name);
                if (object.representative_position != null)
                    message.representative_position = String(object.representative_position);
                if (object.organization_type != null)
                    message.organization_type = String(object.organization_type);
                if (object.legal_address_quality != null)
                    message.legal_address_quality = object.legal_address_quality | 0;
                return message;
            };

            Organization.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.inn = "";
                    object.kpp = "";
                    object.ogrn = "";
                    object.legal_address = "";
                    object.representative_name = "";
                    object.representative_position = "";
                    object.organization_type = "";
                    object.legal_address_quality = 0;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.inn != null && message.hasOwnProperty("inn"))
                    object.inn = message.inn;
                if (message.kpp != null && message.hasOwnProperty("kpp"))
                    object.kpp = message.kpp;
                if (message.ogrn != null && message.hasOwnProperty("ogrn"))
                    object.ogrn = message.ogrn;
                if (message.legal_address != null && message.hasOwnProperty("legal_address"))
                    object.legal_address = message.legal_address;
                if (message.representative_name != null && message.hasOwnProperty("representative_name"))
                    object.representative_name = message.representative_name;
                if (message.representative_position != null && message.hasOwnProperty("representative_position"))
                    object.representative_position = message.representative_position;
                if (message.organization_type != null && message.hasOwnProperty("organization_type"))
                    object.organization_type = message.organization_type;
                if (message.legal_address_quality != null && message.hasOwnProperty("legal_address_quality"))
                    object.legal_address_quality = message.legal_address_quality;
                return object;
            };

            Organization.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            Organization.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.get_organization_suggestion_return_msg.Organization";
            };

            return Organization;
        })();

        return get_organization_suggestion_return_msg;
    })();

    object_msg.search_organization_return_msg = (function() {

        function search_organization_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        search_organization_return_msg.prototype.items = $util.emptyArray;

        search_organization_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.object_msg.search_organization_return_msg.ItemWithId.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        search_organization_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_organization_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.object_msg.search_organization_return_msg.ItemWithId.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        search_organization_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.search_organization_return_msg)
                return object;
            let message = new $root.object_msg.search_organization_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.search_organization_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".object_msg.search_organization_return_msg.items: object expected");
                    message.items[i] = $root.object_msg.search_organization_return_msg.ItemWithId.fromObject(object.items[i]);
                }
            }
            return message;
        };

        search_organization_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.object_msg.search_organization_return_msg.ItemWithId.toObject(message.items[j], options);
            }
            return object;
        };

        search_organization_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        search_organization_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.search_organization_return_msg";
        };

        search_organization_return_msg.ItemWithId = (function() {

            function ItemWithId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            ItemWithId.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            ItemWithId.prototype.name = "";
            ItemWithId.prototype.inn = "";
            ItemWithId.prototype.kpp = "";
            ItemWithId.prototype.owner_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            ItemWithId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(8).uint64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(18).string(message.name);
                if (message.inn != null && Object.hasOwnProperty.call(message, "inn"))
                    writer.uint32(26).string(message.inn);
                if (message.kpp != null && Object.hasOwnProperty.call(message, "kpp"))
                    writer.uint32(34).string(message.kpp);
                if (message.owner_id != null && Object.hasOwnProperty.call(message, "owner_id"))
                    writer.uint32(40).uint64(message.owner_id);
                return writer;
            };

            ItemWithId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_organization_return_msg.ItemWithId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.inn = reader.string();
                            break;
                        }
                    case 4: {
                            message.kpp = reader.string();
                            break;
                        }
                    case 5: {
                            message.owner_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            ItemWithId.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.search_organization_return_msg.ItemWithId)
                    return object;
                let message = new $root.object_msg.search_organization_return_msg.ItemWithId();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.inn != null)
                    message.inn = String(object.inn);
                if (object.kpp != null)
                    message.kpp = String(object.kpp);
                if (object.owner_id != null)
                    if ($util.Long)
                        (message.owner_id = $util.Long.fromValue(object.owner_id)).unsigned = true;
                    else if (typeof object.owner_id === "string")
                        message.owner_id = parseInt(object.owner_id, 10);
                    else if (typeof object.owner_id === "number")
                        message.owner_id = object.owner_id;
                    else if (typeof object.owner_id === "object")
                        message.owner_id = new $util.LongBits(object.owner_id.low >>> 0, object.owner_id.high >>> 0).toNumber(true);
                return message;
            };

            ItemWithId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.inn = "";
                    object.kpp = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.owner_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.owner_id = options.longs === String ? "0" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.inn != null && message.hasOwnProperty("inn"))
                    object.inn = message.inn;
                if (message.kpp != null && message.hasOwnProperty("kpp"))
                    object.kpp = message.kpp;
                if (message.owner_id != null && message.hasOwnProperty("owner_id"))
                    if (typeof message.owner_id === "number")
                        object.owner_id = options.longs === String ? String(message.owner_id) : message.owner_id;
                    else
                        object.owner_id = options.longs === String ? $util.Long.prototype.toString.call(message.owner_id) : options.longs === Number ? new $util.LongBits(message.owner_id.low >>> 0, message.owner_id.high >>> 0).toNumber(true) : message.owner_id;
                return object;
            };

            ItemWithId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            ItemWithId.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.search_organization_return_msg.ItemWithId";
            };

            return ItemWithId;
        })();

        return search_organization_return_msg;
    })();

    object_msg.ServiceApiRequest = (function() {

        function ServiceApiRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        ServiceApiRequest.prototype.get_logs_args = null;
        ServiceApiRequest.prototype.get_address_suggestion_args = null;
        ServiceApiRequest.prototype.add_site_to_crawl_queue_args = null;
        ServiceApiRequest.prototype.get_user_profile_info_args = null;

        let $oneOfFields;

        Object.defineProperty(ServiceApiRequest.prototype, "args", {
            get: $util.oneOfGetter($oneOfFields = ["get_logs_args", "get_address_suggestion_args", "add_site_to_crawl_queue_args", "get_user_profile_info_args"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        ServiceApiRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.get_logs_args != null && Object.hasOwnProperty.call(message, "get_logs_args"))
                $root.object_msg.ServiceApiRequest.get_logs.encode(message.get_logs_args, writer.uint32(10).fork()).ldelim();
            if (message.get_address_suggestion_args != null && Object.hasOwnProperty.call(message, "get_address_suggestion_args"))
                $root.object_msg.ServiceApiRequest.get_address_suggestion.encode(message.get_address_suggestion_args, writer.uint32(18).fork()).ldelim();
            if (message.add_site_to_crawl_queue_args != null && Object.hasOwnProperty.call(message, "add_site_to_crawl_queue_args"))
                $root.object_msg.ServiceApiRequest.add_site_to_crawl_queue.encode(message.add_site_to_crawl_queue_args, writer.uint32(26).fork()).ldelim();
            if (message.get_user_profile_info_args != null && Object.hasOwnProperty.call(message, "get_user_profile_info_args"))
                $root.object_msg.ServiceApiRequest.get_user_profile_info.encode(message.get_user_profile_info_args, writer.uint32(34).fork()).ldelim();
            return writer;
        };

        ServiceApiRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.ServiceApiRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.get_logs_args = $root.object_msg.ServiceApiRequest.get_logs.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.get_address_suggestion_args = $root.object_msg.ServiceApiRequest.get_address_suggestion.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.add_site_to_crawl_queue_args = $root.object_msg.ServiceApiRequest.add_site_to_crawl_queue.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.get_user_profile_info_args = $root.object_msg.ServiceApiRequest.get_user_profile_info.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ServiceApiRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.ServiceApiRequest)
                return object;
            let message = new $root.object_msg.ServiceApiRequest();
            if (object.get_logs_args != null) {
                if (typeof object.get_logs_args !== "object")
                    throw TypeError(".object_msg.ServiceApiRequest.get_logs_args: object expected");
                message.get_logs_args = $root.object_msg.ServiceApiRequest.get_logs.fromObject(object.get_logs_args);
            }
            if (object.get_address_suggestion_args != null) {
                if (typeof object.get_address_suggestion_args !== "object")
                    throw TypeError(".object_msg.ServiceApiRequest.get_address_suggestion_args: object expected");
                message.get_address_suggestion_args = $root.object_msg.ServiceApiRequest.get_address_suggestion.fromObject(object.get_address_suggestion_args);
            }
            if (object.add_site_to_crawl_queue_args != null) {
                if (typeof object.add_site_to_crawl_queue_args !== "object")
                    throw TypeError(".object_msg.ServiceApiRequest.add_site_to_crawl_queue_args: object expected");
                message.add_site_to_crawl_queue_args = $root.object_msg.ServiceApiRequest.add_site_to_crawl_queue.fromObject(object.add_site_to_crawl_queue_args);
            }
            if (object.get_user_profile_info_args != null) {
                if (typeof object.get_user_profile_info_args !== "object")
                    throw TypeError(".object_msg.ServiceApiRequest.get_user_profile_info_args: object expected");
                message.get_user_profile_info_args = $root.object_msg.ServiceApiRequest.get_user_profile_info.fromObject(object.get_user_profile_info_args);
            }
            return message;
        };

        ServiceApiRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.get_logs_args != null && message.hasOwnProperty("get_logs_args")) {
                object.get_logs_args = $root.object_msg.ServiceApiRequest.get_logs.toObject(message.get_logs_args, options);
                if (options.oneofs)
                    object.args = "get_logs_args";
            }
            if (message.get_address_suggestion_args != null && message.hasOwnProperty("get_address_suggestion_args")) {
                object.get_address_suggestion_args = $root.object_msg.ServiceApiRequest.get_address_suggestion.toObject(message.get_address_suggestion_args, options);
                if (options.oneofs)
                    object.args = "get_address_suggestion_args";
            }
            if (message.add_site_to_crawl_queue_args != null && message.hasOwnProperty("add_site_to_crawl_queue_args")) {
                object.add_site_to_crawl_queue_args = $root.object_msg.ServiceApiRequest.add_site_to_crawl_queue.toObject(message.add_site_to_crawl_queue_args, options);
                if (options.oneofs)
                    object.args = "add_site_to_crawl_queue_args";
            }
            if (message.get_user_profile_info_args != null && message.hasOwnProperty("get_user_profile_info_args")) {
                object.get_user_profile_info_args = $root.object_msg.ServiceApiRequest.get_user_profile_info.toObject(message.get_user_profile_info_args, options);
                if (options.oneofs)
                    object.args = "get_user_profile_info_args";
            }
            return object;
        };

        ServiceApiRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ServiceApiRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.ServiceApiRequest";
        };

        ServiceApiRequest.Method = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "unknownMethod"] = 0;
            values[valuesById[1] = "getLogs"] = 1;
            values[valuesById[2] = "getAddressSuggestion"] = 2;
            values[valuesById[3] = "addSiteToCrawlQueue"] = 3;
            values[valuesById[4] = "getUserProfileInfo"] = 4;
            values[valuesById[5] = "voidTest"] = 5;
            values[valuesById[6] = "booleanTest"] = 6;
            values[valuesById[7] = "dbSelectTest"] = 7;
            return values;
        })();

        ServiceApiRequest.get_logs = (function() {

            function get_logs(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_logs.prototype.app = "";

            get_logs.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.app != null && Object.hasOwnProperty.call(message, "app"))
                    writer.uint32(10).string(message.app);
                return writer;
            };

            get_logs.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.ServiceApiRequest.get_logs();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.app = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_logs.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.ServiceApiRequest.get_logs)
                    return object;
                let message = new $root.object_msg.ServiceApiRequest.get_logs();
                if (object.app != null)
                    message.app = String(object.app);
                return message;
            };

            get_logs.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.app = "";
                if (message.app != null && message.hasOwnProperty("app"))
                    object.app = message.app;
                return object;
            };

            get_logs.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_logs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.ServiceApiRequest.get_logs";
            };

            return get_logs;
        })();

        ServiceApiRequest.get_address_suggestion = (function() {

            function get_address_suggestion(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_address_suggestion.prototype.input = "";

            get_address_suggestion.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.input != null && Object.hasOwnProperty.call(message, "input"))
                    writer.uint32(10).string(message.input);
                return writer;
            };

            get_address_suggestion.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.ServiceApiRequest.get_address_suggestion();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.input = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_address_suggestion.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.ServiceApiRequest.get_address_suggestion)
                    return object;
                let message = new $root.object_msg.ServiceApiRequest.get_address_suggestion();
                if (object.input != null)
                    message.input = String(object.input);
                return message;
            };

            get_address_suggestion.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.input = "";
                if (message.input != null && message.hasOwnProperty("input"))
                    object.input = message.input;
                return object;
            };

            get_address_suggestion.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_address_suggestion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.ServiceApiRequest.get_address_suggestion";
            };

            return get_address_suggestion;
        })();

        ServiceApiRequest.add_site_to_crawl_queue = (function() {

            function add_site_to_crawl_queue(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            add_site_to_crawl_queue.prototype.url = "";

            add_site_to_crawl_queue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                    writer.uint32(10).string(message.url);
                return writer;
            };

            add_site_to_crawl_queue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.ServiceApiRequest.add_site_to_crawl_queue();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.url = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            add_site_to_crawl_queue.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.ServiceApiRequest.add_site_to_crawl_queue)
                    return object;
                let message = new $root.object_msg.ServiceApiRequest.add_site_to_crawl_queue();
                if (object.url != null)
                    message.url = String(object.url);
                return message;
            };

            add_site_to_crawl_queue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.url = "";
                if (message.url != null && message.hasOwnProperty("url"))
                    object.url = message.url;
                return object;
            };

            add_site_to_crawl_queue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            add_site_to_crawl_queue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.ServiceApiRequest.add_site_to_crawl_queue";
            };

            return add_site_to_crawl_queue;
        })();

        ServiceApiRequest.get_user_profile_info = (function() {

            function get_user_profile_info(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_user_profile_info.prototype.dull_arg = false;

            get_user_profile_info.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.dull_arg != null && Object.hasOwnProperty.call(message, "dull_arg"))
                    writer.uint32(8).bool(message.dull_arg);
                return writer;
            };

            get_user_profile_info.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.ServiceApiRequest.get_user_profile_info();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.dull_arg = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_user_profile_info.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.ServiceApiRequest.get_user_profile_info)
                    return object;
                let message = new $root.object_msg.ServiceApiRequest.get_user_profile_info();
                if (object.dull_arg != null)
                    message.dull_arg = Boolean(object.dull_arg);
                return message;
            };

            get_user_profile_info.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.dull_arg = false;
                if (message.dull_arg != null && message.hasOwnProperty("dull_arg"))
                    object.dull_arg = message.dull_arg;
                return object;
            };

            get_user_profile_info.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_user_profile_info.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.ServiceApiRequest.get_user_profile_info";
            };

            return get_user_profile_info;
        })();

        return ServiceApiRequest;
    })();

    object_msg.get_address_suggestion_return_msg = (function() {

        function get_address_suggestion_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        get_address_suggestion_return_msg.prototype.items = $util.emptyArray;

        get_address_suggestion_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.object_msg.get_address_suggestion_return_msg.Address.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        get_address_suggestion_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_address_suggestion_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.object_msg.get_address_suggestion_return_msg.Address.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        get_address_suggestion_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.get_address_suggestion_return_msg)
                return object;
            let message = new $root.object_msg.get_address_suggestion_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.get_address_suggestion_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".object_msg.get_address_suggestion_return_msg.items: object expected");
                    message.items[i] = $root.object_msg.get_address_suggestion_return_msg.Address.fromObject(object.items[i]);
                }
            }
            return message;
        };

        get_address_suggestion_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.object_msg.get_address_suggestion_return_msg.Address.toObject(message.items[j], options);
            }
            return object;
        };

        get_address_suggestion_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        get_address_suggestion_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.get_address_suggestion_return_msg";
        };

        get_address_suggestion_return_msg.Address = (function() {

            function Address(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            Address.prototype.address = "";
            Address.prototype.town = "";
            Address.prototype.latitude = 0;
            Address.prototype.longitude = 0;
            Address.prototype.address_quality = 0;

            Address.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(10).string(message.address);
                if (message.town != null && Object.hasOwnProperty.call(message, "town"))
                    writer.uint32(18).string(message.town);
                if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                    writer.uint32(29).float(message.latitude);
                if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                    writer.uint32(37).float(message.longitude);
                if (message.address_quality != null && Object.hasOwnProperty.call(message, "address_quality"))
                    writer.uint32(40).int32(message.address_quality);
                return writer;
            };

            Address.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_address_suggestion_return_msg.Address();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.address = reader.string();
                            break;
                        }
                    case 2: {
                            message.town = reader.string();
                            break;
                        }
                    case 3: {
                            message.latitude = reader.float();
                            break;
                        }
                    case 4: {
                            message.longitude = reader.float();
                            break;
                        }
                    case 5: {
                            message.address_quality = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            Address.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.get_address_suggestion_return_msg.Address)
                    return object;
                let message = new $root.object_msg.get_address_suggestion_return_msg.Address();
                if (object.address != null)
                    message.address = String(object.address);
                if (object.town != null)
                    message.town = String(object.town);
                if (object.latitude != null)
                    message.latitude = Number(object.latitude);
                if (object.longitude != null)
                    message.longitude = Number(object.longitude);
                if (object.address_quality != null)
                    message.address_quality = object.address_quality | 0;
                return message;
            };

            Address.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.address = "";
                    object.town = "";
                    object.latitude = 0;
                    object.longitude = 0;
                    object.address_quality = 0;
                }
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                if (message.town != null && message.hasOwnProperty("town"))
                    object.town = message.town;
                if (message.latitude != null && message.hasOwnProperty("latitude"))
                    object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
                if (message.longitude != null && message.hasOwnProperty("longitude"))
                    object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
                if (message.address_quality != null && message.hasOwnProperty("address_quality"))
                    object.address_quality = message.address_quality;
                return object;
            };

            Address.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            Address.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.get_address_suggestion_return_msg.Address";
            };

            return Address;
        })();

        return get_address_suggestion_return_msg;
    })();

    object_msg.get_user_profile_info_return_msg = (function() {

        function get_user_profile_info_return_msg(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        get_user_profile_info_return_msg.prototype.email = "";
        get_user_profile_info_return_msg.prototype.name = "";
        get_user_profile_info_return_msg.prototype.surname = "";
        get_user_profile_info_return_msg.prototype.patronymic = "";
        get_user_profile_info_return_msg.prototype.communication_type = 0;

        get_user_profile_info_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(10).string(message.email);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(18).string(message.name);
            if (message.surname != null && Object.hasOwnProperty.call(message, "surname"))
                writer.uint32(26).string(message.surname);
            if (message.patronymic != null && Object.hasOwnProperty.call(message, "patronymic"))
                writer.uint32(34).string(message.patronymic);
            if (message.communication_type != null && Object.hasOwnProperty.call(message, "communication_type"))
                writer.uint32(40).int32(message.communication_type);
            return writer;
        };

        get_user_profile_info_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_user_profile_info_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.email = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.surname = reader.string();
                        break;
                    }
                case 4: {
                        message.patronymic = reader.string();
                        break;
                    }
                case 5: {
                        message.communication_type = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        get_user_profile_info_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.get_user_profile_info_return_msg)
                return object;
            let message = new $root.object_msg.get_user_profile_info_return_msg();
            if (object.email != null)
                message.email = String(object.email);
            if (object.name != null)
                message.name = String(object.name);
            if (object.surname != null)
                message.surname = String(object.surname);
            if (object.patronymic != null)
                message.patronymic = String(object.patronymic);
            if (object.communication_type != null)
                message.communication_type = object.communication_type | 0;
            return message;
        };

        get_user_profile_info_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.email = "";
                object.name = "";
                object.surname = "";
                object.patronymic = "";
                object.communication_type = 0;
            }
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.surname != null && message.hasOwnProperty("surname"))
                object.surname = message.surname;
            if (message.patronymic != null && message.hasOwnProperty("patronymic"))
                object.patronymic = message.patronymic;
            if (message.communication_type != null && message.hasOwnProperty("communication_type"))
                object.communication_type = message.communication_type;
            return object;
        };

        get_user_profile_info_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        get_user_profile_info_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.get_user_profile_info_return_msg";
        };

        return get_user_profile_info_return_msg;
    })();

    object_msg.OrdersApiRequest = (function() {

        function OrdersApiRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrdersApiRequest.prototype.update_order_by_manager_args = null;
        OrdersApiRequest.prototype.update_order_status_by_manger_args = null;
        OrdersApiRequest.prototype.approve_stage_args = null;
        OrdersApiRequest.prototype.reload_order_stages_args = null;
        OrdersApiRequest.prototype.update_delivery_profiles_by_manager_args = null;
        OrdersApiRequest.prototype.reload_delivery_profiles_args = null;
        OrdersApiRequest.prototype.update_order_stage_status_by_manger_args = null;
        OrdersApiRequest.prototype.update_stage_delivery_profiles_by_manager_args = null;
        OrdersApiRequest.prototype.select_order_args = null;
        OrdersApiRequest.prototype.search_orders_args = null;
        OrdersApiRequest.prototype.get_stage_action_approximate_price_args = null;
        OrdersApiRequest.prototype.get_action_approximate_prices_for_manager_args = null;
        OrdersApiRequest.prototype.get_validation_code_args = null;
        OrdersApiRequest.prototype.get_or_create_user_args = null;
        OrdersApiRequest.prototype.add_new_owner_to_stage_place_args = null;
        OrdersApiRequest.prototype.get_last_organization_used_for_payment_args = null;
        OrdersApiRequest.prototype.get_upload_url_args = null;
        OrdersApiRequest.prototype.get_download_url_args = null;
        OrdersApiRequest.prototype.request_commercial_offer_for_order_args = null;
        OrdersApiRequest.prototype.set_commercial_offer_reply_time_args = null;
        OrdersApiRequest.prototype.reload_commercial_offer_reply_offers_args = null;
        OrdersApiRequest.prototype.verify_entities_on_successfull_outgoing_payment_args = null;
        OrdersApiRequest.prototype.verify_entities_on_successfull_incoming_payment_args = null;

        let $oneOfFields;

        Object.defineProperty(OrdersApiRequest.prototype, "args", {
            get: $util.oneOfGetter($oneOfFields = ["update_order_by_manager_args", "update_order_status_by_manger_args", "approve_stage_args", "reload_order_stages_args", "update_delivery_profiles_by_manager_args", "reload_delivery_profiles_args", "update_order_stage_status_by_manger_args", "update_stage_delivery_profiles_by_manager_args", "select_order_args", "search_orders_args", "get_stage_action_approximate_price_args", "get_action_approximate_prices_for_manager_args", "get_validation_code_args", "get_or_create_user_args", "add_new_owner_to_stage_place_args", "get_last_organization_used_for_payment_args", "get_upload_url_args", "get_download_url_args", "request_commercial_offer_for_order_args", "set_commercial_offer_reply_time_args", "reload_commercial_offer_reply_offers_args", "verify_entities_on_successfull_outgoing_payment_args", "verify_entities_on_successfull_incoming_payment_args"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        OrdersApiRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.update_order_by_manager_args != null && Object.hasOwnProperty.call(message, "update_order_by_manager_args"))
                $root.object_msg.OrdersApiRequest.update_order_by_manager.encode(message.update_order_by_manager_args, writer.uint32(10).fork()).ldelim();
            if (message.update_order_status_by_manger_args != null && Object.hasOwnProperty.call(message, "update_order_status_by_manger_args"))
                $root.object_msg.OrdersApiRequest.update_order_status_by_manger.encode(message.update_order_status_by_manger_args, writer.uint32(18).fork()).ldelim();
            if (message.approve_stage_args != null && Object.hasOwnProperty.call(message, "approve_stage_args"))
                $root.object_msg.OrdersApiRequest.approve_stage.encode(message.approve_stage_args, writer.uint32(26).fork()).ldelim();
            if (message.reload_order_stages_args != null && Object.hasOwnProperty.call(message, "reload_order_stages_args"))
                $root.object_msg.OrdersApiRequest.reload_order_stages.encode(message.reload_order_stages_args, writer.uint32(34).fork()).ldelim();
            if (message.update_delivery_profiles_by_manager_args != null && Object.hasOwnProperty.call(message, "update_delivery_profiles_by_manager_args"))
                $root.object_msg.OrdersApiRequest.update_delivery_profiles_by_manager.encode(message.update_delivery_profiles_by_manager_args, writer.uint32(42).fork()).ldelim();
            if (message.reload_delivery_profiles_args != null && Object.hasOwnProperty.call(message, "reload_delivery_profiles_args"))
                $root.object_msg.OrdersApiRequest.reload_delivery_profiles.encode(message.reload_delivery_profiles_args, writer.uint32(50).fork()).ldelim();
            if (message.update_order_stage_status_by_manger_args != null && Object.hasOwnProperty.call(message, "update_order_stage_status_by_manger_args"))
                $root.object_msg.OrdersApiRequest.update_order_stage_status_by_manger.encode(message.update_order_stage_status_by_manger_args, writer.uint32(58).fork()).ldelim();
            if (message.update_stage_delivery_profiles_by_manager_args != null && Object.hasOwnProperty.call(message, "update_stage_delivery_profiles_by_manager_args"))
                $root.object_msg.OrdersApiRequest.update_stage_delivery_profiles_by_manager.encode(message.update_stage_delivery_profiles_by_manager_args, writer.uint32(66).fork()).ldelim();
            if (message.select_order_args != null && Object.hasOwnProperty.call(message, "select_order_args"))
                $root.object_msg.OrdersApiRequest.select_order.encode(message.select_order_args, writer.uint32(74).fork()).ldelim();
            if (message.search_orders_args != null && Object.hasOwnProperty.call(message, "search_orders_args"))
                $root.object_msg.OrdersApiRequest.search_orders.encode(message.search_orders_args, writer.uint32(82).fork()).ldelim();
            if (message.get_stage_action_approximate_price_args != null && Object.hasOwnProperty.call(message, "get_stage_action_approximate_price_args"))
                $root.object_msg.OrdersApiRequest.get_stage_action_approximate_price.encode(message.get_stage_action_approximate_price_args, writer.uint32(90).fork()).ldelim();
            if (message.get_action_approximate_prices_for_manager_args != null && Object.hasOwnProperty.call(message, "get_action_approximate_prices_for_manager_args"))
                $root.object_msg.OrdersApiRequest.get_action_approximate_prices_for_manager.encode(message.get_action_approximate_prices_for_manager_args, writer.uint32(98).fork()).ldelim();
            if (message.get_validation_code_args != null && Object.hasOwnProperty.call(message, "get_validation_code_args"))
                $root.object_msg.OrdersApiRequest.get_validation_code.encode(message.get_validation_code_args, writer.uint32(106).fork()).ldelim();
            if (message.get_or_create_user_args != null && Object.hasOwnProperty.call(message, "get_or_create_user_args"))
                $root.object_msg.OrdersApiRequest.get_or_create_user.encode(message.get_or_create_user_args, writer.uint32(114).fork()).ldelim();
            if (message.add_new_owner_to_stage_place_args != null && Object.hasOwnProperty.call(message, "add_new_owner_to_stage_place_args"))
                $root.object_msg.OrdersApiRequest.add_new_owner_to_stage_place.encode(message.add_new_owner_to_stage_place_args, writer.uint32(122).fork()).ldelim();
            if (message.get_last_organization_used_for_payment_args != null && Object.hasOwnProperty.call(message, "get_last_organization_used_for_payment_args"))
                $root.object_msg.OrdersApiRequest.get_last_organization_used_for_payment.encode(message.get_last_organization_used_for_payment_args, writer.uint32(130).fork()).ldelim();
            if (message.get_upload_url_args != null && Object.hasOwnProperty.call(message, "get_upload_url_args"))
                $root.object_msg.OrdersApiRequest.get_upload_url.encode(message.get_upload_url_args, writer.uint32(138).fork()).ldelim();
            if (message.get_download_url_args != null && Object.hasOwnProperty.call(message, "get_download_url_args"))
                $root.object_msg.OrdersApiRequest.get_download_url.encode(message.get_download_url_args, writer.uint32(146).fork()).ldelim();
            if (message.request_commercial_offer_for_order_args != null && Object.hasOwnProperty.call(message, "request_commercial_offer_for_order_args"))
                $root.object_msg.OrdersApiRequest.request_commercial_offer_for_order.encode(message.request_commercial_offer_for_order_args, writer.uint32(154).fork()).ldelim();
            if (message.set_commercial_offer_reply_time_args != null && Object.hasOwnProperty.call(message, "set_commercial_offer_reply_time_args"))
                $root.object_msg.OrdersApiRequest.set_commercial_offer_reply_time.encode(message.set_commercial_offer_reply_time_args, writer.uint32(162).fork()).ldelim();
            if (message.reload_commercial_offer_reply_offers_args != null && Object.hasOwnProperty.call(message, "reload_commercial_offer_reply_offers_args"))
                $root.object_msg.OrdersApiRequest.reload_commercial_offer_reply_offers.encode(message.reload_commercial_offer_reply_offers_args, writer.uint32(170).fork()).ldelim();
            if (message.verify_entities_on_successfull_outgoing_payment_args != null && Object.hasOwnProperty.call(message, "verify_entities_on_successfull_outgoing_payment_args"))
                $root.object_msg.OrdersApiRequest.verify_entities_on_successfull_outgoing_payment.encode(message.verify_entities_on_successfull_outgoing_payment_args, writer.uint32(178).fork()).ldelim();
            if (message.verify_entities_on_successfull_incoming_payment_args != null && Object.hasOwnProperty.call(message, "verify_entities_on_successfull_incoming_payment_args"))
                $root.object_msg.OrdersApiRequest.verify_entities_on_successfull_incoming_payment.encode(message.verify_entities_on_successfull_incoming_payment_args, writer.uint32(186).fork()).ldelim();
            return writer;
        };

        OrdersApiRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.update_order_by_manager_args = $root.object_msg.OrdersApiRequest.update_order_by_manager.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.update_order_status_by_manger_args = $root.object_msg.OrdersApiRequest.update_order_status_by_manger.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.approve_stage_args = $root.object_msg.OrdersApiRequest.approve_stage.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.reload_order_stages_args = $root.object_msg.OrdersApiRequest.reload_order_stages.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.update_delivery_profiles_by_manager_args = $root.object_msg.OrdersApiRequest.update_delivery_profiles_by_manager.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.reload_delivery_profiles_args = $root.object_msg.OrdersApiRequest.reload_delivery_profiles.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.update_order_stage_status_by_manger_args = $root.object_msg.OrdersApiRequest.update_order_stage_status_by_manger.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.update_stage_delivery_profiles_by_manager_args = $root.object_msg.OrdersApiRequest.update_stage_delivery_profiles_by_manager.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.select_order_args = $root.object_msg.OrdersApiRequest.select_order.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.search_orders_args = $root.object_msg.OrdersApiRequest.search_orders.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.get_stage_action_approximate_price_args = $root.object_msg.OrdersApiRequest.get_stage_action_approximate_price.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.get_action_approximate_prices_for_manager_args = $root.object_msg.OrdersApiRequest.get_action_approximate_prices_for_manager.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        message.get_validation_code_args = $root.object_msg.OrdersApiRequest.get_validation_code.decode(reader, reader.uint32());
                        break;
                    }
                case 14: {
                        message.get_or_create_user_args = $root.object_msg.OrdersApiRequest.get_or_create_user.decode(reader, reader.uint32());
                        break;
                    }
                case 15: {
                        message.add_new_owner_to_stage_place_args = $root.object_msg.OrdersApiRequest.add_new_owner_to_stage_place.decode(reader, reader.uint32());
                        break;
                    }
                case 16: {
                        message.get_last_organization_used_for_payment_args = $root.object_msg.OrdersApiRequest.get_last_organization_used_for_payment.decode(reader, reader.uint32());
                        break;
                    }
                case 17: {
                        message.get_upload_url_args = $root.object_msg.OrdersApiRequest.get_upload_url.decode(reader, reader.uint32());
                        break;
                    }
                case 18: {
                        message.get_download_url_args = $root.object_msg.OrdersApiRequest.get_download_url.decode(reader, reader.uint32());
                        break;
                    }
                case 19: {
                        message.request_commercial_offer_for_order_args = $root.object_msg.OrdersApiRequest.request_commercial_offer_for_order.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.set_commercial_offer_reply_time_args = $root.object_msg.OrdersApiRequest.set_commercial_offer_reply_time.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        message.reload_commercial_offer_reply_offers_args = $root.object_msg.OrdersApiRequest.reload_commercial_offer_reply_offers.decode(reader, reader.uint32());
                        break;
                    }
                case 22: {
                        message.verify_entities_on_successfull_outgoing_payment_args = $root.object_msg.OrdersApiRequest.verify_entities_on_successfull_outgoing_payment.decode(reader, reader.uint32());
                        break;
                    }
                case 23: {
                        message.verify_entities_on_successfull_incoming_payment_args = $root.object_msg.OrdersApiRequest.verify_entities_on_successfull_incoming_payment.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrdersApiRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrdersApiRequest)
                return object;
            let message = new $root.object_msg.OrdersApiRequest();
            if (object.update_order_by_manager_args != null) {
                if (typeof object.update_order_by_manager_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.update_order_by_manager_args: object expected");
                message.update_order_by_manager_args = $root.object_msg.OrdersApiRequest.update_order_by_manager.fromObject(object.update_order_by_manager_args);
            }
            if (object.update_order_status_by_manger_args != null) {
                if (typeof object.update_order_status_by_manger_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.update_order_status_by_manger_args: object expected");
                message.update_order_status_by_manger_args = $root.object_msg.OrdersApiRequest.update_order_status_by_manger.fromObject(object.update_order_status_by_manger_args);
            }
            if (object.approve_stage_args != null) {
                if (typeof object.approve_stage_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.approve_stage_args: object expected");
                message.approve_stage_args = $root.object_msg.OrdersApiRequest.approve_stage.fromObject(object.approve_stage_args);
            }
            if (object.reload_order_stages_args != null) {
                if (typeof object.reload_order_stages_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.reload_order_stages_args: object expected");
                message.reload_order_stages_args = $root.object_msg.OrdersApiRequest.reload_order_stages.fromObject(object.reload_order_stages_args);
            }
            if (object.update_delivery_profiles_by_manager_args != null) {
                if (typeof object.update_delivery_profiles_by_manager_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.update_delivery_profiles_by_manager_args: object expected");
                message.update_delivery_profiles_by_manager_args = $root.object_msg.OrdersApiRequest.update_delivery_profiles_by_manager.fromObject(object.update_delivery_profiles_by_manager_args);
            }
            if (object.reload_delivery_profiles_args != null) {
                if (typeof object.reload_delivery_profiles_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.reload_delivery_profiles_args: object expected");
                message.reload_delivery_profiles_args = $root.object_msg.OrdersApiRequest.reload_delivery_profiles.fromObject(object.reload_delivery_profiles_args);
            }
            if (object.update_order_stage_status_by_manger_args != null) {
                if (typeof object.update_order_stage_status_by_manger_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.update_order_stage_status_by_manger_args: object expected");
                message.update_order_stage_status_by_manger_args = $root.object_msg.OrdersApiRequest.update_order_stage_status_by_manger.fromObject(object.update_order_stage_status_by_manger_args);
            }
            if (object.update_stage_delivery_profiles_by_manager_args != null) {
                if (typeof object.update_stage_delivery_profiles_by_manager_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.update_stage_delivery_profiles_by_manager_args: object expected");
                message.update_stage_delivery_profiles_by_manager_args = $root.object_msg.OrdersApiRequest.update_stage_delivery_profiles_by_manager.fromObject(object.update_stage_delivery_profiles_by_manager_args);
            }
            if (object.select_order_args != null) {
                if (typeof object.select_order_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.select_order_args: object expected");
                message.select_order_args = $root.object_msg.OrdersApiRequest.select_order.fromObject(object.select_order_args);
            }
            if (object.search_orders_args != null) {
                if (typeof object.search_orders_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.search_orders_args: object expected");
                message.search_orders_args = $root.object_msg.OrdersApiRequest.search_orders.fromObject(object.search_orders_args);
            }
            if (object.get_stage_action_approximate_price_args != null) {
                if (typeof object.get_stage_action_approximate_price_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.get_stage_action_approximate_price_args: object expected");
                message.get_stage_action_approximate_price_args = $root.object_msg.OrdersApiRequest.get_stage_action_approximate_price.fromObject(object.get_stage_action_approximate_price_args);
            }
            if (object.get_action_approximate_prices_for_manager_args != null) {
                if (typeof object.get_action_approximate_prices_for_manager_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.get_action_approximate_prices_for_manager_args: object expected");
                message.get_action_approximate_prices_for_manager_args = $root.object_msg.OrdersApiRequest.get_action_approximate_prices_for_manager.fromObject(object.get_action_approximate_prices_for_manager_args);
            }
            if (object.get_validation_code_args != null) {
                if (typeof object.get_validation_code_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.get_validation_code_args: object expected");
                message.get_validation_code_args = $root.object_msg.OrdersApiRequest.get_validation_code.fromObject(object.get_validation_code_args);
            }
            if (object.get_or_create_user_args != null) {
                if (typeof object.get_or_create_user_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.get_or_create_user_args: object expected");
                message.get_or_create_user_args = $root.object_msg.OrdersApiRequest.get_or_create_user.fromObject(object.get_or_create_user_args);
            }
            if (object.add_new_owner_to_stage_place_args != null) {
                if (typeof object.add_new_owner_to_stage_place_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.add_new_owner_to_stage_place_args: object expected");
                message.add_new_owner_to_stage_place_args = $root.object_msg.OrdersApiRequest.add_new_owner_to_stage_place.fromObject(object.add_new_owner_to_stage_place_args);
            }
            if (object.get_last_organization_used_for_payment_args != null) {
                if (typeof object.get_last_organization_used_for_payment_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.get_last_organization_used_for_payment_args: object expected");
                message.get_last_organization_used_for_payment_args = $root.object_msg.OrdersApiRequest.get_last_organization_used_for_payment.fromObject(object.get_last_organization_used_for_payment_args);
            }
            if (object.get_upload_url_args != null) {
                if (typeof object.get_upload_url_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.get_upload_url_args: object expected");
                message.get_upload_url_args = $root.object_msg.OrdersApiRequest.get_upload_url.fromObject(object.get_upload_url_args);
            }
            if (object.get_download_url_args != null) {
                if (typeof object.get_download_url_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.get_download_url_args: object expected");
                message.get_download_url_args = $root.object_msg.OrdersApiRequest.get_download_url.fromObject(object.get_download_url_args);
            }
            if (object.request_commercial_offer_for_order_args != null) {
                if (typeof object.request_commercial_offer_for_order_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.request_commercial_offer_for_order_args: object expected");
                message.request_commercial_offer_for_order_args = $root.object_msg.OrdersApiRequest.request_commercial_offer_for_order.fromObject(object.request_commercial_offer_for_order_args);
            }
            if (object.set_commercial_offer_reply_time_args != null) {
                if (typeof object.set_commercial_offer_reply_time_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.set_commercial_offer_reply_time_args: object expected");
                message.set_commercial_offer_reply_time_args = $root.object_msg.OrdersApiRequest.set_commercial_offer_reply_time.fromObject(object.set_commercial_offer_reply_time_args);
            }
            if (object.reload_commercial_offer_reply_offers_args != null) {
                if (typeof object.reload_commercial_offer_reply_offers_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.reload_commercial_offer_reply_offers_args: object expected");
                message.reload_commercial_offer_reply_offers_args = $root.object_msg.OrdersApiRequest.reload_commercial_offer_reply_offers.fromObject(object.reload_commercial_offer_reply_offers_args);
            }
            if (object.verify_entities_on_successfull_outgoing_payment_args != null) {
                if (typeof object.verify_entities_on_successfull_outgoing_payment_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.verify_entities_on_successfull_outgoing_payment_args: object expected");
                message.verify_entities_on_successfull_outgoing_payment_args = $root.object_msg.OrdersApiRequest.verify_entities_on_successfull_outgoing_payment.fromObject(object.verify_entities_on_successfull_outgoing_payment_args);
            }
            if (object.verify_entities_on_successfull_incoming_payment_args != null) {
                if (typeof object.verify_entities_on_successfull_incoming_payment_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.verify_entities_on_successfull_incoming_payment_args: object expected");
                message.verify_entities_on_successfull_incoming_payment_args = $root.object_msg.OrdersApiRequest.verify_entities_on_successfull_incoming_payment.fromObject(object.verify_entities_on_successfull_incoming_payment_args);
            }
            return message;
        };

        OrdersApiRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.update_order_by_manager_args != null && message.hasOwnProperty("update_order_by_manager_args")) {
                object.update_order_by_manager_args = $root.object_msg.OrdersApiRequest.update_order_by_manager.toObject(message.update_order_by_manager_args, options);
                if (options.oneofs)
                    object.args = "update_order_by_manager_args";
            }
            if (message.update_order_status_by_manger_args != null && message.hasOwnProperty("update_order_status_by_manger_args")) {
                object.update_order_status_by_manger_args = $root.object_msg.OrdersApiRequest.update_order_status_by_manger.toObject(message.update_order_status_by_manger_args, options);
                if (options.oneofs)
                    object.args = "update_order_status_by_manger_args";
            }
            if (message.approve_stage_args != null && message.hasOwnProperty("approve_stage_args")) {
                object.approve_stage_args = $root.object_msg.OrdersApiRequest.approve_stage.toObject(message.approve_stage_args, options);
                if (options.oneofs)
                    object.args = "approve_stage_args";
            }
            if (message.reload_order_stages_args != null && message.hasOwnProperty("reload_order_stages_args")) {
                object.reload_order_stages_args = $root.object_msg.OrdersApiRequest.reload_order_stages.toObject(message.reload_order_stages_args, options);
                if (options.oneofs)
                    object.args = "reload_order_stages_args";
            }
            if (message.update_delivery_profiles_by_manager_args != null && message.hasOwnProperty("update_delivery_profiles_by_manager_args")) {
                object.update_delivery_profiles_by_manager_args = $root.object_msg.OrdersApiRequest.update_delivery_profiles_by_manager.toObject(message.update_delivery_profiles_by_manager_args, options);
                if (options.oneofs)
                    object.args = "update_delivery_profiles_by_manager_args";
            }
            if (message.reload_delivery_profiles_args != null && message.hasOwnProperty("reload_delivery_profiles_args")) {
                object.reload_delivery_profiles_args = $root.object_msg.OrdersApiRequest.reload_delivery_profiles.toObject(message.reload_delivery_profiles_args, options);
                if (options.oneofs)
                    object.args = "reload_delivery_profiles_args";
            }
            if (message.update_order_stage_status_by_manger_args != null && message.hasOwnProperty("update_order_stage_status_by_manger_args")) {
                object.update_order_stage_status_by_manger_args = $root.object_msg.OrdersApiRequest.update_order_stage_status_by_manger.toObject(message.update_order_stage_status_by_manger_args, options);
                if (options.oneofs)
                    object.args = "update_order_stage_status_by_manger_args";
            }
            if (message.update_stage_delivery_profiles_by_manager_args != null && message.hasOwnProperty("update_stage_delivery_profiles_by_manager_args")) {
                object.update_stage_delivery_profiles_by_manager_args = $root.object_msg.OrdersApiRequest.update_stage_delivery_profiles_by_manager.toObject(message.update_stage_delivery_profiles_by_manager_args, options);
                if (options.oneofs)
                    object.args = "update_stage_delivery_profiles_by_manager_args";
            }
            if (message.select_order_args != null && message.hasOwnProperty("select_order_args")) {
                object.select_order_args = $root.object_msg.OrdersApiRequest.select_order.toObject(message.select_order_args, options);
                if (options.oneofs)
                    object.args = "select_order_args";
            }
            if (message.search_orders_args != null && message.hasOwnProperty("search_orders_args")) {
                object.search_orders_args = $root.object_msg.OrdersApiRequest.search_orders.toObject(message.search_orders_args, options);
                if (options.oneofs)
                    object.args = "search_orders_args";
            }
            if (message.get_stage_action_approximate_price_args != null && message.hasOwnProperty("get_stage_action_approximate_price_args")) {
                object.get_stage_action_approximate_price_args = $root.object_msg.OrdersApiRequest.get_stage_action_approximate_price.toObject(message.get_stage_action_approximate_price_args, options);
                if (options.oneofs)
                    object.args = "get_stage_action_approximate_price_args";
            }
            if (message.get_action_approximate_prices_for_manager_args != null && message.hasOwnProperty("get_action_approximate_prices_for_manager_args")) {
                object.get_action_approximate_prices_for_manager_args = $root.object_msg.OrdersApiRequest.get_action_approximate_prices_for_manager.toObject(message.get_action_approximate_prices_for_manager_args, options);
                if (options.oneofs)
                    object.args = "get_action_approximate_prices_for_manager_args";
            }
            if (message.get_validation_code_args != null && message.hasOwnProperty("get_validation_code_args")) {
                object.get_validation_code_args = $root.object_msg.OrdersApiRequest.get_validation_code.toObject(message.get_validation_code_args, options);
                if (options.oneofs)
                    object.args = "get_validation_code_args";
            }
            if (message.get_or_create_user_args != null && message.hasOwnProperty("get_or_create_user_args")) {
                object.get_or_create_user_args = $root.object_msg.OrdersApiRequest.get_or_create_user.toObject(message.get_or_create_user_args, options);
                if (options.oneofs)
                    object.args = "get_or_create_user_args";
            }
            if (message.add_new_owner_to_stage_place_args != null && message.hasOwnProperty("add_new_owner_to_stage_place_args")) {
                object.add_new_owner_to_stage_place_args = $root.object_msg.OrdersApiRequest.add_new_owner_to_stage_place.toObject(message.add_new_owner_to_stage_place_args, options);
                if (options.oneofs)
                    object.args = "add_new_owner_to_stage_place_args";
            }
            if (message.get_last_organization_used_for_payment_args != null && message.hasOwnProperty("get_last_organization_used_for_payment_args")) {
                object.get_last_organization_used_for_payment_args = $root.object_msg.OrdersApiRequest.get_last_organization_used_for_payment.toObject(message.get_last_organization_used_for_payment_args, options);
                if (options.oneofs)
                    object.args = "get_last_organization_used_for_payment_args";
            }
            if (message.get_upload_url_args != null && message.hasOwnProperty("get_upload_url_args")) {
                object.get_upload_url_args = $root.object_msg.OrdersApiRequest.get_upload_url.toObject(message.get_upload_url_args, options);
                if (options.oneofs)
                    object.args = "get_upload_url_args";
            }
            if (message.get_download_url_args != null && message.hasOwnProperty("get_download_url_args")) {
                object.get_download_url_args = $root.object_msg.OrdersApiRequest.get_download_url.toObject(message.get_download_url_args, options);
                if (options.oneofs)
                    object.args = "get_download_url_args";
            }
            if (message.request_commercial_offer_for_order_args != null && message.hasOwnProperty("request_commercial_offer_for_order_args")) {
                object.request_commercial_offer_for_order_args = $root.object_msg.OrdersApiRequest.request_commercial_offer_for_order.toObject(message.request_commercial_offer_for_order_args, options);
                if (options.oneofs)
                    object.args = "request_commercial_offer_for_order_args";
            }
            if (message.set_commercial_offer_reply_time_args != null && message.hasOwnProperty("set_commercial_offer_reply_time_args")) {
                object.set_commercial_offer_reply_time_args = $root.object_msg.OrdersApiRequest.set_commercial_offer_reply_time.toObject(message.set_commercial_offer_reply_time_args, options);
                if (options.oneofs)
                    object.args = "set_commercial_offer_reply_time_args";
            }
            if (message.reload_commercial_offer_reply_offers_args != null && message.hasOwnProperty("reload_commercial_offer_reply_offers_args")) {
                object.reload_commercial_offer_reply_offers_args = $root.object_msg.OrdersApiRequest.reload_commercial_offer_reply_offers.toObject(message.reload_commercial_offer_reply_offers_args, options);
                if (options.oneofs)
                    object.args = "reload_commercial_offer_reply_offers_args";
            }
            if (message.verify_entities_on_successfull_outgoing_payment_args != null && message.hasOwnProperty("verify_entities_on_successfull_outgoing_payment_args")) {
                object.verify_entities_on_successfull_outgoing_payment_args = $root.object_msg.OrdersApiRequest.verify_entities_on_successfull_outgoing_payment.toObject(message.verify_entities_on_successfull_outgoing_payment_args, options);
                if (options.oneofs)
                    object.args = "verify_entities_on_successfull_outgoing_payment_args";
            }
            if (message.verify_entities_on_successfull_incoming_payment_args != null && message.hasOwnProperty("verify_entities_on_successfull_incoming_payment_args")) {
                object.verify_entities_on_successfull_incoming_payment_args = $root.object_msg.OrdersApiRequest.verify_entities_on_successfull_incoming_payment.toObject(message.verify_entities_on_successfull_incoming_payment_args, options);
                if (options.oneofs)
                    object.args = "verify_entities_on_successfull_incoming_payment_args";
            }
            return object;
        };

        OrdersApiRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrdersApiRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrdersApiRequest";
        };

        OrdersApiRequest.Method = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "unknownMethod"] = 0;
            values[valuesById[1] = "updateOrderByManager"] = 1;
            values[valuesById[2] = "updateOrderStatusByManger"] = 2;
            values[valuesById[3] = "approveStage"] = 3;
            values[valuesById[4] = "reloadOrderStages"] = 4;
            values[valuesById[5] = "updateDeliveryProfilesByManager"] = 5;
            values[valuesById[6] = "reloadDeliveryProfiles"] = 6;
            values[valuesById[7] = "updateOrderStageStatusByManger"] = 7;
            values[valuesById[8] = "updateStageDeliveryProfilesByManager"] = 8;
            values[valuesById[9] = "selectOrder"] = 9;
            values[valuesById[10] = "searchOrders"] = 10;
            values[valuesById[11] = "getStageActionApproximatePrice"] = 11;
            values[valuesById[12] = "getActionApproximatePricesForManager"] = 12;
            values[valuesById[13] = "getValidationCode"] = 13;
            values[valuesById[14] = "getOrCreateUser"] = 14;
            values[valuesById[15] = "addNewOwnerToStagePlace"] = 15;
            values[valuesById[16] = "getLastOrganizationUsedForPayment"] = 16;
            values[valuesById[17] = "getUploadUrl"] = 17;
            values[valuesById[18] = "getDownloadUrl"] = 18;
            values[valuesById[19] = "requestCommercialOfferForOrder"] = 19;
            values[valuesById[20] = "setCommercialOfferReplyTime"] = 20;
            values[valuesById[21] = "reloadCommercialOfferReplyOffers"] = 21;
            values[valuesById[22] = "verifyEntitiesOnSuccessfullOutgoingPayment"] = 22;
            values[valuesById[23] = "verifyEntitiesOnSuccessfullIncomingPayment"] = 23;
            return values;
        })();

        OrdersApiRequest.update_order_by_manager = (function() {

            function update_order_by_manager(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            update_order_by_manager.prototype.orders = $util.newBuffer([]);
            update_order_by_manager.prototype.order_comments = $util.newBuffer([]);
            update_order_by_manager.prototype.order_comment_files = $util.newBuffer([]);
            update_order_by_manager.prototype.order_stages = $util.newBuffer([]);
            update_order_by_manager.prototype.order_stage_comments = $util.newBuffer([]);
            update_order_by_manager.prototype.order_stage_comment_files = $util.newBuffer([]);
            update_order_by_manager.prototype.laser_cutting_stages = $util.newBuffer([]);
            update_order_by_manager.prototype.laser_engraving_stages = $util.newBuffer([]);
            update_order_by_manager.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            update_order_by_manager.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            update_order_by_manager.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orders != null && Object.hasOwnProperty.call(message, "orders"))
                    writer.uint32(10).bytes(message.orders);
                if (message.order_comments != null && Object.hasOwnProperty.call(message, "order_comments"))
                    writer.uint32(18).bytes(message.order_comments);
                if (message.order_comment_files != null && Object.hasOwnProperty.call(message, "order_comment_files"))
                    writer.uint32(26).bytes(message.order_comment_files);
                if (message.order_stages != null && Object.hasOwnProperty.call(message, "order_stages"))
                    writer.uint32(34).bytes(message.order_stages);
                if (message.order_stage_comments != null && Object.hasOwnProperty.call(message, "order_stage_comments"))
                    writer.uint32(42).bytes(message.order_stage_comments);
                if (message.order_stage_comment_files != null && Object.hasOwnProperty.call(message, "order_stage_comment_files"))
                    writer.uint32(50).bytes(message.order_stage_comment_files);
                if (message.laser_cutting_stages != null && Object.hasOwnProperty.call(message, "laser_cutting_stages"))
                    writer.uint32(58).bytes(message.laser_cutting_stages);
                if (message.laser_engraving_stages != null && Object.hasOwnProperty.call(message, "laser_engraving_stages"))
                    writer.uint32(66).bytes(message.laser_engraving_stages);
                if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                    writer.uint32(72).uint64(message.order_id);
                if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                    writer.uint32(80).uint64(message.user_id);
                return writer;
            };

            update_order_by_manager.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.update_order_by_manager();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.orders = reader.bytes();
                            break;
                        }
                    case 2: {
                            message.order_comments = reader.bytes();
                            break;
                        }
                    case 3: {
                            message.order_comment_files = reader.bytes();
                            break;
                        }
                    case 4: {
                            message.order_stages = reader.bytes();
                            break;
                        }
                    case 5: {
                            message.order_stage_comments = reader.bytes();
                            break;
                        }
                    case 6: {
                            message.order_stage_comment_files = reader.bytes();
                            break;
                        }
                    case 7: {
                            message.laser_cutting_stages = reader.bytes();
                            break;
                        }
                    case 8: {
                            message.laser_engraving_stages = reader.bytes();
                            break;
                        }
                    case 9: {
                            message.order_id = reader.uint64();
                            break;
                        }
                    case 10: {
                            message.user_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            update_order_by_manager.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.update_order_by_manager)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.update_order_by_manager();
                if (object.orders != null)
                    if (typeof object.orders === "string")
                        $util.base64.decode(object.orders, message.orders = $util.newBuffer($util.base64.length(object.orders)), 0);
                    else if (object.orders.length >= 0)
                        message.orders = object.orders;
                if (object.order_comments != null)
                    if (typeof object.order_comments === "string")
                        $util.base64.decode(object.order_comments, message.order_comments = $util.newBuffer($util.base64.length(object.order_comments)), 0);
                    else if (object.order_comments.length >= 0)
                        message.order_comments = object.order_comments;
                if (object.order_comment_files != null)
                    if (typeof object.order_comment_files === "string")
                        $util.base64.decode(object.order_comment_files, message.order_comment_files = $util.newBuffer($util.base64.length(object.order_comment_files)), 0);
                    else if (object.order_comment_files.length >= 0)
                        message.order_comment_files = object.order_comment_files;
                if (object.order_stages != null)
                    if (typeof object.order_stages === "string")
                        $util.base64.decode(object.order_stages, message.order_stages = $util.newBuffer($util.base64.length(object.order_stages)), 0);
                    else if (object.order_stages.length >= 0)
                        message.order_stages = object.order_stages;
                if (object.order_stage_comments != null)
                    if (typeof object.order_stage_comments === "string")
                        $util.base64.decode(object.order_stage_comments, message.order_stage_comments = $util.newBuffer($util.base64.length(object.order_stage_comments)), 0);
                    else if (object.order_stage_comments.length >= 0)
                        message.order_stage_comments = object.order_stage_comments;
                if (object.order_stage_comment_files != null)
                    if (typeof object.order_stage_comment_files === "string")
                        $util.base64.decode(object.order_stage_comment_files, message.order_stage_comment_files = $util.newBuffer($util.base64.length(object.order_stage_comment_files)), 0);
                    else if (object.order_stage_comment_files.length >= 0)
                        message.order_stage_comment_files = object.order_stage_comment_files;
                if (object.laser_cutting_stages != null)
                    if (typeof object.laser_cutting_stages === "string")
                        $util.base64.decode(object.laser_cutting_stages, message.laser_cutting_stages = $util.newBuffer($util.base64.length(object.laser_cutting_stages)), 0);
                    else if (object.laser_cutting_stages.length >= 0)
                        message.laser_cutting_stages = object.laser_cutting_stages;
                if (object.laser_engraving_stages != null)
                    if (typeof object.laser_engraving_stages === "string")
                        $util.base64.decode(object.laser_engraving_stages, message.laser_engraving_stages = $util.newBuffer($util.base64.length(object.laser_engraving_stages)), 0);
                    else if (object.laser_engraving_stages.length >= 0)
                        message.laser_engraving_stages = object.laser_engraving_stages;
                if (object.order_id != null)
                    if ($util.Long)
                        (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                    else if (typeof object.order_id === "string")
                        message.order_id = parseInt(object.order_id, 10);
                    else if (typeof object.order_id === "number")
                        message.order_id = object.order_id;
                    else if (typeof object.order_id === "object")
                        message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
                if (object.user_id != null)
                    if ($util.Long)
                        (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = true;
                    else if (typeof object.user_id === "string")
                        message.user_id = parseInt(object.user_id, 10);
                    else if (typeof object.user_id === "number")
                        message.user_id = object.user_id;
                    else if (typeof object.user_id === "object")
                        message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber(true);
                return message;
            };

            update_order_by_manager.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.orders = "";
                    else {
                        object.orders = [];
                        if (options.bytes !== Array)
                            object.orders = $util.newBuffer(object.orders);
                    }
                    if (options.bytes === String)
                        object.order_comments = "";
                    else {
                        object.order_comments = [];
                        if (options.bytes !== Array)
                            object.order_comments = $util.newBuffer(object.order_comments);
                    }
                    if (options.bytes === String)
                        object.order_comment_files = "";
                    else {
                        object.order_comment_files = [];
                        if (options.bytes !== Array)
                            object.order_comment_files = $util.newBuffer(object.order_comment_files);
                    }
                    if (options.bytes === String)
                        object.order_stages = "";
                    else {
                        object.order_stages = [];
                        if (options.bytes !== Array)
                            object.order_stages = $util.newBuffer(object.order_stages);
                    }
                    if (options.bytes === String)
                        object.order_stage_comments = "";
                    else {
                        object.order_stage_comments = [];
                        if (options.bytes !== Array)
                            object.order_stage_comments = $util.newBuffer(object.order_stage_comments);
                    }
                    if (options.bytes === String)
                        object.order_stage_comment_files = "";
                    else {
                        object.order_stage_comment_files = [];
                        if (options.bytes !== Array)
                            object.order_stage_comment_files = $util.newBuffer(object.order_stage_comment_files);
                    }
                    if (options.bytes === String)
                        object.laser_cutting_stages = "";
                    else {
                        object.laser_cutting_stages = [];
                        if (options.bytes !== Array)
                            object.laser_cutting_stages = $util.newBuffer(object.laser_cutting_stages);
                    }
                    if (options.bytes === String)
                        object.laser_engraving_stages = "";
                    else {
                        object.laser_engraving_stages = [];
                        if (options.bytes !== Array)
                            object.laser_engraving_stages = $util.newBuffer(object.laser_engraving_stages);
                    }
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.order_id = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.user_id = options.longs === String ? "0" : 0;
                }
                if (message.orders != null && message.hasOwnProperty("orders"))
                    object.orders = options.bytes === String ? $util.base64.encode(message.orders, 0, message.orders.length) : options.bytes === Array ? Array.prototype.slice.call(message.orders) : message.orders;
                if (message.order_comments != null && message.hasOwnProperty("order_comments"))
                    object.order_comments = options.bytes === String ? $util.base64.encode(message.order_comments, 0, message.order_comments.length) : options.bytes === Array ? Array.prototype.slice.call(message.order_comments) : message.order_comments;
                if (message.order_comment_files != null && message.hasOwnProperty("order_comment_files"))
                    object.order_comment_files = options.bytes === String ? $util.base64.encode(message.order_comment_files, 0, message.order_comment_files.length) : options.bytes === Array ? Array.prototype.slice.call(message.order_comment_files) : message.order_comment_files;
                if (message.order_stages != null && message.hasOwnProperty("order_stages"))
                    object.order_stages = options.bytes === String ? $util.base64.encode(message.order_stages, 0, message.order_stages.length) : options.bytes === Array ? Array.prototype.slice.call(message.order_stages) : message.order_stages;
                if (message.order_stage_comments != null && message.hasOwnProperty("order_stage_comments"))
                    object.order_stage_comments = options.bytes === String ? $util.base64.encode(message.order_stage_comments, 0, message.order_stage_comments.length) : options.bytes === Array ? Array.prototype.slice.call(message.order_stage_comments) : message.order_stage_comments;
                if (message.order_stage_comment_files != null && message.hasOwnProperty("order_stage_comment_files"))
                    object.order_stage_comment_files = options.bytes === String ? $util.base64.encode(message.order_stage_comment_files, 0, message.order_stage_comment_files.length) : options.bytes === Array ? Array.prototype.slice.call(message.order_stage_comment_files) : message.order_stage_comment_files;
                if (message.laser_cutting_stages != null && message.hasOwnProperty("laser_cutting_stages"))
                    object.laser_cutting_stages = options.bytes === String ? $util.base64.encode(message.laser_cutting_stages, 0, message.laser_cutting_stages.length) : options.bytes === Array ? Array.prototype.slice.call(message.laser_cutting_stages) : message.laser_cutting_stages;
                if (message.laser_engraving_stages != null && message.hasOwnProperty("laser_engraving_stages"))
                    object.laser_engraving_stages = options.bytes === String ? $util.base64.encode(message.laser_engraving_stages, 0, message.laser_engraving_stages.length) : options.bytes === Array ? Array.prototype.slice.call(message.laser_engraving_stages) : message.laser_engraving_stages;
                if (message.order_id != null && message.hasOwnProperty("order_id"))
                    if (typeof message.order_id === "number")
                        object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                    else
                        object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
                if (message.user_id != null && message.hasOwnProperty("user_id"))
                    if (typeof message.user_id === "number")
                        object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                    else
                        object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber(true) : message.user_id;
                return object;
            };

            update_order_by_manager.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            update_order_by_manager.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.update_order_by_manager";
            };

            return update_order_by_manager;
        })();

        OrdersApiRequest.update_order_status_by_manger = (function() {

            function update_order_status_by_manger(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            update_order_status_by_manger.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            update_order_status_by_manger.prototype.status = "";

            update_order_status_by_manger.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                    writer.uint32(8).uint64(message.order_id);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(18).string(message.status);
                return writer;
            };

            update_order_status_by_manger.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.update_order_status_by_manger();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.order_id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.status = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            update_order_status_by_manger.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.update_order_status_by_manger)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.update_order_status_by_manger();
                if (object.order_id != null)
                    if ($util.Long)
                        (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                    else if (typeof object.order_id === "string")
                        message.order_id = parseInt(object.order_id, 10);
                    else if (typeof object.order_id === "number")
                        message.order_id = object.order_id;
                    else if (typeof object.order_id === "object")
                        message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
                if (object.status != null)
                    message.status = String(object.status);
                return message;
            };

            update_order_status_by_manger.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.order_id = options.longs === String ? "0" : 0;
                    object.status = "";
                }
                if (message.order_id != null && message.hasOwnProperty("order_id"))
                    if (typeof message.order_id === "number")
                        object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                    else
                        object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = message.status;
                return object;
            };

            update_order_status_by_manger.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            update_order_status_by_manger.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.update_order_status_by_manger";
            };

            return update_order_status_by_manger;
        })();

        OrdersApiRequest.approve_stage = (function() {

            function approve_stage(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            approve_stage.prototype.order_stages = $util.newBuffer([]);
            approve_stage.prototype.order_stage_comments = $util.newBuffer([]);
            approve_stage.prototype.order_stage_comment_files = $util.newBuffer([]);
            approve_stage.prototype.laser_cutting_stages = $util.newBuffer([]);
            approve_stage.prototype.laser_engraving_stages = $util.newBuffer([]);
            approve_stage.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            approve_stage.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            approve_stage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.order_stages != null && Object.hasOwnProperty.call(message, "order_stages"))
                    writer.uint32(10).bytes(message.order_stages);
                if (message.order_stage_comments != null && Object.hasOwnProperty.call(message, "order_stage_comments"))
                    writer.uint32(18).bytes(message.order_stage_comments);
                if (message.order_stage_comment_files != null && Object.hasOwnProperty.call(message, "order_stage_comment_files"))
                    writer.uint32(26).bytes(message.order_stage_comment_files);
                if (message.laser_cutting_stages != null && Object.hasOwnProperty.call(message, "laser_cutting_stages"))
                    writer.uint32(34).bytes(message.laser_cutting_stages);
                if (message.laser_engraving_stages != null && Object.hasOwnProperty.call(message, "laser_engraving_stages"))
                    writer.uint32(42).bytes(message.laser_engraving_stages);
                if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                    writer.uint32(48).uint64(message.user_id);
                if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                    writer.uint32(56).uint64(message.order_id);
                return writer;
            };

            approve_stage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.approve_stage();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.order_stages = reader.bytes();
                            break;
                        }
                    case 2: {
                            message.order_stage_comments = reader.bytes();
                            break;
                        }
                    case 3: {
                            message.order_stage_comment_files = reader.bytes();
                            break;
                        }
                    case 4: {
                            message.laser_cutting_stages = reader.bytes();
                            break;
                        }
                    case 5: {
                            message.laser_engraving_stages = reader.bytes();
                            break;
                        }
                    case 6: {
                            message.user_id = reader.uint64();
                            break;
                        }
                    case 7: {
                            message.order_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            approve_stage.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.approve_stage)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.approve_stage();
                if (object.order_stages != null)
                    if (typeof object.order_stages === "string")
                        $util.base64.decode(object.order_stages, message.order_stages = $util.newBuffer($util.base64.length(object.order_stages)), 0);
                    else if (object.order_stages.length >= 0)
                        message.order_stages = object.order_stages;
                if (object.order_stage_comments != null)
                    if (typeof object.order_stage_comments === "string")
                        $util.base64.decode(object.order_stage_comments, message.order_stage_comments = $util.newBuffer($util.base64.length(object.order_stage_comments)), 0);
                    else if (object.order_stage_comments.length >= 0)
                        message.order_stage_comments = object.order_stage_comments;
                if (object.order_stage_comment_files != null)
                    if (typeof object.order_stage_comment_files === "string")
                        $util.base64.decode(object.order_stage_comment_files, message.order_stage_comment_files = $util.newBuffer($util.base64.length(object.order_stage_comment_files)), 0);
                    else if (object.order_stage_comment_files.length >= 0)
                        message.order_stage_comment_files = object.order_stage_comment_files;
                if (object.laser_cutting_stages != null)
                    if (typeof object.laser_cutting_stages === "string")
                        $util.base64.decode(object.laser_cutting_stages, message.laser_cutting_stages = $util.newBuffer($util.base64.length(object.laser_cutting_stages)), 0);
                    else if (object.laser_cutting_stages.length >= 0)
                        message.laser_cutting_stages = object.laser_cutting_stages;
                if (object.laser_engraving_stages != null)
                    if (typeof object.laser_engraving_stages === "string")
                        $util.base64.decode(object.laser_engraving_stages, message.laser_engraving_stages = $util.newBuffer($util.base64.length(object.laser_engraving_stages)), 0);
                    else if (object.laser_engraving_stages.length >= 0)
                        message.laser_engraving_stages = object.laser_engraving_stages;
                if (object.user_id != null)
                    if ($util.Long)
                        (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = true;
                    else if (typeof object.user_id === "string")
                        message.user_id = parseInt(object.user_id, 10);
                    else if (typeof object.user_id === "number")
                        message.user_id = object.user_id;
                    else if (typeof object.user_id === "object")
                        message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber(true);
                if (object.order_id != null)
                    if ($util.Long)
                        (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                    else if (typeof object.order_id === "string")
                        message.order_id = parseInt(object.order_id, 10);
                    else if (typeof object.order_id === "number")
                        message.order_id = object.order_id;
                    else if (typeof object.order_id === "object")
                        message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
                return message;
            };

            approve_stage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.order_stages = "";
                    else {
                        object.order_stages = [];
                        if (options.bytes !== Array)
                            object.order_stages = $util.newBuffer(object.order_stages);
                    }
                    if (options.bytes === String)
                        object.order_stage_comments = "";
                    else {
                        object.order_stage_comments = [];
                        if (options.bytes !== Array)
                            object.order_stage_comments = $util.newBuffer(object.order_stage_comments);
                    }
                    if (options.bytes === String)
                        object.order_stage_comment_files = "";
                    else {
                        object.order_stage_comment_files = [];
                        if (options.bytes !== Array)
                            object.order_stage_comment_files = $util.newBuffer(object.order_stage_comment_files);
                    }
                    if (options.bytes === String)
                        object.laser_cutting_stages = "";
                    else {
                        object.laser_cutting_stages = [];
                        if (options.bytes !== Array)
                            object.laser_cutting_stages = $util.newBuffer(object.laser_cutting_stages);
                    }
                    if (options.bytes === String)
                        object.laser_engraving_stages = "";
                    else {
                        object.laser_engraving_stages = [];
                        if (options.bytes !== Array)
                            object.laser_engraving_stages = $util.newBuffer(object.laser_engraving_stages);
                    }
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.user_id = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.order_id = options.longs === String ? "0" : 0;
                }
                if (message.order_stages != null && message.hasOwnProperty("order_stages"))
                    object.order_stages = options.bytes === String ? $util.base64.encode(message.order_stages, 0, message.order_stages.length) : options.bytes === Array ? Array.prototype.slice.call(message.order_stages) : message.order_stages;
                if (message.order_stage_comments != null && message.hasOwnProperty("order_stage_comments"))
                    object.order_stage_comments = options.bytes === String ? $util.base64.encode(message.order_stage_comments, 0, message.order_stage_comments.length) : options.bytes === Array ? Array.prototype.slice.call(message.order_stage_comments) : message.order_stage_comments;
                if (message.order_stage_comment_files != null && message.hasOwnProperty("order_stage_comment_files"))
                    object.order_stage_comment_files = options.bytes === String ? $util.base64.encode(message.order_stage_comment_files, 0, message.order_stage_comment_files.length) : options.bytes === Array ? Array.prototype.slice.call(message.order_stage_comment_files) : message.order_stage_comment_files;
                if (message.laser_cutting_stages != null && message.hasOwnProperty("laser_cutting_stages"))
                    object.laser_cutting_stages = options.bytes === String ? $util.base64.encode(message.laser_cutting_stages, 0, message.laser_cutting_stages.length) : options.bytes === Array ? Array.prototype.slice.call(message.laser_cutting_stages) : message.laser_cutting_stages;
                if (message.laser_engraving_stages != null && message.hasOwnProperty("laser_engraving_stages"))
                    object.laser_engraving_stages = options.bytes === String ? $util.base64.encode(message.laser_engraving_stages, 0, message.laser_engraving_stages.length) : options.bytes === Array ? Array.prototype.slice.call(message.laser_engraving_stages) : message.laser_engraving_stages;
                if (message.user_id != null && message.hasOwnProperty("user_id"))
                    if (typeof message.user_id === "number")
                        object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                    else
                        object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber(true) : message.user_id;
                if (message.order_id != null && message.hasOwnProperty("order_id"))
                    if (typeof message.order_id === "number")
                        object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                    else
                        object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
                return object;
            };

            approve_stage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            approve_stage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.approve_stage";
            };

            return approve_stage;
        })();

        OrdersApiRequest.reload_order_stages = (function() {

            function reload_order_stages(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            reload_order_stages.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            reload_order_stages.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                    writer.uint32(8).uint64(message.order_id);
                return writer;
            };

            reload_order_stages.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.reload_order_stages();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.order_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            reload_order_stages.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.reload_order_stages)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.reload_order_stages();
                if (object.order_id != null)
                    if ($util.Long)
                        (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                    else if (typeof object.order_id === "string")
                        message.order_id = parseInt(object.order_id, 10);
                    else if (typeof object.order_id === "number")
                        message.order_id = object.order_id;
                    else if (typeof object.order_id === "object")
                        message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
                return message;
            };

            reload_order_stages.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.order_id = options.longs === String ? "0" : 0;
                if (message.order_id != null && message.hasOwnProperty("order_id"))
                    if (typeof message.order_id === "number")
                        object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                    else
                        object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
                return object;
            };

            reload_order_stages.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            reload_order_stages.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.reload_order_stages";
            };

            return reload_order_stages;
        })();

        OrdersApiRequest.update_delivery_profiles_by_manager = (function() {

            function update_delivery_profiles_by_manager(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            update_delivery_profiles_by_manager.prototype.profiles = $util.newBuffer([]);
            update_delivery_profiles_by_manager.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            update_delivery_profiles_by_manager.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.profiles != null && Object.hasOwnProperty.call(message, "profiles"))
                    writer.uint32(10).bytes(message.profiles);
                if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                    writer.uint32(16).uint64(message.user_id);
                return writer;
            };

            update_delivery_profiles_by_manager.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.update_delivery_profiles_by_manager();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.profiles = reader.bytes();
                            break;
                        }
                    case 2: {
                            message.user_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            update_delivery_profiles_by_manager.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.update_delivery_profiles_by_manager)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.update_delivery_profiles_by_manager();
                if (object.profiles != null)
                    if (typeof object.profiles === "string")
                        $util.base64.decode(object.profiles, message.profiles = $util.newBuffer($util.base64.length(object.profiles)), 0);
                    else if (object.profiles.length >= 0)
                        message.profiles = object.profiles;
                if (object.user_id != null)
                    if ($util.Long)
                        (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = true;
                    else if (typeof object.user_id === "string")
                        message.user_id = parseInt(object.user_id, 10);
                    else if (typeof object.user_id === "number")
                        message.user_id = object.user_id;
                    else if (typeof object.user_id === "object")
                        message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber(true);
                return message;
            };

            update_delivery_profiles_by_manager.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.profiles = "";
                    else {
                        object.profiles = [];
                        if (options.bytes !== Array)
                            object.profiles = $util.newBuffer(object.profiles);
                    }
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.user_id = options.longs === String ? "0" : 0;
                }
                if (message.profiles != null && message.hasOwnProperty("profiles"))
                    object.profiles = options.bytes === String ? $util.base64.encode(message.profiles, 0, message.profiles.length) : options.bytes === Array ? Array.prototype.slice.call(message.profiles) : message.profiles;
                if (message.user_id != null && message.hasOwnProperty("user_id"))
                    if (typeof message.user_id === "number")
                        object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                    else
                        object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber(true) : message.user_id;
                return object;
            };

            update_delivery_profiles_by_manager.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            update_delivery_profiles_by_manager.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.update_delivery_profiles_by_manager";
            };

            return update_delivery_profiles_by_manager;
        })();

        OrdersApiRequest.reload_delivery_profiles = (function() {

            function reload_delivery_profiles(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            reload_delivery_profiles.prototype.auto_update = false;
            reload_delivery_profiles.prototype.unload = false;
            reload_delivery_profiles.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            reload_delivery_profiles.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.auto_update != null && Object.hasOwnProperty.call(message, "auto_update"))
                    writer.uint32(8).bool(message.auto_update);
                if (message.unload != null && Object.hasOwnProperty.call(message, "unload"))
                    writer.uint32(16).bool(message.unload);
                if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                    writer.uint32(24).uint64(message.user_id);
                return writer;
            };

            reload_delivery_profiles.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.reload_delivery_profiles();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.auto_update = reader.bool();
                            break;
                        }
                    case 2: {
                            message.unload = reader.bool();
                            break;
                        }
                    case 3: {
                            message.user_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            reload_delivery_profiles.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.reload_delivery_profiles)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.reload_delivery_profiles();
                if (object.auto_update != null)
                    message.auto_update = Boolean(object.auto_update);
                if (object.unload != null)
                    message.unload = Boolean(object.unload);
                if (object.user_id != null)
                    if ($util.Long)
                        (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = true;
                    else if (typeof object.user_id === "string")
                        message.user_id = parseInt(object.user_id, 10);
                    else if (typeof object.user_id === "number")
                        message.user_id = object.user_id;
                    else if (typeof object.user_id === "object")
                        message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber(true);
                return message;
            };

            reload_delivery_profiles.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.auto_update = false;
                    object.unload = false;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.user_id = options.longs === String ? "0" : 0;
                }
                if (message.auto_update != null && message.hasOwnProperty("auto_update"))
                    object.auto_update = message.auto_update;
                if (message.unload != null && message.hasOwnProperty("unload"))
                    object.unload = message.unload;
                if (message.user_id != null && message.hasOwnProperty("user_id"))
                    if (typeof message.user_id === "number")
                        object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                    else
                        object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber(true) : message.user_id;
                return object;
            };

            reload_delivery_profiles.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            reload_delivery_profiles.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.reload_delivery_profiles";
            };

            return reload_delivery_profiles;
        })();

        OrdersApiRequest.update_order_stage_status_by_manger = (function() {

            function update_order_stage_status_by_manger(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            update_order_stage_status_by_manger.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            update_order_stage_status_by_manger.prototype.stage_number = 0;
            update_order_stage_status_by_manger.prototype.status = "";

            update_order_stage_status_by_manger.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                    writer.uint32(8).uint64(message.order_id);
                if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                    writer.uint32(16).int32(message.stage_number);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(26).string(message.status);
                return writer;
            };

            update_order_stage_status_by_manger.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.update_order_stage_status_by_manger();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.order_id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.stage_number = reader.int32();
                            break;
                        }
                    case 3: {
                            message.status = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            update_order_stage_status_by_manger.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.update_order_stage_status_by_manger)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.update_order_stage_status_by_manger();
                if (object.order_id != null)
                    if ($util.Long)
                        (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                    else if (typeof object.order_id === "string")
                        message.order_id = parseInt(object.order_id, 10);
                    else if (typeof object.order_id === "number")
                        message.order_id = object.order_id;
                    else if (typeof object.order_id === "object")
                        message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
                if (object.stage_number != null)
                    message.stage_number = object.stage_number | 0;
                if (object.status != null)
                    message.status = String(object.status);
                return message;
            };

            update_order_stage_status_by_manger.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.order_id = options.longs === String ? "0" : 0;
                    object.stage_number = 0;
                    object.status = "";
                }
                if (message.order_id != null && message.hasOwnProperty("order_id"))
                    if (typeof message.order_id === "number")
                        object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                    else
                        object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
                if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                    object.stage_number = message.stage_number;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = message.status;
                return object;
            };

            update_order_stage_status_by_manger.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            update_order_stage_status_by_manger.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.update_order_stage_status_by_manger";
            };

            return update_order_stage_status_by_manger;
        })();

        OrdersApiRequest.update_stage_delivery_profiles_by_manager = (function() {

            function update_stage_delivery_profiles_by_manager(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            update_stage_delivery_profiles_by_manager.prototype.profile = $util.newBuffer([]);
            update_stage_delivery_profiles_by_manager.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            update_stage_delivery_profiles_by_manager.prototype.stage_number = 0;

            update_stage_delivery_profiles_by_manager.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
                    writer.uint32(10).bytes(message.profile);
                if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                    writer.uint32(16).uint64(message.order_id);
                if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                    writer.uint32(24).int32(message.stage_number);
                return writer;
            };

            update_stage_delivery_profiles_by_manager.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.update_stage_delivery_profiles_by_manager();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.profile = reader.bytes();
                            break;
                        }
                    case 2: {
                            message.order_id = reader.uint64();
                            break;
                        }
                    case 3: {
                            message.stage_number = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            update_stage_delivery_profiles_by_manager.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.update_stage_delivery_profiles_by_manager)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.update_stage_delivery_profiles_by_manager();
                if (object.profile != null)
                    if (typeof object.profile === "string")
                        $util.base64.decode(object.profile, message.profile = $util.newBuffer($util.base64.length(object.profile)), 0);
                    else if (object.profile.length >= 0)
                        message.profile = object.profile;
                if (object.order_id != null)
                    if ($util.Long)
                        (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                    else if (typeof object.order_id === "string")
                        message.order_id = parseInt(object.order_id, 10);
                    else if (typeof object.order_id === "number")
                        message.order_id = object.order_id;
                    else if (typeof object.order_id === "object")
                        message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
                if (object.stage_number != null)
                    message.stage_number = object.stage_number | 0;
                return message;
            };

            update_stage_delivery_profiles_by_manager.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.profile = "";
                    else {
                        object.profile = [];
                        if (options.bytes !== Array)
                            object.profile = $util.newBuffer(object.profile);
                    }
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.order_id = options.longs === String ? "0" : 0;
                    object.stage_number = 0;
                }
                if (message.profile != null && message.hasOwnProperty("profile"))
                    object.profile = options.bytes === String ? $util.base64.encode(message.profile, 0, message.profile.length) : options.bytes === Array ? Array.prototype.slice.call(message.profile) : message.profile;
                if (message.order_id != null && message.hasOwnProperty("order_id"))
                    if (typeof message.order_id === "number")
                        object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                    else
                        object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
                if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                    object.stage_number = message.stage_number;
                return object;
            };

            update_stage_delivery_profiles_by_manager.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            update_stage_delivery_profiles_by_manager.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.update_stage_delivery_profiles_by_manager";
            };

            return update_stage_delivery_profiles_by_manager;
        })();

        OrdersApiRequest.select_order = (function() {

            function select_order(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            select_order.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            select_order.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            select_order.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                    writer.uint32(8).uint64(message.order_id);
                if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                    writer.uint32(16).uint64(message.user_id);
                return writer;
            };

            select_order.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.select_order();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.order_id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.user_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            select_order.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.select_order)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.select_order();
                if (object.order_id != null)
                    if ($util.Long)
                        (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                    else if (typeof object.order_id === "string")
                        message.order_id = parseInt(object.order_id, 10);
                    else if (typeof object.order_id === "number")
                        message.order_id = object.order_id;
                    else if (typeof object.order_id === "object")
                        message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
                if (object.user_id != null)
                    if ($util.Long)
                        (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = true;
                    else if (typeof object.user_id === "string")
                        message.user_id = parseInt(object.user_id, 10);
                    else if (typeof object.user_id === "number")
                        message.user_id = object.user_id;
                    else if (typeof object.user_id === "object")
                        message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber(true);
                return message;
            };

            select_order.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.order_id = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.user_id = options.longs === String ? "0" : 0;
                }
                if (message.order_id != null && message.hasOwnProperty("order_id"))
                    if (typeof message.order_id === "number")
                        object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                    else
                        object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
                if (message.user_id != null && message.hasOwnProperty("user_id"))
                    if (typeof message.user_id === "number")
                        object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                    else
                        object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber(true) : message.user_id;
                return object;
            };

            select_order.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            select_order.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.select_order";
            };

            return select_order;
        })();

        OrdersApiRequest.search_orders = (function() {

            function search_orders(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            search_orders.prototype.status = "";

            search_orders.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(10).string(message.status);
                return writer;
            };

            search_orders.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.search_orders();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.status = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            search_orders.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.search_orders)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.search_orders();
                if (object.status != null)
                    message.status = String(object.status);
                return message;
            };

            search_orders.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.status = "";
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = message.status;
                return object;
            };

            search_orders.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            search_orders.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.search_orders";
            };

            return search_orders;
        })();

        OrdersApiRequest.get_stage_action_approximate_price = (function() {

            function get_stage_action_approximate_price(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_stage_action_approximate_price.prototype.delivery_profile_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            get_stage_action_approximate_price.prototype.stage_action_data = $util.newBuffer([]);

            get_stage_action_approximate_price.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.delivery_profile_id != null && Object.hasOwnProperty.call(message, "delivery_profile_id"))
                    writer.uint32(8).uint64(message.delivery_profile_id);
                if (message.stage_action_data != null && Object.hasOwnProperty.call(message, "stage_action_data"))
                    writer.uint32(18).bytes(message.stage_action_data);
                return writer;
            };

            get_stage_action_approximate_price.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.get_stage_action_approximate_price();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.delivery_profile_id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.stage_action_data = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_stage_action_approximate_price.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.get_stage_action_approximate_price)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.get_stage_action_approximate_price();
                if (object.delivery_profile_id != null)
                    if ($util.Long)
                        (message.delivery_profile_id = $util.Long.fromValue(object.delivery_profile_id)).unsigned = true;
                    else if (typeof object.delivery_profile_id === "string")
                        message.delivery_profile_id = parseInt(object.delivery_profile_id, 10);
                    else if (typeof object.delivery_profile_id === "number")
                        message.delivery_profile_id = object.delivery_profile_id;
                    else if (typeof object.delivery_profile_id === "object")
                        message.delivery_profile_id = new $util.LongBits(object.delivery_profile_id.low >>> 0, object.delivery_profile_id.high >>> 0).toNumber(true);
                if (object.stage_action_data != null)
                    if (typeof object.stage_action_data === "string")
                        $util.base64.decode(object.stage_action_data, message.stage_action_data = $util.newBuffer($util.base64.length(object.stage_action_data)), 0);
                    else if (object.stage_action_data.length >= 0)
                        message.stage_action_data = object.stage_action_data;
                return message;
            };

            get_stage_action_approximate_price.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.delivery_profile_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.delivery_profile_id = options.longs === String ? "0" : 0;
                    if (options.bytes === String)
                        object.stage_action_data = "";
                    else {
                        object.stage_action_data = [];
                        if (options.bytes !== Array)
                            object.stage_action_data = $util.newBuffer(object.stage_action_data);
                    }
                }
                if (message.delivery_profile_id != null && message.hasOwnProperty("delivery_profile_id"))
                    if (typeof message.delivery_profile_id === "number")
                        object.delivery_profile_id = options.longs === String ? String(message.delivery_profile_id) : message.delivery_profile_id;
                    else
                        object.delivery_profile_id = options.longs === String ? $util.Long.prototype.toString.call(message.delivery_profile_id) : options.longs === Number ? new $util.LongBits(message.delivery_profile_id.low >>> 0, message.delivery_profile_id.high >>> 0).toNumber(true) : message.delivery_profile_id;
                if (message.stage_action_data != null && message.hasOwnProperty("stage_action_data"))
                    object.stage_action_data = options.bytes === String ? $util.base64.encode(message.stage_action_data, 0, message.stage_action_data.length) : options.bytes === Array ? Array.prototype.slice.call(message.stage_action_data) : message.stage_action_data;
                return object;
            };

            get_stage_action_approximate_price.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_stage_action_approximate_price.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.get_stage_action_approximate_price";
            };

            return get_stage_action_approximate_price;
        })();

        OrdersApiRequest.get_action_approximate_prices_for_manager = (function() {

            function get_action_approximate_prices_for_manager(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_action_approximate_prices_for_manager.prototype.latitude = 0;
            get_action_approximate_prices_for_manager.prototype.longitude = 0;
            get_action_approximate_prices_for_manager.prototype.stage_action_data = $util.newBuffer([]);

            get_action_approximate_prices_for_manager.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                    writer.uint32(13).float(message.latitude);
                if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                    writer.uint32(21).float(message.longitude);
                if (message.stage_action_data != null && Object.hasOwnProperty.call(message, "stage_action_data"))
                    writer.uint32(26).bytes(message.stage_action_data);
                return writer;
            };

            get_action_approximate_prices_for_manager.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.get_action_approximate_prices_for_manager();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.latitude = reader.float();
                            break;
                        }
                    case 2: {
                            message.longitude = reader.float();
                            break;
                        }
                    case 3: {
                            message.stage_action_data = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_action_approximate_prices_for_manager.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.get_action_approximate_prices_for_manager)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.get_action_approximate_prices_for_manager();
                if (object.latitude != null)
                    message.latitude = Number(object.latitude);
                if (object.longitude != null)
                    message.longitude = Number(object.longitude);
                if (object.stage_action_data != null)
                    if (typeof object.stage_action_data === "string")
                        $util.base64.decode(object.stage_action_data, message.stage_action_data = $util.newBuffer($util.base64.length(object.stage_action_data)), 0);
                    else if (object.stage_action_data.length >= 0)
                        message.stage_action_data = object.stage_action_data;
                return message;
            };

            get_action_approximate_prices_for_manager.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.latitude = 0;
                    object.longitude = 0;
                    if (options.bytes === String)
                        object.stage_action_data = "";
                    else {
                        object.stage_action_data = [];
                        if (options.bytes !== Array)
                            object.stage_action_data = $util.newBuffer(object.stage_action_data);
                    }
                }
                if (message.latitude != null && message.hasOwnProperty("latitude"))
                    object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
                if (message.longitude != null && message.hasOwnProperty("longitude"))
                    object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
                if (message.stage_action_data != null && message.hasOwnProperty("stage_action_data"))
                    object.stage_action_data = options.bytes === String ? $util.base64.encode(message.stage_action_data, 0, message.stage_action_data.length) : options.bytes === Array ? Array.prototype.slice.call(message.stage_action_data) : message.stage_action_data;
                return object;
            };

            get_action_approximate_prices_for_manager.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_action_approximate_prices_for_manager.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.get_action_approximate_prices_for_manager";
            };

            return get_action_approximate_prices_for_manager;
        })();

        OrdersApiRequest.get_validation_code = (function() {

            function get_validation_code(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_validation_code.prototype.phone_number = "";

            get_validation_code.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.phone_number != null && Object.hasOwnProperty.call(message, "phone_number"))
                    writer.uint32(10).string(message.phone_number);
                return writer;
            };

            get_validation_code.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.get_validation_code();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.phone_number = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_validation_code.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.get_validation_code)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.get_validation_code();
                if (object.phone_number != null)
                    message.phone_number = String(object.phone_number);
                return message;
            };

            get_validation_code.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.phone_number = "";
                if (message.phone_number != null && message.hasOwnProperty("phone_number"))
                    object.phone_number = message.phone_number;
                return object;
            };

            get_validation_code.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_validation_code.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.get_validation_code";
            };

            return get_validation_code;
        })();

        OrdersApiRequest.get_or_create_user = (function() {

            function get_or_create_user(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_or_create_user.prototype.phone_number = "";

            get_or_create_user.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.phone_number != null && Object.hasOwnProperty.call(message, "phone_number"))
                    writer.uint32(10).string(message.phone_number);
                return writer;
            };

            get_or_create_user.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.get_or_create_user();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.phone_number = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_or_create_user.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.get_or_create_user)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.get_or_create_user();
                if (object.phone_number != null)
                    message.phone_number = String(object.phone_number);
                return message;
            };

            get_or_create_user.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.phone_number = "";
                if (message.phone_number != null && message.hasOwnProperty("phone_number"))
                    object.phone_number = message.phone_number;
                return object;
            };

            get_or_create_user.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_or_create_user.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.get_or_create_user";
            };

            return get_or_create_user;
        })();

        OrdersApiRequest.add_new_owner_to_stage_place = (function() {

            function add_new_owner_to_stage_place(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            add_new_owner_to_stage_place.prototype.phone_number = "";
            add_new_owner_to_stage_place.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            add_new_owner_to_stage_place.prototype.place_have_admin = false;
            add_new_owner_to_stage_place.prototype.place_org_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            add_new_owner_to_stage_place.prototype.org_have_adming = false;

            add_new_owner_to_stage_place.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.phone_number != null && Object.hasOwnProperty.call(message, "phone_number"))
                    writer.uint32(10).string(message.phone_number);
                if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                    writer.uint32(16).uint64(message.place_id);
                if (message.place_have_admin != null && Object.hasOwnProperty.call(message, "place_have_admin"))
                    writer.uint32(24).bool(message.place_have_admin);
                if (message.place_org_id != null && Object.hasOwnProperty.call(message, "place_org_id"))
                    writer.uint32(32).uint64(message.place_org_id);
                if (message.org_have_adming != null && Object.hasOwnProperty.call(message, "org_have_adming"))
                    writer.uint32(40).bool(message.org_have_adming);
                return writer;
            };

            add_new_owner_to_stage_place.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.add_new_owner_to_stage_place();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.phone_number = reader.string();
                            break;
                        }
                    case 2: {
                            message.place_id = reader.uint64();
                            break;
                        }
                    case 3: {
                            message.place_have_admin = reader.bool();
                            break;
                        }
                    case 4: {
                            message.place_org_id = reader.uint64();
                            break;
                        }
                    case 5: {
                            message.org_have_adming = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            add_new_owner_to_stage_place.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.add_new_owner_to_stage_place)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.add_new_owner_to_stage_place();
                if (object.phone_number != null)
                    message.phone_number = String(object.phone_number);
                if (object.place_id != null)
                    if ($util.Long)
                        (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                    else if (typeof object.place_id === "string")
                        message.place_id = parseInt(object.place_id, 10);
                    else if (typeof object.place_id === "number")
                        message.place_id = object.place_id;
                    else if (typeof object.place_id === "object")
                        message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
                if (object.place_have_admin != null)
                    message.place_have_admin = Boolean(object.place_have_admin);
                if (object.place_org_id != null)
                    if ($util.Long)
                        (message.place_org_id = $util.Long.fromValue(object.place_org_id)).unsigned = true;
                    else if (typeof object.place_org_id === "string")
                        message.place_org_id = parseInt(object.place_org_id, 10);
                    else if (typeof object.place_org_id === "number")
                        message.place_org_id = object.place_org_id;
                    else if (typeof object.place_org_id === "object")
                        message.place_org_id = new $util.LongBits(object.place_org_id.low >>> 0, object.place_org_id.high >>> 0).toNumber(true);
                if (object.org_have_adming != null)
                    message.org_have_adming = Boolean(object.org_have_adming);
                return message;
            };

            add_new_owner_to_stage_place.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.phone_number = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.place_id = options.longs === String ? "0" : 0;
                    object.place_have_admin = false;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.place_org_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.place_org_id = options.longs === String ? "0" : 0;
                    object.org_have_adming = false;
                }
                if (message.phone_number != null && message.hasOwnProperty("phone_number"))
                    object.phone_number = message.phone_number;
                if (message.place_id != null && message.hasOwnProperty("place_id"))
                    if (typeof message.place_id === "number")
                        object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                    else
                        object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
                if (message.place_have_admin != null && message.hasOwnProperty("place_have_admin"))
                    object.place_have_admin = message.place_have_admin;
                if (message.place_org_id != null && message.hasOwnProperty("place_org_id"))
                    if (typeof message.place_org_id === "number")
                        object.place_org_id = options.longs === String ? String(message.place_org_id) : message.place_org_id;
                    else
                        object.place_org_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_org_id) : options.longs === Number ? new $util.LongBits(message.place_org_id.low >>> 0, message.place_org_id.high >>> 0).toNumber(true) : message.place_org_id;
                if (message.org_have_adming != null && message.hasOwnProperty("org_have_adming"))
                    object.org_have_adming = message.org_have_adming;
                return object;
            };

            add_new_owner_to_stage_place.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            add_new_owner_to_stage_place.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.add_new_owner_to_stage_place";
            };

            return add_new_owner_to_stage_place;
        })();

        OrdersApiRequest.get_last_organization_used_for_payment = (function() {

            function get_last_organization_used_for_payment(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_last_organization_used_for_payment.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            get_last_organization_used_for_payment.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                    writer.uint32(8).uint64(message.user_id);
                return writer;
            };

            get_last_organization_used_for_payment.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.get_last_organization_used_for_payment();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.user_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_last_organization_used_for_payment.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.get_last_organization_used_for_payment)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.get_last_organization_used_for_payment();
                if (object.user_id != null)
                    if ($util.Long)
                        (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = true;
                    else if (typeof object.user_id === "string")
                        message.user_id = parseInt(object.user_id, 10);
                    else if (typeof object.user_id === "number")
                        message.user_id = object.user_id;
                    else if (typeof object.user_id === "object")
                        message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber(true);
                return message;
            };

            get_last_organization_used_for_payment.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.user_id = options.longs === String ? "0" : 0;
                if (message.user_id != null && message.hasOwnProperty("user_id"))
                    if (typeof message.user_id === "number")
                        object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                    else
                        object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber(true) : message.user_id;
                return object;
            };

            get_last_organization_used_for_payment.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_last_organization_used_for_payment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.get_last_organization_used_for_payment";
            };

            return get_last_organization_used_for_payment;
        })();

        OrdersApiRequest.get_upload_url = (function() {

            function get_upload_url(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_upload_url.prototype.dull_arg = false;

            get_upload_url.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.dull_arg != null && Object.hasOwnProperty.call(message, "dull_arg"))
                    writer.uint32(8).bool(message.dull_arg);
                return writer;
            };

            get_upload_url.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.get_upload_url();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.dull_arg = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_upload_url.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.get_upload_url)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.get_upload_url();
                if (object.dull_arg != null)
                    message.dull_arg = Boolean(object.dull_arg);
                return message;
            };

            get_upload_url.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.dull_arg = false;
                if (message.dull_arg != null && message.hasOwnProperty("dull_arg"))
                    object.dull_arg = message.dull_arg;
                return object;
            };

            get_upload_url.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_upload_url.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.get_upload_url";
            };

            return get_upload_url;
        })();

        OrdersApiRequest.get_download_url = (function() {

            function get_download_url(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_download_url.prototype.file_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            get_download_url.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.file_id != null && Object.hasOwnProperty.call(message, "file_id"))
                    writer.uint32(8).uint64(message.file_id);
                return writer;
            };

            get_download_url.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.get_download_url();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.file_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_download_url.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.get_download_url)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.get_download_url();
                if (object.file_id != null)
                    if ($util.Long)
                        (message.file_id = $util.Long.fromValue(object.file_id)).unsigned = true;
                    else if (typeof object.file_id === "string")
                        message.file_id = parseInt(object.file_id, 10);
                    else if (typeof object.file_id === "number")
                        message.file_id = object.file_id;
                    else if (typeof object.file_id === "object")
                        message.file_id = new $util.LongBits(object.file_id.low >>> 0, object.file_id.high >>> 0).toNumber(true);
                return message;
            };

            get_download_url.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.file_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.file_id = options.longs === String ? "0" : 0;
                if (message.file_id != null && message.hasOwnProperty("file_id"))
                    if (typeof message.file_id === "number")
                        object.file_id = options.longs === String ? String(message.file_id) : message.file_id;
                    else
                        object.file_id = options.longs === String ? $util.Long.prototype.toString.call(message.file_id) : options.longs === Number ? new $util.LongBits(message.file_id.low >>> 0, message.file_id.high >>> 0).toNumber(true) : message.file_id;
                return object;
            };

            get_download_url.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_download_url.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.get_download_url";
            };

            return get_download_url;
        })();

        OrdersApiRequest.request_commercial_offer_for_order = (function() {

            function request_commercial_offer_for_order(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            request_commercial_offer_for_order.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            request_commercial_offer_for_order.prototype.stage_number = 0;
            request_commercial_offer_for_order.prototype.packed_contact_info = $util.newBuffer([]);
            request_commercial_offer_for_order.prototype.message = "";
            request_commercial_offer_for_order.prototype.order_file_ids = $util.newBuffer([]);
            request_commercial_offer_for_order.prototype.stage_file_ids = $util.newBuffer([]);

            request_commercial_offer_for_order.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                    writer.uint32(8).uint64(message.order_id);
                if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                    writer.uint32(16).int32(message.stage_number);
                if (message.packed_contact_info != null && Object.hasOwnProperty.call(message, "packed_contact_info"))
                    writer.uint32(26).bytes(message.packed_contact_info);
                if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                    writer.uint32(34).string(message.message);
                if (message.order_file_ids != null && Object.hasOwnProperty.call(message, "order_file_ids"))
                    writer.uint32(42).bytes(message.order_file_ids);
                if (message.stage_file_ids != null && Object.hasOwnProperty.call(message, "stage_file_ids"))
                    writer.uint32(50).bytes(message.stage_file_ids);
                return writer;
            };

            request_commercial_offer_for_order.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.request_commercial_offer_for_order();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.order_id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.stage_number = reader.int32();
                            break;
                        }
                    case 3: {
                            message.packed_contact_info = reader.bytes();
                            break;
                        }
                    case 4: {
                            message.message = reader.string();
                            break;
                        }
                    case 5: {
                            message.order_file_ids = reader.bytes();
                            break;
                        }
                    case 6: {
                            message.stage_file_ids = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            request_commercial_offer_for_order.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.request_commercial_offer_for_order)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.request_commercial_offer_for_order();
                if (object.order_id != null)
                    if ($util.Long)
                        (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                    else if (typeof object.order_id === "string")
                        message.order_id = parseInt(object.order_id, 10);
                    else if (typeof object.order_id === "number")
                        message.order_id = object.order_id;
                    else if (typeof object.order_id === "object")
                        message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
                if (object.stage_number != null)
                    message.stage_number = object.stage_number | 0;
                if (object.packed_contact_info != null)
                    if (typeof object.packed_contact_info === "string")
                        $util.base64.decode(object.packed_contact_info, message.packed_contact_info = $util.newBuffer($util.base64.length(object.packed_contact_info)), 0);
                    else if (object.packed_contact_info.length >= 0)
                        message.packed_contact_info = object.packed_contact_info;
                if (object.message != null)
                    message.message = String(object.message);
                if (object.order_file_ids != null)
                    if (typeof object.order_file_ids === "string")
                        $util.base64.decode(object.order_file_ids, message.order_file_ids = $util.newBuffer($util.base64.length(object.order_file_ids)), 0);
                    else if (object.order_file_ids.length >= 0)
                        message.order_file_ids = object.order_file_ids;
                if (object.stage_file_ids != null)
                    if (typeof object.stage_file_ids === "string")
                        $util.base64.decode(object.stage_file_ids, message.stage_file_ids = $util.newBuffer($util.base64.length(object.stage_file_ids)), 0);
                    else if (object.stage_file_ids.length >= 0)
                        message.stage_file_ids = object.stage_file_ids;
                return message;
            };

            request_commercial_offer_for_order.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.order_id = options.longs === String ? "0" : 0;
                    object.stage_number = 0;
                    if (options.bytes === String)
                        object.packed_contact_info = "";
                    else {
                        object.packed_contact_info = [];
                        if (options.bytes !== Array)
                            object.packed_contact_info = $util.newBuffer(object.packed_contact_info);
                    }
                    object.message = "";
                    if (options.bytes === String)
                        object.order_file_ids = "";
                    else {
                        object.order_file_ids = [];
                        if (options.bytes !== Array)
                            object.order_file_ids = $util.newBuffer(object.order_file_ids);
                    }
                    if (options.bytes === String)
                        object.stage_file_ids = "";
                    else {
                        object.stage_file_ids = [];
                        if (options.bytes !== Array)
                            object.stage_file_ids = $util.newBuffer(object.stage_file_ids);
                    }
                }
                if (message.order_id != null && message.hasOwnProperty("order_id"))
                    if (typeof message.order_id === "number")
                        object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                    else
                        object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
                if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                    object.stage_number = message.stage_number;
                if (message.packed_contact_info != null && message.hasOwnProperty("packed_contact_info"))
                    object.packed_contact_info = options.bytes === String ? $util.base64.encode(message.packed_contact_info, 0, message.packed_contact_info.length) : options.bytes === Array ? Array.prototype.slice.call(message.packed_contact_info) : message.packed_contact_info;
                if (message.message != null && message.hasOwnProperty("message"))
                    object.message = message.message;
                if (message.order_file_ids != null && message.hasOwnProperty("order_file_ids"))
                    object.order_file_ids = options.bytes === String ? $util.base64.encode(message.order_file_ids, 0, message.order_file_ids.length) : options.bytes === Array ? Array.prototype.slice.call(message.order_file_ids) : message.order_file_ids;
                if (message.stage_file_ids != null && message.hasOwnProperty("stage_file_ids"))
                    object.stage_file_ids = options.bytes === String ? $util.base64.encode(message.stage_file_ids, 0, message.stage_file_ids.length) : options.bytes === Array ? Array.prototype.slice.call(message.stage_file_ids) : message.stage_file_ids;
                return object;
            };

            request_commercial_offer_for_order.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            request_commercial_offer_for_order.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.request_commercial_offer_for_order";
            };

            return request_commercial_offer_for_order;
        })();

        OrdersApiRequest.set_commercial_offer_reply_time = (function() {

            function set_commercial_offer_reply_time(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            set_commercial_offer_reply_time.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            set_commercial_offer_reply_time.prototype.stage_number = 0;
            set_commercial_offer_reply_time.prototype.receiver = "";
            set_commercial_offer_reply_time.prototype.receiver_type = "";
            set_commercial_offer_reply_time.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            set_commercial_offer_reply_time.prototype.send_time = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            set_commercial_offer_reply_time.prototype.reply_time = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            set_commercial_offer_reply_time.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                    writer.uint32(8).uint64(message.order_id);
                if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                    writer.uint32(16).int32(message.stage_number);
                if (message.receiver != null && Object.hasOwnProperty.call(message, "receiver"))
                    writer.uint32(26).string(message.receiver);
                if (message.receiver_type != null && Object.hasOwnProperty.call(message, "receiver_type"))
                    writer.uint32(34).string(message.receiver_type);
                if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                    writer.uint32(40).uint64(message.place_id);
                if (message.send_time != null && Object.hasOwnProperty.call(message, "send_time"))
                    writer.uint32(48).uint64(message.send_time);
                if (message.reply_time != null && Object.hasOwnProperty.call(message, "reply_time"))
                    writer.uint32(56).uint64(message.reply_time);
                return writer;
            };

            set_commercial_offer_reply_time.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.set_commercial_offer_reply_time();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.order_id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.stage_number = reader.int32();
                            break;
                        }
                    case 3: {
                            message.receiver = reader.string();
                            break;
                        }
                    case 4: {
                            message.receiver_type = reader.string();
                            break;
                        }
                    case 5: {
                            message.place_id = reader.uint64();
                            break;
                        }
                    case 6: {
                            message.send_time = reader.uint64();
                            break;
                        }
                    case 7: {
                            message.reply_time = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            set_commercial_offer_reply_time.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.set_commercial_offer_reply_time)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.set_commercial_offer_reply_time();
                if (object.order_id != null)
                    if ($util.Long)
                        (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                    else if (typeof object.order_id === "string")
                        message.order_id = parseInt(object.order_id, 10);
                    else if (typeof object.order_id === "number")
                        message.order_id = object.order_id;
                    else if (typeof object.order_id === "object")
                        message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
                if (object.stage_number != null)
                    message.stage_number = object.stage_number | 0;
                if (object.receiver != null)
                    message.receiver = String(object.receiver);
                if (object.receiver_type != null)
                    message.receiver_type = String(object.receiver_type);
                if (object.place_id != null)
                    if ($util.Long)
                        (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                    else if (typeof object.place_id === "string")
                        message.place_id = parseInt(object.place_id, 10);
                    else if (typeof object.place_id === "number")
                        message.place_id = object.place_id;
                    else if (typeof object.place_id === "object")
                        message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
                if (object.send_time != null)
                    if ($util.Long)
                        (message.send_time = $util.Long.fromValue(object.send_time)).unsigned = true;
                    else if (typeof object.send_time === "string")
                        message.send_time = parseInt(object.send_time, 10);
                    else if (typeof object.send_time === "number")
                        message.send_time = object.send_time;
                    else if (typeof object.send_time === "object")
                        message.send_time = new $util.LongBits(object.send_time.low >>> 0, object.send_time.high >>> 0).toNumber(true);
                if (object.reply_time != null)
                    if ($util.Long)
                        (message.reply_time = $util.Long.fromValue(object.reply_time)).unsigned = true;
                    else if (typeof object.reply_time === "string")
                        message.reply_time = parseInt(object.reply_time, 10);
                    else if (typeof object.reply_time === "number")
                        message.reply_time = object.reply_time;
                    else if (typeof object.reply_time === "object")
                        message.reply_time = new $util.LongBits(object.reply_time.low >>> 0, object.reply_time.high >>> 0).toNumber(true);
                return message;
            };

            set_commercial_offer_reply_time.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.order_id = options.longs === String ? "0" : 0;
                    object.stage_number = 0;
                    object.receiver = "";
                    object.receiver_type = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.place_id = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.send_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.send_time = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.reply_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.reply_time = options.longs === String ? "0" : 0;
                }
                if (message.order_id != null && message.hasOwnProperty("order_id"))
                    if (typeof message.order_id === "number")
                        object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                    else
                        object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
                if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                    object.stage_number = message.stage_number;
                if (message.receiver != null && message.hasOwnProperty("receiver"))
                    object.receiver = message.receiver;
                if (message.receiver_type != null && message.hasOwnProperty("receiver_type"))
                    object.receiver_type = message.receiver_type;
                if (message.place_id != null && message.hasOwnProperty("place_id"))
                    if (typeof message.place_id === "number")
                        object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                    else
                        object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
                if (message.send_time != null && message.hasOwnProperty("send_time"))
                    if (typeof message.send_time === "number")
                        object.send_time = options.longs === String ? String(message.send_time) : message.send_time;
                    else
                        object.send_time = options.longs === String ? $util.Long.prototype.toString.call(message.send_time) : options.longs === Number ? new $util.LongBits(message.send_time.low >>> 0, message.send_time.high >>> 0).toNumber(true) : message.send_time;
                if (message.reply_time != null && message.hasOwnProperty("reply_time"))
                    if (typeof message.reply_time === "number")
                        object.reply_time = options.longs === String ? String(message.reply_time) : message.reply_time;
                    else
                        object.reply_time = options.longs === String ? $util.Long.prototype.toString.call(message.reply_time) : options.longs === Number ? new $util.LongBits(message.reply_time.low >>> 0, message.reply_time.high >>> 0).toNumber(true) : message.reply_time;
                return object;
            };

            set_commercial_offer_reply_time.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            set_commercial_offer_reply_time.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.set_commercial_offer_reply_time";
            };

            return set_commercial_offer_reply_time;
        })();

        OrdersApiRequest.reload_commercial_offer_reply_offers = (function() {

            function reload_commercial_offer_reply_offers(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            reload_commercial_offer_reply_offers.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            reload_commercial_offer_reply_offers.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                    writer.uint32(8).uint64(message.order_id);
                return writer;
            };

            reload_commercial_offer_reply_offers.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.reload_commercial_offer_reply_offers();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.order_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            reload_commercial_offer_reply_offers.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.reload_commercial_offer_reply_offers)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.reload_commercial_offer_reply_offers();
                if (object.order_id != null)
                    if ($util.Long)
                        (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                    else if (typeof object.order_id === "string")
                        message.order_id = parseInt(object.order_id, 10);
                    else if (typeof object.order_id === "number")
                        message.order_id = object.order_id;
                    else if (typeof object.order_id === "object")
                        message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
                return message;
            };

            reload_commercial_offer_reply_offers.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.order_id = options.longs === String ? "0" : 0;
                if (message.order_id != null && message.hasOwnProperty("order_id"))
                    if (typeof message.order_id === "number")
                        object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                    else
                        object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
                return object;
            };

            reload_commercial_offer_reply_offers.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            reload_commercial_offer_reply_offers.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.reload_commercial_offer_reply_offers";
            };

            return reload_commercial_offer_reply_offers;
        })();

        OrdersApiRequest.verify_entities_on_successfull_outgoing_payment = (function() {

            function verify_entities_on_successfull_outgoing_payment(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            verify_entities_on_successfull_outgoing_payment.prototype.src_place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            verify_entities_on_successfull_outgoing_payment.prototype.src_org_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            verify_entities_on_successfull_outgoing_payment.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            verify_entities_on_successfull_outgoing_payment.prototype.stage_number = 0;

            verify_entities_on_successfull_outgoing_payment.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.src_place_id != null && Object.hasOwnProperty.call(message, "src_place_id"))
                    writer.uint32(8).uint64(message.src_place_id);
                if (message.src_org_id != null && Object.hasOwnProperty.call(message, "src_org_id"))
                    writer.uint32(16).uint64(message.src_org_id);
                if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                    writer.uint32(24).uint64(message.order_id);
                if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                    writer.uint32(32).int32(message.stage_number);
                return writer;
            };

            verify_entities_on_successfull_outgoing_payment.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.verify_entities_on_successfull_outgoing_payment();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.src_place_id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.src_org_id = reader.uint64();
                            break;
                        }
                    case 3: {
                            message.order_id = reader.uint64();
                            break;
                        }
                    case 4: {
                            message.stage_number = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            verify_entities_on_successfull_outgoing_payment.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.verify_entities_on_successfull_outgoing_payment)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.verify_entities_on_successfull_outgoing_payment();
                if (object.src_place_id != null)
                    if ($util.Long)
                        (message.src_place_id = $util.Long.fromValue(object.src_place_id)).unsigned = true;
                    else if (typeof object.src_place_id === "string")
                        message.src_place_id = parseInt(object.src_place_id, 10);
                    else if (typeof object.src_place_id === "number")
                        message.src_place_id = object.src_place_id;
                    else if (typeof object.src_place_id === "object")
                        message.src_place_id = new $util.LongBits(object.src_place_id.low >>> 0, object.src_place_id.high >>> 0).toNumber(true);
                if (object.src_org_id != null)
                    if ($util.Long)
                        (message.src_org_id = $util.Long.fromValue(object.src_org_id)).unsigned = true;
                    else if (typeof object.src_org_id === "string")
                        message.src_org_id = parseInt(object.src_org_id, 10);
                    else if (typeof object.src_org_id === "number")
                        message.src_org_id = object.src_org_id;
                    else if (typeof object.src_org_id === "object")
                        message.src_org_id = new $util.LongBits(object.src_org_id.low >>> 0, object.src_org_id.high >>> 0).toNumber(true);
                if (object.order_id != null)
                    if ($util.Long)
                        (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                    else if (typeof object.order_id === "string")
                        message.order_id = parseInt(object.order_id, 10);
                    else if (typeof object.order_id === "number")
                        message.order_id = object.order_id;
                    else if (typeof object.order_id === "object")
                        message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
                if (object.stage_number != null)
                    message.stage_number = object.stage_number | 0;
                return message;
            };

            verify_entities_on_successfull_outgoing_payment.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.src_place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.src_place_id = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.src_org_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.src_org_id = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.order_id = options.longs === String ? "0" : 0;
                    object.stage_number = 0;
                }
                if (message.src_place_id != null && message.hasOwnProperty("src_place_id"))
                    if (typeof message.src_place_id === "number")
                        object.src_place_id = options.longs === String ? String(message.src_place_id) : message.src_place_id;
                    else
                        object.src_place_id = options.longs === String ? $util.Long.prototype.toString.call(message.src_place_id) : options.longs === Number ? new $util.LongBits(message.src_place_id.low >>> 0, message.src_place_id.high >>> 0).toNumber(true) : message.src_place_id;
                if (message.src_org_id != null && message.hasOwnProperty("src_org_id"))
                    if (typeof message.src_org_id === "number")
                        object.src_org_id = options.longs === String ? String(message.src_org_id) : message.src_org_id;
                    else
                        object.src_org_id = options.longs === String ? $util.Long.prototype.toString.call(message.src_org_id) : options.longs === Number ? new $util.LongBits(message.src_org_id.low >>> 0, message.src_org_id.high >>> 0).toNumber(true) : message.src_org_id;
                if (message.order_id != null && message.hasOwnProperty("order_id"))
                    if (typeof message.order_id === "number")
                        object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                    else
                        object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
                if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                    object.stage_number = message.stage_number;
                return object;
            };

            verify_entities_on_successfull_outgoing_payment.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            verify_entities_on_successfull_outgoing_payment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.verify_entities_on_successfull_outgoing_payment";
            };

            return verify_entities_on_successfull_outgoing_payment;
        })();

        OrdersApiRequest.verify_entities_on_successfull_incoming_payment = (function() {

            function verify_entities_on_successfull_incoming_payment(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            verify_entities_on_successfull_incoming_payment.prototype.src_org_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            verify_entities_on_successfull_incoming_payment.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            verify_entities_on_successfull_incoming_payment.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.src_org_id != null && Object.hasOwnProperty.call(message, "src_org_id"))
                    writer.uint32(8).uint64(message.src_org_id);
                if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                    writer.uint32(16).uint64(message.order_id);
                return writer;
            };

            verify_entities_on_successfull_incoming_payment.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.verify_entities_on_successfull_incoming_payment();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.src_org_id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.order_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            verify_entities_on_successfull_incoming_payment.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.verify_entities_on_successfull_incoming_payment)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.verify_entities_on_successfull_incoming_payment();
                if (object.src_org_id != null)
                    if ($util.Long)
                        (message.src_org_id = $util.Long.fromValue(object.src_org_id)).unsigned = true;
                    else if (typeof object.src_org_id === "string")
                        message.src_org_id = parseInt(object.src_org_id, 10);
                    else if (typeof object.src_org_id === "number")
                        message.src_org_id = object.src_org_id;
                    else if (typeof object.src_org_id === "object")
                        message.src_org_id = new $util.LongBits(object.src_org_id.low >>> 0, object.src_org_id.high >>> 0).toNumber(true);
                if (object.order_id != null)
                    if ($util.Long)
                        (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                    else if (typeof object.order_id === "string")
                        message.order_id = parseInt(object.order_id, 10);
                    else if (typeof object.order_id === "number")
                        message.order_id = object.order_id;
                    else if (typeof object.order_id === "object")
                        message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
                return message;
            };

            verify_entities_on_successfull_incoming_payment.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.src_org_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.src_org_id = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.order_id = options.longs === String ? "0" : 0;
                }
                if (message.src_org_id != null && message.hasOwnProperty("src_org_id"))
                    if (typeof message.src_org_id === "number")
                        object.src_org_id = options.longs === String ? String(message.src_org_id) : message.src_org_id;
                    else
                        object.src_org_id = options.longs === String ? $util.Long.prototype.toString.call(message.src_org_id) : options.longs === Number ? new $util.LongBits(message.src_org_id.low >>> 0, message.src_org_id.high >>> 0).toNumber(true) : message.src_org_id;
                if (message.order_id != null && message.hasOwnProperty("order_id"))
                    if (typeof message.order_id === "number")
                        object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                    else
                        object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
                return object;
            };

            verify_entities_on_successfull_incoming_payment.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            verify_entities_on_successfull_incoming_payment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.verify_entities_on_successfull_incoming_payment";
            };

            return verify_entities_on_successfull_incoming_payment;
        })();

        return OrdersApiRequest;
    })();

    object_msg.search_orders_return_msg = (function() {

        function search_orders_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        search_orders_return_msg.prototype.items = $util.emptyArray;

        search_orders_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.object_msg.search_orders_return_msg.ItemWithId.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        search_orders_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_orders_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.object_msg.search_orders_return_msg.ItemWithId.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        search_orders_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.search_orders_return_msg)
                return object;
            let message = new $root.object_msg.search_orders_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.search_orders_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".object_msg.search_orders_return_msg.items: object expected");
                    message.items[i] = $root.object_msg.search_orders_return_msg.ItemWithId.fromObject(object.items[i]);
                }
            }
            return message;
        };

        search_orders_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.object_msg.search_orders_return_msg.ItemWithId.toObject(message.items[j], options);
            }
            return object;
        };

        search_orders_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        search_orders_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.search_orders_return_msg";
        };

        search_orders_return_msg.ItemWithId = (function() {

            function ItemWithId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            ItemWithId.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            ItemWithId.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            ItemWithId.prototype.creation_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            ItemWithId.prototype.name = "";
            ItemWithId.prototype.status_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            ItemWithId.prototype.status = "";
            ItemWithId.prototype.stages_approved = false;
            ItemWithId.prototype.stages_price_set = false;

            ItemWithId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(8).uint64(message.orderId);
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(16).uint64(message.userId);
                if (message.creation_time != null && Object.hasOwnProperty.call(message, "creation_time"))
                    writer.uint32(24).int64(message.creation_time);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(34).string(message.name);
                if (message.status_time != null && Object.hasOwnProperty.call(message, "status_time"))
                    writer.uint32(40).int64(message.status_time);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(50).string(message.status);
                if (message.stages_approved != null && Object.hasOwnProperty.call(message, "stages_approved"))
                    writer.uint32(56).bool(message.stages_approved);
                if (message.stages_price_set != null && Object.hasOwnProperty.call(message, "stages_price_set"))
                    writer.uint32(64).bool(message.stages_price_set);
                return writer;
            };

            ItemWithId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_orders_return_msg.ItemWithId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.orderId = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.userId = reader.uint64();
                            break;
                        }
                    case 3: {
                            message.creation_time = reader.int64();
                            break;
                        }
                    case 4: {
                            message.name = reader.string();
                            break;
                        }
                    case 5: {
                            message.status_time = reader.int64();
                            break;
                        }
                    case 6: {
                            message.status = reader.string();
                            break;
                        }
                    case 7: {
                            message.stages_approved = reader.bool();
                            break;
                        }
                    case 8: {
                            message.stages_price_set = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            ItemWithId.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.search_orders_return_msg.ItemWithId)
                    return object;
                let message = new $root.object_msg.search_orders_return_msg.ItemWithId();
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = true;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber(true);
                if (object.userId != null)
                    if ($util.Long)
                        (message.userId = $util.Long.fromValue(object.userId)).unsigned = true;
                    else if (typeof object.userId === "string")
                        message.userId = parseInt(object.userId, 10);
                    else if (typeof object.userId === "number")
                        message.userId = object.userId;
                    else if (typeof object.userId === "object")
                        message.userId = new $util.LongBits(object.userId.low >>> 0, object.userId.high >>> 0).toNumber(true);
                if (object.creation_time != null)
                    if ($util.Long)
                        (message.creation_time = $util.Long.fromValue(object.creation_time)).unsigned = false;
                    else if (typeof object.creation_time === "string")
                        message.creation_time = parseInt(object.creation_time, 10);
                    else if (typeof object.creation_time === "number")
                        message.creation_time = object.creation_time;
                    else if (typeof object.creation_time === "object")
                        message.creation_time = new $util.LongBits(object.creation_time.low >>> 0, object.creation_time.high >>> 0).toNumber();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.status_time != null)
                    if ($util.Long)
                        (message.status_time = $util.Long.fromValue(object.status_time)).unsigned = false;
                    else if (typeof object.status_time === "string")
                        message.status_time = parseInt(object.status_time, 10);
                    else if (typeof object.status_time === "number")
                        message.status_time = object.status_time;
                    else if (typeof object.status_time === "object")
                        message.status_time = new $util.LongBits(object.status_time.low >>> 0, object.status_time.high >>> 0).toNumber();
                if (object.status != null)
                    message.status = String(object.status);
                if (object.stages_approved != null)
                    message.stages_approved = Boolean(object.stages_approved);
                if (object.stages_price_set != null)
                    message.stages_price_set = Boolean(object.stages_price_set);
                return message;
            };

            ItemWithId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.userId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.userId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.creation_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.creation_time = options.longs === String ? "0" : 0;
                    object.name = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.status_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.status_time = options.longs === String ? "0" : 0;
                    object.status = "";
                    object.stages_approved = false;
                    object.stages_price_set = false;
                }
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber(true) : message.orderId;
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (typeof message.userId === "number")
                        object.userId = options.longs === String ? String(message.userId) : message.userId;
                    else
                        object.userId = options.longs === String ? $util.Long.prototype.toString.call(message.userId) : options.longs === Number ? new $util.LongBits(message.userId.low >>> 0, message.userId.high >>> 0).toNumber(true) : message.userId;
                if (message.creation_time != null && message.hasOwnProperty("creation_time"))
                    if (typeof message.creation_time === "number")
                        object.creation_time = options.longs === String ? String(message.creation_time) : message.creation_time;
                    else
                        object.creation_time = options.longs === String ? $util.Long.prototype.toString.call(message.creation_time) : options.longs === Number ? new $util.LongBits(message.creation_time.low >>> 0, message.creation_time.high >>> 0).toNumber() : message.creation_time;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.status_time != null && message.hasOwnProperty("status_time"))
                    if (typeof message.status_time === "number")
                        object.status_time = options.longs === String ? String(message.status_time) : message.status_time;
                    else
                        object.status_time = options.longs === String ? $util.Long.prototype.toString.call(message.status_time) : options.longs === Number ? new $util.LongBits(message.status_time.low >>> 0, message.status_time.high >>> 0).toNumber() : message.status_time;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = message.status;
                if (message.stages_approved != null && message.hasOwnProperty("stages_approved"))
                    object.stages_approved = message.stages_approved;
                if (message.stages_price_set != null && message.hasOwnProperty("stages_price_set"))
                    object.stages_price_set = message.stages_price_set;
                return object;
            };

            ItemWithId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            ItemWithId.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.search_orders_return_msg.ItemWithId";
            };

            return ItemWithId;
        })();

        return search_orders_return_msg;
    })();

    object_msg.get_stage_action_approximate_price_return_msg = (function() {

        function get_stage_action_approximate_price_return_msg(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        get_stage_action_approximate_price_return_msg.prototype.approximate_price = 0;
        get_stage_action_approximate_price_return_msg.prototype.in_predefined_range = false;

        get_stage_action_approximate_price_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.approximate_price != null && Object.hasOwnProperty.call(message, "approximate_price"))
                writer.uint32(13).float(message.approximate_price);
            if (message.in_predefined_range != null && Object.hasOwnProperty.call(message, "in_predefined_range"))
                writer.uint32(16).bool(message.in_predefined_range);
            return writer;
        };

        get_stage_action_approximate_price_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_stage_action_approximate_price_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.approximate_price = reader.float();
                        break;
                    }
                case 2: {
                        message.in_predefined_range = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        get_stage_action_approximate_price_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.get_stage_action_approximate_price_return_msg)
                return object;
            let message = new $root.object_msg.get_stage_action_approximate_price_return_msg();
            if (object.approximate_price != null)
                message.approximate_price = Number(object.approximate_price);
            if (object.in_predefined_range != null)
                message.in_predefined_range = Boolean(object.in_predefined_range);
            return message;
        };

        get_stage_action_approximate_price_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.approximate_price = 0;
                object.in_predefined_range = false;
            }
            if (message.approximate_price != null && message.hasOwnProperty("approximate_price"))
                object.approximate_price = options.json && !isFinite(message.approximate_price) ? String(message.approximate_price) : message.approximate_price;
            if (message.in_predefined_range != null && message.hasOwnProperty("in_predefined_range"))
                object.in_predefined_range = message.in_predefined_range;
            return object;
        };

        get_stage_action_approximate_price_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        get_stage_action_approximate_price_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.get_stage_action_approximate_price_return_msg";
        };

        return get_stage_action_approximate_price_return_msg;
    })();

    object_msg.get_action_approximate_prices_for_manager_return_msg = (function() {

        function get_action_approximate_prices_for_manager_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        get_action_approximate_prices_for_manager_return_msg.prototype.items = $util.emptyArray;

        get_action_approximate_prices_for_manager_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.object_msg.get_action_approximate_prices_for_manager_return_msg.ItemWithPrice.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        get_action_approximate_prices_for_manager_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_action_approximate_prices_for_manager_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.object_msg.get_action_approximate_prices_for_manager_return_msg.ItemWithPrice.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        get_action_approximate_prices_for_manager_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.get_action_approximate_prices_for_manager_return_msg)
                return object;
            let message = new $root.object_msg.get_action_approximate_prices_for_manager_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.get_action_approximate_prices_for_manager_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".object_msg.get_action_approximate_prices_for_manager_return_msg.items: object expected");
                    message.items[i] = $root.object_msg.get_action_approximate_prices_for_manager_return_msg.ItemWithPrice.fromObject(object.items[i]);
                }
            }
            return message;
        };

        get_action_approximate_prices_for_manager_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.object_msg.get_action_approximate_prices_for_manager_return_msg.ItemWithPrice.toObject(message.items[j], options);
            }
            return object;
        };

        get_action_approximate_prices_for_manager_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        get_action_approximate_prices_for_manager_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.get_action_approximate_prices_for_manager_return_msg";
        };

        get_action_approximate_prices_for_manager_return_msg.ItemWithPrice = (function() {

            function ItemWithPrice(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            ItemWithPrice.prototype.approximate_price = 0;
            ItemWithPrice.prototype.distance = 0;
            ItemWithPrice.prototype.in_predefined_range = false;

            ItemWithPrice.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.approximate_price != null && Object.hasOwnProperty.call(message, "approximate_price"))
                    writer.uint32(13).float(message.approximate_price);
                if (message.distance != null && Object.hasOwnProperty.call(message, "distance"))
                    writer.uint32(21).float(message.distance);
                if (message.in_predefined_range != null && Object.hasOwnProperty.call(message, "in_predefined_range"))
                    writer.uint32(24).bool(message.in_predefined_range);
                return writer;
            };

            ItemWithPrice.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_action_approximate_prices_for_manager_return_msg.ItemWithPrice();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.approximate_price = reader.float();
                            break;
                        }
                    case 2: {
                            message.distance = reader.float();
                            break;
                        }
                    case 3: {
                            message.in_predefined_range = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            ItemWithPrice.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.get_action_approximate_prices_for_manager_return_msg.ItemWithPrice)
                    return object;
                let message = new $root.object_msg.get_action_approximate_prices_for_manager_return_msg.ItemWithPrice();
                if (object.approximate_price != null)
                    message.approximate_price = Number(object.approximate_price);
                if (object.distance != null)
                    message.distance = Number(object.distance);
                if (object.in_predefined_range != null)
                    message.in_predefined_range = Boolean(object.in_predefined_range);
                return message;
            };

            ItemWithPrice.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.approximate_price = 0;
                    object.distance = 0;
                    object.in_predefined_range = false;
                }
                if (message.approximate_price != null && message.hasOwnProperty("approximate_price"))
                    object.approximate_price = options.json && !isFinite(message.approximate_price) ? String(message.approximate_price) : message.approximate_price;
                if (message.distance != null && message.hasOwnProperty("distance"))
                    object.distance = options.json && !isFinite(message.distance) ? String(message.distance) : message.distance;
                if (message.in_predefined_range != null && message.hasOwnProperty("in_predefined_range"))
                    object.in_predefined_range = message.in_predefined_range;
                return object;
            };

            ItemWithPrice.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            ItemWithPrice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.get_action_approximate_prices_for_manager_return_msg.ItemWithPrice";
            };

            return ItemWithPrice;
        })();

        return get_action_approximate_prices_for_manager_return_msg;
    })();

    object_msg.get_upload_url_return_msg = (function() {

        function get_upload_url_return_msg(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        get_upload_url_return_msg.prototype.url = "";
        get_upload_url_return_msg.prototype.file_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        get_upload_url_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(10).string(message.url);
            if (message.file_id != null && Object.hasOwnProperty.call(message, "file_id"))
                writer.uint32(16).uint64(message.file_id);
            return writer;
        };

        get_upload_url_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_upload_url_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.url = reader.string();
                        break;
                    }
                case 2: {
                        message.file_id = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        get_upload_url_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.get_upload_url_return_msg)
                return object;
            let message = new $root.object_msg.get_upload_url_return_msg();
            if (object.url != null)
                message.url = String(object.url);
            if (object.file_id != null)
                if ($util.Long)
                    (message.file_id = $util.Long.fromValue(object.file_id)).unsigned = true;
                else if (typeof object.file_id === "string")
                    message.file_id = parseInt(object.file_id, 10);
                else if (typeof object.file_id === "number")
                    message.file_id = object.file_id;
                else if (typeof object.file_id === "object")
                    message.file_id = new $util.LongBits(object.file_id.low >>> 0, object.file_id.high >>> 0).toNumber(true);
            return message;
        };

        get_upload_url_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.url = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.file_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.file_id = options.longs === String ? "0" : 0;
            }
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.file_id != null && message.hasOwnProperty("file_id"))
                if (typeof message.file_id === "number")
                    object.file_id = options.longs === String ? String(message.file_id) : message.file_id;
                else
                    object.file_id = options.longs === String ? $util.Long.prototype.toString.call(message.file_id) : options.longs === Number ? new $util.LongBits(message.file_id.low >>> 0, message.file_id.high >>> 0).toNumber(true) : message.file_id;
            return object;
        };

        get_upload_url_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        get_upload_url_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.get_upload_url_return_msg";
        };

        return get_upload_url_return_msg;
    })();

    object_msg.AIApiRequest = (function() {

        function AIApiRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        AIApiRequest.prototype.start_crawl_args = null;
        AIApiRequest.prototype.start_crawl_global_args = null;
        AIApiRequest.prototype.start_crawl_global_sequential_args = null;
        AIApiRequest.prototype.set_not_crawled_global_args = null;
        AIApiRequest.prototype.delete_crawl_global_args = null;
        AIApiRequest.prototype.delete_crawl_args = null;
        AIApiRequest.prototype.select_website_args = null;
        AIApiRequest.prototype.set_postpone_website_args = null;
        AIApiRequest.prototype.replace_url_in_case_of_redirect_args = null;
        AIApiRequest.prototype.collect_links_from_website_args = null;
        AIApiRequest.prototype.add_permanent_useless_path_args = null;
        AIApiRequest.prototype.set_useless_path_permanent_args = null;
        AIApiRequest.prototype.delete_pending_pages_with_useless_path_args = null;
        AIApiRequest.prototype.discard_useless_path_args = null;
        AIApiRequest.prototype.delete_pending_page_args = null;
        AIApiRequest.prototype.start_scrapping_websites_from_map_args = null;
        AIApiRequest.prototype.open_websites_in_selenium_args = null;
        AIApiRequest.prototype.delete_price_path_args = null;
        AIApiRequest.prototype.remove_place_coordinates_and_address_quality_args = null;
        AIApiRequest.prototype.set_place_coordinates_args = null;

        let $oneOfFields;

        Object.defineProperty(AIApiRequest.prototype, "args", {
            get: $util.oneOfGetter($oneOfFields = ["start_crawl_args", "start_crawl_global_args", "start_crawl_global_sequential_args", "set_not_crawled_global_args", "delete_crawl_global_args", "delete_crawl_args", "select_website_args", "set_postpone_website_args", "replace_url_in_case_of_redirect_args", "collect_links_from_website_args", "add_permanent_useless_path_args", "set_useless_path_permanent_args", "delete_pending_pages_with_useless_path_args", "discard_useless_path_args", "delete_pending_page_args", "start_scrapping_websites_from_map_args", "open_websites_in_selenium_args", "delete_price_path_args", "remove_place_coordinates_and_address_quality_args", "set_place_coordinates_args"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        AIApiRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.start_crawl_args != null && Object.hasOwnProperty.call(message, "start_crawl_args"))
                $root.object_msg.AIApiRequest.start_crawl.encode(message.start_crawl_args, writer.uint32(10).fork()).ldelim();
            if (message.start_crawl_global_args != null && Object.hasOwnProperty.call(message, "start_crawl_global_args"))
                $root.object_msg.AIApiRequest.start_crawl_global.encode(message.start_crawl_global_args, writer.uint32(18).fork()).ldelim();
            if (message.start_crawl_global_sequential_args != null && Object.hasOwnProperty.call(message, "start_crawl_global_sequential_args"))
                $root.object_msg.AIApiRequest.start_crawl_global_sequential.encode(message.start_crawl_global_sequential_args, writer.uint32(26).fork()).ldelim();
            if (message.set_not_crawled_global_args != null && Object.hasOwnProperty.call(message, "set_not_crawled_global_args"))
                $root.object_msg.AIApiRequest.set_not_crawled_global.encode(message.set_not_crawled_global_args, writer.uint32(34).fork()).ldelim();
            if (message.delete_crawl_global_args != null && Object.hasOwnProperty.call(message, "delete_crawl_global_args"))
                $root.object_msg.AIApiRequest.delete_crawl_global.encode(message.delete_crawl_global_args, writer.uint32(42).fork()).ldelim();
            if (message.delete_crawl_args != null && Object.hasOwnProperty.call(message, "delete_crawl_args"))
                $root.object_msg.AIApiRequest.delete_crawl.encode(message.delete_crawl_args, writer.uint32(50).fork()).ldelim();
            if (message.select_website_args != null && Object.hasOwnProperty.call(message, "select_website_args"))
                $root.object_msg.AIApiRequest.select_website.encode(message.select_website_args, writer.uint32(58).fork()).ldelim();
            if (message.set_postpone_website_args != null && Object.hasOwnProperty.call(message, "set_postpone_website_args"))
                $root.object_msg.AIApiRequest.set_postpone_website.encode(message.set_postpone_website_args, writer.uint32(66).fork()).ldelim();
            if (message.replace_url_in_case_of_redirect_args != null && Object.hasOwnProperty.call(message, "replace_url_in_case_of_redirect_args"))
                $root.object_msg.AIApiRequest.replace_url_in_case_of_redirect.encode(message.replace_url_in_case_of_redirect_args, writer.uint32(74).fork()).ldelim();
            if (message.collect_links_from_website_args != null && Object.hasOwnProperty.call(message, "collect_links_from_website_args"))
                $root.object_msg.AIApiRequest.collect_links_from_website.encode(message.collect_links_from_website_args, writer.uint32(82).fork()).ldelim();
            if (message.add_permanent_useless_path_args != null && Object.hasOwnProperty.call(message, "add_permanent_useless_path_args"))
                $root.object_msg.AIApiRequest.add_permanent_useless_path.encode(message.add_permanent_useless_path_args, writer.uint32(90).fork()).ldelim();
            if (message.set_useless_path_permanent_args != null && Object.hasOwnProperty.call(message, "set_useless_path_permanent_args"))
                $root.object_msg.AIApiRequest.set_useless_path_permanent.encode(message.set_useless_path_permanent_args, writer.uint32(98).fork()).ldelim();
            if (message.delete_pending_pages_with_useless_path_args != null && Object.hasOwnProperty.call(message, "delete_pending_pages_with_useless_path_args"))
                $root.object_msg.AIApiRequest.delete_pending_pages_with_useless_path.encode(message.delete_pending_pages_with_useless_path_args, writer.uint32(106).fork()).ldelim();
            if (message.discard_useless_path_args != null && Object.hasOwnProperty.call(message, "discard_useless_path_args"))
                $root.object_msg.AIApiRequest.discard_useless_path.encode(message.discard_useless_path_args, writer.uint32(114).fork()).ldelim();
            if (message.delete_pending_page_args != null && Object.hasOwnProperty.call(message, "delete_pending_page_args"))
                $root.object_msg.AIApiRequest.delete_pending_page.encode(message.delete_pending_page_args, writer.uint32(122).fork()).ldelim();
            if (message.start_scrapping_websites_from_map_args != null && Object.hasOwnProperty.call(message, "start_scrapping_websites_from_map_args"))
                $root.object_msg.AIApiRequest.start_scrapping_websites_from_map.encode(message.start_scrapping_websites_from_map_args, writer.uint32(130).fork()).ldelim();
            if (message.open_websites_in_selenium_args != null && Object.hasOwnProperty.call(message, "open_websites_in_selenium_args"))
                $root.object_msg.AIApiRequest.open_websites_in_selenium.encode(message.open_websites_in_selenium_args, writer.uint32(138).fork()).ldelim();
            if (message.delete_price_path_args != null && Object.hasOwnProperty.call(message, "delete_price_path_args"))
                $root.object_msg.AIApiRequest.delete_price_path.encode(message.delete_price_path_args, writer.uint32(146).fork()).ldelim();
            if (message.remove_place_coordinates_and_address_quality_args != null && Object.hasOwnProperty.call(message, "remove_place_coordinates_and_address_quality_args"))
                $root.object_msg.AIApiRequest.remove_place_coordinates_and_address_quality.encode(message.remove_place_coordinates_and_address_quality_args, writer.uint32(154).fork()).ldelim();
            if (message.set_place_coordinates_args != null && Object.hasOwnProperty.call(message, "set_place_coordinates_args"))
                $root.object_msg.AIApiRequest.set_place_coordinates.encode(message.set_place_coordinates_args, writer.uint32(162).fork()).ldelim();
            return writer;
        };

        AIApiRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.AIApiRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.start_crawl_args = $root.object_msg.AIApiRequest.start_crawl.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.start_crawl_global_args = $root.object_msg.AIApiRequest.start_crawl_global.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.start_crawl_global_sequential_args = $root.object_msg.AIApiRequest.start_crawl_global_sequential.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.set_not_crawled_global_args = $root.object_msg.AIApiRequest.set_not_crawled_global.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.delete_crawl_global_args = $root.object_msg.AIApiRequest.delete_crawl_global.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.delete_crawl_args = $root.object_msg.AIApiRequest.delete_crawl.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.select_website_args = $root.object_msg.AIApiRequest.select_website.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.set_postpone_website_args = $root.object_msg.AIApiRequest.set_postpone_website.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.replace_url_in_case_of_redirect_args = $root.object_msg.AIApiRequest.replace_url_in_case_of_redirect.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.collect_links_from_website_args = $root.object_msg.AIApiRequest.collect_links_from_website.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.add_permanent_useless_path_args = $root.object_msg.AIApiRequest.add_permanent_useless_path.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.set_useless_path_permanent_args = $root.object_msg.AIApiRequest.set_useless_path_permanent.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        message.delete_pending_pages_with_useless_path_args = $root.object_msg.AIApiRequest.delete_pending_pages_with_useless_path.decode(reader, reader.uint32());
                        break;
                    }
                case 14: {
                        message.discard_useless_path_args = $root.object_msg.AIApiRequest.discard_useless_path.decode(reader, reader.uint32());
                        break;
                    }
                case 15: {
                        message.delete_pending_page_args = $root.object_msg.AIApiRequest.delete_pending_page.decode(reader, reader.uint32());
                        break;
                    }
                case 16: {
                        message.start_scrapping_websites_from_map_args = $root.object_msg.AIApiRequest.start_scrapping_websites_from_map.decode(reader, reader.uint32());
                        break;
                    }
                case 17: {
                        message.open_websites_in_selenium_args = $root.object_msg.AIApiRequest.open_websites_in_selenium.decode(reader, reader.uint32());
                        break;
                    }
                case 18: {
                        message.delete_price_path_args = $root.object_msg.AIApiRequest.delete_price_path.decode(reader, reader.uint32());
                        break;
                    }
                case 19: {
                        message.remove_place_coordinates_and_address_quality_args = $root.object_msg.AIApiRequest.remove_place_coordinates_and_address_quality.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.set_place_coordinates_args = $root.object_msg.AIApiRequest.set_place_coordinates.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        AIApiRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.AIApiRequest)
                return object;
            let message = new $root.object_msg.AIApiRequest();
            if (object.start_crawl_args != null) {
                if (typeof object.start_crawl_args !== "object")
                    throw TypeError(".object_msg.AIApiRequest.start_crawl_args: object expected");
                message.start_crawl_args = $root.object_msg.AIApiRequest.start_crawl.fromObject(object.start_crawl_args);
            }
            if (object.start_crawl_global_args != null) {
                if (typeof object.start_crawl_global_args !== "object")
                    throw TypeError(".object_msg.AIApiRequest.start_crawl_global_args: object expected");
                message.start_crawl_global_args = $root.object_msg.AIApiRequest.start_crawl_global.fromObject(object.start_crawl_global_args);
            }
            if (object.start_crawl_global_sequential_args != null) {
                if (typeof object.start_crawl_global_sequential_args !== "object")
                    throw TypeError(".object_msg.AIApiRequest.start_crawl_global_sequential_args: object expected");
                message.start_crawl_global_sequential_args = $root.object_msg.AIApiRequest.start_crawl_global_sequential.fromObject(object.start_crawl_global_sequential_args);
            }
            if (object.set_not_crawled_global_args != null) {
                if (typeof object.set_not_crawled_global_args !== "object")
                    throw TypeError(".object_msg.AIApiRequest.set_not_crawled_global_args: object expected");
                message.set_not_crawled_global_args = $root.object_msg.AIApiRequest.set_not_crawled_global.fromObject(object.set_not_crawled_global_args);
            }
            if (object.delete_crawl_global_args != null) {
                if (typeof object.delete_crawl_global_args !== "object")
                    throw TypeError(".object_msg.AIApiRequest.delete_crawl_global_args: object expected");
                message.delete_crawl_global_args = $root.object_msg.AIApiRequest.delete_crawl_global.fromObject(object.delete_crawl_global_args);
            }
            if (object.delete_crawl_args != null) {
                if (typeof object.delete_crawl_args !== "object")
                    throw TypeError(".object_msg.AIApiRequest.delete_crawl_args: object expected");
                message.delete_crawl_args = $root.object_msg.AIApiRequest.delete_crawl.fromObject(object.delete_crawl_args);
            }
            if (object.select_website_args != null) {
                if (typeof object.select_website_args !== "object")
                    throw TypeError(".object_msg.AIApiRequest.select_website_args: object expected");
                message.select_website_args = $root.object_msg.AIApiRequest.select_website.fromObject(object.select_website_args);
            }
            if (object.set_postpone_website_args != null) {
                if (typeof object.set_postpone_website_args !== "object")
                    throw TypeError(".object_msg.AIApiRequest.set_postpone_website_args: object expected");
                message.set_postpone_website_args = $root.object_msg.AIApiRequest.set_postpone_website.fromObject(object.set_postpone_website_args);
            }
            if (object.replace_url_in_case_of_redirect_args != null) {
                if (typeof object.replace_url_in_case_of_redirect_args !== "object")
                    throw TypeError(".object_msg.AIApiRequest.replace_url_in_case_of_redirect_args: object expected");
                message.replace_url_in_case_of_redirect_args = $root.object_msg.AIApiRequest.replace_url_in_case_of_redirect.fromObject(object.replace_url_in_case_of_redirect_args);
            }
            if (object.collect_links_from_website_args != null) {
                if (typeof object.collect_links_from_website_args !== "object")
                    throw TypeError(".object_msg.AIApiRequest.collect_links_from_website_args: object expected");
                message.collect_links_from_website_args = $root.object_msg.AIApiRequest.collect_links_from_website.fromObject(object.collect_links_from_website_args);
            }
            if (object.add_permanent_useless_path_args != null) {
                if (typeof object.add_permanent_useless_path_args !== "object")
                    throw TypeError(".object_msg.AIApiRequest.add_permanent_useless_path_args: object expected");
                message.add_permanent_useless_path_args = $root.object_msg.AIApiRequest.add_permanent_useless_path.fromObject(object.add_permanent_useless_path_args);
            }
            if (object.set_useless_path_permanent_args != null) {
                if (typeof object.set_useless_path_permanent_args !== "object")
                    throw TypeError(".object_msg.AIApiRequest.set_useless_path_permanent_args: object expected");
                message.set_useless_path_permanent_args = $root.object_msg.AIApiRequest.set_useless_path_permanent.fromObject(object.set_useless_path_permanent_args);
            }
            if (object.delete_pending_pages_with_useless_path_args != null) {
                if (typeof object.delete_pending_pages_with_useless_path_args !== "object")
                    throw TypeError(".object_msg.AIApiRequest.delete_pending_pages_with_useless_path_args: object expected");
                message.delete_pending_pages_with_useless_path_args = $root.object_msg.AIApiRequest.delete_pending_pages_with_useless_path.fromObject(object.delete_pending_pages_with_useless_path_args);
            }
            if (object.discard_useless_path_args != null) {
                if (typeof object.discard_useless_path_args !== "object")
                    throw TypeError(".object_msg.AIApiRequest.discard_useless_path_args: object expected");
                message.discard_useless_path_args = $root.object_msg.AIApiRequest.discard_useless_path.fromObject(object.discard_useless_path_args);
            }
            if (object.delete_pending_page_args != null) {
                if (typeof object.delete_pending_page_args !== "object")
                    throw TypeError(".object_msg.AIApiRequest.delete_pending_page_args: object expected");
                message.delete_pending_page_args = $root.object_msg.AIApiRequest.delete_pending_page.fromObject(object.delete_pending_page_args);
            }
            if (object.start_scrapping_websites_from_map_args != null) {
                if (typeof object.start_scrapping_websites_from_map_args !== "object")
                    throw TypeError(".object_msg.AIApiRequest.start_scrapping_websites_from_map_args: object expected");
                message.start_scrapping_websites_from_map_args = $root.object_msg.AIApiRequest.start_scrapping_websites_from_map.fromObject(object.start_scrapping_websites_from_map_args);
            }
            if (object.open_websites_in_selenium_args != null) {
                if (typeof object.open_websites_in_selenium_args !== "object")
                    throw TypeError(".object_msg.AIApiRequest.open_websites_in_selenium_args: object expected");
                message.open_websites_in_selenium_args = $root.object_msg.AIApiRequest.open_websites_in_selenium.fromObject(object.open_websites_in_selenium_args);
            }
            if (object.delete_price_path_args != null) {
                if (typeof object.delete_price_path_args !== "object")
                    throw TypeError(".object_msg.AIApiRequest.delete_price_path_args: object expected");
                message.delete_price_path_args = $root.object_msg.AIApiRequest.delete_price_path.fromObject(object.delete_price_path_args);
            }
            if (object.remove_place_coordinates_and_address_quality_args != null) {
                if (typeof object.remove_place_coordinates_and_address_quality_args !== "object")
                    throw TypeError(".object_msg.AIApiRequest.remove_place_coordinates_and_address_quality_args: object expected");
                message.remove_place_coordinates_and_address_quality_args = $root.object_msg.AIApiRequest.remove_place_coordinates_and_address_quality.fromObject(object.remove_place_coordinates_and_address_quality_args);
            }
            if (object.set_place_coordinates_args != null) {
                if (typeof object.set_place_coordinates_args !== "object")
                    throw TypeError(".object_msg.AIApiRequest.set_place_coordinates_args: object expected");
                message.set_place_coordinates_args = $root.object_msg.AIApiRequest.set_place_coordinates.fromObject(object.set_place_coordinates_args);
            }
            return message;
        };

        AIApiRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.start_crawl_args != null && message.hasOwnProperty("start_crawl_args")) {
                object.start_crawl_args = $root.object_msg.AIApiRequest.start_crawl.toObject(message.start_crawl_args, options);
                if (options.oneofs)
                    object.args = "start_crawl_args";
            }
            if (message.start_crawl_global_args != null && message.hasOwnProperty("start_crawl_global_args")) {
                object.start_crawl_global_args = $root.object_msg.AIApiRequest.start_crawl_global.toObject(message.start_crawl_global_args, options);
                if (options.oneofs)
                    object.args = "start_crawl_global_args";
            }
            if (message.start_crawl_global_sequential_args != null && message.hasOwnProperty("start_crawl_global_sequential_args")) {
                object.start_crawl_global_sequential_args = $root.object_msg.AIApiRequest.start_crawl_global_sequential.toObject(message.start_crawl_global_sequential_args, options);
                if (options.oneofs)
                    object.args = "start_crawl_global_sequential_args";
            }
            if (message.set_not_crawled_global_args != null && message.hasOwnProperty("set_not_crawled_global_args")) {
                object.set_not_crawled_global_args = $root.object_msg.AIApiRequest.set_not_crawled_global.toObject(message.set_not_crawled_global_args, options);
                if (options.oneofs)
                    object.args = "set_not_crawled_global_args";
            }
            if (message.delete_crawl_global_args != null && message.hasOwnProperty("delete_crawl_global_args")) {
                object.delete_crawl_global_args = $root.object_msg.AIApiRequest.delete_crawl_global.toObject(message.delete_crawl_global_args, options);
                if (options.oneofs)
                    object.args = "delete_crawl_global_args";
            }
            if (message.delete_crawl_args != null && message.hasOwnProperty("delete_crawl_args")) {
                object.delete_crawl_args = $root.object_msg.AIApiRequest.delete_crawl.toObject(message.delete_crawl_args, options);
                if (options.oneofs)
                    object.args = "delete_crawl_args";
            }
            if (message.select_website_args != null && message.hasOwnProperty("select_website_args")) {
                object.select_website_args = $root.object_msg.AIApiRequest.select_website.toObject(message.select_website_args, options);
                if (options.oneofs)
                    object.args = "select_website_args";
            }
            if (message.set_postpone_website_args != null && message.hasOwnProperty("set_postpone_website_args")) {
                object.set_postpone_website_args = $root.object_msg.AIApiRequest.set_postpone_website.toObject(message.set_postpone_website_args, options);
                if (options.oneofs)
                    object.args = "set_postpone_website_args";
            }
            if (message.replace_url_in_case_of_redirect_args != null && message.hasOwnProperty("replace_url_in_case_of_redirect_args")) {
                object.replace_url_in_case_of_redirect_args = $root.object_msg.AIApiRequest.replace_url_in_case_of_redirect.toObject(message.replace_url_in_case_of_redirect_args, options);
                if (options.oneofs)
                    object.args = "replace_url_in_case_of_redirect_args";
            }
            if (message.collect_links_from_website_args != null && message.hasOwnProperty("collect_links_from_website_args")) {
                object.collect_links_from_website_args = $root.object_msg.AIApiRequest.collect_links_from_website.toObject(message.collect_links_from_website_args, options);
                if (options.oneofs)
                    object.args = "collect_links_from_website_args";
            }
            if (message.add_permanent_useless_path_args != null && message.hasOwnProperty("add_permanent_useless_path_args")) {
                object.add_permanent_useless_path_args = $root.object_msg.AIApiRequest.add_permanent_useless_path.toObject(message.add_permanent_useless_path_args, options);
                if (options.oneofs)
                    object.args = "add_permanent_useless_path_args";
            }
            if (message.set_useless_path_permanent_args != null && message.hasOwnProperty("set_useless_path_permanent_args")) {
                object.set_useless_path_permanent_args = $root.object_msg.AIApiRequest.set_useless_path_permanent.toObject(message.set_useless_path_permanent_args, options);
                if (options.oneofs)
                    object.args = "set_useless_path_permanent_args";
            }
            if (message.delete_pending_pages_with_useless_path_args != null && message.hasOwnProperty("delete_pending_pages_with_useless_path_args")) {
                object.delete_pending_pages_with_useless_path_args = $root.object_msg.AIApiRequest.delete_pending_pages_with_useless_path.toObject(message.delete_pending_pages_with_useless_path_args, options);
                if (options.oneofs)
                    object.args = "delete_pending_pages_with_useless_path_args";
            }
            if (message.discard_useless_path_args != null && message.hasOwnProperty("discard_useless_path_args")) {
                object.discard_useless_path_args = $root.object_msg.AIApiRequest.discard_useless_path.toObject(message.discard_useless_path_args, options);
                if (options.oneofs)
                    object.args = "discard_useless_path_args";
            }
            if (message.delete_pending_page_args != null && message.hasOwnProperty("delete_pending_page_args")) {
                object.delete_pending_page_args = $root.object_msg.AIApiRequest.delete_pending_page.toObject(message.delete_pending_page_args, options);
                if (options.oneofs)
                    object.args = "delete_pending_page_args";
            }
            if (message.start_scrapping_websites_from_map_args != null && message.hasOwnProperty("start_scrapping_websites_from_map_args")) {
                object.start_scrapping_websites_from_map_args = $root.object_msg.AIApiRequest.start_scrapping_websites_from_map.toObject(message.start_scrapping_websites_from_map_args, options);
                if (options.oneofs)
                    object.args = "start_scrapping_websites_from_map_args";
            }
            if (message.open_websites_in_selenium_args != null && message.hasOwnProperty("open_websites_in_selenium_args")) {
                object.open_websites_in_selenium_args = $root.object_msg.AIApiRequest.open_websites_in_selenium.toObject(message.open_websites_in_selenium_args, options);
                if (options.oneofs)
                    object.args = "open_websites_in_selenium_args";
            }
            if (message.delete_price_path_args != null && message.hasOwnProperty("delete_price_path_args")) {
                object.delete_price_path_args = $root.object_msg.AIApiRequest.delete_price_path.toObject(message.delete_price_path_args, options);
                if (options.oneofs)
                    object.args = "delete_price_path_args";
            }
            if (message.remove_place_coordinates_and_address_quality_args != null && message.hasOwnProperty("remove_place_coordinates_and_address_quality_args")) {
                object.remove_place_coordinates_and_address_quality_args = $root.object_msg.AIApiRequest.remove_place_coordinates_and_address_quality.toObject(message.remove_place_coordinates_and_address_quality_args, options);
                if (options.oneofs)
                    object.args = "remove_place_coordinates_and_address_quality_args";
            }
            if (message.set_place_coordinates_args != null && message.hasOwnProperty("set_place_coordinates_args")) {
                object.set_place_coordinates_args = $root.object_msg.AIApiRequest.set_place_coordinates.toObject(message.set_place_coordinates_args, options);
                if (options.oneofs)
                    object.args = "set_place_coordinates_args";
            }
            return object;
        };

        AIApiRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        AIApiRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.AIApiRequest";
        };

        AIApiRequest.Method = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "unknownMethod"] = 0;
            values[valuesById[1] = "startCrawl"] = 1;
            values[valuesById[2] = "startCrawlGlobal"] = 2;
            values[valuesById[3] = "startCrawlGlobalSequential"] = 3;
            values[valuesById[4] = "setNotCrawledGlobal"] = 4;
            values[valuesById[5] = "deleteCrawlGlobal"] = 5;
            values[valuesById[6] = "approveCrawlGlobal"] = 6;
            values[valuesById[7] = "deleteCrawl"] = 7;
            values[valuesById[8] = "selectWebsite"] = 8;
            values[valuesById[9] = "setPostponeWebsite"] = 9;
            values[valuesById[10] = "replaceUrlInCaseOfRedirect"] = 10;
            values[valuesById[11] = "collectLinksFromWebsite"] = 11;
            values[valuesById[12] = "collectLinksFromWebsites"] = 12;
            values[valuesById[13] = "collectPhonesEmailsFromWebsites"] = 13;
            values[valuesById[14] = "recompressPengingPages"] = 14;
            values[valuesById[15] = "deletePendingPagesByPermanentUselessPaths"] = 15;
            values[valuesById[16] = "addPermanentUselessPath"] = 16;
            values[valuesById[17] = "filterPendingPagesUsability"] = 17;
            values[valuesById[18] = "setUselessPathPermanent"] = 18;
            values[valuesById[19] = "deletePendingPagesWithUselessPath"] = 19;
            values[valuesById[20] = "discardUselessPath"] = 20;
            values[valuesById[21] = "deletePendingPage"] = 21;
            values[valuesById[22] = "startScrappingWebsitesFromMap"] = 22;
            values[valuesById[23] = "completeScrappingWebsitesFromMap"] = 23;
            values[valuesById[24] = "startScrappingCoordinatesFromMap"] = 24;
            values[valuesById[25] = "completeScrappingCoordinatesFromMap"] = 25;
            values[valuesById[26] = "openWebsitesInSelenium"] = 26;
            values[valuesById[27] = "deletePricePath"] = 27;
            values[valuesById[28] = "collectPricePathsFromCollectedInfoJson"] = 28;
            values[valuesById[29] = "getPossibleDuplicatedPlaces"] = 29;
            values[valuesById[30] = "removePlaceCoordinatesAndAddressQuality"] = 30;
            values[valuesById[31] = "setPlaceCoordinates"] = 31;
            return values;
        })();

        AIApiRequest.start_crawl = (function() {

            function start_crawl(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            start_crawl.prototype.url = "";
            start_crawl.prototype.info_type = 0;

            start_crawl.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                    writer.uint32(10).string(message.url);
                if (message.info_type != null && Object.hasOwnProperty.call(message, "info_type"))
                    writer.uint32(16).int32(message.info_type);
                return writer;
            };

            start_crawl.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.AIApiRequest.start_crawl();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.url = reader.string();
                            break;
                        }
                    case 2: {
                            message.info_type = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            start_crawl.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.AIApiRequest.start_crawl)
                    return object;
                let message = new $root.object_msg.AIApiRequest.start_crawl();
                if (object.url != null)
                    message.url = String(object.url);
                if (object.info_type != null)
                    message.info_type = object.info_type | 0;
                return message;
            };

            start_crawl.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.url = "";
                    object.info_type = 0;
                }
                if (message.url != null && message.hasOwnProperty("url"))
                    object.url = message.url;
                if (message.info_type != null && message.hasOwnProperty("info_type"))
                    object.info_type = message.info_type;
                return object;
            };

            start_crawl.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            start_crawl.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.AIApiRequest.start_crawl";
            };

            return start_crawl;
        })();

        AIApiRequest.start_crawl_global = (function() {

            function start_crawl_global(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            start_crawl_global.prototype.info_type = 0;
            start_crawl_global.prototype.pages_count = 0;

            start_crawl_global.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.info_type != null && Object.hasOwnProperty.call(message, "info_type"))
                    writer.uint32(8).int32(message.info_type);
                if (message.pages_count != null && Object.hasOwnProperty.call(message, "pages_count"))
                    writer.uint32(16).int32(message.pages_count);
                return writer;
            };

            start_crawl_global.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.AIApiRequest.start_crawl_global();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.info_type = reader.int32();
                            break;
                        }
                    case 2: {
                            message.pages_count = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            start_crawl_global.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.AIApiRequest.start_crawl_global)
                    return object;
                let message = new $root.object_msg.AIApiRequest.start_crawl_global();
                if (object.info_type != null)
                    message.info_type = object.info_type | 0;
                if (object.pages_count != null)
                    message.pages_count = object.pages_count | 0;
                return message;
            };

            start_crawl_global.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.info_type = 0;
                    object.pages_count = 0;
                }
                if (message.info_type != null && message.hasOwnProperty("info_type"))
                    object.info_type = message.info_type;
                if (message.pages_count != null && message.hasOwnProperty("pages_count"))
                    object.pages_count = message.pages_count;
                return object;
            };

            start_crawl_global.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            start_crawl_global.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.AIApiRequest.start_crawl_global";
            };

            return start_crawl_global;
        })();

        AIApiRequest.start_crawl_global_sequential = (function() {

            function start_crawl_global_sequential(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            start_crawl_global_sequential.prototype.info_type = 0;
            start_crawl_global_sequential.prototype.pages_count = 0;

            start_crawl_global_sequential.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.info_type != null && Object.hasOwnProperty.call(message, "info_type"))
                    writer.uint32(8).int32(message.info_type);
                if (message.pages_count != null && Object.hasOwnProperty.call(message, "pages_count"))
                    writer.uint32(16).int32(message.pages_count);
                return writer;
            };

            start_crawl_global_sequential.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.AIApiRequest.start_crawl_global_sequential();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.info_type = reader.int32();
                            break;
                        }
                    case 2: {
                            message.pages_count = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            start_crawl_global_sequential.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.AIApiRequest.start_crawl_global_sequential)
                    return object;
                let message = new $root.object_msg.AIApiRequest.start_crawl_global_sequential();
                if (object.info_type != null)
                    message.info_type = object.info_type | 0;
                if (object.pages_count != null)
                    message.pages_count = object.pages_count | 0;
                return message;
            };

            start_crawl_global_sequential.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.info_type = 0;
                    object.pages_count = 0;
                }
                if (message.info_type != null && message.hasOwnProperty("info_type"))
                    object.info_type = message.info_type;
                if (message.pages_count != null && message.hasOwnProperty("pages_count"))
                    object.pages_count = message.pages_count;
                return object;
            };

            start_crawl_global_sequential.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            start_crawl_global_sequential.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.AIApiRequest.start_crawl_global_sequential";
            };

            return start_crawl_global_sequential;
        })();

        AIApiRequest.set_not_crawled_global = (function() {

            function set_not_crawled_global(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            set_not_crawled_global.prototype.info_type = 0;

            set_not_crawled_global.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.info_type != null && Object.hasOwnProperty.call(message, "info_type"))
                    writer.uint32(8).int32(message.info_type);
                return writer;
            };

            set_not_crawled_global.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.AIApiRequest.set_not_crawled_global();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.info_type = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            set_not_crawled_global.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.AIApiRequest.set_not_crawled_global)
                    return object;
                let message = new $root.object_msg.AIApiRequest.set_not_crawled_global();
                if (object.info_type != null)
                    message.info_type = object.info_type | 0;
                return message;
            };

            set_not_crawled_global.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.info_type = 0;
                if (message.info_type != null && message.hasOwnProperty("info_type"))
                    object.info_type = message.info_type;
                return object;
            };

            set_not_crawled_global.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            set_not_crawled_global.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.AIApiRequest.set_not_crawled_global";
            };

            return set_not_crawled_global;
        })();

        AIApiRequest.delete_crawl_global = (function() {

            function delete_crawl_global(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            delete_crawl_global.prototype.info_type = 0;

            delete_crawl_global.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.info_type != null && Object.hasOwnProperty.call(message, "info_type"))
                    writer.uint32(8).int32(message.info_type);
                return writer;
            };

            delete_crawl_global.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.AIApiRequest.delete_crawl_global();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.info_type = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            delete_crawl_global.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.AIApiRequest.delete_crawl_global)
                    return object;
                let message = new $root.object_msg.AIApiRequest.delete_crawl_global();
                if (object.info_type != null)
                    message.info_type = object.info_type | 0;
                return message;
            };

            delete_crawl_global.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.info_type = 0;
                if (message.info_type != null && message.hasOwnProperty("info_type"))
                    object.info_type = message.info_type;
                return object;
            };

            delete_crawl_global.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            delete_crawl_global.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.AIApiRequest.delete_crawl_global";
            };

            return delete_crawl_global;
        })();

        AIApiRequest.delete_crawl = (function() {

            function delete_crawl(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            delete_crawl.prototype.url = "";
            delete_crawl.prototype.info_type = 0;

            delete_crawl.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                    writer.uint32(10).string(message.url);
                if (message.info_type != null && Object.hasOwnProperty.call(message, "info_type"))
                    writer.uint32(16).int32(message.info_type);
                return writer;
            };

            delete_crawl.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.AIApiRequest.delete_crawl();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.url = reader.string();
                            break;
                        }
                    case 2: {
                            message.info_type = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            delete_crawl.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.AIApiRequest.delete_crawl)
                    return object;
                let message = new $root.object_msg.AIApiRequest.delete_crawl();
                if (object.url != null)
                    message.url = String(object.url);
                if (object.info_type != null)
                    message.info_type = object.info_type | 0;
                return message;
            };

            delete_crawl.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.url = "";
                    object.info_type = 0;
                }
                if (message.url != null && message.hasOwnProperty("url"))
                    object.url = message.url;
                if (message.info_type != null && message.hasOwnProperty("info_type"))
                    object.info_type = message.info_type;
                return object;
            };

            delete_crawl.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            delete_crawl.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.AIApiRequest.delete_crawl";
            };

            return delete_crawl;
        })();

        AIApiRequest.select_website = (function() {

            function select_website(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            select_website.prototype.url = "";

            select_website.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                    writer.uint32(10).string(message.url);
                return writer;
            };

            select_website.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.AIApiRequest.select_website();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.url = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            select_website.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.AIApiRequest.select_website)
                    return object;
                let message = new $root.object_msg.AIApiRequest.select_website();
                if (object.url != null)
                    message.url = String(object.url);
                return message;
            };

            select_website.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.url = "";
                if (message.url != null && message.hasOwnProperty("url"))
                    object.url = message.url;
                return object;
            };

            select_website.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            select_website.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.AIApiRequest.select_website";
            };

            return select_website;
        })();

        AIApiRequest.set_postpone_website = (function() {

            function set_postpone_website(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            set_postpone_website.prototype.url = "";
            set_postpone_website.prototype.postpone = false;

            set_postpone_website.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                    writer.uint32(10).string(message.url);
                if (message.postpone != null && Object.hasOwnProperty.call(message, "postpone"))
                    writer.uint32(16).bool(message.postpone);
                return writer;
            };

            set_postpone_website.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.AIApiRequest.set_postpone_website();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.url = reader.string();
                            break;
                        }
                    case 2: {
                            message.postpone = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            set_postpone_website.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.AIApiRequest.set_postpone_website)
                    return object;
                let message = new $root.object_msg.AIApiRequest.set_postpone_website();
                if (object.url != null)
                    message.url = String(object.url);
                if (object.postpone != null)
                    message.postpone = Boolean(object.postpone);
                return message;
            };

            set_postpone_website.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.url = "";
                    object.postpone = false;
                }
                if (message.url != null && message.hasOwnProperty("url"))
                    object.url = message.url;
                if (message.postpone != null && message.hasOwnProperty("postpone"))
                    object.postpone = message.postpone;
                return object;
            };

            set_postpone_website.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            set_postpone_website.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.AIApiRequest.set_postpone_website";
            };

            return set_postpone_website;
        })();

        AIApiRequest.replace_url_in_case_of_redirect = (function() {

            function replace_url_in_case_of_redirect(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            replace_url_in_case_of_redirect.prototype.src_url = "";
            replace_url_in_case_of_redirect.prototype.dest_url = "";

            replace_url_in_case_of_redirect.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.src_url != null && Object.hasOwnProperty.call(message, "src_url"))
                    writer.uint32(10).string(message.src_url);
                if (message.dest_url != null && Object.hasOwnProperty.call(message, "dest_url"))
                    writer.uint32(18).string(message.dest_url);
                return writer;
            };

            replace_url_in_case_of_redirect.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.AIApiRequest.replace_url_in_case_of_redirect();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.src_url = reader.string();
                            break;
                        }
                    case 2: {
                            message.dest_url = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            replace_url_in_case_of_redirect.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.AIApiRequest.replace_url_in_case_of_redirect)
                    return object;
                let message = new $root.object_msg.AIApiRequest.replace_url_in_case_of_redirect();
                if (object.src_url != null)
                    message.src_url = String(object.src_url);
                if (object.dest_url != null)
                    message.dest_url = String(object.dest_url);
                return message;
            };

            replace_url_in_case_of_redirect.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.src_url = "";
                    object.dest_url = "";
                }
                if (message.src_url != null && message.hasOwnProperty("src_url"))
                    object.src_url = message.src_url;
                if (message.dest_url != null && message.hasOwnProperty("dest_url"))
                    object.dest_url = message.dest_url;
                return object;
            };

            replace_url_in_case_of_redirect.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            replace_url_in_case_of_redirect.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.AIApiRequest.replace_url_in_case_of_redirect";
            };

            return replace_url_in_case_of_redirect;
        })();

        AIApiRequest.collect_links_from_website = (function() {

            function collect_links_from_website(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            collect_links_from_website.prototype.url = "";
            collect_links_from_website.prototype.recursive = false;

            collect_links_from_website.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                    writer.uint32(10).string(message.url);
                if (message.recursive != null && Object.hasOwnProperty.call(message, "recursive"))
                    writer.uint32(16).bool(message.recursive);
                return writer;
            };

            collect_links_from_website.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.AIApiRequest.collect_links_from_website();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.url = reader.string();
                            break;
                        }
                    case 2: {
                            message.recursive = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            collect_links_from_website.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.AIApiRequest.collect_links_from_website)
                    return object;
                let message = new $root.object_msg.AIApiRequest.collect_links_from_website();
                if (object.url != null)
                    message.url = String(object.url);
                if (object.recursive != null)
                    message.recursive = Boolean(object.recursive);
                return message;
            };

            collect_links_from_website.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.url = "";
                    object.recursive = false;
                }
                if (message.url != null && message.hasOwnProperty("url"))
                    object.url = message.url;
                if (message.recursive != null && message.hasOwnProperty("recursive"))
                    object.recursive = message.recursive;
                return object;
            };

            collect_links_from_website.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            collect_links_from_website.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.AIApiRequest.collect_links_from_website";
            };

            return collect_links_from_website;
        })();

        AIApiRequest.add_permanent_useless_path = (function() {

            function add_permanent_useless_path(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            add_permanent_useless_path.prototype.path = "";

            add_permanent_useless_path.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                    writer.uint32(10).string(message.path);
                return writer;
            };

            add_permanent_useless_path.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.AIApiRequest.add_permanent_useless_path();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.path = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            add_permanent_useless_path.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.AIApiRequest.add_permanent_useless_path)
                    return object;
                let message = new $root.object_msg.AIApiRequest.add_permanent_useless_path();
                if (object.path != null)
                    message.path = String(object.path);
                return message;
            };

            add_permanent_useless_path.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.path = "";
                if (message.path != null && message.hasOwnProperty("path"))
                    object.path = message.path;
                return object;
            };

            add_permanent_useless_path.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            add_permanent_useless_path.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.AIApiRequest.add_permanent_useless_path";
            };

            return add_permanent_useless_path;
        })();

        AIApiRequest.set_useless_path_permanent = (function() {

            function set_useless_path_permanent(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            set_useless_path_permanent.prototype.path = "";

            set_useless_path_permanent.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                    writer.uint32(10).string(message.path);
                return writer;
            };

            set_useless_path_permanent.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.AIApiRequest.set_useless_path_permanent();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.path = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            set_useless_path_permanent.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.AIApiRequest.set_useless_path_permanent)
                    return object;
                let message = new $root.object_msg.AIApiRequest.set_useless_path_permanent();
                if (object.path != null)
                    message.path = String(object.path);
                return message;
            };

            set_useless_path_permanent.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.path = "";
                if (message.path != null && message.hasOwnProperty("path"))
                    object.path = message.path;
                return object;
            };

            set_useless_path_permanent.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            set_useless_path_permanent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.AIApiRequest.set_useless_path_permanent";
            };

            return set_useless_path_permanent;
        })();

        AIApiRequest.delete_pending_pages_with_useless_path = (function() {

            function delete_pending_pages_with_useless_path(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            delete_pending_pages_with_useless_path.prototype.path = "";

            delete_pending_pages_with_useless_path.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                    writer.uint32(10).string(message.path);
                return writer;
            };

            delete_pending_pages_with_useless_path.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.AIApiRequest.delete_pending_pages_with_useless_path();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.path = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            delete_pending_pages_with_useless_path.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.AIApiRequest.delete_pending_pages_with_useless_path)
                    return object;
                let message = new $root.object_msg.AIApiRequest.delete_pending_pages_with_useless_path();
                if (object.path != null)
                    message.path = String(object.path);
                return message;
            };

            delete_pending_pages_with_useless_path.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.path = "";
                if (message.path != null && message.hasOwnProperty("path"))
                    object.path = message.path;
                return object;
            };

            delete_pending_pages_with_useless_path.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            delete_pending_pages_with_useless_path.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.AIApiRequest.delete_pending_pages_with_useless_path";
            };

            return delete_pending_pages_with_useless_path;
        })();

        AIApiRequest.discard_useless_path = (function() {

            function discard_useless_path(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            discard_useless_path.prototype.path = "";

            discard_useless_path.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                    writer.uint32(10).string(message.path);
                return writer;
            };

            discard_useless_path.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.AIApiRequest.discard_useless_path();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.path = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            discard_useless_path.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.AIApiRequest.discard_useless_path)
                    return object;
                let message = new $root.object_msg.AIApiRequest.discard_useless_path();
                if (object.path != null)
                    message.path = String(object.path);
                return message;
            };

            discard_useless_path.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.path = "";
                if (message.path != null && message.hasOwnProperty("path"))
                    object.path = message.path;
                return object;
            };

            discard_useless_path.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            discard_useless_path.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.AIApiRequest.discard_useless_path";
            };

            return discard_useless_path;
        })();

        AIApiRequest.delete_pending_page = (function() {

            function delete_pending_page(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            delete_pending_page.prototype.main_url = "";
            delete_pending_page.prototype.pending_url = "";

            delete_pending_page.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.main_url != null && Object.hasOwnProperty.call(message, "main_url"))
                    writer.uint32(10).string(message.main_url);
                if (message.pending_url != null && Object.hasOwnProperty.call(message, "pending_url"))
                    writer.uint32(18).string(message.pending_url);
                return writer;
            };

            delete_pending_page.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.AIApiRequest.delete_pending_page();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.main_url = reader.string();
                            break;
                        }
                    case 2: {
                            message.pending_url = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            delete_pending_page.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.AIApiRequest.delete_pending_page)
                    return object;
                let message = new $root.object_msg.AIApiRequest.delete_pending_page();
                if (object.main_url != null)
                    message.main_url = String(object.main_url);
                if (object.pending_url != null)
                    message.pending_url = String(object.pending_url);
                return message;
            };

            delete_pending_page.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.main_url = "";
                    object.pending_url = "";
                }
                if (message.main_url != null && message.hasOwnProperty("main_url"))
                    object.main_url = message.main_url;
                if (message.pending_url != null && message.hasOwnProperty("pending_url"))
                    object.pending_url = message.pending_url;
                return object;
            };

            delete_pending_page.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            delete_pending_page.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.AIApiRequest.delete_pending_page";
            };

            return delete_pending_page;
        })();

        AIApiRequest.start_scrapping_websites_from_map = (function() {

            function start_scrapping_websites_from_map(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            start_scrapping_websites_from_map.prototype.action = "";
            start_scrapping_websites_from_map.prototype.pages_per_run = 0;

            start_scrapping_websites_from_map.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                    writer.uint32(10).string(message.action);
                if (message.pages_per_run != null && Object.hasOwnProperty.call(message, "pages_per_run"))
                    writer.uint32(16).int32(message.pages_per_run);
                return writer;
            };

            start_scrapping_websites_from_map.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.AIApiRequest.start_scrapping_websites_from_map();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.action = reader.string();
                            break;
                        }
                    case 2: {
                            message.pages_per_run = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            start_scrapping_websites_from_map.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.AIApiRequest.start_scrapping_websites_from_map)
                    return object;
                let message = new $root.object_msg.AIApiRequest.start_scrapping_websites_from_map();
                if (object.action != null)
                    message.action = String(object.action);
                if (object.pages_per_run != null)
                    message.pages_per_run = object.pages_per_run | 0;
                return message;
            };

            start_scrapping_websites_from_map.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.action = "";
                    object.pages_per_run = 0;
                }
                if (message.action != null && message.hasOwnProperty("action"))
                    object.action = message.action;
                if (message.pages_per_run != null && message.hasOwnProperty("pages_per_run"))
                    object.pages_per_run = message.pages_per_run;
                return object;
            };

            start_scrapping_websites_from_map.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            start_scrapping_websites_from_map.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.AIApiRequest.start_scrapping_websites_from_map";
            };

            return start_scrapping_websites_from_map;
        })();

        AIApiRequest.open_websites_in_selenium = (function() {

            function open_websites_in_selenium(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            open_websites_in_selenium.prototype.websites = "";

            open_websites_in_selenium.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.websites != null && Object.hasOwnProperty.call(message, "websites"))
                    writer.uint32(10).string(message.websites);
                return writer;
            };

            open_websites_in_selenium.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.AIApiRequest.open_websites_in_selenium();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.websites = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            open_websites_in_selenium.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.AIApiRequest.open_websites_in_selenium)
                    return object;
                let message = new $root.object_msg.AIApiRequest.open_websites_in_selenium();
                if (object.websites != null)
                    message.websites = String(object.websites);
                return message;
            };

            open_websites_in_selenium.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.websites = "";
                if (message.websites != null && message.hasOwnProperty("websites"))
                    object.websites = message.websites;
                return object;
            };

            open_websites_in_selenium.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            open_websites_in_selenium.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.AIApiRequest.open_websites_in_selenium";
            };

            return open_websites_in_selenium;
        })();

        AIApiRequest.delete_price_path = (function() {

            function delete_price_path(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            delete_price_path.prototype.path = "";

            delete_price_path.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                    writer.uint32(10).string(message.path);
                return writer;
            };

            delete_price_path.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.AIApiRequest.delete_price_path();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.path = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            delete_price_path.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.AIApiRequest.delete_price_path)
                    return object;
                let message = new $root.object_msg.AIApiRequest.delete_price_path();
                if (object.path != null)
                    message.path = String(object.path);
                return message;
            };

            delete_price_path.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.path = "";
                if (message.path != null && message.hasOwnProperty("path"))
                    object.path = message.path;
                return object;
            };

            delete_price_path.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            delete_price_path.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.AIApiRequest.delete_price_path";
            };

            return delete_price_path;
        })();

        AIApiRequest.remove_place_coordinates_and_address_quality = (function() {

            function remove_place_coordinates_and_address_quality(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            remove_place_coordinates_and_address_quality.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            remove_place_coordinates_and_address_quality.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(8).uint64(message.id);
                return writer;
            };

            remove_place_coordinates_and_address_quality.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.AIApiRequest.remove_place_coordinates_and_address_quality();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            remove_place_coordinates_and_address_quality.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.AIApiRequest.remove_place_coordinates_and_address_quality)
                    return object;
                let message = new $root.object_msg.AIApiRequest.remove_place_coordinates_and_address_quality();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                return message;
            };

            remove_place_coordinates_and_address_quality.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                return object;
            };

            remove_place_coordinates_and_address_quality.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            remove_place_coordinates_and_address_quality.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.AIApiRequest.remove_place_coordinates_and_address_quality";
            };

            return remove_place_coordinates_and_address_quality;
        })();

        AIApiRequest.set_place_coordinates = (function() {

            function set_place_coordinates(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            set_place_coordinates.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            set_place_coordinates.prototype.address = "";
            set_place_coordinates.prototype.latitude = 0;
            set_place_coordinates.prototype.longitude = 0;
            set_place_coordinates.prototype.town = "";

            set_place_coordinates.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(8).uint64(message.id);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(18).string(message.address);
                if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                    writer.uint32(29).float(message.latitude);
                if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                    writer.uint32(37).float(message.longitude);
                if (message.town != null && Object.hasOwnProperty.call(message, "town"))
                    writer.uint32(42).string(message.town);
                return writer;
            };

            set_place_coordinates.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.AIApiRequest.set_place_coordinates();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.address = reader.string();
                            break;
                        }
                    case 3: {
                            message.latitude = reader.float();
                            break;
                        }
                    case 4: {
                            message.longitude = reader.float();
                            break;
                        }
                    case 5: {
                            message.town = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            set_place_coordinates.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.AIApiRequest.set_place_coordinates)
                    return object;
                let message = new $root.object_msg.AIApiRequest.set_place_coordinates();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.address != null)
                    message.address = String(object.address);
                if (object.latitude != null)
                    message.latitude = Number(object.latitude);
                if (object.longitude != null)
                    message.longitude = Number(object.longitude);
                if (object.town != null)
                    message.town = String(object.town);
                return message;
            };

            set_place_coordinates.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.address = "";
                    object.latitude = 0;
                    object.longitude = 0;
                    object.town = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                if (message.latitude != null && message.hasOwnProperty("latitude"))
                    object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
                if (message.longitude != null && message.hasOwnProperty("longitude"))
                    object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
                if (message.town != null && message.hasOwnProperty("town"))
                    object.town = message.town;
                return object;
            };

            set_place_coordinates.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            set_place_coordinates.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.AIApiRequest.set_place_coordinates";
            };

            return set_place_coordinates;
        })();

        return AIApiRequest;
    })();

    object_msg.get_possible_duplicated_places_return_msg = (function() {

        function get_possible_duplicated_places_return_msg(properties) {
            this.places = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        get_possible_duplicated_places_return_msg.prototype.places = $util.emptyArray;

        get_possible_duplicated_places_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.places != null && message.places.length)
                for (let i = 0; i < message.places.length; ++i)
                    $root.object_msg.get_possible_duplicated_places_return_msg.Place.encode(message.places[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        get_possible_duplicated_places_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_possible_duplicated_places_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.places && message.places.length))
                            message.places = [];
                        message.places.push($root.object_msg.get_possible_duplicated_places_return_msg.Place.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        get_possible_duplicated_places_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.get_possible_duplicated_places_return_msg)
                return object;
            let message = new $root.object_msg.get_possible_duplicated_places_return_msg();
            if (object.places) {
                if (!Array.isArray(object.places))
                    throw TypeError(".object_msg.get_possible_duplicated_places_return_msg.places: array expected");
                message.places = [];
                for (let i = 0; i < object.places.length; ++i) {
                    if (typeof object.places[i] !== "object")
                        throw TypeError(".object_msg.get_possible_duplicated_places_return_msg.places: object expected");
                    message.places[i] = $root.object_msg.get_possible_duplicated_places_return_msg.Place.fromObject(object.places[i]);
                }
            }
            return message;
        };

        get_possible_duplicated_places_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.places = [];
            if (message.places && message.places.length) {
                object.places = [];
                for (let j = 0; j < message.places.length; ++j)
                    object.places[j] = $root.object_msg.get_possible_duplicated_places_return_msg.Place.toObject(message.places[j], options);
            }
            return object;
        };

        get_possible_duplicated_places_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        get_possible_duplicated_places_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.get_possible_duplicated_places_return_msg";
        };

        get_possible_duplicated_places_return_msg.Place = (function() {

            function Place(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            Place.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            Place.prototype.name = "";
            Place.prototype.address = "";
            Place.prototype.latitude = 0;
            Place.prototype.longitude = 0;

            Place.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(8).uint64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(18).string(message.name);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(26).string(message.address);
                if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                    writer.uint32(37).float(message.latitude);
                if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                    writer.uint32(45).float(message.longitude);
                return writer;
            };

            Place.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_possible_duplicated_places_return_msg.Place();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.address = reader.string();
                            break;
                        }
                    case 4: {
                            message.latitude = reader.float();
                            break;
                        }
                    case 5: {
                            message.longitude = reader.float();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            Place.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.get_possible_duplicated_places_return_msg.Place)
                    return object;
                let message = new $root.object_msg.get_possible_duplicated_places_return_msg.Place();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.address != null)
                    message.address = String(object.address);
                if (object.latitude != null)
                    message.latitude = Number(object.latitude);
                if (object.longitude != null)
                    message.longitude = Number(object.longitude);
                return message;
            };

            Place.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.address = "";
                    object.latitude = 0;
                    object.longitude = 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                if (message.latitude != null && message.hasOwnProperty("latitude"))
                    object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
                if (message.longitude != null && message.hasOwnProperty("longitude"))
                    object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
                return object;
            };

            Place.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            Place.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.get_possible_duplicated_places_return_msg.Place";
            };

            return Place;
        })();

        return get_possible_duplicated_places_return_msg;
    })();

    object_msg.WebsiteApiRequest = (function() {

        function WebsiteApiRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        WebsiteApiRequest.prototype.reload_website_action_info_args = null;
        WebsiteApiRequest.prototype.update_website_args = null;

        let $oneOfFields;

        Object.defineProperty(WebsiteApiRequest.prototype, "args", {
            get: $util.oneOfGetter($oneOfFields = ["reload_website_action_info_args", "update_website_args"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        WebsiteApiRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.reload_website_action_info_args != null && Object.hasOwnProperty.call(message, "reload_website_action_info_args"))
                $root.object_msg.WebsiteApiRequest.reload_website_action_info.encode(message.reload_website_action_info_args, writer.uint32(10).fork()).ldelim();
            if (message.update_website_args != null && Object.hasOwnProperty.call(message, "update_website_args"))
                $root.object_msg.WebsiteApiRequest.update_website.encode(message.update_website_args, writer.uint32(18).fork()).ldelim();
            return writer;
        };

        WebsiteApiRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.WebsiteApiRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.reload_website_action_info_args = $root.object_msg.WebsiteApiRequest.reload_website_action_info.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.update_website_args = $root.object_msg.WebsiteApiRequest.update_website.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        WebsiteApiRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.WebsiteApiRequest)
                return object;
            let message = new $root.object_msg.WebsiteApiRequest();
            if (object.reload_website_action_info_args != null) {
                if (typeof object.reload_website_action_info_args !== "object")
                    throw TypeError(".object_msg.WebsiteApiRequest.reload_website_action_info_args: object expected");
                message.reload_website_action_info_args = $root.object_msg.WebsiteApiRequest.reload_website_action_info.fromObject(object.reload_website_action_info_args);
            }
            if (object.update_website_args != null) {
                if (typeof object.update_website_args !== "object")
                    throw TypeError(".object_msg.WebsiteApiRequest.update_website_args: object expected");
                message.update_website_args = $root.object_msg.WebsiteApiRequest.update_website.fromObject(object.update_website_args);
            }
            return message;
        };

        WebsiteApiRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.reload_website_action_info_args != null && message.hasOwnProperty("reload_website_action_info_args")) {
                object.reload_website_action_info_args = $root.object_msg.WebsiteApiRequest.reload_website_action_info.toObject(message.reload_website_action_info_args, options);
                if (options.oneofs)
                    object.args = "reload_website_action_info_args";
            }
            if (message.update_website_args != null && message.hasOwnProperty("update_website_args")) {
                object.update_website_args = $root.object_msg.WebsiteApiRequest.update_website.toObject(message.update_website_args, options);
                if (options.oneofs)
                    object.args = "update_website_args";
            }
            return object;
        };

        WebsiteApiRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        WebsiteApiRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.WebsiteApiRequest";
        };

        WebsiteApiRequest.Method = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "unknownMethod"] = 0;
            values[valuesById[1] = "reloadWebsiteActionInfo"] = 1;
            values[valuesById[2] = "updateWebsite"] = 2;
            values[valuesById[3] = "generateMainPage"] = 3;
            values[valuesById[4] = "generateMainContacts"] = 4;
            values[valuesById[5] = "generateCityContacts"] = 5;
            values[valuesById[6] = "generateMainActions"] = 6;
            return values;
        })();

        WebsiteApiRequest.reload_website_action_info = (function() {

            function reload_website_action_info(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            reload_website_action_info.prototype.action = "";
            reload_website_action_info.prototype.auto_update = false;
            reload_website_action_info.prototype.unload_model = false;

            reload_website_action_info.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                    writer.uint32(10).string(message.action);
                if (message.auto_update != null && Object.hasOwnProperty.call(message, "auto_update"))
                    writer.uint32(16).bool(message.auto_update);
                if (message.unload_model != null && Object.hasOwnProperty.call(message, "unload_model"))
                    writer.uint32(24).bool(message.unload_model);
                return writer;
            };

            reload_website_action_info.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.WebsiteApiRequest.reload_website_action_info();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.action = reader.string();
                            break;
                        }
                    case 2: {
                            message.auto_update = reader.bool();
                            break;
                        }
                    case 3: {
                            message.unload_model = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            reload_website_action_info.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.WebsiteApiRequest.reload_website_action_info)
                    return object;
                let message = new $root.object_msg.WebsiteApiRequest.reload_website_action_info();
                if (object.action != null)
                    message.action = String(object.action);
                if (object.auto_update != null)
                    message.auto_update = Boolean(object.auto_update);
                if (object.unload_model != null)
                    message.unload_model = Boolean(object.unload_model);
                return message;
            };

            reload_website_action_info.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.action = "";
                    object.auto_update = false;
                    object.unload_model = false;
                }
                if (message.action != null && message.hasOwnProperty("action"))
                    object.action = message.action;
                if (message.auto_update != null && message.hasOwnProperty("auto_update"))
                    object.auto_update = message.auto_update;
                if (message.unload_model != null && message.hasOwnProperty("unload_model"))
                    object.unload_model = message.unload_model;
                return object;
            };

            reload_website_action_info.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            reload_website_action_info.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.WebsiteApiRequest.reload_website_action_info";
            };

            return reload_website_action_info;
        })();

        WebsiteApiRequest.update_website = (function() {

            function update_website(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            update_website.prototype.website_actions = $util.newBuffer([]);

            update_website.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.website_actions != null && Object.hasOwnProperty.call(message, "website_actions"))
                    writer.uint32(10).bytes(message.website_actions);
                return writer;
            };

            update_website.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.WebsiteApiRequest.update_website();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.website_actions = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            update_website.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.WebsiteApiRequest.update_website)
                    return object;
                let message = new $root.object_msg.WebsiteApiRequest.update_website();
                if (object.website_actions != null)
                    if (typeof object.website_actions === "string")
                        $util.base64.decode(object.website_actions, message.website_actions = $util.newBuffer($util.base64.length(object.website_actions)), 0);
                    else if (object.website_actions.length >= 0)
                        message.website_actions = object.website_actions;
                return message;
            };

            update_website.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if (options.bytes === String)
                        object.website_actions = "";
                    else {
                        object.website_actions = [];
                        if (options.bytes !== Array)
                            object.website_actions = $util.newBuffer(object.website_actions);
                    }
                if (message.website_actions != null && message.hasOwnProperty("website_actions"))
                    object.website_actions = options.bytes === String ? $util.base64.encode(message.website_actions, 0, message.website_actions.length) : options.bytes === Array ? Array.prototype.slice.call(message.website_actions) : message.website_actions;
                return object;
            };

            update_website.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            update_website.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.WebsiteApiRequest.update_website";
            };

            return update_website;
        })();

        return WebsiteApiRequest;
    })();

    return object_msg;
})();

export { $root as default };
